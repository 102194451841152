import React from "react";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { Container, Box, Paper, Typography, Divider } from "@mui/material";

export const GTLayout = ({ title, children }) => {
  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <Container maxWidth="lg" sx={{ minHeight: "100vh", paddingBlock: 8 }}>
          <Box sx={styles.content}>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexFlow: "column",
                padding: "max(32px, 3vw) max(16px, 3vw)",
                background: "#e6e6e636",
              }}
            >
              {title && (
                <Typography
                  sx={{
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 400,
                    textAlign: "center",
                    color: "#272c33",
                  }}
                  variant="h5"
                >
                  {title}
                </Typography>
              )}
              <Divider sx={{ width: "100%", height: 1, marginBlock: 3, background: "#e6e6e6" }} />
              {children}
            </Paper>
          </Box>
        </Container>
      )}
    </HomeLayout>
  );
};

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "max(32px, 2vw)",
    position: "relative",
    zIndex: 1,
  },
};
