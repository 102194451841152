import { Search } from "@mui/icons-material";
import { Box, Input } from "@mui/material";
import React from "react";

function SearchInput({ value, onChange }) {
  return (
    <Box sx={styles.box}>
      <Input
        type="text"
        placeholder="Pesquisar..."
        value={value}
        onChange={onChange}
        disableUnderline
        endAdornment={<Search sx={{ color: "gray" }} />}
        sx={styles.input}
      />
    </Box>
  );
}

export default SearchInput;

const styles = {
  box: {
    width: "min(300px, 90%)",
    boder: "none",
    "@media (min-width: 600px)": {
      marginLeft: "auto",
    },
  },
  input: {
    width: "100%",
    height: "40px",
    fontSize: 14,
    fontFamily: "Montserrat",
    background: "#E6E6E6",
    borderRadius: "8px",
    border: "none",
    padding: "0px 10px",
    "& input::placeholder": {
      color: "gray",
      opacity: 1,
    },
  },
};
