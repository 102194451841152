import React from "react";
import { Box } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({ initialValue, name, handleEditorChange, height = 500, error = false }) => {
  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: error ? "1px solid #d32f2f" : "none",
        height: "fit-content",
      }}
    >
      <Editor
        value={initialValue || ""}
        textareaName={name}
        onEditorChange={handleEditorChange}
        apiKey="iu3uxsk52x1ac9e0e4vuatjj40rivarq3ach4yl0kdchad3f"
        init={{
          height: height,
          plugins: `
            preview importcss searchreplace autolink autosave
            save directionality code visualblocks visualchars fullscreen
            image link media codesample table charmap pagebreak
            nonbreaking anchor insertdatetime advlist lists wordcount
           help charmap quickbars emoticons
          `,
          toolbar: `
            undo redo | bold italic underline strikethrough | fontselect
            fontsizeselect formatselect | alignleft aligncenter alignright
            alignjustify | outdent indent | numlist bullist | forecolor
            backcolor removeformat | pagebreak | charmap emoticons |
            fullscreen preview save print | insertfile image media template
            link anchor codesample | ltr rtl
          `,
          menubar: "file edit view insert format tools table help",
          toolbar_sticky: false,
          file_picker_callback: (callback, value, meta) => {},
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          contextmenu: "link image imagetools table",
        }}
      />
    </Box>
  );
};

export default TextEditor;
