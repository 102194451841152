import React from "react";
import { Box, Container, MenuItem, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import SetPeriodButton from "./components/SetPeriodButton";
import ActiveFilters from "./components/ActiveFilters";
import { Search } from "@mui/icons-material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Grid from "@mui/material/Grid2";

const user = JSON.parse(localStorage.getItem("GT_user"));

function Filters({
  orderBy,
  setOrderBy,
  documentSituation,
  setDocumentSituation,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  formatDateToBrazilian,
  searchField,
  handleSearch,
  handleFilters,
  fetchDataIntoState,
  documentType,
  setDocumentType,
  handleSearchPayment,
}) {
  const handleDeleteFilters = async () => {
    setOrderBy("");
    setDocumentSituation("");
    setDocumentType("");
    await setInitialDate(null);
    await setFinalDate(null);
    handleSearch("");
    fetchDataIntoState(null);
  };

  return (
    <Container maxWidth="100%" sx={styles.Container}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Tooltip title="Apagar todos os filtros" onClick={handleDeleteFilters}>
          <IconButton>
            <DeleteOutlineIcon sx={{ color: "#000000" }} fontSize="small" />
          </IconButton>
        </Tooltip>
        <ActiveFilters
          orderBy={orderBy}
          documentSituation={documentSituation}
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          formatDateToBrazilian={formatDateToBrazilian}
          setOrderBy={setOrderBy}
          setDocumentSituation={setDocumentSituation}
          documentType={documentType}
          setDocumentType={setDocumentType}
        />
      </Box>
      <Grid container spacing={1} sx={styles.gridContainer}>
        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <RButton
            type="filterSelect"
            title="Filtrar Por"
            defaultValue="dateOfIssue"
            placeholder="Selecione uma ordem"
            value={orderBy}
            onChange={(option) => setOrderBy(option.target?.value)}
          >
            <MenuItem value="dateOfIssue">Data de emissão</MenuItem>
            <MenuItem value="dateOfPayment">Data de pagamento</MenuItem>
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <SetPeriodButton
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            formatDateToBrazilian={formatDateToBrazilian}
            placeholder="Selecione um período"
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <RButton
            type="filterSelect"
            title="Situação"
            value={documentSituation}
            onChange={(option) => setDocumentSituation(option.target?.value)}
          >
            <MenuItem value="never">Não enviados em nenhum evento</MenuItem>
            <MenuItem value="isR2010">Enviados no evento R-2010</MenuItem>
            <MenuItem value="isR4020">Enviados no evento R-4020</MenuItem>
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
          <RButton
            type="filterSelect"
            title="Tipo de Documento"
            value={documentType}
            onChange={(option) => setDocumentType(option.target?.value)}
          >
            <MenuItem value={0}>NFSe - Nota fiscal de serviço</MenuItem>
            <MenuItem value={1}>NFe - Nota fiscal de produto</MenuItem>
            <MenuItem value={2}>SIAFI</MenuItem>
            <MenuItem value={3}>Planilha</MenuItem>
            <MenuItem value={4}>Recibo</MenuItem>
            {["admin", "superAdmin"].includes(user?.role) && ( //condicional até o desenv. completo da funcionalidade
              <MenuItem value={98}>Pagamento pessoa física</MenuItem>
            )}
            <MenuItem value={99}>Outros</MenuItem>
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 2.5 }}>
          <div>
            <p style={{ fontSize: 12, fontStyle: "italic" }}>Pesquisa</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="text"
                style={styles.searchField}
                placeholder="Pesquisar..."
                value={searchField}
                onChange={(e) => {
                  const value = e.target.value;
                  handleSearch(value);
                }}
              />
              <Search style={{ color: "#626262", position: "absolute", right: "2%" }} />
            </div>
          </div>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 1.5 }} sx={{ paddingBottom: "1px" }}>
          <RButton
            onClick={() => {
              if (documentType === 98) {
                handleSearchPayment(); // Filtra pagamentos
              } else {
                handleFilters(); // Filtrar NFSe
              }
            }}
          >
            APLICAR
          </RButton>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Filters;

const inputStyle = {
  background: "#DCDCDC",
  border: "none",
  borderRadius: "5px",
  padding: "0px 10px",
  height: "40px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  fontSize: 14,
  fontFamily: "Montserrat",
  outline: "none",
  boxShadow: "none",
};

const styles = {
  Container: {
    backgroundColor: "white",
    marginTop: 1,
  },
  gridContainer: {
    display: "flex",
    alignItems: "end",
    marginBottom: 3,
    marginTop: 1,
  },
  searchField: {
    ...inputStyle,
    "&:hover": {
      ...inputStyle,
    },
    "&:focus": {
      ...inputStyle,
    },
    "&:active": {
      ...inputStyle,
    },
  },
};
