import React, { useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getRetentionTable } from "~/presentation/views/Reinf/Utils/utilsReinf";
import Input from "../../../Common/Inputs";
import RButton from "../../../Common/Buttons/RButton";

function CalculateRetentions({ onClose, values, handleChangeFieldValue }) {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const [percentage, setPercentage] = useState(null);
  //Linha C as opções são diferentes
  const codeOptions = QSO === "C" ? [0.24, 1.2, 2.4, 4.8] : getRetentionTable();

  const handleCalculate = () => {
    let retentionIR = values?.amountIR;
    let retentionCSLL = values?.amountCSLL;
    let retentionCOFINS = values?.amountCOFINS;
    let retentionPIS = values?.amountPIS;
    let aliquotaIR = values?.aliquotIR;
    let aliquotaCSRF = values?.aliquotCSRF;
    const bcIR = values?.bcIR;
    const bcCSRF = values?.bcCSRF;

    if (QSO === "A" || QSO === "B") {
      retentionIR = bcIR * (percentage.IR / 100);
      retentionCSLL = bcIR * (percentage.CSLL / 100);
      retentionCOFINS = bcIR * (percentage.COFINS / 100);
      retentionPIS = bcIR * (percentage.PIS / 100);
      aliquotaIR = percentage.percentage;
    }

    if (QSO === "C") {
      retentionIR = bcIR * (percentage / 100);
      aliquotaIR = percentage;
    }

    if (QSO === "D") {
      retentionIR = bcIR * (percentage.IR / 100);
      retentionCSLL = bcCSRF * (percentage.CSLL / 100);
      retentionCOFINS = bcCSRF * (percentage.COFINS / 100);
      retentionPIS = bcCSRF * (percentage.PIS / 100);
      aliquotaCSRF = percentage.percentage;
    }

    handleChangeFieldValue({
      newItem: {
        amountIR: retentionIR,
        amountCSLL: retentionCSLL,
        amountCOFINS: retentionCOFINS,
        amountPIS: retentionPIS,
        aliquotCSRF: aliquotaCSRF,
        aliquotIR: aliquotaIR,
        amountAggregate: (aliquotaIR / 100) * values?.amountOperation,
      },
    });

    onClose();
  };

  return (
    <Grid container spacing={2} sx={styles.container}>
      {QSO === "C" ? (
        <>
          <Grid size={12}>
            <Typography sx={styles.title}>Calcular retenção</Typography>
          </Grid>
          <Grid size={12}>
            <Typography sx={styles.text}>
              A retenção do IR será calculada automaticamente de acordo com a alíquota selecionada.
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Input
              type="select"
              placeholder="Selecione a alíquota"
              title="Selecione a alíquota do IR"
              options={codeOptions}
              height={80}
              getOptionLabel={(option) => `Alíquota: ${option}%`}
              value={codeOptions?.filter((item) => item == percentage) || null}
              onChange={(option) => {
                setPercentage(option);
              }}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid size={12}>
            <Typography sx={styles.title}>Calcular retenções</Typography>
          </Grid>
          <Grid size={12}>
            <Typography sx={styles.text}>
              As retenções de IR, CSLL, PIS/Pasep e COFINS serão calculadas automaticamente de
              acordo com o código de receita selecionado.
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 8 }}>
            <Input
              type="select"
              title="Selecione o código da receita"
              placeholder="Selecione a código"
              options={codeOptions}
              height={80}
              getOptionLabel={(option) =>
                `Código: ${option?.code}: Alíquota agregada - ${option?.percentage}%`
              }
              value={codeOptions?.find((item) => item.code === percentage?.code) || null}
              onChange={(option) => {
                setPercentage(option);
              }}
            />
          </Grid>
        </>
      )}
      <Grid size={12} sx={styles.buttons}>
        <RButton sx={{ width: "250px" }} variant="outlined" onClick={onClose}>
          CANCELAR
        </RButton>
        <RButton sx={{ width: "250px" }} onClick={handleCalculate} disabled={!percentage}>
          CALCULAR
        </RButton>
      </Grid>
    </Grid>
  );
}

export default CalculateRetentions;

const styles = {
  container: {
    p: 4,
    maxWidth: "700px",
  },
  title: {
    fontSize: 25,
    fontWeight: 600,
    letterSpacing: 0,
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
  },
  text: {
    fontSize: 16,
    pt: 1,
    letterSpacing: 0,
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "justify",
    lineHeight: 2,
  },
  buttons: {
    marginTop: 2,
    display: "flex",
    alignItems: "center",
    gap: 2,
    justifyContent: "right",
  },
};
