import { Formik, Form } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { floatToReais } from "~/presentation/utils/reais-parser";
import {
  Button,
  FormWrapper,
  RadioQuestion,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { reportMapper } from "./reportMapper";

const aliquotPercentageOptions = [
  { label: "20%", value: "0.2" },
  { label: "22,5%", value: "0.225" },
];

const initialValues = {
  isApplicableService: false,
  aliquotPercentage: aliquotPercentageOptions[0].value,
};

export const InssMei = () => {
  const resultCardRef = useRef();
  const [result, setResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState("");
  const { simulatorValue, setInvalidSimulatorValue, handleCacheReport } =
    useContext(SimulatorContext);

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  const calculateRetentionValue = (values) => {
    return simulatorValue * Number(values.aliquotPercentage);
  };

  useEffect(() => {
    if (result && resultCardRef.current) {
      resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [result, resultCardRef]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }

        action.setSubmitting(true);
        setLastSubmitValues(values);

        const result = calculateRetentionValue(values);

        setResult(calculateRetentionValue(values));

        action.setSubmitting(false);

        const response = reportMapper(values, result);

        handleCacheReport(response);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <FormWrapper>
              <RadioQuestion
                text="O serviço contratado é de hidráulica, eletricidade, pintura, alvenaria, carpintaria ou de manutenção ou reparo de veículos?"
                value={values.isApplicableService.toString()}
                onChange={(value) => setFieldValue("isApplicableService", value === "true")}
              />

              {values.isApplicableService ? (
                <RadioQuestion
                  text="Alíquota da contribuição patronal:"
                  excerpt="As alíquotas da contribuição patronal incidentes sobre a base de cálculo são de 20% para as empresas em geram e equiparadas (art. 22, III, da Lei nº 8.212/91); ou 22,5% para as instituições financeiras (art. 22, § 1º, da Lei nº 8.212/91). No caso de pagamento a MEI a incidência da contribuição patronal se restringe aos serviços de hidráulica, eletricidade, pintura, alvenaria, carpintaria e de manutenção ou reparo de veículos."
                  value={values.aliquotPercentage}
                  firstLabel={aliquotPercentageOptions[0].label}
                  firstValue={aliquotPercentageOptions[0].value}
                  secondLabel={aliquotPercentageOptions[1].label}
                  secondValue={aliquotPercentageOptions[1].value}
                  onChange={(value) => setFieldValue("aliquotPercentage", value)}
                />
              ) : null}

              <Button>Ver Resultado</Button>

              {result && valuesNotChangedSinceLastSubmit(values) ? (
                values.isApplicableService ? (
                  <SimulatorResultCard
                    componentRef={resultCardRef}
                    title="Valor total da contribuição patronal:"
                    value={floatToReais(result)}
                    description="O valor da contribuição patronal corresponde ao resultado da multiplicação da alíquota da contribuição pela base de cálculo."
                  />
                ) : (
                  <SimulatorResultCard
                    componentRef={resultCardRef}
                    description="Não é hipótese de incidência de CPP."
                    noTitleOrValue
                  />
                )
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
