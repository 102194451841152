import React, { useState, useContext } from "react";
import { AccountCircleOutlined } from "@mui/icons-material";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiAuthentication } from "~/data/usecases/authentication/apiAuthentication";
import {
  Box,
  CircularProgress,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";

const ForgotPassForm = ({ currentModal, setCurrentModal }) => {
  const [values, setValues] = useState({
    email: currentModal?.content?.email,
  });
  const [loading, setLoading] = useState(false);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { email } = values;
      const apiAuth = new ApiAuthentication(makeHttpClient());

      const response = await apiAuth.recoverPassword(email);

      setFeedbackSnackbar({
        isOpen: true,
        message: response?.data
          ? response?.data
          : "Sua nova senha foi enviada para o e-mail cadastrado",
        type: "success",
      });

      redirectToLoginModal();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: error?.response?.data ? error?.response?.data : "Erro ao recuperar senha",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      email: event.target.value,
    });
  };

  const redirectToLoginModal = () =>
    setCurrentModal({ name: "login", content: { email: values.email } });

  const StyledOutlinedInput = styled(TextField)(() => ({
    "& .MuiOutlinedInput-root": {
      "input:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 1000px transparent inset",
      },
      "& fieldset": {
        borderColor: "#14213D",
      },
      "&:hover": {
        borderColor: "#14213D",
      },
      "&:focused": {
        borderColor: "#14213D",
      },
    },
  }));

  return (
    <Box>
      <Box sx={styles.form}>
        <Typography sx={{ fontFamily: "Montserrat", textAlign: "center" }}>
          Para recuperar a senha, informe-nos o seu email.
        </Typography>
        <StyledOutlinedInput
          placeholder="Email"
          name="email"
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleOutlined style={{ color: "gray" }} />
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
          value={values.email}
          autoFocus
          disabled={values.email}
        />

        <Typography sx={styles.backText} onClick={redirectToLoginModal}>
          Voltar para o login
        </Typography>

        <Box sx={formStyles.buttonContainer}>
          <ButtonCommon sx={{ backgroundColor: "#1341a1", fontWeight: 500 }} onClick={handleSubmit}>
            {loading ? <CircularProgress color="inherit" size="1.4rem" /> : "Recuperar senha"}
          </ButtonCommon>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassForm;

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 3,
    width: "100%",
  },
  backText: {
    cursor: "pointer",
    fontSize: 14,
    fontFamily: "Montserrat",
    "&: hover": {
      fontWeight: 700,
    },
  },
};
