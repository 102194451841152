import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import StatsTable from "~/presentation/views/Admin/Stats/Display/StatsTable";
import { statsTableColumns } from "~/presentation/views/Admin/Stats/Display/statsTableColumns";
import { StatsForm } from "~/presentation/views/Admin/Stats/Display/StatsForm";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import PageListHeader from "../../components/PageListHeader";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { statsFormValidationSchema } from "./statsFormValidationSchema";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const Stats = ({ statsUseCases, companyUseCases, userUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const [searchType, setSearchType] = useState("");
  const [companiesData, setCompaniesData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [statsData, setStatsData] = useState([]);

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    searchType: "",
    companyId: "",
    initialDate: new Date(new Date().getFullYear(), 0, 1),
    finalDate: new Date(),
    userId: "",
    isDetail: false,
  });

  const handleChangeValue = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    getSelectOptions();
  }, []);

  const getSelectOptions = async () => {
    openBackdrop(true, "Carregando");
    try {
      const response = await Promise.all([
        companyUseCases.getCompanies(true),
        userUseCases.getUsers(),
      ]);
      setCompaniesData(dataToSelectMapper(response[0]));
      setUsersData(dataToSelectMapper(response[1]));
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar opções.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const handleValidation = (values) => {
    statsFormValidationSchema
      .validate(values, { abortEarly: false })
      .then(async () => handleSubmit(values))
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Pesquisando");

    const submitValues = {
      companyId: values.companyId,
      searchType: values.searchType,
      userId: values.userId,
      initialDate: values.initialDate.toISOString().split("T")[0],
      finalDate: values.finalDate.toISOString().split("T")[0],
      isDetail: values.isDetail,
    };

    switch (searchType) {
      case "search":
        setStatsData(
          await statsUseCases.getStatsSearch(
            submitValues.companyId,
            submitValues.userId,
            submitValues.initialDate,
            submitValues.finalDate
          )
        );
        break;

      case "search-summary":
        setStatsData(await statsUseCases.getStatsSearchSummary(submitValues.companyId));
        break;

      case "content-access":
        setStatsData(
          await statsUseCases.getStatsContentAccess(submitValues.companyId, submitValues.isDetail)
        );
        break;

      default:
        break;
    }
    openBackdrop(false);
  };

  return (
    <>
      <PageListHeader title="Visualizações" />
      <Box>
        <Box sx={formStyles.container}>
          <StatsForm
            companiesData={companiesData}
            usersData={usersData}
            handleSubmit={handleValidation}
            setStatsData={setStatsData}
            searchType={searchType}
            setSearchType={setSearchType}
            errors={errors}
            values={values}
            onChangeValue={handleChangeValue}
          />
        </Box>
        <Box sx={formStyles.container}>
          <StatsTable
            data={statsData}
            columns={statsTableColumns[searchType]}
            searchType={searchType}
          />
        </Box>
      </Box>
    </>
  );
};

export default Stats;
