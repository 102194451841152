import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, IconButton, Switch, Tooltip } from "@mui/material";
import Input from "~/presentation/components/Common/Input";

function AnswerContainer({ answer, index, onChangeValue, onChangeAnswer, values }) {
  const isCorrectSet = values?.answers?.filter((item) => item.isCorrect);
  const isOnlyOneCorrect = isCorrectSet.length === 1;

  const handleOnChangeCorrect = (e) => {
    if (answer.isCorrect && isOnlyOneCorrect) {
      return; //se só existe uma resposta e ela é correta, não deixar desmarcar
    }
    onChangeAnswer("isCorrect", e.target.checked, index);
  };

  const handleDeleteAnswers = () => {
    let newAnswers = [...values.answers];
    newAnswers.splice(index, 1); //eliminar resposta
    if ((answer.isCorrect && isOnlyOneCorrect) || isCorrectSet.length === 0) {
      newAnswers[0].isCorrect = true; //sempre deixar uma resposta como correta
    }
    return onChangeValue("answers", newAnswers);
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={{ width: "100%" }}>
          <Input
            name={`answers[${index}].text`}
            type="text"
            title={`Resposta ${index + 1}`}
            value={answer.text}
            onChange={(e) => {
              onChangeAnswer("text", e.target.value, index);
            }}
          />
        </Box>
        <Box sx={styles.actions}>
          <Tooltip title={answer.isCorrect ? "Resposta correta" : "Resposta incorreta"}>
            <Switch
              checked={answer.isCorrect}
              onChange={handleOnChangeCorrect}
              style={{ color: "#7D7D7D" }}
            />
          </Tooltip>
          {values.answers.length > 1 && (
            <Box sx={styles.deleteBtn}>
              <IconButton onClick={handleDeleteAnswers}>
                <Tooltip title="Eliminar resposta">
                  <RemoveCircleOutlineIcon fontSize="small" color="error" />
                </Tooltip>
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default AnswerContainer;

const styles = {
  container: {
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "2px",
  },
  deleteBtn: {
    color: "#e1e1e1",
  },
};
