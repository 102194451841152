import React from "react";
import { GlobalStyles as MuiGlobalStyles } from "@mui/material";

const GlobalStyles = () => (
  <MuiGlobalStyles
    styles={{
      "*": {
        margin: 0,
        padding: 0,
        boxSizing: "border-box",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "normal",
        transition: "color 0.2s ease-in-out, background-color 0.2s ease-in-out",
      },
      html: {
        height: "100%",
        background: "#fafafa",
      },
      body: {
        height: "100%",
      },
      "#root": {
        height: "100%",
      },
      a: {
        textDecoration: "none",
      },
      input: {
        fontFamily: "Montserrat",
      },
      ".flex": {
        display: "flex",
        "&.align-end": { alignItems: "flex-end" },
        "&.center": { alignItems: "center" },
        "&.justify-end": { justifyContent: "flex-end" },
        "&.space-between": { justifyContent: "space-between" },
        "&.justify-center": { justifyContent: "center" },
        "&.column": { flexFlow: "column" },
      },
      ".text-center": {
        textAlign: "center",
      },
      ".form-grid": {
        display: "grid",
        gridGap: "12px",
        gridTemplateColumns: "repeat(4, 1fr)",
        alignContent: "flex-start",
        "@media screen and (min-width: 600px)": {
          // Column styles for larger screens
          ".col-1_5": { gridColumn: "1/5" },
          ".col-1_4": { gridColumn: "1/4" },
          ".col-1_3": { gridColumn: "1/3" },
          ".col-1_2": { gridColumn: "1/2" },
          ".col-2_5": { gridColumn: "2/5" },
          ".col-2_4": { gridColumn: "2/4" },
          ".col-2_3": { gridColumn: "2/3" },
          ".col-3_5": { gridColumn: "3/5" },
          ".col-3_4": { gridColumn: "3/4" },
          ".col-4_5": { gridColumn: "4/5" },
        },
      },
      ".mt_12": { marginTop: "12px" },
      ".mb_12": { marginBottom: "12px" },
      ".ml_12": { marginLeft: "12px" },
      ".mr_12": { marginRight: "12px" },
      ".scroll-x": {
        width: "100%",
        overflowX: "auto",
      },
      ".relative": {
        position: "relative",
      },
      ".absolute-center": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      // Change Autocomplete styles in Chrome
      "input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill": {
        border: "none",
        WebkitTextFillColor: "#38348",
        WebkitBoxShadow: "0 0 0px 1000px transparent inset",
        transition: "background-color 5000s ease-in-out 0s",
        fontSize: "unset",
      },
      // Scrollbar styles
      ".scrollbar-gray": {
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 10px 10px #ddd",
          border: "solid 11px transparent",
          borderRadius: "30px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 10px 10px #aaa",
          border: "solid 11px transparent",
          borderRadius: "30px",
        },
      },
      ".scrollbar-white": {
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 10px 10px #ddd",
          border: "solid 11px transparent",
          borderRadius: "30px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 10px 10px #aaa",
          border: "solid 11px transparent",
          borderRadius: "30px",
        },
      },
      // Global scrollbar styles
      "::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }}
  />
);

export default GlobalStyles;
