import React, { useState, useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";
import TableActions from "~/presentation/components/TableActions";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import PageListHeader from "../../components/PageListHeader";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import Table from "~/presentation/components/Table";
import CreateEdit from "../../CompanyCategory/CreateEdit/CreateEdit";
import { Box, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

const UpdateCitiesForm = ({ cityUseCases, companyUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState({ city: null, iss: 0, totalRetentions: null });

  //MODAL Lista Categorias
  const [openList, setOpenList] = useState(false);
  const handleOpenList = () => setOpenList(true);
  const handleCloseList = () => setOpenList(false);

  //MODAL Modal Criar Categoria
  const [openCreateCategory, setOpenCreateCategory] = useState(false);
  const handleOpenCreateCategory = () => setOpenCreateCategory(true);
  const handleCloseCreateCategory = () => setOpenCreateCategory(false);

  const getCities = async () => {
    openBackdrop(true, "Carregando cidades");
    const cities = await cityUseCases.getAll();
    setCities(cities);
    openBackdrop(false);
  };

  const getCategories = async () => {
    const categories = await companyUseCases.getCompanyCategories();
    const mapperCategories = dataToSelectMapper(categories);
    setCategories(mapperCategories);
  };

  const setNewValues = async (values) => {
    openBackdrop(true, "Carregando cidade");
    const { id } = values;
    const city = await cityUseCases.getCityById(id);
    setValues({ ...city, totalRetentions: city.totalRetentions ? city.totalRetentions : [] });
    openBackdrop(false);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Atualizando município");
    try {
      const submitValues = {
        ...values,
        totalRetentions: values.totalRetentions.map((item) => {
          return {
            id: item.id,
            name: item.name,
            letter: item.letter,
          };
        }),
      };
      await cityUseCases.updateCity(submitValues);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Município atualizado com sucesso",
        type: "success",
      });
      await getCities();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.data || error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleDeleteSubstitute = (id) => {
    if (values.totalRetentions.length === 1) {
      setValues((prev) => ({ ...prev, totalRetentions: [] }));
    } else {
      const filtedValue = values?.totalRetentions?.filter((item) => item.id !== id);
      setValues((prev) => ({ ...prev, totalRetentions: filtedValue }));
    }
  };

  useEffect(() => {
    getCities();
    getCategories();
  }, []);

  return (
    <>
      <PageListHeader title="Atualizar Municípios" />
      <>
        <Box sx={formStyles.container}>
          <Typography sx={formStyles.title}>Município</Typography>
          <Grid container spacing={1}>
            <Grid size={{ xs: 12, md: 8 }}>
              <Input
                type="select"
                name="city"
                title="Selecionar Município"
                value={cities.find((item) => item === values.city)}
                options={cities}
                onChange={(event) => setNewValues(event)}
                getOptionLabel={(option) => `${option.name} (${option.uf})`}
              />
            </Grid>
            {values?.id && (
              <>
                <Grid size={{ xs: 12, md: 4 }}>
                  <Input
                    name="iss"
                    type="number"
                    title="Alíquota do ISS"
                    value={values.iss}
                    onChange={(e) => setValues((prev) => ({ ...prev, iss: e.target.value }))}
                  />
                </Grid>
                <Grid size={12}>
                  <Box sx={{ paddingBlock: 2 }}>
                    <PageListHeader
                      title="Retenção Total"
                      handleAdd={handleOpenList}
                      fontSize={18}
                    />
                    <Table
                      tableOptions={false}
                      columns={[
                        { field: "name", headerName: "Nome", flex: 2, align: "center" },
                        { field: "letter", headerName: "Letra", flex: 1, align: "center" },
                        {
                          field: "actions",
                          headerName: "Ações",
                          sortable: false,
                          filterable: false,
                          align: "center",
                          renderCell: (params) => (
                            <TableActions
                              handleDelete={() => {
                                handleDeleteSubstitute(params.row.id);
                              }}
                            />
                          ),
                        },
                      ]}
                      data={values?.totalRetentions}
                    />
                    <ModalBox open={openList} onClose={handleCloseList} bg="white" maxWidth={500}>
                      <ListComponent
                        data={categories}
                        checkedData={values?.totalRetentions}
                        title="Categorias"
                        titleAdd="Criar uma nova categoria"
                        handleCreate={handleOpenCreateCategory}
                        onClose={handleCloseList}
                        handleAdd={(values) =>
                          setValues((prev) => ({ ...prev, totalRetentions: values }))
                        }
                      />
                    </ModalBox>
                    <ModalBox
                      open={openCreateCategory}
                      onClose={handleCloseCreateCategory}
                      bg="white"
                    >
                      <CreateEdit
                        data={null}
                        onCloseForm={handleCloseCreateCategory}
                        refreshData={getCategories}
                      />
                    </ModalBox>
                  </Box>
                </Grid>
                <Box sx={formStyles.buttonContainer}>
                  <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
                    Salvar
                  </ButtonCommon>
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </>
    </>
  );
};

export default UpdateCitiesForm;
