import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import parse from "html-react-parser";
import PageListHeader from "../components/PageListHeader";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";

const limitCaracteres = (value) => {
  if (value?.length > 50) {
    return value.substring(0, 50) + "...";
  }
  return value;
};

const Annotation = ({ annotationUseCases, cnaesUseCases, termUseCases }) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [notes, setNotes] = useState([]);

  //select
  const [cnaes, setCnaes] = useState([]);
  const [terms, setTerms] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados");
    const { data = [] } = await annotationUseCases.getAnnotations();
    setNotes(data);
    openBackdrop(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 2,
    },
    {
      field: "text",
      headerName: "Texto",
      flex: 5,
      renderCell: (params) => {
        const text = parse(params.value);
        const textParsed = text?.props?.children;
        return textParsed ? limitCaracteres(textParsed) : null;
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo resposta");
    try {
      await annotationUseCases.deleteAnnotation(id);
      setNotes((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir anotação",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchTerms = async () => {
    openBackdrop(true, "Carregando Termos");
    const termsListResponse = await termUseCases.getAll();
    setTerms(termsListResponse);
    openBackdrop(false);
  };

  const fetchCnaes = async () => {
    openBackdrop(true, "Carregando Cnaes");
    const { data } = await cnaesUseCases.listCnaes();
    setCnaes(data);
    openBackdrop(false);
  };

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          annotationUseCases={annotationUseCases}
          fetchTerms={fetchTerms}
          fetchCnaes={fetchCnaes}
          cnaes={cnaes}
          terms={terms}
        />
      ) : (
        <>
          <PageListHeader
            title="Anotações"
            titleAdd="Criar anotação"
            handleAdd={() => handleOpenForm()}
          />
          <Box sx={{ margin: 3 }}>
            <Table columns={columns} data={notes} />
          </Box>
        </>
      )}
    </Box>
  );
};
export default Annotation;
