import React, { useContext, useEffect, useState } from "react";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";
import PageListHeader from "../components/PageListHeader";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import CreateEdit from "./CreateEdit/CreateEdit";

export const AditionalCompanyPlan = ({ additionalCompanyPlanUseCases, company }) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const [additionalCompanyPlanList, setAdditionalCompanyPlanList] = useState([]);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const [error, setError] = useState(false);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);
  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    try {
      const response = await additionalCompanyPlanUseCases.getAdditionalCompanyPlans(company.id);
      setAdditionalCompanyPlanList(response);
    } catch (error) {
      setError("Erro ao carregar os adicionais de plano.");
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar os adicionais de plano.",
        type: "error",
      });
    }
  };

  const columns = [
    {
      field: "typeDescription",
      headerName: "Tipo",
      renderCell: (params) => (params.value === "User" ? "Usuário" : params.value),
      align: "center",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Quantidade",
      align: "center",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Preço",
      renderCell: (params) => formatNumberToRealString(params.value),
      align: "center",
      flex: 1,
    },
    {
      field: "limitDate",
      headerName: "Data de vencimento",
      renderCell: (params) => (params.value ? formatDateToBrazilian(params.value) : "-"),
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Ações",
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
      sortable: false,
      filterable: false,
      align: "center",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo adicional de plano");

    try {
      await additionalCompanyPlanUseCases.deleteAdditionalCompanyPlan(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Adicional de plano excluído com sucesso.",
        type: "success",
      });
      setAdditionalCompanyPlanList((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o adicional de plano.",
        type: "error",
      });
    }

    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este adicional de plano?`,
      callback: () => handleDelete(id),
    });
  };

  return (
    <>
      <Box>
        <PageListHeader
          title="Adicionais de plano"
          titleAdd="Novo adicional de plano"
          handleAdd={() => handleOpenForm()}
        />

        <Table columns={columns} data={additionalCompanyPlanList} errorMsg={error} />
      </Box>
      <ModalBox open={openForm} onClose={handleCloseForm} bg="white">
        <CreateEdit
          data={formProps}
          additionalCompanyPlanUseCases={additionalCompanyPlanUseCases}
          company={company}
          closeModal={handleCloseForm}
          refreshData={fetchData}
        />
      </ModalBox>
    </>
  );
};
