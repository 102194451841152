export const formatCpfCnpj = (value) => {
  const cleanValue = value.replace(/\D/g, "");
  if (cleanValue.length <= 11) {
    // CPF
    return cleanValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else {
    // CNPJ
    return cleanValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
};

export const getMonths = (locale = "pt-BR") => {
  return Array.from({ length: 12 }, (v, i) => {
    return {
      value: i + 1, // Mês começando de 1 para 12
      label: new Date(0, i).toLocaleString(locale, { month: "long" }),
    };
  });
};

export const getYears = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push({ value: year, label: year.toString() });
  }
  return years;
};

export const getEFDReinfEvents = (user) => {
  return [
    { value: "r2010", label: "R-2010 - Retenção Contribuição Previdenciária - Serviços Tomados" },
    { value: "r2098", label: "R-2098 - Reabertura dos Eventos Periódicos" },
    { value: "r2099", label: "R-2099 - Fechamento dos Eventos Periódicos" },
    ...// //condicional até o desenv. completo da funcionalidade
    (["admin", "superAdmin"].includes(user?.role)
      ? [{ value: "r4010", label: "R-4010 - Pagamentos/créditos a beneficiário pessoa física" }]
      : []),
    { value: "r4020", label: "R-4020 - Pagamentos/créditos a beneficiário pessoa jurídica" },
    { value: "r4099", label: "R-4099 - Fechamento/reabertura dos eventos da série R-4000" },
    { value: "r9000", label: "R-9000 - Exclusão de eventos das séries R-2000 e R-4000" },
  ];
};

export const validateDocumentsBasedOnEFDReinfEvent = (event, nfseData) => {
  const requiredFields = {
    r2010: ["amountBC", "amountOperation", "bcINSS"],
    itemsNFse: ["serviceClassificationId", "bc", "amount"],
    r4020A: ["amountBC", "amountOperation", "dateOfPayment", "incomeNatureId"],
    r4020B: ["amountBC", "amountOperation", "dateOfPayment", "incomeNatureId"],
    r4020C: ["amountBC", "amountOperation", "dateOfPayment", "incomeNatureId", "bcIR"],
    r4020D: ["amountBC", "amountOperation", "dateOfPayment", "incomeNatureId", "bcIR", "bcCSRF"],
  };

  // Função para verificar a presença de campos em objetos aninhados
  const hasRequiredFieldsInItems = (items, requiredFields) => {
    return items.every((item) => requiredFields.every((field) => item[field]));
  };

  const newNfseData = nfseData.map((nfse) => {
    let newNfse = { ...nfse };
    // Verifica os campos de nível superior
    const hasAllRequiredFields = requiredFields[event].every((field) => nfse[field]);

    if (nfse.incomeNatureId === 15768 && nfse.dateOfPayment) {
      return { ...newNfse, isReady: true };
    }

    if (event === "r2010") {
      // Se for o evento r2010, também verifica os campos dentro de itemsNFse
      const itemsNfseValid =
        nfse.itemsNFse && nfse.itemsNFse.length > 0
          ? hasRequiredFieldsInItems(nfse.itemsNFse, requiredFields["itemsNFse"])
          : false;

      if (hasAllRequiredFields && itemsNfseValid) {
        newNfse = { ...newNfse, isReady: true };
      }
    } else if (hasAllRequiredFields) {
      newNfse = { ...newNfse, isReady: true };
    }
    return newNfse;
  });

  return newNfseData;
};

// para ter o campo de acordo ao int dado pelo back
export const getDocType = (int) => {
  const type = {
    0: "NFSe",
    1: "NFe",
    2: "SIAFI",
    3: "Planilha",
    4: "Recibo",
    98: "Pgto pessoa física",
    99: "Outros",
  };
  return type[int];
};

// para calcular retenções em automatico
export const getRetentionTable = () => {
  return [
    {
      code: 6147,
      percentage: 5.85,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 1.2,
    },
    {
      code: 9060,
      percentage: 4.89,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 0.24,
    },
    {
      code: 8739,
      percentage: 1.24,
      PIS: 0.0,
      COFINS: 0.0,
      CSLL: 1.0,
      IR: 0.24,
    },
    {
      code: 8767,
      percentage: 2.2,
      PIS: 0.0,
      COFINS: 0.0,
      CSLL: 1.0,
      IR: 1.2,
    },
    {
      code: 6175,
      percentage: 7.05,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 2.4,
    },
    {
      code: 8850,
      percentage: 3.4,
      PIS: 0.0,
      COFINS: 0.0,
      CSLL: 1.0,
      IR: 2.4,
    },
    {
      code: 8863,
      percentage: 4.65,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 0,
    },
    {
      code: 6188,
      percentage: 7.05,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 2.4,
    },
    {
      code: 6190,
      percentage: 9.45,
      PIS: 0.65,
      COFINS: 3.0,
      CSLL: 1.0,
      IR: 4.8,
    },
  ];
};

// opções R-4010
export const deductionOptions = [
  { id: 1, label: "Previdência oficial" },
  { id: 2, label: "Previdência privada" },
  { id: 3, label: "Fundo de aposentadoria programada individual - Fapi" },
  { id: 4, label: "Fundação de previdência complementar do servidor público - Funpresp" },
  { id: 5, label: "Pensão alimentícia" },
  { id: 7, label: "Dependentes" },
];

export const getDeductionLabelById = (id) => {
  const option = deductionOptions.find((opt) => opt.id === id);
  return option ? option.label : "";
};

export const dependentOptions = [
  { id: 1, label: "Cônjuge" },
  {
    id: 2,
    label:
      "Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua declaração de união estável",
  },
  { id: 3, label: "Filho(a) ou enteado(a)" },
  {
    id: 6,
    label:
      "Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial",
  },
  { id: 8, label: "Pais" },
  { id: 9, label: "Avós e bisavós" },
  { id: 10, label: "Menor pobre do qual detenha a guarda judicial" },
  { id: 11, label: "A pessoa absolutamente incapaz, da qual seja tutor ou curador" },
  { id: 12, label: "Ex-cônjuge" },
  { id: 99, label: "Agregado/Outros" },
];

export const getDependentLabelById = (id) => {
  const option = dependentOptions.find((opt) => opt.id === id);
  return option ? option.label : "";
};
