import React, { useState } from "react";
import { Check } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { Box } from "@mui/material";
import RButton from "../../../../Common/Buttons/RButton";
import { formStyles } from "../../../../Styles/FormStyles";

function Nota({
  values,
  errors,
  businessPartnerOptions,
  isUnconditionalDiscountWrong,
  serieError,
  setSerieError,
  inputDisabled,
  handleChangeFieldValue,
}) {
  const [showInputs, setShowInputs] = useState(false);

  const handleClick = () => {
    setShowInputs(!showInputs);
  };
  //filtrando parceiros de negócio CNPJ
  const cnpjPartners = businessPartnerOptions.filter((partner) => partner.personType === 1);

  //ERROR: O desconto incondicional não pode ser maior do que o valor bruto da nota!
  const isUnconditionalDiscountWrongText =
    isUnconditionalDiscountWrong &&
    "O desconto incondicional não pode ser maior do que o valor bruto da nota!";

  //ERROR: só para mostrar se por engano escreverem o desconto maior do que o valor bruto
  const isAmountOperationNegative = values?.amountOperation < 0;
  const isAmountOperationNegativeText = isAmountOperationNegative && "Valor não pode ser negativo!";

  return (
    <>
      {values.doc === "Planilha" && (
        <Box sx={{ display: "flex", alignItems: "start", marginBottom: 2 }}>
          <RButton
            variant="outlined"
            color={showInputs ? "secondary" : "primary"}
            onClick={handleClick}
            sx={{ width: "300px", height: "38px" }}
          >
            {showInputs ? "Ocultar campos do cabeçalho" : "Editar campos do cabeçalho"}
          </RButton>
        </Box>
      )}
      {showInputs && (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-evenly",
              alignItems: "start",
              ...formStyles.fieldsWrapper,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Input
                type="date"
                title="Data de emissão"
                value={values?.dateOfIssue}
                onChange={(date) => {
                  handleChangeFieldValue({
                    newItem: { dateOfIssue: date },
                    field: "dateOfIssue",
                  });
                }}
                error={errors[`[${values?.id}].dateOfIssue`]}
                disabled={inputDisabled}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Input
                type="select"
                title="CNPJ do Contratado"
                options={cnpjPartners}
                getOptionLabel={(option) =>
                  `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`
                }
                placeholder="Selecione um Contratado"
                value={cnpjPartners?.find((item) => item.id == values?.businessPartnerId)}
                onChange={(option) => {
                  const { id } = option;
                  handleChangeFieldValue({
                    newItem: { businessPartnerId: id },
                    field: "businessPartnerId",
                  });
                }}
                disabled={inputDisabled}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Input
                type="text"
                title="Nº Doc"
                value={values?.nFseNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  handleChangeFieldValue({
                    newItem: { nFseNumber: value },
                    field: "nFseNumber",
                  });
                }}
                disabled={inputDisabled}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Input
                type="amount"
                title="Valor da operação"
                value={values?.amountOperation}
                onChange={(value) => {
                  handleChangeFieldValue({
                    newItem: { amountOperation: value },
                    field: "amountOperation",
                  });
                }}
                disabled={inputDisabled}
              />
            </Box>
          </Box>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-evenly",
          paddingInline: 1,
          alignItems: "start",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Input
            type="amount"
            title="Valor Bruto do Doc. Fiscal"
            value={values?.amountBC}
            onChange={(value) => {
              handleChangeFieldValue({
                newItem: { amountBC: value },
                field: "amountBC",
              });
            }}
            error={errors[`[${values?.id}].amountBC`]}
            disabled={inputDisabled}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            type="amount"
            title="(-) Desconto Condicional"
            value={values?.conditionalDiscount}
            onChange={(value) => {
              handleChangeFieldValue({
                newItem: { conditionalDiscount: value },
                field: "conditionalDiscount",
              });
            }}
            error={errors[`[${values?.id}].conditionalDiscount`]}
            disabled={inputDisabled}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            type="amount"
            title="(-) Desconto Incondicional"
            value={values?.unconditionalDiscount}
            onChange={(value) => {
              handleChangeFieldValue({
                newItem: { unconditionalDiscount: value },
                field: "unconditionalDiscount",
              });
            }}
            error={
              errors[`[${values?.id}].unconditionalDiscount`] || isUnconditionalDiscountWrongText
            }
            disabled={inputDisabled}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            type="amount"
            title="Valor Total da Operação"
            allowNegative
            value={values?.amountOperation}
            error={errors[`[${values?.id}].amountOperation`] || isAmountOperationNegativeText}
            disabled
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            type="text"
            title="Série NF"
            placeholder="Nº série NF"
            value={values?.serie}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 5) {
                handleChangeFieldValue({
                  newItem: { serie: value },
                  field: "serie",
                });
                setSerieError(null);
              } else {
                setSerieError("Número de série deve ter no máximo 5 dígitos.");
              }
            }}
            error={errors[`[${values?.id}].serie`] || serieError}
            disabled={inputDisabled}
          />
        </Box>
        <Box>
          {/*Esse campo se refere a um percentual de 11% ou 3,5%. O mais comum é de 11%. Se não há marcação sub entende-se ser 11% */}
          <p style={style.Label}>CPRB?</p>
          <Box
            style={style.checkBox}
            onClick={() => {
              if (inputDisabled) {
                return;
              }
              const lastValue = values?.isCPRB;
              handleChangeFieldValue({
                newItem: { isCPRB: lastValue ? false : true },
              });
            }}
          >
            {values?.isCPRB && <Check fontSize="large" />}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Nota;

const style = {
  checkBox: {
    width: "38px",
    height: "38px",
    background: "#FBFBFB",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  Label: {
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "700",
    fontSize: 12,
    textAlign: "left",
    maxWidth: "unset",
    paddingBottom: "4px",
  },
};
