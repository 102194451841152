import { Accordion, styled } from "@mui/material";

export const AnswerAccordionStyles = {
  Container: styled("div")({
    marginTop: "48px",
    display: "flex",
    flexFlow: "column",
    rowGap: "4px",
  }),

  Title: styled("p")({
    marginBlock: "0",
    fontFamily: '"Roboto", sans-serif',
    fontSize: "max(16px, 0.7vw)",
    textTransform: "uppercase",
  }),

  Text: styled("div")({
    fontSize: "max(16px, 0.7vw)",
    fontWeight: 300,

    "& strong": {
      fontWeight: "bold",

      "& > *": {
        fontWeight: "bold",
      },
    },
  }),

  PopoverTrigger: styled("span")({
    cursor: "pointer",
    color: "#0366d6",
    "&:hover": {
      textDecoration: "underline",
    },
  }),

  PopoverContent: styled("span")({
    display: "block",
    padding: "12px",

    "& strong": {
      fontWeight: "bold",

      "& > *": {
        fontWeight: "bold",
      },
    },
  }),

  Link: styled("span")({
    textDecoration: "underline",
    cursor: "pointer",

    "&:hover": {
      color: "#cf9455",
    },
  }),

  Line: styled("div")({
    width: "4px",
    height: "4px",
    borderRadius: "4px",
    backgroundColor: "currentColor",
    alignSelf: "center",
    marginRight: "8px",
  }),

  AccordionComp: styled(Accordion)(({ expanded }) => ({
    "&.MuiAccordion-root": {
      boxShadow: "none",
      backgroundColor: "#fafafa",

      "::before": {
        display: "none",
      },
    },

    ".MuiAccordionSummary-root": {
      color: expanded ? "#fafafa" : "#000",
      backgroundColor: expanded ? "#1341a1" : "#fafafa",
      minHeight: "48px",
      border: "solid 1px #dedede",
      borderRadius: "4px",

      "&:hover": {
        color: "#fafafa",
        backgroundColor: "#1341a1",
      },

      "&.Mui-expanded": {
        boxShadow: "0px 8px 10px -5px rgba(0, 0, 0, 0.17)",
        minHeight: "48px",
      },

      ".MuiAccordionSummary-content.Mui-expanded": {
        margin: "12px 0",
      },
    },

    ".MuiAccordionDetails-root": {
      padding: "16px",
    },
  })),
};
