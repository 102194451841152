import React, { useEffect, useState } from "react";
import { Title } from "../../../../../../components/Layouts/styles";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

function IR({
  item,
  onChangeItem,
  index,
  nfseData,
  disabled,
  incomeNatureOptions,
  errors,
  modalCalculateRetention,
}) {
  const [show] = useState(false);
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  //
  //bcIR
  const isBcIRWrong = item?.bcIR > item?.amountBC;
  const isBcIRWrongText =
    isBcIRWrong && "Valor da base IR não pode ser maior do que o Valor de Operação do documento!";

  //aliquotIR
  const aliquotValue = (item?.amountIR / item.bcIR) * 100;
  const aliquotsABC = ["0.24", "1.20", "1.50", "2.40", "4.80"];
  const aliquotsD = ["1.00", "1.50"];
  const possibleAliquots = QSO === "D" ? [...aliquotsD] : [...aliquotsABC];
  const isAliquotValid = possibleAliquots?.includes(aliquotValue.toFixed(2));
  const isAliquotWrong = item?.bcIR > 0 && item?.amountIR > 0 && !isAliquotValid;
  const isAliquotWrongText = isAliquotWrong && "O valor da retenção pode estar incorreto";

  //ERROR: data no futuro
  const isDateInTheFuture = item?.dateOfPayment > new Date();
  const isDateWrong = isDateInTheFuture && "Escolha uma data no presente";

  useEffect(() => {
    onChangeItem(
      {
        ...nfseData[index],
        aliquotIR: aliquotValue,
      },
      index,
      null,
      true
    );
  }, [item?.amountIR, item.bcIR]);

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Title>Retenção de IR</Title>
        {item?.typeDoc && modalCalculateRetention}
      </Box>
      <Box
        sx={{
          paddingTop: 2,
          paddingInline: 4,
          paddingBottom: show ? 4 : 3,
          background: "#D9D9D9",
          borderRadius: 4,
          marginB: 0,
        }}
      >
        <Grid container spacing={1}>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((i) => i.id == item.incomeNatureId)}
              onChange={(option) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    incomeNatureId: option.id,
                    incomeNatureDescription: option.incomeDescription,
                  },
                  index,
                  "incomeNatureId"
                );
              }}
              error={errors[`[${index}].incomeNatureId`]}
              disabled={disabled}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="date"
              title="Data do Crédito/Pagamento"
              value={item.dateOfPayment}
              onChange={(date) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    dateOfPayment: date,
                  },
                  index,
                  "dateOfPayment"
                );
              }}
              error={errors[`[${index}].dateOfPayment`] || isDateWrong}
              disabled={disabled}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Base do IRRF"
              value={item.bcIR}
              onChange={(e) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    bcIR: e,
                  },
                  index,
                  "bcIR"
                );
              }}
              error={errors[`[${index}].bcIR`] || isBcIRWrongText}
              disabled={disabled}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Retenção do IRRF"
              value={item.amountIR}
              onChange={(e) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    amountIR: e,
                  },
                  index,
                  "amountIR"
                );
              }}
              error={errors[`[${index}].amountIR`]}
              disabled={disabled}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              prefix="%"
              title="Alíquota"
              value={item.aliquotIR}
              error={isAliquotWrongText}
            />
          </Grid>
          <Grid size={{ xs: 4, lg: 3 }}>
            <Input
              type="amount"
              title="Valor da Retenção não efetuada"
              value={item.amountNotRetained}
              onChange={(e) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    amountNotRetained: e,
                  },
                  index,
                  "amountNotRetained"
                );
              }}
              error={errors[`[${index}].amountNotRetained`]}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default IR;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginBlock: 1,
  },
};
