import React, { useContext, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { FormControl, Button, Box } from "@mui/material";
import validationSchema from "./validationSchema";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";

export const EditUserForm = ({ user, updateUser }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [userEdit, setUserEdit] = useState(user);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false); // State to track password visibility

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClick = () => {
    window.location.reload();
  };

  const handleSubmit = async (values) => {
    event.preventDefault();
    const data = {
      companyId: user.companyId,
      name: values.name ?? user.name,
      email: values.email ?? user.email,
      password: values.password ?? user.password,
      confirmPassword: values.confirmPassword ?? user.confirmPassword,
      role: user.role,
      userProfileId: user.userProfileId,
      active: values.active,
      homePhone: values?.homePhone ?? user.homePhone,
      mobilePhone: values?.mobilePhone,
    };
    validationSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        openDialogBox({
          message: `Deseja mesmo editar este usuário?`,
          callback: async () => {
            try {
              openBackdrop(true, "Editando usuário");
              await updateUser(data);
              setFeedbackSnackbar({
                isOpen: true,
                message: "Usuário editado com sucesso!",
                type: "success",
              });
            } catch (error) {
              setFeedbackSnackbar({
                isOpen: true,
                message: "Erro ao editar usuário: " + error?.response.data,
                type: "error",
              });
            } finally {
              openBackdrop(false);
              window.location.reload();
            }
          },
        });
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  return (
    <Box>
      {user && (
        <FormControl>
          {user && (
            <Grid container spacing={1}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  title="Nome"
                  styles={styles.field}
                  name="name"
                  type="text"
                  value={userEdit?.name ?? user.name}
                  onChange={(e) => setUserEdit({ ...userEdit, name: e.target.value })}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  title="Email"
                  styles={styles.field}
                  name="email"
                  type="email"
                  value={userEdit?.email ?? user.email}
                  onChange={(e) => setUserEdit({ ...userEdit, email: e.target.value })}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  title="Telefone"
                  type="mask"
                  name="homePhone"
                  mask="(00) 00000-0000"
                  value={userEdit?.homePhone ?? user.homePhone}
                  styles={styles.field}
                  onChange={(value) => {
                    setUserEdit({ ...userEdit, homePhone: value });
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  title="Celular"
                  type="mask"
                  name="mobilePhone"
                  mask="(00) 00000-0000"
                  value={userEdit?.mobilePhone ?? user.mobilePhone}
                  styles={styles.field}
                  onChange={(value) => {
                    setUserEdit({ ...userEdit, mobilePhone: value });
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  styles={styles.field}
                  title="Senha"
                  name="password"
                  placeholder="Digite sua senha"
                  type={showPassword ? "text" : "password"}
                  value={userEdit?.password ?? user.password}
                  onChange={(e) => setUserEdit({ ...userEdit, password: e.target.value })}
                  error={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  title="Confirmar Senha"
                  placeholder="Digite sua senha"
                  styles={styles.field}
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  value={userEdit?.confirmPassword ?? user.confirmPassword}
                  onChange={(e) => setUserEdit({ ...userEdit, confirmPassword: e.target.value })}
                  error={errors.confirmPassword}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }} sx={{ marginTop: 1 }}>
                <Button sx={styles.CancelButton} onClick={handleClick} variant="contained">
                  Cancelar
                </Button>
                <Button
                  sx={styles.Button}
                  variant="contained"
                  onClick={() => handleSubmit(userEdit)}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          )}
        </FormControl>
      )}
    </Box>
  );
};

const styles = {
  button: {
    backgroundColor: "#081445",
    color: "white",
    padding: "10px 30px",
    borderRadius: 1,
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
    transition: "background-color 0.12s ease-in-out",
    "&:hover": {
      backgroundColor: "#0092C7",
    },
    mr: 2,
  },
  buttonCancel: {
    backgroundColor: "#DDD",
    color: "#000",
    padding: "10px 30px",
    borderRadius: 1,
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
    transition: "background-color 0.12s ease-in-out",
    "&:hover": {
      backgroundColor: "#CCC",
    },
  },
  field: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    height: "54px",
    borderRadius: "6px",
    border: "1px solid #D7D7D7",
  },
  CancelButton: {
    backgroundColor: "#EFF2F4",
    color: "black",
    "&:hover": {
      color: "#FFF",
    },
  },
  Button: {
    background: "#081445",
    paddingInline: 4,
    paddingBlock: 1,
    marginLeft: 2,
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0e3279",
    },
  },
};
