import React from "react";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";

const TermForm = ({ values, onChangeValue, handleSubmit, errors }) => {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={{ ...formStyles.title, color: errors.term && "#d32f2f" }}>
          Termo de Pesquisa
        </Typography>
        <Input
          type="text"
          name="term"
          placeholder="Ensire o termo"
          value={values.term}
          onChange={(e) => onChangeValue("term", e.target.value)}
          error={errors.term}
        />
      </Box>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default TermForm;
