import { apiURL } from "../../usecases/api";
export class ApiAdditionalCompanyPlanUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAdditionalCompanyPlans(companyId) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan/?companyId=${companyId}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getAdditionalCompanyPlan(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async updateAdditionalCompanyPlan(additionalCompanyPlan) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan`,
        method: "PUT",
        data: additionalCompanyPlan,
      });
      return response.data;
    } catch (err) {
      console.log("🚀 ~ file: apiAdditionalCompanyPlan.js:51 ~ ApiPlanUseCases ~ updatePlan ~ err:", err);
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async deleteAdditionalCompanyPlan(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async createAdditionalCompanyPlan(additionalCompanyPlan) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/AdditionalCompanyPlan`,
        method: "POST",
        data: additionalCompanyPlan,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }
}
