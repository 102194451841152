import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import TableActions from "~/presentation/components/TableActions";
import RegisterEdit from "./RegisterEdit/RegisterEdit";
import PageListHeader from "../components/PageListHeader";
import { Box } from "@mui/material";
import Table from "~/presentation/components/Table";

function Company({
  companyUseCases,
  planUseCases,
  stateUseCases,
  cityUseCases,
  legalNatureUseCases,
  userUseCases,
}) {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //todas as empresas
  const [companies, setCompanies] = useState([]);

  //Select options
  const [users, setUsers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [legalNatureOptions, setLegalNatureOptions] = useState([]);
  const [citiesCache, setCitiesCache] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const response = await companyUseCases.getCompanies();
    setCompanies(response);
    openBackdrop(false);
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 2 },
    { field: "email", headerName: "Email", flex: 2 },
    { field: "planName", headerName: "Plano", flex: 1 },
    {
      field: "active",
      headerName: "Ativo",
      align: "center",

      renderCell: (params) => (params.value ? "Sim" : "Não"),
    },
    { field: "categoryName", headerName: "Categoria", flex: 1 },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo empresa");
    try {
      await companyUseCases.deleteCompany(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Empresa excluída!",
        type: "success",
      });
      setCompanies((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro: " + error.response.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta empresa?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Só queremos que faça o fetch quando o usuário clicar pela 1º vez para editar
  //Depois disso, queremos que não haja fetch
  const handleGetFormOptions = async () => {
    openBackdrop(true, "Carregando dados do formulário");
    if (states.length === 0) {
      const result = dataToSelectMapper(await loadStates());
      setStates(result);
    }
    if (legalNatureOptions.length === 0) {
      const result = dataToSelectMapper(await loadLegalNatureOptions());
      setLegalNatureOptions(result);
    }
    if (citiesCache.length === 0) {
      const result = dataToSelectMapper(await loadCities());
      setCitiesCache(result);
    }
    openBackdrop(false);
  };

  const getPlans = async () => {
    openBackdrop(true, "Carregando planos");
    const result = dataToSelectMapper(await loadPlans());
    setPlans(result);
    openBackdrop(false);
  };

  const getCategories = async () => {
    openBackdrop(true, "Carregando categorias");
    const result = dataToSelectMapper(await loadCategories());
    setCategories(result);
    openBackdrop(false);
  };

  const getUsers = async () => {
    openBackdrop(true, "Carregando usuários");
    const result = await loadUsers();
    setUsers(result);
    openBackdrop(false);
  };

  const loadPlans = async () => {
    return await planUseCases.getPlans();
  };

  const loadCategories = async () => {
    return await companyUseCases.getCompanyCategories();
  };

  const loadStates = async () => {
    return await stateUseCases.getStates();
  };

  const loadCities = async () => {
    return await cityUseCases.getAll();
  };

  const loadLegalNatureOptions = async () => {
    return await legalNatureUseCases.list();
  };

  const loadUsers = async () => {
    return await userUseCases.getUsers();
  };

  return (
    <Box>
      {openForm ? (
        <RegisterEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          onGetFormOptions={handleGetFormOptions}
          plans={plans}
          categories={categories}
          states={states}
          legalNatureOptions={legalNatureOptions}
          citiesCache={citiesCache}
          users={users}
          getPlans={getPlans}
          getCategories={getCategories}
          getUsers={getUsers}
        />
      ) : (
        <>
          <PageListHeader title="Empresa" handleAdd={() => handleOpenForm()} />
          <Box sx={{ margin: 3 }}>
            <Table columns={columns} data={companies} />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Company;
