import React, { useEffect, useState } from "react";
import { Check } from "@mui/icons-material";
import { Box } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";

function Nota({ item, onChangeItem, index, nfseData, disabled, errors }) {
  const [serieError, setSerieError] = useState(null);
  //ERROR: O desconto incondicional não pode ser maior do que o valor bruto da nota!
  const isUnconditionalDiscountWrong = item?.unconditionalDiscount >= item?.amountBC;
  const isUnconditionalDiscountWrongText =
    isUnconditionalDiscountWrong &&
    "O desconto incondicional não pode ser maior ou igual ao valor bruto da nota!";

  //ERROR: só para mostrar se por engano escreverem o desconto maior do que o valor bruto
  const isAmountOperationNegative = item?.amountOperation < 0;
  const isAmountOperationNegativeText = isAmountOperationNegative && "Valor não pode ser negativo!";

  useEffect(() => {
    onChangeItem(
      {
        ...nfseData[index],
        aliquotINSS: item?.isCPRB ? 3.5 : 11,
      },
      index,
      null,
      true
    );
  }, [item?.isCPRB]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "space-evenly",
        paddingInline: 1,
        alignItems: "start",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Input
          type="amount"
          title="Valor Bruto do Doc. Fiscal"
          value={item.amountBC}
          onChange={(value) => {
            onChangeItem(
              {
                ...nfseData[index],
                amountBC: value,
                amountOperation: value - item?.unconditionalDiscount,
              },
              index,
              "amountBC"
            );
          }}
          error={errors[`[${index}].amountBC`]}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Input
          type="amount"
          title="(-) Desconto Condicional"
          value={item.conditionalDiscount}
          onChange={(e) => {
            onChangeItem(
              {
                ...nfseData[index],
                conditionalDiscount: e,
              },
              index,
              "conditionalDiscount"
            );
          }}
          error={errors[`[${index}].conditionalDiscount`]}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Input
          type="amount"
          title="(-) Desconto Incondicional"
          value={item.unconditionalDiscount}
          onChange={(value) => {
            onChangeItem(
              {
                ...nfseData[index],
                unconditionalDiscount: value,
                amountOperation: item.amountBC - value,
              },
              index,
              "unconditionalDiscount"
            );
          }}
          error={errors[`[${index}].unconditionalDiscount`] || isUnconditionalDiscountWrongText}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Input
          type="amount"
          title="Valor Total da Operação"
          allowNegative
          value={item.amountOperation}
          onChange={(e) => {
            onChangeItem(
              {
                ...nfseData[index],
                amountOperation: e,
              },
              index,
              "amountOperation"
            );
          }}
          error={errors[`[${index}].amountOperation`] || isAmountOperationNegativeText}
          disabled
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Input
          type="text"
          title="Série NF"
          value={item.serie}
          onChange={(e) => {
            const number = e.target.value;
            if (number.length < 6) {
              onChangeItem(
                {
                  ...nfseData[index],
                  serie: number,
                },
                index,
                "serie"
              );
              setSerieError(null);
            } else {
              setSerieError("Número de série deve ter no máximo 5 dígitos.");
            }
          }}
          error={errors[`[${index}].serie`] || serieError}
          disabled={disabled}
        />
      </Box>
      <Box>
        {/*Esse campo se refere a um percentual de 11% ou 3,5%. O mais comum é de 11%. Se não há marcação sub entende-se ser 11% */}
        <p style={style.Label}>CPRB?</p>
        <Box
          style={style.checkBox}
          onClick={() => {
            if (disabled) {
              return;
            }
            if (item.isCPRB) {
              onChangeItem(
                {
                  ...nfseData[index],
                  isCPRB: false,
                },
                index
              );
            } else if (!item.isCPRB) {
              onChangeItem(
                {
                  ...nfseData[index],
                  isCPRB: true,
                },
                index
              );
            }
          }}
        >
          {item.isCPRB && <Check fontSize="large" />}
        </Box>
      </Box>
    </Box>
  );
}

export default Nota;

const style = {
  checkBox: {
    width: "38px",
    height: "38px",
    background: "#FBFBFB",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  Label: {
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "700",
    fontSize: 12,
    textAlign: "left",
    maxWidth: "unset",
  },
};
