import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Form from "~/presentation/views/Admin/Survey/CreateEdit/Form";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";

function CreateEdit({ data, onCloseForm, refreshData, surveyUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const newAnswerArray = [{ id: 0, text: "", isCorrect: true }];
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    id: 0,
    question: "",
    draft: false,
    publishDate: new Date(),
    answers: newAnswerArray,
  });

  useEffect(() => {
    if (data?.id) {
      setValues({
        id: data.id,
        question: data.question,
        draft: data.draft,
        publishDate: new Date(data.publishDate),
        answers: data.answers.length > 0 ? data.answers : newAnswerArray,
      });
    }
  }, [data]);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  const handleChangeAnswer = (field, newValue, index) => {
    const newAnswers = [...values.answers];
    let newAnswerValue = newAnswers[index];
    newAnswerValue = { ...newAnswerValue, [field]: newValue };
    newAnswers.splice(index, 1, newAnswerValue);
    setValues((prev) => ({ ...prev, answers: newAnswers }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} nova enquete`);
    try {
      if (isCreate) {
        await surveyUseCases.create(values);
      } else {
        await surveyUseCases.update(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Enquete ${isCreate ? "criada" : "editada"}!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.data || error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Enquete`}
        handleBack={onCloseForm}
        titleBack="Voltar para Enquetes"
      />
      {values && (
        <Form
          values={values}
          onChangeValue={handleChangeValue}
          onChangeAnswer={handleChangeAnswer}
          handleSubmit={handleValidation}
          errors={errors}
        />
      )}
    </>
  );
}

export default CreateEdit;
