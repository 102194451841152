import { styled } from "@mui/material";

export const Container = styled("div")({
  width: "276px",
  height: "45px",
  flexShrink: 0,

  "& div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
    background: "#FF6829",
    borderRadius: "4px",
    color: "white",
    fontSize: "24px",
    fontFamily: '"Bebas Neue"',
    fontWeight: 400,
    overflowWrap: "break-word",
    width: "fit-content",
    padding: "0px 30px",
    position: "absolute",
    top: "-25px",
  },

  "& h2": {
    color: "#FFF",
    fontFamily: "Bebas Neue",
    fontSize: "35px",
    fontStyle: "normal",
    fontWeight: 400,
    textTransform: "capitalize",
  },
});
