import React from "react";
import { Container, Typography as Text, Box, Stack, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";

const Reinf = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(90deg, #FFFFFF 30%, #72A2FF 100%)",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid size={{ xs: 1, lg: 0.2 }}>
            <Divider
              orientation="vertical"
              sx={{
                background: "linear-gradient(to bottom, #FE7F1E 0%, rgba(254, 127, 30, 0) 100%)",
                width: 10,
                height: 300,
                marginTop: 7,
              }}
            />
          </Grid>
          <Grid size={{ xs: 11, lg: 5.8 }} sx={{ paddingLeft: 2, paddingTop: 5 }}>
            <Stack spacing={-2.5}>
              <Text sx={{ ...styles.title, fontWeight: 700 }}>Bem-vindo</Text>
              <Text sx={{ ...styles.title, fontWeight: 500 }}>ao módulo</Text>
              <Text sx={{ ...styles.title, color: "#FE7F1E" }}>GT-Reinf.</Text>
              <Text sx={{ ...styles.bodyText }}>
                Envio da EFD-Reinf de forma
                <Text component="span" sx={{ ...styles.bodyText, fontWeight: 600 }}>
                  {" "}
                  simplificada{" "}
                </Text>
                <br />
                com a{" "}
                <Text component="span" sx={{ ...styles.bodyText, fontWeight: 600 }}>
                  segurança
                </Text>{" "}
                que você precisa.
              </Text>
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <Box
              component="img"
              src="/assets/laptopreinf.png"
              alt="img-reinf-home"
              sx={styles.img}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Reinf;

const styles = {
  title: {
    fontFamily: "Montserrat, sans-serif",
    color: "#0E2352",
    fontSize: "3rem",
    fontWeight: 700,
    textAlign: "left",
    "@media (min-width: 800px)": {
      fontSize: "4rem",
    },
  },
  bodyText: {
    fontFamily: "Montserrat, sans-serif",
    color: "#0E2352",
    fontWeight: 500,
    fontSize: "1.2rem",
    textAlign: "justify",
    pt: 2,
    maxWidth: "275px",
    "@media (min-width: 600px)": {
      maxWidth: "100%",
    },
  },
  img: {
    width: "95%",
    maxWidth: "400px",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "@media (min-width: 600px)": {
      maxWidth: "600px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "800px",
    },
  },
};
