import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import LogicAndValidation from "./Components/LogicAndValidation/LogicAndValidation";
import { Box } from "@mui/material";

export default function RenderLayout({
  nfseData,
  serviceClassificationOptions,
  incomeNatureOptions,
  businessPartnerOptions,
  selectedEvent,
  refreshData,
  isEventClosed,
  handleChangeFieldValue,
  handleChangeINSSfield,
  errors,
  setErrors,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const apiNfsevalues = new ApiNfseUseCases(makeHttpClient());
  const [inputDisabled, setInputDisabled] = useState(null);

  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  //UPDATE NFSE
  const handleUpdateNfse = async (data) => {
    openBackdrop(true, "Atualizando nota");
    //enviar para o back a nota sem o campo hadChanges
    const { hadChanges, isReady, ...values } = data;
    try {
      await apiNfsevalues.updateNfse(values);
      //mudar campo hadChanges no state para false
      handleChangeFieldValue({
        newItem: { hadChanges: false },
        id: values?.id,
        byPass: true,
      });
      setFeedbackSnackbar({
        isOpen: true,
        message: "Doc. Fiscal atualizado com sucesso",
        type: "success",
      });
      openBackdrop(false);
      if (selectedEvent) {
        refreshData();
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar Doc. Fiscal",
        type: "error",
      });
      openBackdrop(false);
    }
  };

  useEffect(() => {
    if (nfseData?.isR2010) {
      setInputDisabled("R2010");
    }
    if (nfseData?.isR4020) {
      if (QSO === "A" || QSO === "B") {
        setInputDisabled("R4020AB");
      } else if (QSO === "C") {
        setInputDisabled("R4020C");
      } else if (QSO === "D") {
        setInputDisabled("R4020D");
      }
    }
  }, [nfseData?.id]);

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <LogicAndValidation
          values={nfseData}
          onSubmit={handleUpdateNfse}
          serviceClassificationOptions={serviceClassificationOptions}
          incomeNatureOptions={incomeNatureOptions}
          businessPartnerOptions={businessPartnerOptions}
          handleChangeINSSfield={handleChangeINSSfield}
          handleChangeFieldValue={handleChangeFieldValue}
          selectedEvent={selectedEvent}
          isEventClosed={isEventClosed}
          errors={errors}
          setErrors={setErrors}
          inputDisabled={inputDisabled}
        />
      </Box>
    </>
  );
}
