import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";

const ValueVariable = ({ valueVariableUseCases }) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [valueVariables, setValueVariables] = useState();

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const apiValueVariables = await valueVariableUseCases.getAll();
    setValueVariables(apiValueVariables);
    openBackdrop(false);
  };

  const columns = [
    { field: "code", headerName: "Código", align: "center" },
    { field: "value", headerName: "Valor", flex: 1, align: "center" },
    { field: "name", headerName: "Nome", flex: 2 },
    {
      name: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo Variável de Valor");
    try {
      await valueVariableUseCases.delete(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Variável de Valor excluída com sucesso!",
        type: "success",
      });
      setValueVariables((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir Variável de Valor: Missing endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta variável de valor?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <ModalBox open={openForm} onClose={handleCloseForm} bg="white">
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          valueVariableUseCases={valueVariableUseCases}
        />
      </ModalBox>
      <PageListHeader
        title="Variável de Valor"
        titleAccess="Nova variável"
        handleAdd={() => handleOpenForm()}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={valueVariables} />
      </Box>
    </Box>
  );
};

export default ValueVariable;
