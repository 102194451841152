import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import { makeBlogHome, makeBlogPost, makeBlogList } from "~/main/factories/pages/blog";
import PrivateRoute from "./PrivateRoute";

const BlogRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={makeBlogHome()} />
      <Route path="/list/" element={makeBlogList()} />
      <Route
        path="/post/:id"
        element={
          <PrivateRoute roles={["user", "manager", "admin", "superAdmin"]}>
            {makeBlogPost()}
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default BlogRoutes;
