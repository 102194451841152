import React, { useState } from "react";
import QSOTable from "~/presentation/components/QSOTable";
import TextEditor from "~/presentation/components/TextEditor";
import ImageInput from "~/presentation/components/ImageInput";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import CreateEdit from "../../../BlogCategory/CreateEdit/CreateEdit";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import PageListHeader from "../../../components/PageListHeader";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const BlogForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  categories,
  tags,
  uploadedImage,
  changeHandler,
  getCategories,
}) => {
  //MODAL Criar
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const handleOpenModalCategory = () => setOpenModalCategory(true);
  const handleCloseModalCategory = () => {
    setOpenModalCategory(false);
  };

  //MODAL Lista de totalSubstituteOrDismissed
  const [openList, setOpenList] = useState(false);
  const handleOpenList = () => setOpenList(true);
  const handleCloseList = () => setOpenList(false);

  const handleDeleteSubstitute = (params) => {
    if (values.tags.length === 1) {
      onChangeValue("tags", []);
    } else {
      const filtedValue = values?.tags?.filter((item) => item.id !== params.row.id);
      onChangeValue("tags", filtedValue);
    }
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Dados da Publicação</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 6, xl: 3 }}>
            <Input
              type="select"
              options={categories}
              onChange={(value) => onChangeValue("categoryId", Number(value.id))}
              name="categoryId"
              title="Categoria"
              value={categories.find((item) => item.id === values?.categoryId)}
              error={errors.categoryId}
              icon={{
                title: "Criar nova categoria",
                func: () => {
                  handleOpenModalCategory();
                },
              }}
            />
            <ModalBox open={openModalCategory} onClose={handleCloseModalCategory} bg="white">
              <CreateEdit
                data={null}
                onCloseForm={handleCloseModalCategory}
                refreshData={getCategories}
              />
            </ModalBox>
          </Grid>
          <Grid size={{ xs: 12, md: 6, xl: 9 }}>
            <Input
              type="text"
              name="title"
              title="Título"
              value={values.title}
              onChange={(e) => onChangeValue("title", e.target.value)}
              error={errors.title}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4, xl: 3 }}>
            <Input
              type="date"
              getHours
              value={values.publishDate}
              name="publishDate"
              title="Data da publicação"
              onChange={(value) => {
                onChangeValue("publishDate", value);
              }}
              error={errors.publishDate}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 8, xl: 9 }}>
            <Input
              type="text"
              name="autor"
              title="Autor da Publicação"
              value={values.autor}
              onChange={(e) => onChangeValue("autor", e.target.value)}
              error={errors.autor}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.container}>
        <PageListHeader
          title="Tags"
          titleAdd="Adicionar tag"
          handleAdd={handleOpenList}
          fontSize={22}
        />
        <Table
          tableOptions={false}
          columns={[
            { field: "name", headerName: "Nome", flex: 1, align: "center" },
            {
              field: "actions",
              headerName: "Ações",
              sortable: false,
              filterable: false,
              align: "center",
              renderCell: (params) => (
                <TableActions handleDelete={() => handleDeleteSubstitute(params)} />
              ),
            },
          ]}
          data={values?.tags}
        />
        <ModalBox open={openList} onClose={handleCloseList} bg="white" maxWidth={400}>
          <ListComponent
            data={tags}
            checkedData={values?.tags}
            title="Tags"
            onClose={handleCloseList}
            handleAdd={(values) => onChangeValue("tags", values)}
          />
        </ModalBox>
      </Box>
      <Box sx={formStyles.container}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6, lg: 5 }}>
            <Typography sx={{ color: errors.image && "#d32f2f", ...formStyles.title }}>
              Imagem da Publicação
            </Typography>
            <ImageInput
              title="Selecionar imagem"
              imageFile={values.image}
              onChange={(event) => {
                changeHandler(event);
                onChangeValue("image", event.target.files[0].name);
              }}
              uploadedImageRef={uploadedImage}
              error={errors.image}
            />
            {errors.image && <Typography sx={formStyles.errorMsg}>{errors.image}</Typography>}
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 7 }}>
            <Typography sx={formStyles.title}>Quadro QSO</Typography>
            <QSOTable
              handleChange={(table) => onChangeValue("qsoTable", table)}
              values={values.qsoTable}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography sx={formStyles.title}>Url do Vídeo</Typography>
            <Input
              type="text"
              name="videoUrl"
              placeholder="Url do vídeo"
              value={values.videoUrl}
              onChange={(e) => onChangeValue("videoUrl", e.target.value)}
              error={errors.videoUrl}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography sx={formStyles.title}>Multimídia</Typography>
            <FormControlLabel
              sx={formStyles.controlLabel}
              label="Adicionar publicação em Multimídia"
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values.addToMedia}
                  onChange={() => onChangeValue("addToMedia", !values.addToMedia)}
                  name="addToMedia"
                />
              }
            />
          </Grid>

          <Grid size={12}>
            <Typography sx={{ ...formStyles.title, color: errors.text && "#d32f2f" }}>
              Texto
            </Typography>
            <TextEditor
              initialValue={values.text}
              name="text"
              height={400}
              handleEditorChange={(content) => {
                onChangeValue("text", content);
              }}
              error={errors.text}
            />
            {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
          </Grid>
        </Grid>
      </Box>

      <Box sx={formStyles.doubleButtonContainer}>
        <ButtonCommon
          sx={formStyles.button}
          color="secondary"
          onClick={() => handleSubmit({ ...values, draft: true })}
        >
          Salvar como rascunho
        </ButtonCommon>
        <ButtonCommon
          sx={formStyles.button}
          onClick={() => handleSubmit({ ...values, draft: false })}
        >
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default BlogForm;
