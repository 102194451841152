import React, { useContext, useRef } from "react";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Menu from "~/presentation/components/Menu";
import AppContext from "~/presentation/AppContext";
import { Avatar, IconButton, MenuItem, MenuList, Typography as Text, Box } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import CountDownToken from "../CountDownToken/CountDownToken";
import { useNavigate } from "react-router-dom";

const MenuListItems = ({ open, handleClose, handleListKeyDown, children, ...props }) => (
  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} {...props}>
    <MenuItem onClick={handleClose}>{children} Nº 1</MenuItem>
    <MenuItem onClick={handleClose}>{children} Nº 2</MenuItem>
    <MenuItem onClick={handleClose}>{children} Nº 3</MenuItem>
  </MenuList>
);

const DashboardToolbar = ({ windowWidth }) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  let userFirstLetter = localStorage.GT_user
    ? useRef(JSON.parse(localStorage.GT_user).name.charAt(0))
    : "";

  const emailBtnRef = useRef(null);
  const notificationsBtnRef = useRef(null);
  const profileBtnRef = useRef(null);
  const { current: avatarColor } = useRef(`#${Math.floor(Math.random() * 16777215).toString(16)}`);

  const iconSize = () => (windowWidth > 425 ? "medium" : "small");

  return (
    <Box sx={styles.container}>
      <Text sx={styles.text}>Painel admin - gestaotributaria.com.br</Text>
      <Box sx={styles.iconsContainer}>
        <CountDownToken />

        {/* menu com ícone de email */}
        <Menu
          anchorRef={emailBtnRef}
          anchorButton={(props) => (
            <IconButton size={iconSize()} {...props}>
              <MailIcon fontSize="inherit" sx={{ color: "#fff" }} />
            </IconButton>
          )}
        >
          {(props) => <MenuListItems {...props}>Email</MenuListItems>}
        </Menu>

        {/* menu com ícone de sino/notificação */}
        <Menu
          anchorRef={notificationsBtnRef}
          anchorButton={(props) => (
            <IconButton size={iconSize()} {...props}>
              <NotificationsIcon fontSize="inherit" sx={{ color: "#fff" }} />
            </IconButton>
          )}
        >
          {(props) => <MenuListItems {...props}>Notificação</MenuListItems>}
        </Menu>

        {/* menu com avatar de usuário */}
        <Menu
          anchorRef={profileBtnRef}
          anchorButton={(props) => (
            <IconButton {...props}>
              <Avatar sx={{ ...styles.avatar, backgroundColor: avatarColor }}>
                {userFirstLetter.current}
              </Avatar>
            </IconButton>
          )}
        >
          {(props) => (
            <MenuList id="profile-options-list" {...props}>
              <MenuItem onClick={() => navigate(`/cliente`)}>Ir para Dashboard</MenuItem>
              <MenuItem onClick={() => navigate(`/admin/meu-perfil`)}>Meu Perfil</MenuItem>
              <MenuItem onClick={() => context.handleLogout()}>
                Logout
                <PowerSettingsNewIcon fontSize="small" />
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      </Box>
    </Box>
  );
};

export default DashboardToolbar;

const styles = {
  container: {
    height: "100%",
    width: "100%",
    maxHeight: "50px",
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    gap: "6px",
  },
  iconsContainer: {
    display: "flex",
    justifySelf: " flex-end",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  avatar: {
    height: "26px",
    width: "26px",
    "@media screen and (min-width: 425)": {
      height: "35px",
      width: "35px",
    },
  },
  optionsContainer: {
    "@media (min-width: 480px)": {
      display: "flex",
      alignItems: "center",
      gap: 1,
    },
  },
  text: {
    fontFamily: "Montserrat",
    color: "#fff",
    fontSize: 8,
    "@media (min-width: 600px)": {
      fontSize: 13,
    },
  },
  searchBox: {
    margin: "0 10px",
    maxWidth: "300px",
    backgroundColor: "#e6e6e6",
    borderRadius: 8,
    paddingTop: "2px",
  },
};
