import BaseSectionHeader from "~/presentation/views/Blog/components/SectionHeader";
import SecondarySearchField from "~/presentation/components/SecondarySearchField";
import { Pagination as MuiPagination, styled } from "@mui/material";
import { Inbox } from "@mui/icons-material";

export const Container = styled("div")({
  background: "#fafafa",
  padding: "max(40px, 6vw) max(10px, 5vw)",

  "& h3": {
    color: "#1341a1",
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 600,
    fontSize: "max(1.6vw, 18px)",
  },
});

export const SectionHeader = styled(BaseSectionHeader)({});

export const SearchAndFilterWrapper = styled("div")({
  padding: "max(40px, 3vw) max(16px, 5vw) max(60px, 2.5vw)",
  display: "grid",
  gridTemplateColumns: "auto",
  gridGap: "max(40px, 6vw)",
  gridTemplateAreas: '"S" "F"',
  background: "#1341a1",
  borderRadius: "max(8px, 1vw)",
  marginTop: "max(30px, 4vw)",
  marginBottom: "max(20px, 2vw)",

  "& h3": {
    color: "#fafafa",
  },

  "@media screen and (min-width: 768px)": {
    gridTemplateColumns: "auto auto",
    gridTemplateAreas: '"F S"',
  },
});

export const SearchFieldWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  flexFlow: "column",
  gap: "max(1.6vw, 18px)",
  gridArea: "S",
});

export const SearchField = styled(SecondarySearchField)({
  invertedColors: true,
});

export const FiltersWrapper = styled("div")({
  display: "grid",
  gridGap: "max(1.6vw, 18px)",
  alignItems: "center",
  gridArea: "F",
});

export const CategoriesWrapper = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "max(0.9vw, 10px)",
});

export const CategoryButton = styled("button")({
  outline: "none",
  background: "transparent",
  border: "solid #fafafa 2px",
  borderRadius: "50px",
  padding: "max(0.3vw, 4px) max(1vw, 12px)",
  fontSize: "max(16px, 1.2vw)",
  fontFamily: '"Montserrat", sans-serif',
  transition: "all 0.3s ease-in-out",
  color: "#fafafa",
  cursor: "pointer",

  "&:hover, &.focused": {
    background: "#fafafa",
    color: "#022550",
  },
});

export const ClearFilterButton = styled(CategoryButton)({
  borderColor: "#fc4b6c",
  color: "#fc4b6c",

  "&:hover": {
    background: "#fc4b6c",
    color: "#fafafa",
  },
});

export const CardsWrapper = styled("div")({
  padding: "max(40px, 3vw) max(6px, 3vw)",
  display: "grid",
  justifyContent: "center",
  gridRowGap: "max(6vw, 40px)",
  gridTemplateColumns: "100%",

  "@media screen and (min-width: 600px)": {
    gridTemplateColumns: "70%",
  },

  "@media screen and (min-width: 960px)": {
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: "2%",
  },
});

export const Pagination = styled(MuiPagination)({
  justifySelf: "center",
  padding: "30px",

  "@media screen and (min-width: 960px)": {
    gridColumn: "1/4",
  },

  "&.MuiPagination-root": {
    display: "flex",
    alignItems: "center",
    ".MuiPaginationItem-root": {
      color: "inherit",

      "&.Mui-selected": {
        backgroundColor: "#1341a1",
        color: "#fafafa",
      },
    },
  },
});

export const InboxIcon = styled(Inbox)({
  "&.MuiSvgIcon-root": {
    fill: "#1341a1",
  },
});

export const NoPostsFound = styled("div")({
  justifySelf: "center",
  display: "flex",
  alignItems: "center",
  fontSize: "max(18px, 1.4vw)",
  gap: "max(16px, 1.2vw)",
  marginTop: "max(36px, 5vw)",
  padding: "max(80px, 8vw) 0",
  flexFlow: "column",
  textAlign: "center",

  "@media screen and (min-width: 768px)": {
    flexFlow: "row",
  },

  "@media screen and (min-width: 960px)": {
    gridColumn: "1/4",
  },
});
