import React, { useEffect, useState, useContext } from "react";
import { Box, Typography as Text } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Input from "../../../../components/Common/Inputs";
import RButton from "../../../../components/Common/Buttons/RButton";
import validationSchema from "./validationSchema";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import removeMask from "~/presentation/functions/removeMask";

export default function MatrizCompany({ companyUser }) {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const companyUseCases = new ApiCompanyUseCases(makeHttpClient());

  const [values, setValues] = useState({
    id: 0,
    cnpj: "",
    name: "",
    mobilePhone: "",
    zipCode: "",
    complement: "",
    contactName: "",
    contactCpf: "",
    email: "",
    address: "",
    homePhone: "",
    isECD: false,
    isExemptionFrom: false,
    isExemptionAgreement: false,
    isUnion: false,
    isEFR: false,
    cnpjEFR: "",
    taxClassificationId: null,
    codeUG: "",
  });
  const [errors, setErrors] = useState({});

  const handleChangeValues = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (!companyUser) {
      return;
    }
    setValues({
      id: companyUser?.id,
      cnpj: companyUser?.cnpj,
      name: companyUser?.name,
      mobilePhone: companyUser?.mobilePhone,
      zipCode: companyUser?.zipCode,
      complement: companyUser?.complement,
      contactName: companyUser?.contactName,
      contactCpf: companyUser?.contactCpf,
      email: companyUser?.email,
      address: companyUser?.address,
      homePhone: companyUser?.homePhone,
      isECD: companyUser?.isECD,
      isExemptionFrom: companyUser?.isExemptionFrom,
      isExemptionAgreement: companyUser?.isExemptionAgreement,
      isUnion: companyUser?.isUnion,
      isEFR: values.isEFR,
      cnpjEFR: companyUser?.cnpjEFR,
      taxClassificationId: companyUser?.taxClassification?.id,
      legalNature: companyUser?.legalNature,
      codeUG: companyUser?.codeUG,
    });
  }, [companyUser]);

  const handleSubmitCompany = async () => {
    const dataToSubmit = {
      id: values?.id,
      cnpj: values?.cnpj,
      name: values?.name,
      active: companyUser?.active,
      mobilePhone: values?.mobilePhone,
      zipCode: removeMask(values?.zipCode, "cep"),
      complement: values?.complement,
      dateEnd: companyUser?.endDate,
      dateStart: companyUser?.startDate,
      contactName: values?.contactName,
      contactCpf: values?.contactCpf,
      email: values?.email,
      address: values?.address,
      homePhone: values?.homePhone,
      dateCompanyRegistered: companyUser?.dateCompanyRegistered,
      legalNatureId: companyUser?.legalNature?.id,
      categoryId: companyUser?.category?.id,
      taxClassificationId: null,
      cityId: companyUser?.city?.id,
      planId: companyUser?.plan?.id,
      isECD: values?.isECD,
      isExemptionFrom: values?.isExemptionFrom,
      isExemptionAgreement: values?.isExemptionAgreement,
      isUnion: values?.isUnion,
      isEFR: values?.isEFR,
      cnpjEFR: values?.cnpjEFR,
      codeUG: values?.codeUG,
    };
    validationSchema
      .validate(dataToSubmit, { abortEarly: false })
      .then(async () => {
        setErrors({});
        try {
          await companyUseCases.update(dataToSubmit);
          setFeedbackSnackbar({
            isOpen: true,
            message: "Informações atualizadas com sucesso!",
            type: "success",
          });
        } catch (error) {
          setFeedbackSnackbar({
            isOpen: true,
            message: "Erro ao atualizar Informações",
            type: "error",
          });
        }
      })
      .catch((errors) => {
        setErrors(
          errors?.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  return (
    <>
      <Box sx={{ margin: 3 }}>
        <Box sx={{ backgroundColor: "#E6E6E6", borderRadius: 2 }}>
          <Grid container spacing={1} sx={{ padding: 3 }}>
            <Grid size={{ xs: 4, sm: 12 }}>
              <Text style={styles.Title}>Informações da Empresa</Text>
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <Input
                type="mask"
                mask={"00.000.000/0000-00"}
                title="CNPJ"
                value={values.cnpj}
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 7 }}>
              <Input
                type="text"
                placeholder="Estabelecimento"
                title="Estabelecimento"
                value={values?.name || "carregando dados do estabelecimento"}
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <Input
                type="text"
                placeholder="Cód. UG"
                title="Cód. Unidade Gestora"
                value={values?.codeUG}
                onChange={(e) => handleChangeValues("codeUG", e.target.value)}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <Input
                type="text"
                placeholder="Natureza Jurídica"
                title="Natureza Jurídica"
                value={values?.legalNature?.description}
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 5 }}>
              <Input
                type="text"
                placeholder="Endereço"
                title="Endereço"
                value={values?.address}
                onChange={(e) => handleChangeValues("address", e.target.value)}
                error={errors.address}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <Input
                type="text"
                placeholder="Complemento"
                title="Complemento"
                value={values?.complement}
                onChange={(e) => handleChangeValues("complement", e.target.value)}
                error={errors.complement}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <Input
                type="mask"
                mask="00000-000"
                placeholder="00000-000"
                title="CEP"
                value={values?.zipCode}
                onChange={(e) => handleChangeValues("zipCode", e)}
                error={errors.zipCode}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <Input
                type="text"
                title="Responsável pelas Informações"
                placeholder="Insira nome"
                value={values?.contactName}
                onChange={(e) => handleChangeValues("contactName", e.target.value)}
                error={errors.contactName}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 2 }}>
              <Input
                type="mask"
                mask="000.000.000-00"
                placeholder="000.000.000-00"
                title="CPF do Responsável"
                value={values?.contactCpf}
                onChange={(e) => handleChangeValues("contactCpf", e)}
                error={errors.contactCpf}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <Input
                type="email"
                placeholder="E-mail"
                title="E-mail"
                value={values?.email}
                onChange={(e) => handleChangeValues("email", e.target.value)}
                error={errors.email}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <Input
                type="mask"
                mask="(00) 0000-0000"
                placeholder="(00) 0000-0000"
                title="Telefone"
                value={values?.homePhone}
                onChange={(e) => handleChangeValues("homePhone", e)}
                error={errors.homePhone}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 2 }}>
              <Input
                type="mask"
                mask="(00) 00000-0000"
                placeholder="(00) 00000-0000"
                title="Celular"
                value={values?.mobilePhone}
                onChange={(e) => handleChangeValues("mobilePhone", e)}
                error={errors.mobilePhone}
              />
            </Grid>
            <Grid size={12} sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
              <RButton sx={{ width: "min(150px, 100%)" }} onClick={handleSubmitCompany}>
                Salvar
              </RButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
};
