import * as Yup from "yup";

const validationSchema = (typeId) => {
  return Yup.object({
    title: Yup.string().required("Campo Obrigatório!"),
    type: Yup.object().nullable().required("Campo obrigatório!"),
    publishDate: Yup.date()
      .typeError("Selecione uma data!")
      .when([], {
        is: () => typeId === 4,
        then: (schema) => schema.required("Campo Obrigatório!"),
        otherwise: (schema) => schema.notRequired(),
      }),
    videoUrl: Yup.string().when([], {
      is: () => typeId === 4,
      then: (schema) => schema.required("Campo Obrigatório!"),
      otherwise: (schema) => schema.notRequired(),
    }),
    category: Yup.object()
      .nullable()
      .when([], {
        is: () => typeId === 3,
        then: (schema) =>
          schema
            .test("is-valid-category", "Selecione uma categoria!", (value) => {
              return value && value.id && value.name;
            })
            .required("Campo obrigatório!"),
        otherwise: (schema) => schema.notRequired(),
      }),
    text: Yup.string().required("Campo Obrigatório!"),
    image: Yup.string().when([], {
      is: () => typeId !== 4,
      then: (schema) => schema.required("Campo Obrigatório!"),
      otherwise: (schema) => schema.notRequired(),
    }),
    summary: Yup.string().when([], {
      is: () => typeId === 3,
      then: (schema) =>
        schema
          .max(512, "O resumo não pode ter mais que 512 caracteres.")
          .required("Campo Obrigatório!"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
};

export default validationSchema;
