import { styled } from "@mui/material/styles";

export const Container = styled("div")({
  position: "relative",
  height: "100%",
});

export const Wrapper = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "10px",
  overflowY: "auto",
  padding: "1.5vw",
  height: "calc(100% - 40px - 12px)", // 40: h2 height // 12px: margin-top
  display: "grid",
  gap: "16px",

  "@media screen and (max-width: 350px)": {
    height: "calc(100% - 80px - 12px)", // 80: h2 height (2 lines) + 12px: margin-top
  },

  // Preserve the padding bottom
  "&::after": {
    content: '""',
    display: "block",
    height: "1px",
    width: "100%",
  },
});

export const MessageWrapper = styled("div")({
  display: "grid",
  gap: "12px",
  padding: "0.5vw",

  ".fromTo": {
    "> div": {
      display: "grid",
      gridTemplateColumns: "min-content auto",

      "> span": {
        marginLeft: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },

    "@media screen and (max-width: 600px)": {
      padding: "6px 12px",
    },
  },

  "> hr": {
    border: "none",
    height: "1px",
    background: "#383f48",
  },
});

export const Chat = styled("div")({
  display: "grid",
  gap: "12px",

  ".chatMessage": {
    background: "#e1e1e1",
    borderRadius: "10px",
    padding: "1vw",

    ".sender": {
      marginBottom: "6px",
    },
  },
});
