import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import Table from "~/presentation/components/Table";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";
import { Box } from "@mui/material";
import TableActions from "~/presentation/components/TableActions";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";

const Terms = ({ termUseCases }) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [terms, setTerms] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    openBackdrop(true, "Carregando os dados");
    const terms = await termUseCases.getAll();
    setTerms(terms);
    openBackdrop(false);
  };

  const columns = [
    { field: "term", headerName: "Termo de busca", flex: 1 },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este termo?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo termo");
    try {
      await termUseCases.deleteTerm(id);
      setTerms((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o termo",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Box>
      <PageListHeader
        title="Termos de Pesquisa"
        titleAdd="Criar novo termo de pesquisa"
        handleAdd={() => handleOpenForm()}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={terms} />
      </Box>
      <ModalBox open={openForm} onClose={handleCloseForm} bg="white">
        <CreateEdit data={formProps} onCloseForm={handleCloseForm} refreshData={fetchData} />
      </ModalBox>
    </Box>
  );
};

export default Terms;
