import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
// layout
import HomeLayout from "~/presentation/layouts/HomeLayout";
// sections
import HomeSection from "~/presentation/views/LandingPage/Home/Sections/Home";
import AboutSection from "~/presentation/views/LandingPage/Home/Sections/About";
import ProductsSection from "~/presentation/views/LandingPage/Home/Sections/Products";
import ClientsSection from "~/presentation/views/LandingPage/Home/Sections/Clients";
import TaxInfoSection from "~/presentation/views/LandingPage/Home/Sections/TaxInfo";
import PlansSection from "~/presentation/views/LandingPage/Home/Sections/Plans";
import ContactSection from "~/presentation/views/LandingPage/Home/Sections/Contact";
import navbarItems from "~/presentation/views/LandingPage/data/navbarItems";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
const Home = ({
  authenticationUseCases,
  contactUseCases,
  planUseCases,
  companyUseCases,
  subscribeUseCases,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const handleLogin = async (values) => {
    return authenticationUseCases.auth(values);
  };
  const navigate = useNavigate();

  const handleSubscription = async (values) => {
    openBackdrop(true, "Cadastrando inscrição");
    try {
      await subscribeUseCases.create(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Inscrição realizada com sucesso!",
        type: "success",
      });
      navigate("/");
    } catch (err) {
      console.error(err);
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no cadastro",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleSubmitContactForm = (values) => {
    return contactUseCases.contactUsForm(values);
  };

  /* const handleSubscription = (values) => {
    subscribeUseCases.create(values);
    return contactUseCases.subscription(values);

  };*/

  return (
    <HomeLayout navbarItems={navbarItems} handleLogin={handleLogin} noNavbarSpace>
      {({ mobile, openModal }) => (
        <>
          <HomeSection mobile={mobile} id="home" />
          <AboutSection openModal={openModal} id="about" />
          <ProductsSection openModal={openModal} id="products" />
          <ClientsSection id="clients" />
          <TaxInfoSection id="taxInformation" />
          <PlansSection
            openModal={openModal}
            id="plans"
            onSubscription={handleSubscription}
            planUseCases={planUseCases}
            companyUseCases={companyUseCases}
          />
          <ContactSection id="contact" onSubmitContactForm={handleSubmitContactForm} />
        </>
      )}
    </HomeLayout>
  );
};

export default Home;
