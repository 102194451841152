import React from "react";
import { QuestionLabel } from "~/presentation/views/GTFacil/Simulator/components/QuestionLabel";
import { QuestionContainer } from "~/presentation/views/GTFacil/Simulator/components/QuestionContainer";
import SelectField from "~/presentation/components/Select";
import { Box } from "@mui/material";

export const SelectQuestion = ({
  text,
  excerpt,
  value,
  onChange,
  options,
  vertical = false,
  menuPlacement,
  disabled,
}) => {
  return (
    <QuestionContainer vertical={vertical}>
      <QuestionLabel text={text} excerpt={excerpt} />
      <Box sx={{ backgroundColor: "#fafafa", height: "min-content" }}>
        <SelectField
          onChange={onChange}
          options={options}
          field={{ value }}
          padding="4px 16px"
          menuPlacement={menuPlacement}
          placeholder="Selecione"
          disabled={disabled}
        />
      </Box>
    </QuestionContainer>
  );
};
