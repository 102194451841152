import React, { useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import { useTokenCheck } from "~/presentation/hooks/useTokenCheck";
import { DialogContext } from "../providers/DialogProvider";
import LoginBox from "../views/LandingPage/Home/LoginBox";

const PrivateRoute = ({ roles, children }) => {
  const { setUser, isLogged } = useContext(AppContext);
  const { currentUser, isTokenValid } = useTokenCheck();
  const { openDialogBox } = useContext(DialogContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleStorageChange = (event) => {
    if (event.key === "GT_token" || (event.type === "storage" && event.newValue === null)) {
      //window.location.reload(); // Reload the app to update the PrivateRoute component
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const localStorageUser = localStorage.getItem("GT_user");
    setUser({ ...currentUser, ...JSON.parse(localStorageUser) });
  }, []);

  const renderLoginBox = () => (
    <LoginBox
      handleClose={() => openDialogBox({ ...openDialogBox, open: false })}
      navigate={navigate}
      isReLogin
    />
  );
  const openLoginDialog = () => {
    openDialogBox({
      disableScrollLock: true,
      customComponent: () => renderLoginBox(),
      blockScreen: true,
    });
  };

  const isUserLogged = isLogged();
  // Se o usuário está logado, mas o token não é válido, abre o diálogo de re-login
  if (isUserLogged && currentUser && !isTokenValid) {
    openLoginDialog();
  }

  // Se o usuário não está logado, redireciona para a página inicial
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // Verifica se o usuário tem permissão para acessar a rota (baseado em roles)
  if (roles && !roles.includes(currentUser.role)) {
    return <Navigate to="/cliente" />;
  }

  // Verificação adicional para rotas específicas de "conteudo-selecionado"
  if (
    pathname.includes("conteudo-selecionado") &&
    !["user", "admin", "manager", "superAdmin"].includes(currentUser.role)
  ) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return <Navigate to="/cliente" />;
  }

  // Retorna o conteúdo protegido se todas as verificações passarem
  return children;
};

export default PrivateRoute;
