import React, { useState, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { Container, Box } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import { AddBusiness } from "@mui/icons-material";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/PartnerForm/PartnerForm";
import Grid from "@mui/material/Grid2";

export const CompanyForm = ({ partners, handleReturn, refreshData }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const apiNfse = new ApiNfseUseCases(makeHttpClient());
  const [open, setOpen] = useState(false);
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [nFseNumberError, setNfseNumberError] = useState(null);

  //filtrando cnpjs partners
  const cnpjPartnersFilter = partners.filter((partners) => partners.personType === 1);

  const [nfseData, setNfseData] = useState({
    businessPartnerId: "",
    nFseNumber: "",
    dateOfIssue: new Date(),
    amountBC: "",
    typeDoc: QSO === "D" ? 0 : null,
  });

  const isTypeDocEmpy = QSO !== "D" && nfseData.typeDoc === null;

  const isSomeFieldEmpty =
    !nfseData?.businessPartnerId || !nfseData?.dateOfIssue || !nfseData?.amountBC || isTypeDocEmpy;

  const handleChange = (e) => {
    const newValue = e.target.value;
    const maxDigits = newValue.length <= 15;
    if (maxDigits) {
      setNfseData({ ...nfseData, nFseNumber: newValue });
      setNfseNumberError(null);
    } else {
      setNfseNumberError("Deve ter no máximo 15 dígitos.");
    }
  };

  const handleDateChange = (name, date) => {
    setNfseData((prev) => ({
      ...prev,
      [name]: date ? new Date(date) : "",
    }));
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Cadastrando Documento Fiscal...");
    try {
      await apiNfse.createNfse(nfseData);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Doc. cadastrado com sucesso",
        type: "success",
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar.",
        type: "error",
      });
    } finally {
      openBackdrop(false);
      handleReturn();
    }
  };

  const messageErrorText = "Campo Obrigatório";

  const documentOptions = [
    {
      value: 1,
      title: "Nota Fiscal de Produto Eletrônica (NF-e)",
    },
    {
      value: 0,
      title: "Nota Fiscal de Serviço Eletrônica (NFs-e)",
    },
  ];

  return (
    <>
      <Container maxWidth={false} sx={{ padding: 3 }}>
        <Box sx={{ backgroundColor: "#E6E6E6", borderRadius: 2 }}>
          <Grid container spacing={2} sx={{ paddingInline: 4, paddingBlock: 2 }}>
            <Grid size={12}>
              <Input
                type="select"
                title="Selecionar contratado"
                options={cnpjPartnersFilter}
                getOptionLabel={(option) =>
                  `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`
                }
                placeholder="Selecione o contratado"
                value={
                  cnpjPartnersFilter.find((item) => item.id === nfseData.businessPartnerId) || null
                }
                onChange={(option) => {
                  setNfseData({ ...nfseData, businessPartnerId: option?.id });
                }}
                error={!nfseData?.businessPartnerId && messageErrorText}
                icon={{
                  title: "Criar novo contratado",
                  func: () => {
                    handleOpen();
                  },
                  component: <AddBusiness sx={{ color: "#fff" }} />,
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Input
                style={{ ...styles.inputs, borderOnHover: "1px solid #E6E6E6" }}
                disabled={QSO === "D"}
                type="select"
                title="Tipo de Documento Fiscal"
                options={documentOptions}
                getOptionLabel={(option) => option.title}
                placeholder="Selecione o tipo de documento"
                value={documentOptions.find((item) => item.value === nfseData?.typeDoc)}
                onChange={(option) => {
                  setNfseData({ ...nfseData, typeDoc: option.value });
                }}
                error={nfseData?.typeDoc === null && messageErrorText}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 2 }}>
              <Input
                style={styles.inputs}
                title="Nº do Doc. Fiscal"
                type="number"
                name="nFseNumber"
                value={nfseData.nFseNumber}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleChange(e);
                  }
                }}
                error={nFseNumberError}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <Input
                title="Data de Emissão"
                type="date"
                value={nfseData.dateOfIssue}
                onChange={(date) => handleDateChange("dateOfIssue", date)}
                error={!nfseData?.dateOfIssue && messageErrorText}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <Input
                border="1px solid #E6E6E6"
                backgroundColor="#fff"
                title="Valor Bruto"
                type="amount"
                name="amountBC"
                value={nfseData.amountBC}
                onChange={(value) => {
                  setNfseData({ ...nfseData, amountBC: value });
                }}
                error={!nfseData?.amountBC && messageErrorText}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end", gap: 1, p: 4 }}>
            <RButton sx={{ width: "min(150px, 100%)" }} variant="outlined" onClick={handleReturn}>
              Sair
            </RButton>
            <RButton
              sx={{ width: "min(175px, 100%)" }}
              onClick={handleSubmit}
              disabled={isSomeFieldEmpty}
            >
              Salvar
            </RButton>
          </Box>
        </Box>
      </Container>
      <ModalContainer open={open} onClose={handleClose}>
        <PartnerForm personType={1} onClose={handleClose} refreshData={refreshData} />
      </ModalContainer>
    </>
  );
};

const styles = {
  content: {
    paddingBlock: 5,
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  buttonsContainer: {
    justifyContent: "right",
    display: "flex",
    gap: 1,
    mt: 6,
  },
};
