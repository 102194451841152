import React from "react";
import { TextField, InputLabel, Box, Typography } from "@mui/material";

function renderInputComponent(type, props) {
  const label = (
    <InputLabel
      sx={{
        ...styles.title,
        color: props?.error ? "#d32f2f" : "#021148",
      }}
    >
      {props.title}
    </InputLabel>
  );
  const errorMessage = <Typography sx={styles.ErrorText}>{props?.error}</Typography>;

  switch (type) {
    case "text":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="text"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "password":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="password"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "email":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="email"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    default:
      return (
        <Box sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: "#d32f2f" }}>
            {"Defina o Type Input: select, text, number, amount, mask, aliquot"}
          </InputLabel>
          <TextField sx={InputStyles()} variant="outlined" />
        </Box>
      );
  }
}

// ⇣⇣⇣⇣⇣⇣⇣⇣ SOBRE A ESTILIZAÇÃO DOS INPUTS: ⇣⇣⇣⇣⇣⇣⇣⇣
// styles={{}} e passar os styles adicionais que queremos

function Input({ type, ...props }) {
  return <>{renderInputComponent(type, props)}</>;
}

export default Input;

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "left",
    fontFamily: "Montserrat",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingLeft: "2px",
  },
  ErrorText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    color: "#d32f2f",
    paddingLeft: 1,
  },
};

const InputStyles = (styles, error) => ({
  "input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 1000px transparent inset",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: error ? "1px solid #081445" : styles?.border ? styles.border : "1px solid #7D7D7D",
  },
  "& .MuiInputBase-input::placeholder": {
    opacity: 0.8,
  },
  ".MuiOutlinedInput-root": {
    width: "100%",
    fontSize: 14,
    fontFamily: "Montserrat",
    backgroundColor: "transparent",
    ...styles,
  },
});
