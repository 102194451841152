import React, { useContext, useEffect, useState } from "react";
import AnswerForm from "./Form";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeAnswerUseCases } from "~/main/factories/use-cases/answers";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";

const initialValues = {
  id: 0,
  gtAnswerName: "",
  description: "",
  typeTax: null,
  cityId: null,
  isSimple: true,
  companyTypes: [],
  categories: [],
  cnaes: [],
  gtAnswerItems: [],
  searchTerms: [],
};

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  fetchTerms,
  fetchCNAE,
  fetchItemAnswer,
  fetchCategories,
  cnaes,
  itemsAnswer,
  categories,
  terms,
}) {
  const answerUseCases = makeAnswerUseCases();
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data || data.isCopy; //caso seja copiar dados

  //input value and errors
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (terms.length === 0) {
      fetchTerms();
    }
    if (cnaes.length === 0) {
      fetchCNAE();
    }
    if (itemsAnswer.length === 0) {
      fetchItemAnswer();
    }
    if (categories.length === 0) {
      fetchCategories();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      openBackdrop(true, "Carregando informação");
      const result = await answerUseCases.getById(data.id);
      setValues({
        ...result,
        companyTypes:
          result.companyTypes?.length > 0
            ? result.companyTypes.map((item) => ({
                id: item.companyType,
                ...item,
              }))
            : null,
      });
      openBackdrop(false);
    };
    if (data?.id) {
      fetch();
    }
  }, [data]);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} resposta`);

    const submitValues = {
      ...values,
      companyTypes:
        values.companyTypes?.map((item) => {
          return {
            companyType: item.id,
          };
        }) || [],
      categories:
        values.categories?.map((category) => {
          let cat = {
            id: category.id,
            name: category.name,
            letter: category.letter,
          };
          return cat;
        }) || null,
      cityId: values.cityId,
      cnaes:
        values.cnaes?.map((item) => {
          let cnae = {
            id: item.id,
            code: item.code,
            description: item.description,
            serviceCodes: item.serviceCodes,
          };
          return cnae;
        }) || null,
      gtAnswerItems:
        values.gtAnswerItems?.map((item) => {
          let gtAnswerItem = {
            id: item.id,
            title: item.title,
          };
          return gtAnswerItem;
        }) || null,
      searchTerms:
        values.searchTerms?.map((item) => {
          let term = {
            id: item.id,
            term: item.term,
          };
          return term;
        }) || null,
    };

    try {
      if (isCreate) {
        await answerUseCases.createAnswer(submitValues);
      } else {
        await answerUseCases.update(submitValues);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Resposta ${isCreate ? "criada" : "editada"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro. ${error?.data || error?.response?.data}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} resposta`}
        handleBack={onCloseForm}
      />
      {values && (
        <AnswerForm
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
          fetchTerms={fetchTerms}
          fetchItemAnswer={fetchItemAnswer}
          fetchCategories={fetchCategories}
          cnaes={cnaes}
          itemsAnswer={itemsAnswer}
          categories={categories}
          terms={terms}
        />
      )}
    </>
  );
}

export default CreateEdit;
