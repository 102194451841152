import React, { useEffect, useState } from "react";
import { NewUserForm } from "./components/NewUserForm";
import { Box, Typography as Text } from "@mui/material";
import Grid from "@mui/material/Grid2";

export const UserPlan = ({ companyUseCases, userUseCases }) => {
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const [planDetails, setPlanDetails] = useState();

  const getPlanDetails = async () => {
    const result = await companyUseCases.getPlanDetails(user.companyId);
    setPlanDetails(result);
  };

  useEffect(() => {
    getPlanDetails();
  }, []);

  return (
    <Grid container spacing={2} sx={{ paddingBlock: 3 }}>
      <Grid size={12}>
        <Text sx={styles.title}>Dados do Plano</Text>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>Descrição do plano:</Text>
          <Text>{planDetails?.name}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>Consultar ISS:</Text>
          <Text>{planDetails?.isConsultISS ? "Sim" : "Não"}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>Gerar relatório:</Text>
          <Text>{planDetails?.isGenerateReport ? "Sim" : "Não"}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>Informações Fiscais:</Text>
          <Text>{planDetails?.isAllowTaxInformation ? "Sim" : "Não"}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>N° de Informações Fiscais: </Text>
          <Text>{planDetails?.taxInformationNumber}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>N° de consultas GT-Fácil:</Text>
          <Text>{planDetails?.consultLimit}</Text>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box sx={styles.containerFields}>
          <Text sx={styles.planDetailsText}>N° de usuários: </Text>
          <Text>{planDetails?.numberOfUsers}</Text>
        </Box>
      </Grid>
      <Grid size={12} sx={{ mt: 12 }}>
        {user && ["manager", "admin", "superAdmin"].includes(user.role) && (
          <NewUserForm userUseCases={userUseCases} companyId={user.companyId} />
        )}
      </Grid>
    </Grid>
  );
};
const styles = {
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  text: {
    color: "#021148",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  planDetailsText: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    mb: 2,
  },
};
