import React, { useState, useEffect, useContext, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
import CustomDialogConfirmPassword from "~/presentation/views/Admin/User/components/FormConfirmPassword";
// functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import RegisterEdit from "./RegisterEdit/RegisterEdit";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";

const User = ({
  userUseCases,
  companyUseCases,
  companyId = null,
  data = [],
  refreshTheData = () => {},
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [users, setUsers] = useState([]);

  //Select
  const [companies, setCompanies] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const handleRole = (type) => {
    if (type === "user") return "Usuário";
    if (type === "manager") return "Gerente";
    if (type === "admin") return "Administrador";
    if (type === "superAdmin") return "Super Administrador";
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 4 },
    { field: "email", headerName: "Email", flex: 4 },
    {
      field: "role",
      headerName: "Cargo",
      flex: 2,
      align: "center",
      renderCell: (params) => handleRole(params.value),
    },
    {
      field: "actions",
      headerName: "Ações",
      renderCell: (params) => handleActions(params.row),
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
    },
  ];

  const fetchData = async () => {
    openBackdrop(true, "Carregando usuários");
    try {
      const users = await userUseCases.getUsers();
      setUsers(users);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar usuários " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleActions = (user) => {
    const { role } = JSON.parse(localStorage.getItem("GT_user"));
    if (role === "superAdmin") {
      return (
        <TableActions
          user={user}
          handleDelete={() => deleteDialog(user.id)}
          handleEdit={() => handleOpenForm(user)}
          handlePromoteOrDemote={() => promoteOrDemoteDialog(user)}
        />
      );
    } else {
      return <TableActions user={user} handleEdit={() => handleOpenForm(user)} />;
    }
  };

  const renderCustomDialogConfirmPassword = (user) => {
    return (
      <CustomDialogConfirmPassword
        handleConfirm={(password) => handlePromoteOrDemote(user, password)}
        handleClose={() => openDialogBox({ open: false })}
      />
    );
  };

  const promoteOrDemoteDialog = (user) => {
    openDialogBox({
      customComponent: () => renderCustomDialogConfirmPassword(user),
    });
  };

  const handlePromoteOrDemote = async (user, password) => {
    const action = user.role === "user" ? "promote" : "demote";
    openBackdrop(
      true,
      action === "promote"
        ? `Promovendo usuário a Admin.`
        : `Removendo privilégios de Admin do usuário.`
    );
    try {
      await userUseCases.promoteOrDemoteUser({
        id: user.id,
        action,
        password,
      });
      setFeedbackSnackbar({
        isOpen: true,
        message: "Usuário atualizado!",
        type: "success",
      });
      if (companyId) {
        await refreshTheData();
      } else {
        await fetchData();
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar usuário.",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este usuário?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo usuário");

    try {
      await userUseCases.deleteUser(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Usuário excluído!",
        type: "success",
      });
      setUsers((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir usuário.",
        type: "error",
      });
    }

    openBackdrop(false);
  };

  const firstRender = useRef(true);

  useEffect(() => {
    if (companyId && data.length > 0) {
      const users = data?.filter((item) => item.companyId === companyId);
      setUsers(users);
    } else {
      if (firstRender.current) {
        fetchData();
        firstRender.current = false;
      }
    }
  }, [data]);

  const getUserGroups = async () => {
    setUserGroups(await userUseCases.getUserGroups());
  };

  const fetchCompanys = async () => {
    openBackdrop(true, "Carregando dados do formulário");
    try {
      const apiCompanies = await companyUseCases.getCompanies();
      apiCompanies.forEach((company) => {
        company.value = company.id;
        company.label = company.name;
      });
      setCompanies(apiCompanies);
      await getUserGroups();
    } catch (error) {
      setCompanies([]);
    }
    openBackdrop(false);
  };

  return (
    <Box>
      <ModalBox open={openForm} onClose={handleCloseForm} bg="white">
        <RegisterEdit
          companyId={companyId}
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={() => {
            if (companyId) {
              refreshTheData();
            } else {
              fetchData();
            }
          }}
          userUseCases={userUseCases}
          companies={companies}
          userGroups={userGroups}
          fetchCompanys={fetchCompanys}
        />
      </ModalBox>
      <PageListHeader
        bg="white"
        title="Usuários"
        titleAccess="Novo usuário"
        handleAdd={() => handleOpenForm()}
      />
      <Box sx={{ margin: companyId ? 0 : 3 }}>
        <Table columns={columns} data={users} />
      </Box>
    </Box>
  );
};

export default User;
