import { styled } from "@mui/material/styles";
import { transparentize } from "polished"; // se ainda precisar do polished
import { IconButton as MuiIconButton } from "@mui/material";

// Container estilizado com grid layout
export const Container = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, min-content) auto;
`;

// IconButton estilizado
export const IconButton = styled(MuiIconButton)(({ theme, backgroundColor }) => ({
  backgroundColor: theme[backgroundColor],

  "&:hover": {
    backgroundColor: backgroundColor ? transparentize(0.5, theme[backgroundColor]) : undefined,
  },

  "&.Mui-disabled .MuiSvgIcon-root": {
    color: transparentize(0.5, "#383f48"),
  },
}));
