import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { makeContentUseCases as contentUseCases } from "~/main/factories/use-cases/content";
import QuadroQSO from "~/presentation/views/Client/components/QuadroQSO";
import { MostAccessed } from "~/presentation/views/Client/Legislation/Home/Sections/MostAccessedSection/MostAccessedSection";
import { Box, Container, Skeleton, Typography } from "@mui/material";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";
import { MostAccessedSection } from "~/presentation/views/Client/Article/components/MostAccessedSection/MostAccessedSection";
import Grid from "@mui/material/Grid2";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const TELEGRAM_IMG = "/assets/canalTelegram.jpg";

export const PostContent = ({ type, post, defaultImg, loading }) => {
  const navigate = useNavigate();
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const qsoTable = post?.qsoTable;
  const autor = post?.autor;
  const audio = post?.audio;
  const source = post?.source;
  const title = post?.title;
  const publishDate = post?.publishDate && new Date(post?.publishDate);
  const image = post?.image && post?.image !== "." ? post?.image : null;
  const text = post?.text;
  const summary = post?.summary;

  const formattedDate = publishDate && formatDateToBrazilian(publishDate);
  const hasQsoTable = qsoTable && Object.values(qsoTable).some((array) => array.length > 0);
  const [mostAccessed, setMostAccessed] = useState([]);

  const fecthData = async () => {
    try {
      if (type === "LEGISLAÇÃO") {
        const response = await contentUseCases().getMostAccessedLegislation({ limit: 3 });
        setMostAccessed(response);
      } else if (type === "ARTIGOS") {
        const response = await contentUseCases().getMostAccessedArticles(3);
        setMostAccessed(response.contents);
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar as publicações mais acessadas.",
        type: "error",
      });
    }
  };

  const goToPost = (id) => {
    if (type === "LEGISLAÇÃO") {
      navigate("/cliente/conteudo-selecionado/legislacao/post/" + id);
    } else if (type === "ARTIGOS") {
      navigate("/cliente/conteudo-selecionado/artigo/post/" + id);
    }
  };

  const getAutorOfPost = () => {
    if (autor) {
      return `${" "}Por: ${" "}${autor}${" "}`;
    }
    if (source) {
      return ` ${" "}Por: ${" "}${source}${" "}`;
    }
  };

  useEffect(() => {
    fecthData();
  }, []);

  const renderMostAccessed = () => {
    if (type === "LEGISLAÇÃO") {
      return (
        <MostAccessed
          mostAccessedPosts={mostAccessed}
          goToPost={(id) => goToPost(id)}
          defaultImage={defaultImg}
        />
      );
    } else if (type === "ARTIGOS") {
      return (
        <MostAccessedSection
          mostAccessedPosts={mostAccessed}
          goToPost={(id) => goToPost(id)}
          defaultImage={defaultImg}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          marginBlock: 2,
          overflow: "hidden",
          width: "100%",
          maxWidth: "100vw",
        }}
      >
        <Grid container spacing={4}>
          <Grid size={{ xs: 12, lg: 8 }}>
            {loading ? (
              <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <Skeleton variant="rounded" height={100} />
                <Skeleton variant="rounded" height={40} />
                <Skeleton variant="rounded" height={1000} />
              </Box>
            ) : (
              <Box>
                <Typography
                  sx={{
                    ...styles.text,
                    fontWeight: 700,
                    fontSize: 25,
                    color: "#213952",
                    marginBlock: 2,
                  }}
                >
                  {title}
                </Typography>

                <Typography
                  sx={{
                    ...styles.text,
                    lineHeight: "36.08px",
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 1,
                  }}
                >
                  {`Publicado em: ${formattedDate} - ${getAutorOfPost()}`}
                </Typography>
                {image && type !== "LEGISLAÇÃO" && (
                  <Box component="img" src={image} alt="post-image" sx={styles.img} />
                )}
                {type === "LEGISLAÇÃO" && summary && (
                  <Typography
                    sx={{
                      ...styles.text,
                      margin: "1.5rem 0 1.5rem auto",
                      textAlign: "justify !important",
                      maxWidth: "50%",
                    }}
                  >
                    {summary}
                  </Typography>
                )}
                <Typography
                  sx={{
                    ...styles.innerHTMLContentStyle,
                    marginTop: 2,
                    textAlign: "justify !important",
                  }}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
                {audio && (
                  <Box>
                    <label>Áudio</label>
                    <audio controls controlsList="nodownload">
                      <source src={audio} type="audio/mpeg" />
                      Seu navegador não suporta o elemento de áudio
                    </audio>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
          <Grid size={{ xs: 12, lg: 4 }} sx={styles.MostAccessed}>
            {loading && !mostAccessed ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={140} />
                <Skeleton variant="rounded" height={140} />
                <Skeleton variant="rounded" height={140} />
                <Skeleton variant="rounded" height={590} />
              </Box>
            ) : (
              <>
                {renderMostAccessed()}
                <Box
                  component="a"
                  href="https://t.me/+j00d6m6nZ1tkMGYx"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: "block",
                    cursor: "pointer",
                    margin: "0 auto 3rem auto",
                  }}
                >
                  <Box component="img" src={TELEGRAM_IMG} sx={styles.flyer} />
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        {hasQsoTable && (
          <Box sx={{ marginBlock: 2 }}>
            <QuadroQSO data={qsoTable} />
          </Box>
        )}
      </Container>
    </>
  );
};

const styles = {
  MostAccessed: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    marginTop: 2,
  },
  flyer: {
    justifySelf: "center",
    height: 500,
    borderRadius: "6px",
    objectFit: "cover",
    transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
    "&:hover": {
      transform: "scale(1.01)",
    },
  },
  text: {
    fontFamily: "Montserrat",
    textAlign: "left",
  },
  img: {
    marginBlock: 2,
    borderRadius: "6px",
    maxWidth: "100%",
  },
  innerHTMLContentStyle: {
    "&& strong": {
      "font-weight": "bold",

      "& *": {
        "font-weight": "bold",
      },
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      borderSpacing: 0,
      marginBlock: "1rem",
      "& td, th": {
        border: "1px solid #ddd",
        padding: "8px",
      },
    },
  },
};
