import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import CreateEdit from "./CreateEdit/CreateEdit";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";

const TypeAnswer = ({ typesAnswerUseCases }) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [typesAnswer, setTypesAnswer] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer();
    setTypesAnswer(apiTypesAnswer);
    openBackdrop(false);
  };

  const columns = [
    { field: "title", headerName: "Título", flex: 5 },
    { field: "order", headerName: "Ordem", flex: 1, align: "center" },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo tipo de resposta");
    try {
      await typesAnswerUseCases.deleteTypeAnswer(id);
      setTypesAnswer((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.data || error?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este serviço?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <PageListHeader title="Tipo de resposta" handleAdd={() => handleOpenForm()} />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={typesAnswer} />
      </Box>
      <ModalBox open={openForm} onClose={handleCloseForm} bg="white">
        <CreateEdit data={formProps} onCloseForm={handleCloseForm} refreshData={fetchData} />
      </ModalBox>
    </Box>
  );
};

export default TypeAnswer;
