import React, { useContext, useEffect, useState } from "react";
import TableActions from "~/presentation/components/TableActions";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { Box } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import Table from "~/presentation/components/Table";
import RegisterEdit from "./RegisterEdit/RegisterEdit";

function Plan({ planUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const { role } = JSON.parse(localStorage.getItem("GT_user"));

  //Data
  const [plans, setPlans] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const response = await planUseCases.getPlans();
    setPlans(response);
    openBackdrop(false);
  };

  const columns = [
    { field: "name", headerName: "Nome", flex: 1 },
    { field: "numberOfUsers", headerName: "Usuários", align: "center", flex: 1 },
    {
      field: "valor",
      headerName: "Preço",
      renderCell: (params) => formatNumberToRealString(params.value),
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo plano");
    try {
      await planUseCases.deletePlan(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Plano excluído!",
        type: "success",
      });
      setPlans((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o plano. " + error?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este plano?`,
      callback: () => handleDelete(id),
    });
  };

  return (
    <>
      <Box>
        {openForm ? (
          <RegisterEdit data={formProps} onCloseForm={handleCloseForm} refreshData={fetchData} />
        ) : (
          <>
            <PageListHeader
              title="Plano"
              titleAccess="Novo plano"
              handleAdd={() => handleOpenForm()}
            />
            <Box sx={{ margin: 3 }}>
              <Table columns={columns} data={plans} />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default Plan;
