import React, { useEffect, useRef, useState } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { Features } from "~/presentation/views/Client/ClientHome/Sections/Features";
import { SelectedContent } from "~/presentation/views/Client/ClientHome/Sections/SelectedContent";
import { scrollToElement } from "~/presentation/functions/scrollToElement";
import useMobile from "~/presentation/hooks/useMobile";
import { Box, Modal } from "@mui/material";
import UnauthorizedModal from "~/presentation/components/UnauthorizedModal/UnauthorizedModal";
import { useLocation } from "react-router-dom";
import MotionDiv from "~/presentation/components/Motion/MotionDiv";

const Dash = () => {
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const companyUseCases = new ApiCompanyUseCases(makeHttpClient());
  const [UserCompany, setUserCompany] = useState(null);
  const CompanyUser = user.companyId;
  const [expand, setExpand] = useState(false);
  const mobile = useMobile();
  const ref = useRef();
  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  //modal unauthorized
  const location = useLocation();
  const [showModal, setShowModal] = useState(location?.state?.showModal || false);
  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (expand) scrollToElement(ref.current, -99, mobile);
  }, [expand]);

  useEffect(() => {
    const getCompany = async () => {
      const result = await companyUseCases.getCompanyById(CompanyUser);
      setUserCompany(result);
    };

    getCompany();
  }, [CompanyUser]);

  // Salvando os dados de UserCompany no localStorage
  useEffect(() => {
    if (UserCompany) {
      const ReinfAuthorized = UserCompany?.plan?.isAllowReinf;
      const letter = UserCompany?.legalNature?.letter;

      if (ReinfAuthorized !== undefined) {
        localStorage.setItem("GT_ReinfAcess", JSON.stringify(ReinfAuthorized));
      }

      if (letter) {
        localStorage.setItem("GT_layoutQSO", JSON.stringify(letter));
      }
    }
  }, [UserCompany]);

  return (
    <>
      <HomeLayout navbarItems={clientNavbarItems()}>
        {() => (
          <Box>
            <Features onExpand={handleExpand} user={user} openModal={() => setShowModal(true)} />
            {expand ? (
              <MotionDiv expand={expand} ref={ref}>
                <SelectedContent componentRef={ref} />
              </MotionDiv>
            ) : null}
          </Box>
        )}
      </HomeLayout>
      <Modal open={showModal} onClose={handleClose}>
        <UnauthorizedModal />
      </Modal>
    </>
  );
};

export default Dash;
