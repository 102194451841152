export const formStyles = {
  container: {
    margin: 3,
    padding: 3,
    backgroundColor: "#E6E6E6",
    borderRadius: 2,
  },
  fieldsWrapper: {
    marginBottom: 2,
    paddingInline: 4,
    paddingTop: 2,
    paddingBottom: 2,
    background: "#D9D9D9",
    borderRadius: 4,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    gap: 1,
    width: "100%",
    paddingTop: 2,
  },
  buttonCancel: {
    width: "min(150px, 100%)",
  },
  button: {
    width: "min(200px, 100%)",
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
};
