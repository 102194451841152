import React, { useContext, useEffect, useRef, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { getRealTimeZoneDate } from "~/presentation/utils/getTimeZoneData";
import BlogForm from "./Form";
import PageListHeader from "../../components/PageListHeader";
import validationSchema from "./Form/validationSchema";
import { useUploadFile } from "~/presentation/hooks/useUploadFile";

const initialValues = {
  id: 0,
  categoryId: null,
  title: "",
  autor: "",
  image: null,
  videoUrl: "",
  addToMedia: false,
  publishDate: new Date(),
  tags: [],
  text: "",
  qsoTable: {
    inss1: [],
    inss2: [],
    inss3: [],
    irrf1: [],
    irrf2: [],
    csllPisCofins: [],
    iss1: [],
    iss2: [],
  },
  isDraft: false,
};

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  blogUseCases,
  blogTags,
  blogCategories,
  getTags,
  getCategories,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { importFile, uploadFile } = useUploadFile();
  const isCreate = !data;

  //input value and errors
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const uploadedImage = useRef(null);
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    if (blogTags.length === 0) {
      getTags();
    }
  }, []);

  useEffect(() => {
    if (data?.id) {
      setValues({
        ...data,
        publishDate: getRealTimeZoneDate(data.publishDate),
      });
    }
  }, [data]);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const changeHandler = (event) => {
    const data = importFile(event, uploadedImage);
    setSelectedFile(data);
  };

  const filterTags = (tags) => {
    const selectedTags = tags.map((tag) => {
      if (!tag.id) {
        return {
          id: 0,
          name: tag.value,
        };
      } else {
        return {
          id: tag.id,
          name: tag.name,
        };
      }
    });
    return selectedTags;
  };

  function embedVideo(url) {
    if (url === null || url === undefined) {
      return null;
    }
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/(watch\?)?(v=)?(.+)$/;
    const vimeoRegex = /^(https?:\/\/)?(player\.)?vimeo\.com\/(video\/)?(\d+)(\?.*)?$/;
    const vimeoManageRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/(manage\/videos\/)?(\d+)$/;

    if (url.match(youtubeRegex)) {
      const videoId = url.match(youtubeRegex)[6];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (url.match(vimeoRegex)) {
      const videoId = url.match(vimeoRegex)[4];
      return `https://player.vimeo.com/video/${videoId}`;
    } else if (url.match(vimeoManageRegex)) {
      const videoId = url.match(vimeoManageRegex)[5];
      return `https://player.vimeo.com/video/${videoId}`;
    } else {
      return null;
    }
  }

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} post do blog`);
    const convertedUrl = embedVideo(values.videoUrl);

    let submitValues = {
      id: values.id,
      title: values.title,
      autor: values.autor,
      image: values.image,
      videoUrl: convertedUrl,
      addToMedia: values.addToMedia,
      publishDate: values.publishDate.toISOString(),
      tags: filterTags(values.tags),
      text: values.text,
      isHighlight: values?.isHighlight || false,
      numberOfComments: values?.numberOfComments || 0,
      categoryId: values.categoryId,
      qsoTable: values.qsoTable,
      isDraft: values.isDraft,
    };

    const url = selectedFile ? await uploadFile(selectedFile, selectedFile?.name) : null;

    if (values.image || url) {
      submitValues = {
        ...values,
        image: url ? url : values.image,
      };
    }

    try {
      if (isCreate) {
        await blogUseCases.createBlogPost(submitValues);
      } else {
        await blogUseCases.updateBlogPost(submitValues);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Post ${isCreate ? "criado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro. ${error?.data || error?.response?.data}`,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <PageListHeader title={`${isCreate ? "Criar" : "Editar"} Post`} handleBack={onCloseForm} />
      {values && (
        <BlogForm
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
          categories={blogCategories}
          tags={blogTags}
          uploadedImage={uploadedImage}
          changeHandler={changeHandler}
          getCategories={getCategories}
        />
      )}
    </>
  );
}

export default CreateEdit;
