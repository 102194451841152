import React from "react";
import { Typography as Text, Box, Typography, Button } from "@mui/material";
import GTFacilModal from "~/presentation/views/LandingPage/Home/Modals/GTFacilModal";
import SelectedContentModal from "~/presentation/views/LandingPage/Home/Modals/SelectedContentModal";
import BlogModal from "~/presentation/views/LandingPage/Home/Modals/BlogModal";
import CalculatorIcon from "~/presentation/views/LandingPage/icons/CalculatorIcon";
import TextIcon from "~/presentation/views/LandingPage/icons/TextIcon";
import TaskIcon from "~/presentation/views/LandingPage/icons/TaskIcon";
import DialogIcon from "~/presentation/views/LandingPage/icons/DialogIcon";
import ReinfInfoModal from "~/presentation/views/LandingPage/Home/Modals/ReinfInfoModal";
import MotionDiv from "~/presentation/components/Motion/MotionDiv";

const products = [
  {
    icon: CalculatorIcon,
    title: "GT-Fácil",
    excerpt:
      "Um poderoso recurso de pesquisa desenvolvido para orientar empresas e entidades públicas e privadas na tomada de decisões em relação à tributação de seus contratos.",
    modalComponent: GTFacilModal,
  },
  {
    icon: TaskIcon,
    title: "GT-Reinf",
    excerpt:
      "Tenha em mãos o poderoso sistema de envio rápido e fácil da EFD-Reinf que garante maior eficiência no cumprimento das suas obrigações fiscais.",
    modalComponent: ReinfInfoModal,
  },
  {
    icon: TextIcon,
    title: "Conteúdo Selecionado",
    excerpt:
      "Selecionamos os conteúdos mais relevantes e publicamos aqui apenas o que diz respeito ao INSS, IRRF, Contribuições Sociais (CSLL, PIS/Pasep e COFINS) e ISS.",
    modalComponent: SelectedContentModal,
  },
  {
    icon: DialogIcon,
    title: "Blog",
    excerpt:
      "Tenha acesso às séries exclusivas produzidas pelo nosso time de especialistas para lhe atualizar acerca das principais polêmicas envolvendo a matéria.",
    modalComponent: BlogModal,
  },
];

const Product = ({ id, openModal }) => {
  return (
    <Box sx={styles.container} id={id}>
      <Typography sx={styles.subtitle}>SOLUÇÕES DO SISTEMA WEB GESTÃO TRIBUTÁRIA</Typography>
      <Box sx={styles.header}>
        <Box sx={styles.line} />
        <Typography sx={styles.title}>Principais Recursos</Typography>
        <Box sx={styles.line} />
      </Box>
      <Box sx={styles.main}>
        {products.map((p, index) => (
          <MotionDiv
            up={{ from: 50 + 100 * index }}
            once
            key={"landing-page-products-card-" + index}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <>
                <p.icon />
                <Text
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    paddingBlock: 3,
                    fontSize: 20,
                    color: "#fff",
                  }}
                >
                  {p.title}
                </Text>
                <Text
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 300,
                    paddingBlock: 2,
                    fontSize: 16,
                    color: "#fff",
                    padding: 3,
                  }}
                >
                  {p.excerpt}
                </Text>
              </>
              <Button sx={styles.button} onClick={() => openModal(p.modalComponent)}>
                Saiba Mais
              </Button>
            </Box>
          </MotionDiv>
        ))}
      </Box>
    </Box>
  );
};

export default Product;

const styles = {
  container: {
    minHeight: "70vh",
    height: "min-content",
    backgroundImage: "linear-gradient(114.74deg, #152340 34.25%, #14213d 86.96%)",
    display: "grid",
    gridAutoRows: "min-content auto",
    padding: "max(40px, 6.7%) 6.3% max(36px, 6%) 6.8%",
  },
  subtitle: {
    color: "#3ED1DB",
    textAlign: "center",
    fontFamily: "Bebas Neue",
    fontSize: "29px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "8%",
    marginBottom: "-8%",
    paddingTop: "2rem",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "2.3rem",
    color: "#fafafa",

    "@media screen and (max-width: 600px)": {
      fontSize: "1.7rem",
    },
  },
  line: {
    display: "none",
    "@media screen and (min-width: 600px)": {
      display: "block",
      height: "3px",
      width: "100%",
      background: "#fafafa",
    },
  },
  header: {
    display: "grid",
    marginTop: "8%",
    gridTemplateColumns: "auto",
    alignItems: "center",
    justifyItems: "center",
    width: "100%",
    "@media screen and (min-width: 600px)": {
      gridTemplateColumns: "auto max-content auto",
      gap: "max(30px, 2%)",
    },
  },
  main: {
    marginTop: "2%",
    display: "grid",
    gridTemplateRows: "auto auto auto",
    alignItems: "center",
    justifyItems: "center",

    "@media screen and (min-width: 768px)": {
      gridTemplateRows: "auto",
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, auto))",
    },
  },
  cardWrapper: {
    height: "min-content",
    maxWidth: "380px",
    display: "grid",
    justifyItems: "center",
    marginInline: "10px",
    color: "#fafafa",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "min(313px, 40%)",
    height: "40px",
    borderRadius: "10px",
    padding: "20px 5px 15px 5px",
    background: "transparent",
    border: "3px solid #34A6B6",
    color: "#FFF",
    fontFamily: "Bebas Neue",
    fontSize: 25,
    cursor: "pointer",
    transition: "background-color 0.3s, border-color 0.3s",
    marginBottom: "40px",
    "@media screen and (min-width: 769px)": {
      marginBottom: "-40px",
    },
    "&:hover": {
      background: "#34A6B6",
    },
  },
};
