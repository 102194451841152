import React, { useState } from "react";
import {
  SearchFieldContainer,
  SearchIconWrapper,
  InputBase,
} from "~/presentation/components/SearchField/styles";
import { Search as SearchIcon } from "@mui/icons-material";

const SearchField = ({
  windowWidth,
  handleSearch,
  inputColor,
  parentRef,
  containerRef,
  inputProps,
  value,
  doSearchWhenUserClickInTheIcon,
  onClickSearchIcon,
  hiddenIcon = false,
  placeholder,
  type,
  ...props
}) => {
  /** Componente de campo de pesquisa. A se modificar para
   * ser reutilizável em várias partes do sistema.
   */

  const [search, setSearch] = useState("");
  const hideIcon = hiddenIcon;
  const iconSize = () => (windowWidth > 425 ? "medium" : "small");

  return (
    <SearchFieldContainer ref={containerRef} {...props}>
      <InputBase
        type={type}
        color={inputColor}
        value={value}
        inputRef={parentRef}
        onChange={(event) => {
          const value = event.target.value;
          if (!doSearchWhenUserClickInTheIcon) {
            handleSearch(value);
          }
          setSearch(value);
        }}
        placeholder={placeholder || "Pesquisar"}
        classes={{
          root: "inputRoot",
          input: "inputInput",
        }}
        inputProps={{ "aria-label": "search" }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && doSearchWhenUserClickInTheIcon) {
            onClickSearchIcon({ itemContent: search });
          }
        }}
        {...inputProps}
      />
      <SearchIconWrapper>
        <SearchIcon
          hidden={hideIcon}
          fontSize={iconSize()}
          onClick={() => onClickSearchIcon({ itemContent: search })}
        />
      </SearchIconWrapper>
    </SearchFieldContainer>
  );
};

export default SearchField;
