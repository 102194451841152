import React from "react";
import { PostAdd, Undo } from "@mui/icons-material";
import { IconButton, Typography, Tooltip, Box } from "@mui/material";
import { useSize } from "~/presentation/hooks/useSize";

const PageListHeader = ({ title, handleAdd, titleAdd, handleBack, titleBack, fontSize, error }) => {
  const { isMobile } = useSize();
  const size = Number(fontSize) || 28;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleAndButtonContainer}>
        <Box>
          <Typography
            sx={{
              ...styles.title,
              fontSize: isMobile ? size - 6 : size,
              color: error && "#d32f2f",
            }}
          >
            {title}
          </Typography>
        </Box>
        {handleAdd && (
          <Box sx={styles.addIcon}>
            <Tooltip title={titleAdd || `Criar ${title}`}>
              <IconButton onClick={handleAdd}>
                <PostAdd sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      {handleBack && (
        <Tooltip title={titleBack || "Voltar"}>
          <IconButton onClick={handleBack}>
            <Undo sx={{ color: "#000" }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default PageListHeader;

const styles = {
  container: {
    background: "#E6E6E6",
    padding: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleAndButtonContainer: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  addIcon: {
    backgroundColor: "#001A50",
    padding: "2px",
    borderRadius: "12px",
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "left",
    fontWeight: 600,
    color: "#242424",
  },
};
