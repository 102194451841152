import React, { useContext, useState, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validationSchema from "./validationSchema";
import randomString from "~/presentation/utils/randomString";
import { Button, Typography as Text, Box } from "@mui/material";
import { Send, Upload } from "@mui/icons-material";
import { Close } from "@mui/icons-material";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import Input from "~/presentation/views/Client/components/Common/Input";
import { useUploadFile } from "~/presentation/hooks/useUploadFile";

export const NewTIForm = ({ taxInformationUseCases, getRemainingTaxInformation }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const { uploadFile } = useUploadFile();
  //input value and errors
  const [values, setValues] = useState({ subject: "", message: "", files: [] });
  const [errors, setErrors] = useState({});

  const user = JSON.parse(localStorage.getItem("GT_user"));

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  // Dentro do seu componente:
  const fileInputRef = useRef(null);

  // Função para chamar o clique do input:
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (files) => {
    try {
      if (files.length > 0) {
        let uploadedFiles = [];
        for (let index = 0; index < files.length; index++) {
          const renamedFile = new File([files[index]], `${randomString()}-${files[index].name}`);
          const url = await uploadFile(renamedFile, renamedFile.name);
          const uploadedFile = {
            filename: renamedFile.name,
            url: url,
          };
          uploadedFiles.push(uploadedFile);
          if (index === files.length - 1) return uploadedFiles;
        }
      } else {
        return false;
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao fazer upload.",
        type: "error",
      });
      return error;
    }
  };

  const handleValidation = () => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    let submitValues = { ...values, id: 0, userId: user.id };
    let uploads = {};
    if (values.files) {
      uploads = await handleFileUpload(values.files);
    }
    if (uploads.length > 0) {
      submitValues = {
        ...submitValues,
        files: uploads.length > 0 ? uploads : null,
      };
    }

    openDialogBox({
      message: `Deseja mesmo enviar esta IF?`,
      callback: async () => {
        try {
          openBackdrop(true, "Enviando IF");
          await taxInformationUseCases.sendTaxInformation(submitValues);
          setFeedbackSnackbar({
            isOpen: true,
            message: "IF enviada com sucesso!",
            type: "success",
          });
        } catch (error) {
          setFeedbackSnackbar({
            isOpen: true,
            message: "Erro ao enviar IF : " + error.response.data,
            type: "error",
          });
        } finally {
          openBackdrop(false);
          getRemainingTaxInformation();
        }
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Input
          title="Assunto *"
          name="subject"
          type="text"
          value={values.subject}
          onChange={(e) => {
            handleChangeValue("subject", e.target.value);
          }}
          error={errors.subject}
        />

        <Input
          title="Mensagem *"
          name="message"
          type="text"
          multiline
          rows={6}
          value={values.message}
          onChange={(e) => {
            handleChangeValue("message", e.target.value);
          }}
          error={errors.message}
        />
      </Box>

      <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}>
        {values.files.length <= 0 && (
          <Button variant="contained" startIcon={<Upload />} onClick={handleButtonClick}>
            Upload de arquivos
          </Button>
        )}

        <input
          type="file"
          name="file"
          id="file"
          multiple
          ref={fileInputRef}
          onChange={(event) => {
            event.preventDefault();
            handleChangeValue("files", [...event.target.files]);
          }}
          style={{ display: "none" }}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {values.files &&
            values.files.map((file) => (
              <Text sx={styles.subtitle} key={file.name}>
                {file.name}
              </Text>
            ))}
          {values.files &&
            values.files.map((file) => (
              <Button
                key={file.name}
                onClick={() => {
                  handleChangeValue("files", []);
                }}
                sx={{
                  padding: 2,
                  marginInline: 2,
                  height: 40,
                  width: 40,
                  minWidth: "auto",
                  borderRadius: 20,
                }}
              >
                <Close />
              </Button>
            ))}
        </Box>

        <Button
          sx={{ backgroundColor: "#081445", ml: 2 }}
          variant="contained"
          startIcon={<Send />}
          backgroundColor={"#081445"}
          onClick={handleValidation}
        >
          Enviar
        </Button>
      </Box>
    </>
  );
};

const styles = {
  subtitle: {
    fontSize: 12,
    fontWeight: 700,
    width: "100%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
};
