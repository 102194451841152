import { apiURL } from "../../usecases/api";
export class ApiValueVariableUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/systemvariable`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getById(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/systemvariable/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
  
  async create(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/systemvariable`,
      method: "POST",
      data,
    });
    return response.data;
  }

  async getByCode(code) {
    // let response = {
    //   data: fakeValueVariablesList.find(
    //     (vv) =>
    //       vv.code.replaceAll(" ", "").toLowerCase() ==
    //       code.replaceAll(" ", "").toLowerCase(),
    //   ),
    // };
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/systemvariable/${code}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
    // if (response.data)
    //   return `R$ ${response.data.value.toFixed(2).replace(".", ",")}`;
    // else return;
  }

  async update(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/systemvariable`,
      method: "PUT",
      data,
    });
    return response.data;
  }

  async delete(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/systemvariable/${id}`,
      method: "DELETE",
    });
    return response.data;
  }
}
