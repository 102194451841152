import React, { useState } from "react";
import ButtonCommon from "~/presentation/components/Common/Button";
import { Box, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import PageListHeader from "../../../components/PageListHeader";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import CreateEdit from "../../../Service/CreateEdit/CreateEdit";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";
import Grid from "@mui/material/Grid2";

const CnaeForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  services,
  cnaes,
  fetchServices,
}) => {
  //MODAL List Service
  const [openServieList, setOpenServieList] = useState(false);
  const handleOpenServieList = () => setOpenServieList(true);
  const handleCloseServieList = () => setOpenServieList(false);

  //MODAL Modal Create Service
  const [openServieModal, setOpenServieModal] = useState(false);
  const handleOpenServieModal = () => setOpenServieModal(true);
  const handleCloseServieModal = () => setOpenServieModal(false);

  //MODAL Lista de CNAE
  const [openCnaeList, setOpenCnaeList] = useState(false);
  const handleOpenCnaeList = () => setOpenCnaeList(true);
  const handleCloseCnaeList = () => setOpenCnaeList(false);

  const handleDeleteServices = (params) => {
    if (values.serviceCodes.length === 1) {
      onChangeValue("serviceCodes", []);
    } else {
      const filtedValue = values?.serviceCodes?.filter((item) => item.id !== params.row.id);
      onChangeValue("serviceCodes", filtedValue);
    }
  };

  const handleDeleteCnae = (params) => {
    if (values.cnaeAnswersLinked.length === 1) {
      onChangeValue("cnaeAnswersLinked", []);
    } else {
      const filtedValue = values?.cnaeAnswersLinked?.filter((item) => item.id !== params.row.id);
      onChangeValue("cnaeAnswersLinked", filtedValue);
    }
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>CNAE</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: !2, sm: 6, md: 3 }}>
            <Input
              type="mask"
              mask="0000-0/00"
              name="code"
              title="Código"
              value={values.code}
              onChange={(e) => onChangeValue("code", e)}
              error={errors.code}
            />
          </Grid>
          <Grid size={{ xs: !2, sm: 6, md: 9 }}>
            <Input
              type="text"
              name="detailCode"
              title="Detalhamento"
              value={values.detailCode}
              onChange={(e) => onChangeValue("detailCode", e.target.value)}
              error={errors.detailCode}
            />
          </Grid>
          <Grid size={{ xs: !2, sm: 6 }}>
            <Input
              type="text"
              name="description"
              title="Descrição"
              value={values.description}
              onChange={(e) => onChangeValue("description", e.target.value)}
              error={errors.description}
            />
          </Grid>

          <Grid size={{ xs: !2, sm: 6 }}>
            <Input
              type="text"
              name="synonym"
              title="Sinônimo"
              value={values.synonym}
              onChange={(e) => onChangeValue("synonym", e.target.value)}
              error={errors.synonym}
            />
          </Grid>
          <Grid size={12}>
            <Input
              type="text"
              name="comment"
              title="Comentário"
              value={values.comment}
              onChange={(e) => onChangeValue("comment", e.target.value)}
              error={errors.comment}
            />
          </Grid>
        </Grid>
      </Box>

      <Tabs defaultValue={1}>
        <TabsList>
          <Tab value={1} error={errors.serviceCodes}>
            Serviços
          </Tab>
          <Tab value={2} error={errors.cnaeAnswersLinked}>
            CNAES
          </Tab>
        </TabsList>
        <TabPanel value={1} error={errors.serviceCodes}>
          <PageListHeader
            title="Serviços"
            titleAdd="Adicionar Serviços"
            handleAdd={handleOpenServieList}
          />
          <Table
            tableOptions={false}
            columns={[
              { field: "code", headerName: "Código", flex: 1, align: "center" },
              { field: "group", headerName: "Grupo", flex: 1, align: "center" },
              { field: "description", headerName: "Descrição", flex: 6 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDeleteServices(params)} />
                ),
              },
            ]}
            data={values?.serviceCodes}
          />
          <ModalBox open={openServieList} onClose={handleCloseServieList} bg="white">
            <ListComponent
              disablePadding={false}
              data={services}
              checkedData={values?.serviceCodes}
              title="Serviços"
              onClose={handleCloseServieList}
              handleAdd={(values) => onChangeValue("serviceCodes", values)}
              handleCreate={handleOpenServieModal}
              titleAdd="Criar novo serviço"
              itemsPerPage={5}
            />
          </ModalBox>
          <ModalBox open={openServieModal} onClose={handleCloseServieModal} bg="white">
            <CreateEdit
              data={null}
              onCloseForm={handleCloseServieModal}
              refreshData={fetchServices}
              cnaes={cnaes}
            />
          </ModalBox>
        </TabPanel>
        <TabPanel value={2} error={errors.cnaeAnswersLinked}>
          <PageListHeader title="Cnaes" titleAdd="Adicionar Cnaes" handleAdd={handleOpenCnaeList} />
          <Table
            tableOptions={false}
            columns={[
              { field: "code", headerName: "Código", flex: 1, align: "center" },
              { field: "description", headerName: "Descrição", flex: 6 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDeleteCnae(params)} />
                ),
              },
            ]}
            data={values?.cnaeAnswersLinked}
          />
          <ModalBox open={openCnaeList} onClose={handleCloseCnaeList} bg="white">
            <ListComponent
              disablePadding={false}
              data={cnaes}
              checkedData={values?.cnaeAnswersLinked}
              title="Cnaes"
              onClose={handleCloseCnaeList}
              handleAdd={(values) => onChangeValue("cnaeAnswersLinked", values)}
              itemsPerPage={5}
            />
          </ModalBox>
        </TabPanel>
      </Tabs>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default CnaeForm;
