import React from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

export const PlanForm = ({ values, handleSubmit, onChangeValue, errors }) => {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Plano Personalizado</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="text"
              name="name"
              title="Nome"
              value={values?.name}
              onChange={(e) => onChangeValue("name", e.target.value)}
              error={errors.name}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Input
              type="number"
              name="numberOfUsers"
              title="Número de Usuários"
              value={values?.numberOfUsers}
              onChange={(e) => onChangeValue("numberOfUsers", e.target.value)}
              error={errors.numberOfUsers}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Input
              type="amount"
              title="Preço"
              name="valor"
              value={values?.valor}
              onChange={(value) => onChangeValue("valor", value)}
              error={errors?.valor}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Input
              type="number"
              name="consultLimit"
              title="Limite de consultas"
              value={values?.consultLimit}
              onChange={(e) => onChangeValue("consultLimit", e.target.value)}
              error={errors.consultLimit}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Input
              type="number"
              name="taxInformationNumber"
              title="N° de informações fiscais"
              value={!values?.isAllowTaxInformation ? "" : values?.taxInformationNumber}
              onChange={(e) => onChangeValue("taxInformationNumber", e.target.value)}
              disabled={!values?.isAllowTaxInformation}
              error={errors.taxInformationNumber}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          <Grid size={{ xs: 12, md: "auto" }} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isCustom || null}
                  onChange={() => onChangeValue("isCustom", !values?.isCustom)}
                  name="isCustom"
                />
              }
              label="Plano Personalizado"
            />
          </Grid>
          <Grid size={{ xs: 12, md: "auto" }} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isAllowTaxInformation || null}
                  onChange={() =>
                    onChangeValue("isAllowTaxInformation", !values?.isAllowTaxInformation)
                  }
                  name="isAllowTaxInformation"
                />
              }
              label="Permitir informações fiscais"
            />
          </Grid>
          <Grid size={{ xs: 12, md: "auto" }} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isGenerateReport || null}
                  onChange={() => onChangeValue("isGenerateReport", !values?.isGenerateReport)}
                  name="isGenerateReport"
                />
              }
              label="Gerar Relatórios"
            />
          </Grid>
          <Grid size={{ xs: 12, md: "auto" }} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isCalculationSimulator || null}
                  onChange={() =>
                    onChangeValue("isCalculationSimulator", !values?.isCalculationSimulator)
                  }
                  name="isCalculationSimulator"
                />
              }
              label="Simulador de Cálculo"
            />
          </Grid>
          <Grid size={{ xs: 12, md: "auto" }} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isConsultISS || null}
                  onChange={() => onChangeValue("isConsultISS", !values?.isConsultISS)}
                  name="isConsultISS"
                />
              }
              label="Consulta ISS"
            />
          </Grid>
          <Grid size={{ xs: 12, md: "auto" }} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isAllowReinf || null}
                  onChange={() => onChangeValue("isAllowReinf", !values?.isAllowReinf)}
                  name="isAllowReinf"
                />
              }
              label="Possui REINF"
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};
