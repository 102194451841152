import { apiURL } from "../../usecases/api";
export class ApiPaymentRecordUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getPaymentRecord() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/PaymentRecord`,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getPaymentRecordById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/${id}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data; // Assuming your data is in the 'data' field of the response.
      } else {
        throw new Error("API request failed with status: " + response.status);
      }
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  }

  async updatePaymentRecord(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/PaymentRecord`,
        method: "PUT",
        data, 
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async createPaymentRecord(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/PaymentRecord`,
        method: "POST",
        data,
      });

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async deletePaymentRecord(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/PaymentRecord/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
