import React, { useEffect, useState } from "react";

import { Card } from "~/presentation/views/GTFacil/components/Card";
import { Box, Typography } from "@mui/material";

export const SearchCard = ({ getSearch, searchType }) => {
  const [search, setSearch] = useState();

  const loadSearch = async () => {
    setSearch(await getSearch());
  };

  const renderPreTitle = () => {
    switch (searchType) {
      case "cnae":
        return "Código CNAE";
      case "lc116":
        return "Código na LC 116/2003";
      default:
        return "Código na LC 116/2003";
    }
  };

  const renderCode = () => {
    const { code, detailCode } = search;
    if (code && detailCode) {
      return `${code} (${detailCode}) - `;
    } else {
      return `${code} - `;
    }
  };

  useEffect(() => {
    loadSearch();
  }, []);

  useEffect(() => {
    if (search?.observation) {
      localStorage.setItem("selected-subitem", search?.observation.substring(0, 4));
    } else {
      localStorage.removeItem("selected-subitem");
    }
  }, search);

  return (
    <Card title="Sua pesquisa foi realizada por:" loading={!search}>
      {search && searchType === "cnae" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>{renderPreTitle()}:</b> {renderCode()}
            {search.description}
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>Relacionado ao subitem da LC 116/2003:</b> {search.observation}
          </Typography>
          {search.annotation ? (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  marginRight: 2,
                  fontWeight: 300,
                  "> b": {
                    fontWeight: 500,
                  },
                }}
              >
                <b>Observação: </b>
                <Typography
                  sx={{
                    wordBreak: "break-word",

                    "& *": {
                      display: "inline",
                      fontWeight: 300,
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: search.annotation }}
                />
              </Typography>
            </div>
          ) : null}
        </Box>
      )}
      {search && searchType === "answer" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>{renderPreTitle()}:</b> {renderCode()} {search.description}
            {search.gtAnswerName}
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>Relacionado ao CNAE:</b> {search.observation}
          </Typography>
          {search.annotation ? (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  marginRight: 2,
                  fontWeight: 300,
                  "> b": {
                    fontWeight: 500,
                  },
                }}
              >
                <b>Observação: </b>
                <Typography
                  sx={{
                    wordBreak: "break-word",

                    "& *": {
                      display: "inline",
                      fontWeight: 300,
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: search.annotation }}
                />
              </Typography>
            </div>
          ) : null}
        </Box>
      )}

      {search && searchType === "freeSearch" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              marginRight: 2,
              fontWeight: 300,
              "> b": {
                fontWeight: 500,
              },
            }}
          >
            <b>Pesquisa específica:</b> {search.term}
          </Typography>
        </Box>
      )}
    </Card>
  );
};
