import React, { useState } from "react";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import typeTax from "~/presentation/utils/typeTax";
import PageListHeader from "../../../components/PageListHeader";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import companyType from "~/presentation/utils/companyType";
import CreateEditCompanyCategory from "../../../CompanyCategory/CreateEdit/CreateEdit";
import CreateEdit from "../../../Term/CreateEdit/CreateEdit";
import CreateEditItemAnswer from "../../../ItemAnswer/CreateEdit/CreateEdit";
import { makeTypeAnswerUseCases } from "~/main/factories/use-cases/types-answer";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const AnswerForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  fetchTerms,
  fetchItemAnswer,
  fetchCategories,
  cnaes,
  itemsAnswer,
  categories,
  terms,
}) => {
  const typesAnswerUseCases = makeTypeAnswerUseCases();

  //CATEGORIA - LISTA
  const [openListCategory, setOpenListCategory] = useState(false);
  const handleOpenListCategory = () => setOpenListCategory(true);
  const handleCloseListCategory = () => {
    setOpenListCategory(false);
  };

  //CATEGORIA - MODAL CRIAR
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const handleOpenModalCategory = () => setOpenModalCategory(true);
  const handleCloseModalCategory = () => {
    setOpenModalCategory(false);
  };

  //TIPO EMPRESA - LISTA
  const [openListCompType, setOpenListCompType] = useState(false);
  const handleOpenListCompType = () => setOpenListCompType(true);
  const handleCloseListCompType = () => {
    setOpenListCompType(false);
  };

  //TERMOS - LISTA
  const [openListTerms, setOpenListTerms] = useState(false);
  const handleOpenListTerms = () => setOpenListTerms(true);
  const handleCloseListTerms = () => {
    setOpenListTerms(false);
  };

  //TERMOS - MODAL CRIAR
  const [openModalTerms, setOpenModalTerms] = useState(false);
  const handleOpenModalTerms = () => setOpenModalTerms(true);
  const handleCloseModalTerms = () => {
    setOpenModalTerms(false);
  };

  //CNAE - LISTA
  const [openListCNAE, setOpenListCNAE] = useState(false);
  const handleOpenListCNAE = () => setOpenListCNAE(true);
  const handleCloseListCNAE = () => {
    setOpenListCNAE(false);
  };

  //TERMOS - LISTA
  const [openListItemA, setOpenListItemA] = useState(false);
  const handleOpenListItemA = () => setOpenListItemA(true);
  const handleCloseListItemA = () => {
    setOpenListItemA(false);
  };

  //TERMOS - MODAL CRIAR
  const [openModalItemA, setOpenModalItemA] = useState(false);
  const handleOpenModalItemA = () => setOpenModalItemA(true);
  const handleCloseModalItemA = () => {
    setOpenModalItemA(false);
  };

  const handleDelete = (id, field) => {
    if (values[field].length === 1) {
      onChangeValue(`${field}`, []);
    } else {
      const filtedValue = values[field].filter((item) => item.id !== id);
      onChangeValue(`${field}`, filtedValue);
    }
  };

  const [typesAnswer, setTypesAnswer] = useState([]);

  const getTypesAnswer = async () => {
    const result = await loadTypes();
    setTypesAnswer(result);
  };

  const loadTypes = async () => {
    const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer();
    return apiTypesAnswer;
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Detalhes da resposta</Typography>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Input
              type="text"
              name="gtAnswerName"
              title="Título"
              value={values.gtAnswerName}
              onChange={(e) => onChangeValue("gtAnswerName", e.target.value)}
              error={errors.gtAnswerName}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 9 }}>
            <Input
              type="text"
              name="description"
              title="Descrição"
              value={values.description}
              onChange={(e) => onChangeValue("description", e.target.value)}
              error={errors.description}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 3 }}>
            <Input
              type="select"
              options={typeTax}
              onChange={(item) => onChangeValue("typeTax", item.value)}
              name="typeTax"
              title="Tipo de taxa"
              value={typeTax.find((item) => item.value === values?.typeTax)}
              error={errors.typeTax}
            />
          </Grid>
        </Grid>
      </Box>

      <Tabs defaultValue={1}>
        <TabsList>
          <Tab value={1} error={errors.cnaes}>
            Cnaes
          </Tab>
          <Tab value={2} error={errors.categories}>
            Categoria
          </Tab>
          <Tab value={3} error={errors.companyTypes}>
            Tipo de empresa
          </Tab>
          <Tab value={4} error={errors.searchTerms}>
            Termos de Pesquisa
          </Tab>
          <Tab value={5} error={errors.gtAnswerItems}>
            Itens de resposta
          </Tab>
        </TabsList>
        <TabPanel value={1} error={errors.cnaes}>
          <PageListHeader
            title="Cnaes"
            titleAdd="Adicionar Cnaes"
            handleAdd={handleOpenListCNAE}
            error={errors.cnaes}
          />
          <Table
            tableOptions={false}
            columns={[
              { field: "code", headerName: "Código", flex: 1, align: "center" },
              { field: "description", headerName: "Descrição", flex: 6 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDelete(params.row.id, "cnaes")} />
                ),
              },
            ]}
            data={values?.cnaes}
          />
          <ModalBox open={openListCNAE} onClose={handleCloseListCNAE} bg="white">
            <ListComponent
              disablePadding={false}
              data={cnaes}
              checkedData={values?.cnaes}
              title="Cnaes"
              onClose={handleCloseListCNAE}
              handleAdd={(values) => onChangeValue("cnaes", values)}
              itemsPerPage={5}
            />
          </ModalBox>
          {errors.cnaes && <Typography sx={formStyles.errorMsg}>{errors.cnaes}</Typography>}
        </TabPanel>
        <TabPanel value={2} error={errors.categories}>
          <PageListHeader
            title="Categoria"
            titleAdd="Adicionar Categoria"
            handleAdd={handleOpenListCategory}
            error={errors.categories}
          />
          <Table
            tableOptions={false}
            columns={[
              { field: "letter", headerName: "Letra", flex: 1, align: "center" },
              { field: "name", headerName: "Nome", flex: 6 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDelete(params.row.id, "categories")} />
                ),
              },
            ]}
            data={values?.categories}
          />
          <ModalBox
            open={openListCategory}
            onClose={handleCloseListCategory}
            bg="white"
            maxWidth={500}
          >
            <ListComponent
              data={categories}
              checkedData={values?.categories}
              title="Categorias"
              onClose={handleCloseListCategory}
              handleAdd={(values) => onChangeValue("categories", values)}
              handleCreate={handleOpenModalCategory}
              titleAdd="Criar nova categoria"
            />
          </ModalBox>
          <ModalBox open={openModalCategory} onClose={handleCloseModalCategory} bg="white">
            <CreateEditCompanyCategory
              data={null}
              onCloseForm={handleCloseModalCategory}
              refreshData={fetchCategories}
            />
          </ModalBox>
          {errors.categories && (
            <Typography sx={formStyles.errorMsg}>{errors.categories}</Typography>
          )}
        </TabPanel>
        <TabPanel value={3} error={errors.companyTypes}>
          <PageListHeader
            title="Tipo de empresa"
            handleAdd={handleOpenListCompType}
            error={errors.companyTypes}
          />
          <Table
            tableOptions={false}
            columns={[
              { field: "description", headerName: "Descrição", flex: 6 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDelete(params.row.id, "companyTypes")} />
                ),
              },
            ]}
            data={values?.companyTypes}
          />
          <ModalBox
            open={openListCompType}
            onClose={handleCloseListCompType}
            bg="white"
            maxWidth={300}
          >
            <ListComponent
              data={companyType}
              checkedData={values?.companyTypes}
              title="Tipos de empresa"
              onClose={handleCloseListCompType}
              handleAdd={(values) => onChangeValue("companyTypes", values)}
            />
          </ModalBox>
          {errors.companyTypes && (
            <Typography sx={formStyles.errorMsg}>{errors.companyTypes}</Typography>
          )}
        </TabPanel>
        <TabPanel value={4} error={errors.searchTerms}>
          <PageListHeader
            title="Termos de pesquisa"
            titleAdd="Adicionar termos de pesquisa"
            handleAdd={handleOpenListTerms}
            error={errors.searchTerms}
          />
          <Table
            tableOptions={false}
            columns={[
              { field: "term", headerName: "Termo de busca", flex: 1 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDelete(params.row.id, "searchTerms")} />
                ),
              },
            ]}
            data={values?.searchTerms}
          />
          <ModalBox open={openListTerms} onClose={handleCloseListTerms} bg="white" maxWidth={600}>
            <ListComponent
              data={terms}
              checkedData={values?.searchTerms}
              title="Termos de pesquisa"
              onClose={handleCloseListTerms}
              handleAdd={(values) => onChangeValue("searchTerms", values)}
              handleCreate={handleOpenModalTerms}
              titleAdd="Criar novo termo de pesquisa"
            />
          </ModalBox>
          <ModalBox open={openModalTerms} onClose={handleCloseModalTerms} bg="white">
            <CreateEdit data={null} onCloseForm={handleCloseModalTerms} refreshData={fetchTerms} />
          </ModalBox>
          {errors.searchTerms && (
            <Typography sx={formStyles.errorMsg}>{errors.searchTerms}</Typography>
          )}
        </TabPanel>
        <TabPanel value={5} error={errors.gtAnswerItems}>
          <PageListHeader
            title="Itens de resposta"
            titleAdd="Adicionar itens de resposta"
            handleAdd={handleOpenListItemA}
            error={errors.gtAnswerItems}
          />
          <Table
            tableOptions={false}
            columns={[
              {
                field: "gtAnswerItemType",
                headerName: "Tipo",
                renderCell: (params) => params?.value?.title || "-",
                align: "center",
                flex: 1,
              },
              { field: "title", headerName: "Título", flex: 5 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDelete(params.row.id, "gtAnswerItems")} />
                ),
              },
            ]}
            data={values?.gtAnswerItems}
          />
          <ModalBox open={openListItemA} onClose={handleCloseListItemA} bg="white">
            <ListComponent
              data={itemsAnswer}
              checkedData={values?.gtAnswerItems}
              title="Itens de resposta"
              onClose={handleCloseListItemA}
              handleAdd={(values) => onChangeValue("gtAnswerItems", values)}
              handleCreate={handleOpenModalItemA}
              titleAdd="Criar novo item"
              disablePadding={false}
              itemsPerPage={5}
            />
          </ModalBox>
          <ModalBox open={openModalItemA} onClose={handleCloseModalItemA} bg="white">
            <CreateEditItemAnswer
              data={null}
              onCloseForm={handleCloseModalItemA}
              refreshData={fetchItemAnswer}
              typesAnswer={typesAnswer}
              fetchOptionsData={getTypesAnswer}
            />
          </ModalBox>
          {errors.gtAnswerItems && (
            <Typography sx={formStyles.errorMsg}>{errors.gtAnswerItems}</Typography>
          )}
        </TabPanel>
      </Tabs>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default AnswerForm;
