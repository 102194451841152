import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import { formatDateToTable } from "~/presentation/functions/date";
import CreateEdit from "./CreateEdit/CreateEdit";

const Blog = ({ blogUseCases, blogCategoryUseCases }) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //data
  const [posts, setPosts] = useState([]);
  const [blogTags, setBlogTags] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando conteúdo");
    const response = await blogUseCases.getBlogAllPosts();
    await getCategories();
    setPosts(response);
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTags = async () => {
    openBackdrop(true, "Carregando tags");
    let tags = await blogUseCases.getBlogTags();
    tags = dataToSelectMapper(tags);
    setBlogTags(tags);
    openBackdrop(false);
  };

  const getCategories = async () => {
    openBackdrop(true, "Carregando categorias");
    let categories = await blogCategoryUseCases.getBlogCategories();
    categories = dataToSelectMapper(categories, "id", "title");
    setBlogCategories(categories);
    openBackdrop(false);
  };

  const columns = [
    {
      field: "categoryId",
      headerName: "Categoria",
      filter: false,
      sort: false,
      flex: 1,
      align: "center",
      renderCell: (params) => blogCategories.find((category) => category.id === params.value).title,
    },
    { field: "title", headerName: "Título", flex: 4 },
    {
      field: "publishDate",
      headerName: "Data",
      filter: false,
      renderCell: (params) => formatDateToTable(params.value),
      flex: 1,
      align: "center",
    },
    {
      field: "isDraft",
      headerName: "Rascunho",
      sort: false,
      renderCell: (params) => (params.value ? "Sim" : "Não"),
      flex: 1,
      align: "center",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo post do blog");
    try {
      await blogUseCases.deleteBlogPost(id);
      setPosts((prev) => prev.filter((item) => item.id !== id));
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir post: Missing endpoint",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este post?`,
      callback: () => handleDelete(id),
    });
  };

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          blogUseCases={blogUseCases}
          blogTags={blogTags}
          blogCategories={blogCategories}
          getTags={getTags}
          getCategories={getCategories}
        />
      ) : (
        <>
          <PageListHeader
            title="Blog"
            titleAdd="Criar novo post"
            handleAdd={() => handleOpenForm()}
          />
          <Box sx={{ margin: 3 }}>
            <Table columns={columns} data={posts} categories={blogCategories} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Blog;
