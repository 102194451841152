import React from "react";
import { Box, Button, Typography } from "@mui/material";
import MotionDiv from "~/presentation/components/Motion/MotionDiv";

const plans = [
  {
    id: 1,
    name: "Diamante",
    isConsultISS: true,
    isGenerateReport: true,
    consultLimit: 99999999999999,
    taxInformationNumber: 10,
    numberOfUsers: 12,
    isCalculationSimulator: true,
    isSpecificSubstitutionAnalysis: true,
    isAllowTaxInformation: false,
    isCustom: true,
    valor: 999.0,
  },
  {
    id: 2,
    name: "Ouro",
    isConsultISS: false,
    isGenerateReport: true,
    consultLimit: 500,
    taxInformationNumber: 15,
    numberOfUsers: 6,
    isCalculationSimulator: true,
    isSpecificSubstitutionAnalysis: false,
    isAllowTaxInformation: true,
    isCustom: true,
    customCard: true,
    valor: 699.0,
  },
  {
    id: 2,
    name: "Prata",
    isConsultISS: false,
    isGenerateReport: false,
    consultLimit: 100,
    taxInformationNumber: 12,
    numberOfUsers: 5,
    isCalculationSimulator: false,
    isSpecificSubstitutionAnalysis: false,
    isAllowTaxInformation: true,
    isCustom: true,
    customCard: true,
    valor: 499.0,
  },
];

function mapPlanFeatures(plan) {
  let featuresList = [];

  if (plan.consultLimit > 500) {
    featuresList.push("Consultas ilimitadas");
  } else {
    featuresList.push(`${plan.consultLimit} consultas/mês`);
  }

  if (plan.isGenerateReport) {
    featuresList.push("Geração de relatório PDF das consultas");
  }

  if (plan.isCalculationSimulator) {
    featuresList.push("Simulador de cálculo");
  }

  if (plan.isConsultISS) {
    featuresList.push("Consulta de legislação por município");
  }

  return featuresList;
}

const handleOnClickContactButton = () => {
  window.open(
    "https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Acessei%20o%20site%20Gest%C3%A3o%20Tribut%C3%A1ria%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20planos%20junto%20a%20um%20representante.",
    "_blank",
    "noopener,noreferrer"
  );
};

const Plans = ({ id }) => {
  return (
    <Box sx={styles.container} id={id}>
      <MotionDiv once>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.title}>
            Prepare-se para viver uma <span>nova</span> e <span>revolucionária</span> forma de lidar
            com as principais Retenções Tributárias incidentes em seus contratos.
          </Typography>
        </Box>
      </MotionDiv>

      <Box sx={styles.cardsContainer}>
        {plans.map((plan, index) => {
          const isCostum = plan.customCard;
          return (
            <MotionDiv
              right={index === 0}
              up={index === 1}
              left={index === 2}
              once
              key={"landing-page-plans-card-" + index}
            >
              <Box sx={[styles.card, isCostum && styles.costumCard]}>
                {!isCostum && <Box sx={styles.tagPlan}>APROVEITE</Box>}
                <Box sx={styles.cardTitle}>
                  <Typography sx={[styles.cardTitle.pre, isCostum && styles.costumColor]}>
                    GT
                  </Typography>
                  <Typography sx={[styles.cardTitle.title, isCostum && styles.costumColor]}>
                    {plan.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "15px",
                    backgroundColor: !isCostum ? "#fff" : "#173468",
                    height: "1px",
                    width: "100%",
                  }}
                />
                <Box sx={styles.price}>
                  <Typography sx={[styles.price.currency, isCostum && styles.costumColor]}>
                    R$
                  </Typography>
                  <Typography sx={[styles.price.value, isCostum && styles.costumColor]}>
                    {plan.valor.toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                  </Typography>
                  <Typography sx={[styles.price.period, isCostum && styles.costumColor]}>
                    /mês
                  </Typography>
                </Box>
                <Button sx={styles.button} onClick={handleOnClickContactButton}>
                  Contrate
                </Button>
                <Box sx={styles.year}>
                  <Typography sx={[styles.year.text, isCostum && styles.costumColor]}>
                    *No plano anual (Total = R${" "}
                    {(plan.valor * 12).toLocaleString("pt-br", { minimumFractionDigits: 2 })})
                  </Typography>
                </Box>
                <Box sx={styles.content}>
                  <Box sx={styles.features}>
                    <Typography sx={[styles.features.text, isCostum && styles.costumColor]}>
                      CADASTRO DE {plan.numberOfUsers} USUÁRIOS
                    </Typography>
                    <Typography sx={[styles.features.text, isCostum && styles.costumColor]}>
                      ACESSO AOS ARTIGOS PUBLICADOS
                    </Typography>
                    <Typography sx={[styles.features.text, isCostum && styles.costumColor]}>
                      ACESSO À LEGISLAÇÃO SELECIONADA
                    </Typography>
                    <Typography sx={[styles.features.text, isCostum && styles.costumColor]}>
                      ACESSO A VÍDEOS
                    </Typography>
                    <Typography sx={[styles.features.text, isCostum && styles.costumColor]}>
                      ACESSO AO GT-FÁCIL
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: !isCostum ? "#fff" : "#173468",
                      height: "1px",
                      width: "100%",
                      marginTop: "25px",
                      marginBottom: "10px",
                    }}
                  />
                  <Box>
                    <Box sx={styles.gtFacil}>
                      <Box
                        component="img"
                        src={
                          !isCostum
                            ? "/assets/icons/calendar-white.png"
                            : "/assets/icons/calendar-blue.png"
                        }
                        alt="calendar"
                        sx={{
                          width: "53px",
                          height: "53px",
                          objectFit: "contain",
                          marginRight: "10px",
                        }}
                      />
                      <Typography sx={[styles.gtFacil.title, isCostum && styles.costumColor]}>
                        RECURSOS DO GT-FÁCIL DISPONÍVEIS:
                      </Typography>
                    </Box>
                    <Box sx={styles.gtFacil.list}>
                      {mapPlanFeatures(plan).map((feature, index) => (
                        <Box
                          key={`feature${index}`}
                          sx={[styles.gtFacil.feature, isCostum && styles.costumColor]}
                        >
                          <Box
                            component="img"
                            src={
                              !isCostum ? "/assets/icons/check.png" : "/assets/icons/blue-check.png"
                            }
                            alt="check"
                            sx={{
                              width: "20px",
                              height: "20px",
                              objectFit: "contain",
                              marginRight: "10px",
                            }}
                          />
                          {feature}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </MotionDiv>
          );
        })}
      </Box>
      <Box sx={styles.personalPlan}>
        <Box sx={styles.personalPlan.content}>
          <Typography sx={styles.personalPlan.content.title}>
            Dá um upgrade no seu plano!
          </Typography>
          <Typography sx={styles.personalPlan.content.text}>
            Nós temos planos personalizados para lhe atender de forma ainda mais completa. Confira
            os recursos avançados disponíveis:
          </Typography>
          <Typography sx={styles.personalPlan.content.text}>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Consultas ao <b>GT-Fácil</b> Ilimitadas
          </Typography>
          <Typography sx={styles.personalPlan.content.text}>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Envio facilitado da <b>EFD-Reinf</b>
          </Typography>
          <Typography sx={styles.personalPlan.content.text}>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Pacote de Informação Fiscal Ilimitados
          </Typography>
          <Typography sx={styles.personalPlan.content.text}>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Suporte direto via WhatsApp
          </Typography>
          <Typography sx={styles.personalPlan.content.text}>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Planos a partir de R$ 3.000,00/Mês
          </Typography>
          <Typography sx={styles.personalPlan.content.text}>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Plano GT Fácil + GT Reinf + IFs (Informações fiscais) a partir de R$6.000,00
          </Typography>
          <Button sx={styles.button} onClick={handleOnClickContactButton}>
            Contrate
          </Button>
        </Box>
        <Box sx={styles.personalPlan.side} />
      </Box>
    </Box>
  );
};

export default Plans;

const styles = {
  container: {
    position: "relative",
    height: "fit-content",
    backgroundImage: 'url("/assets/maze5.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    display: "grid",
    gridTemplateColumns: "auto",
    gridTemplateRows: "min-content min-content",
    justifyItems: "center",
    padding: "max(10%, 40px) 3%",
    gap: "min(7vw, 51px)",
  },
  titleWrapper: {
    display: "grid",
    justifyItems: "center",
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "24px",
    lineHeight: "43px",
    letterSpacing: "0.06em",
    color: "#fafafa",
    textAlign: "center",
    marginBlock: "12px",
    "> span": {
      fontWeight: 600,
    },
    "@media (min-width: 768px)": {
      fontSize: "40px",
    },
  },
  cardsContainer: {
    display: "grid",
    alignItems: "start",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto",
    gap: "32px",

    "@media (max-width: 1245px)": {
      gridTemplateColumns: " repeat(2, 1fr)",
      gridTemplateRows: "repeat(2, min-content)",
      gap: "32px",
    },

    "@media (max-width: 600px)": {
      gridTemplateColumns: "auto",
      gridTemplateRows: "repeat(3, min-content)",
      gap: "32px",
      justifyItems: "center",
    },
  },
  card: {
    display: "flex",
    flexFlow: "column",
    justifyItems: "center",
    borderRadius: "18px",
    background: "linear-gradient(180deg, #e97c03 47.92%, #6a4113 100%)",
    boxShadow: "0px 0px 15px -2px rgba(0, 0, 0, 0.25)",
    padding: "28px",
    transition: "transform 0.2s ease-in-out",
    width: "100%",
    minHeight: "803px",
    height: "803px",
    maxWidth: "386px",
    "&:hover": {
      transform: "translateY(-12px)",
    },
  },
  costumCard: {
    background: "linear-gradient(180deg, #fffdfc 0%, #d0cac3 100%)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  costumColor: { color: "#173468" },
  tagPlan: {
    height: "32px",
    backgroundColor: "#03c0e9",
    borderRadius: "18px",
    color: "white",
    fontSize: "24px",
    fontFamily: "'Bebas Neue', sans-serif",
    fontWeight: 400,
    wordWrap: "break-word",
    width: "fit-content",
    padding: "0px 30px",
    position: "absolute",
    top: "-25px",
  },
  cardTitle: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    pre: {
      paddingLeft: "5px",
      fontSize: "30px",
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: "0.06em",
      fontFamily: "Montserrat, sans-serif",
      color: "#fafafa",
    },
    title: {
      paddingLeft: "10px",
      fontFamily: "'Yellowtail', cursive",
      fontSize: "50px",
      lineHeight: "32px",
      color: "#fff",
      textAlign: "left",
    },
  },
  price: {
    display: "flex",
    alignItems: "baseline",
    width: "100%",
    marginTop: "20px",
    height: "43px",
    currency: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 20,
      color: "#fff",
      fontWeight: 500,
      marginRight: "5px",
    },
    value: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 30,
      color: "#fff",
      fontWeight: 500,
    },
    period: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 20,
      color: "#fff",
      fontWeight: 500,
    },
  },
  button: {
    marginTop: "20px",
    width: "min(313px, 100%)",
    marginBottom: "1rem",
    height: "57px",
    borderRadius: "18px",
    background: "linear-gradient(180deg, #2455b2 0%, #102342 100%)",
    color: "#fff",
    textAlign: "center",
    fontFamily: "'Bebas Neue', sans-serif",
    fontSize: "28px",
    border: "none",
    "&:hover": {
      transform: "scale(1.01)",
    },
  },
  year: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "42px",
    flexShrink: 0,
    marginTop: "20px",
    text: {
      width: "100%",
      color: "#fff",
      textAlign: "center",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  features: {
    marginTop: "20px",
    text: {
      fontFamily: "Montserrat, sans-serif",
      color: "#fff",
      fontSize: "16px",
      fontWeight: 500,
      marginBottom: "5px",
    },
  },
  gtFacil: {
    marginTop: "5px",
    display: "flex",
    flexFlow: "row nowrap",
    title: {
      color: "#FFF",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      margin: 0,
      marginBottom: "10px",
    },
    list: {
      color: "#FFF",

      paddingLeft: "10px",
      paddingBottom: "10px",
      width: "100%",
      display: "flex",
      flexFlow: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    feature: {
      color: "#FFF",

      fontFamily: "Montserrat, sans-serif",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: 500,
    },
  },
  personalPlan: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    borderRadius: "1.8rem",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.25)",
    transition: "transform 0.2s ease-in-out",
    width: "100%",
    minHeight: "39rem",
    height: "39rem",
    maxWidth: "77rem",
    "&:hover": {
      transform: "scale(1.01)",
    },
    "@media (max-width: 768px)": {
      minHeight: "55rem",
      height: "55rem",
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto auto",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      borderRadius: "18px 0 0 18px",
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingTop: "40px",
      background: "white",
      gridRow: "auto",
      gridColumn: "auto",
      "@media (max-width: 768px)": {
        borderRadius: "18px 18px 0 0",
        gridRow: "1",
        gridColumn: "1",
      },
      title: {
        color: "#132a52",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "50px",
        fontStyle: "italic",
        fontWeight: 800,
        lineHeight: "normal",
        textAlign: "left",
        marginBottom: 2,
        "@media (max-width: 960px)": {
          textAlign: "center",
        },
      },
      text: {
        color: "#000",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        marginBlock: 2,
        textAlign: "left",
        "> img": {
          width: "27px",
          height: "16px",
          objectFit: "contain",
          marginRight: "12px",
        },
        "> b": {
          color: "#000",
          fontFamily: "Montserrat, sans-serif",
          fontSize: "20px",
          fontStyle: "italic",
          fontWeight: 800,
          lineHeight: "normal",
        },
        "@media (max-width: 960px)": {
          textAlign: "center",
        },
      },
    },
    side: {
      background: 'url("/assets/tubiner-seu-plano.png") lightgray 0% / cover no-repeat',
      backgroundSize: "100% 100%",
      borderRadius: "0 18px 18px 0",
      "@media (max-width: 768px)": {
        background: "white",
        borderRadius: "0 0 18px 18px",
        objectFit: "cover",
      },
    },
  },
};
