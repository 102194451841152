import React, { useEffect, useState } from "react";
import { EditUserForm } from "~/presentation/views/Client/MyPanel/Pages/UserData/components/EditUserForm/index";
import { Container, Box, Typography as Text, CircularProgress } from "@mui/material";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";

export const UserData = ({ companyUseCases, userUseCases }) => {
  const { companyId, id: userId } = JSON.parse(localStorage.getItem("GT_user"));
  const [company, setCompany] = useState(null);
  const [user, setUser] = useState();
  const [, setTaxData] = useState();
  const [loading, setLoading] = useState({ user: true, company: true });

  const getUser = async () => {
    const result = await userUseCases.getUserById(userId);
    setUser(result);
    setLoading((prev) => ({ ...prev, user: false }));
  };

  const getTax = async () => {
    const result = await userUseCases.getTaxData();
    setTaxData(result);
  };

  const updateUser = (data) => {
    return userUseCases.updateUser(userId, data);
  };

  const getCompany = async () => {
    const result = await companyUseCases.getCompanyById(companyId);
    const mobilePhone = result?.mobilePhone;
    const homePhone = result?.mobilePhone;
    const cnpj = result?.cnpj;
    const cep = result?.zipCode;
    const formattedMobilePhone = mobilePhone ? (
      <Input type="mask" mask="(00) 00000-0000" value={mobilePhone} styles={styles.input} />
    ) : null;

    const formattedHomePhone = homePhone ? (
      <Input type="mask" mask="(00) 00000-0000" value={homePhone} styles={styles.input} />
    ) : null;

    const formattedCpnj = cnpj ? (
      <Input type="mask" mask="00.000.000/0000-00" value={cnpj} styles={styles.input} />
    ) : null;

    const formattedCep = cnpj ? (
      <Input type="mask" mask="00000-000" value={cep} styles={styles.input} />
    ) : null;

    setCompany([
      ["Categoria", result.category.name],
      ["Plano", result.plan.name],
      ["CNPJ", formattedCpnj],
      ["Nome", result.name],
      ["Endereço", result.address],
      ["Complemento", result.complement],
      ["CEP", formattedCep],
      ["Cidade", result.city.name],
      ["Estado", result.state.name],
      ["Telefone", formattedHomePhone],
      ["Celular", formattedMobilePhone],
      ["E-mail", result.email],
    ]);

    setLoading((prev) => ({ ...prev, company: false }));
  };

  useEffect(() => {
    getCompany();
    getUser();
    getTax();
  }, []);

  const loadingProgress = () => (
    <Box sx={styles.progress}>
      <CircularProgress size={40} color="inherit" />
    </Box>
  );

  return (
    <Container sx={{ paddingBottom: 6 }}>
      <Text sx={styles.title}>Minha Empresa</Text>
      {loading.company ? (
        loadingProgress()
      ) : (
        <Grid container spacing={2}>
          {company?.map((c, i) => (
            <Grid size={{ xs: 12, sm: 6 }} key={i}>
              <Box sx={styles.box}>
                <Text sx={[styles.text, { fontWeight: 500 }]}>{c[0]}</Text>
                <Text sx={styles.text}>{c[1]}</Text>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      <Text sx={styles.title}>Minhas Informações</Text>
      {loading.user ? loadingProgress() : <EditUserForm user={user} updateUser={updateUser} />}
    </Container>
  );
};

const styles = {
  box: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  ifs: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#EFF2F4",
    padding: "20px 20px",
    borderRadius: 2,
  },
  border: {
    borderLeft: "2px solid #9EA5AA",
    height: 20,
  },
  text: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    paddingBlock: 3,
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    backgroundColor: "transparent",
    border: "none",
    textAlign: "right",
    color: "#021148",
    height: "fit-content",
  },
};
