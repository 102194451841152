import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import TableActions from "~/presentation/components/TableActions";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import PageListHeader from "../components/PageListHeader";
import Table from "~/presentation/components/Table";
import CreateEdit from "./CreateEdit/CreateEdit";

function ExplanatoryCategory({ explanatoryVideoCategory }) {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [categories, setCategories] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const categories = await explanatoryVideoCategory.get();
    setCategories(categories);
    openBackdrop(false);
  };

  const columns = [
    { field: "title", headerName: "Título", flex: 2 },
    { field: "description", headerName: "Descrição", flex: 5 },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo categoria");
    try {
      await explanatoryVideoCategory.delete(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: `Categoria eliminada com sucesso!`,
        type: "success",
      });
      setCategories((prev) => prev.filter((item) => item.id !== id));
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro " + err?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta categoria?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <ModalBox open={openForm} onClose={handleCloseForm} bg="white">
        <CreateEdit data={formProps} onCloseForm={handleCloseForm} refreshData={fetchData} />
      </ModalBox>
      <PageListHeader
        title="Categorias FAQ"
        titleAdd="Criar nova categoria"
        handleAdd={() => handleOpenForm()}
      />
      <Box sx={{ margin: 3 }}>
        <Table columns={columns} data={categories} />
      </Box>
    </Box>
  );
}

export default ExplanatoryCategory;
