import React from "react";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { getMonthInPortuguese } from "../../../Utils/utilsDate";

function ActiveFilters({ allCertificates, filter, handleChangeFilter }) {
  const certificateObject = allCertificates?.find((item) => item.id == filter?.certificate);
  const event = `R-${filter?.event?.slice(1, 5)}`;
  return (
    <Box sx={styles.content}>
      {filter?.certificate && (
        <Box sx={styles.FilterContainer}>
          <Box sx={styles.textContainer}>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Certificado:</em>
            {certificateObject?.name}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleChangeFilter({ certificate: "" });
            }}
          />
        </Box>
      )}
      {filter?.month && (
        <Box sx={styles.FilterContainer}>
          <Box sx={styles.textContainer}>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Mês:</em>
            {getMonthInPortuguese(filter?.month)}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleChangeFilter({ month: "" });
            }}
          />
        </Box>
      )}
      {filter?.year && (
        <Box sx={styles.FilterContainer}>
          <Box sx={styles.textContainer}>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Ano:</em>
            {filter?.year}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleChangeFilter({ year: "" });
            }}
          />
        </Box>
      )}
      {filter?.event && (
        <Box sx={styles.FilterContainer}>
          <Box sx={styles.textContainer}>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Evento:</em>
            {event?.toUpperCase()}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleChangeFilter({ event: "" });
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ActiveFilters;

const styles = {
  content: {
    display: "flex",
    alignItems: "start",
    gap: 1,
    flexDirection: "column",
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  FilterContainer: {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E6E6E6",
    gap: 2,
    padding: "2px 8px",
    borderRadius: 20,
    fontSize: 12,
    color: "black",
  },
};
