import React, { useState } from "react";
import RButton from "../../Common/Buttons/RButton";
import {
  Typography as Text,
  Box,
  CircularProgress,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import {
  CheckCircle,
  CloudDownload,
  Error,
  InsertDriveFileOutlined,
  Pending,
  RadioButtonCheckedOutlined,
  RadioButtonUnchecked,
  Warning,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

function SHEET({ handleSHEET, files, handleClose }) {
  const renderIcon = (code) => {
    if (code === 1) {
      return <Error fontSize="small" color="error" />;
    }

    if (code === 2) {
      return <Warning fontSize="small" color="warning" />;
    }

    if (code === 3) {
      return <CheckCircle fontSize="small" color="success" />;
    }

    return <Pending fontSize="small" color="primary" />;
  };

  const [isReplace, setIsReplace] = useState(false);

  return (
    <>
      <Box
        sx={{
          ...styles.permissionText,
          border: `2px solid ${isReplace ? "#1341a1" : "#CECECE"}`,
        }}
        onClick={() => setIsReplace((prev) => !prev)}
      >
        <Typography sx={{ ...styles.subTitle, cursor: "pointer" }}>
          <strong>Permito a subtituição</strong> dos dados de documentos fiscais <br /> existentes
          no banco de dados pelos dessa planilha
        </Typography>
        <IconButton>
          {isReplace ? (
            <RadioButtonCheckedOutlined sx={{ color: "#1341a1" }} />
          ) : (
            <RadioButtonUnchecked sx={{ color: "#c0c0c0" }} />
          )}
        </IconButton>
      </Box>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {files.map((file, index) => (
          <Grid size={12} key={index}>
            <Grid container sx={styles.containerFields}>
              <Grid size={12}>
                <Box sx={styles.fields}>
                  <Box
                    sx={{
                      ...styles.fieldsText,
                      maxWidth: "100%",
                      "@media (min-width: 600px)": {
                        maxWidth: "60%",
                      },
                    }}
                  >
                    <InsertDriveFileOutlined />
                    <Text sx={styles.text}>{file.name}</Text>
                  </Box>
                  <Box sx={styles.fieldsText}>
                    <Text sx={styles.errorText}>
                      {file.success ? "Ficheiro carregado" : file.errorMsg}
                    </Text>
                    {file.loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : file.success ? (
                      <></>
                    ) : (
                      <Error fontSize="small" color="error" />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {file?.description && file.description.length === 0 ? (
                    <Box sx={styles.subItems}>
                      <Typography
                        sx={{
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Nenhuma operação foi realizada com as informações existentes no arquivo
                      </Typography>
                      <Box>{renderIcon(1)}</Box>
                    </Box>
                  ) : (
                    file.description?.map((item, index) => {
                      return (
                        <Box key={index} sx={styles.subItems}>
                          <Typography
                            sx={{
                              fontFamily: "Montserrat, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            {item.message}
                          </Typography>
                          <Box>{renderIcon(item.code)}</Box>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Link
            href="https://gtfacil-static.s3.sa-east-1.amazonaws.com/Documents/PlanilhaGTReinf.xlsx"
            rel="noopener noreferrer"
            download
          >
            <RButton variant="outlined" sx={styles.downloadButton} startIcon={<CloudDownload />}>
              Baixar planilha
            </RButton>
          </Link>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3 }}>
          <RButton variant="outlined" onClick={handleClose}>
            Fechar
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 5 }}>
          <>
            <input
              type="file"
              id="file-input"
              style={{ display: "none" }}
              accept=".xlsx"
              onChange={(event) => {
                const file = event.target.files[0];
                handleSHEET(file, isReplace);
              }}
              disabled={files.length > 0}
            />
            <label htmlFor="file-input">
              <RButton component="span" disabled={files.length > 0}>
                Selecionar Arquivos
              </RButton>
            </label>
          </>
        </Grid>
      </Grid>
    </>
  );
}

export default SHEET;

const styles = {
  subTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 15,
    fontWeight: 500,
    color: "#404040",
  },
  permissionText: {
    display: "flex",
    marginLeft: "auto",
    marginBlock: 1,
    borderRadius: "6px",
    width: "fit-content",
    padding: "6px 6px 6px 12px",
    transition: "border 0.1s",
  },
  errorText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "15px",
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: 2,
  },
  fields: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    flexDirection: "column",
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },
  fieldsText: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "10px",
  },
  subItems: {
    display: "flex",
    gap: 1,
    padding: 1,
  },
  text: {
    maxWidth: "40ch" /* Tamanho */,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Montserrat, sans-serif",
    whiteSpace: "nowrap",
  },
  downloadButton: {
    border: "none",
    color: "#217346",
    margin: "0 auto",
    "&: hover": {
      border: "none",
    },
  },
};
