import React from "react";
import {
  DataGrid,
  GridFooterContainer,
  GridPagination,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Input, styled } from "@mui/material";
import { Search } from "@mui/icons-material";

const Table = ({
  columns = [], //colunas
  data = [], //linhas
  errorMsg = false, //errorMsg costumizado
  setSelectedRows = null, //entrega os id das linhas selecinadas
  tableOptions = true, //caso false elimina o toolbar options (colunas, filtrar, densidade e exportar)
  searchInput = true, //caso false não mostra o input de search
  loading = false,
  customToolbarExtraComponents = [],
}) => {
  const StyledTable = styled(DataGrid)(() => ({
    border: "none", //tira o border à volta do Toolbar
    maxHeight: "calc(100% - 53px) !important",
    width: "100%",
    overflowX: "auto", // Permite que a tabela role horizontalmente se necessário
    "& .MuiDataGrid-main": {
      borderRadius: "12px", // Apply to the whole grid
      border: "none",
      overflow: "hidden", // Ensure overflow respects the border-radius
    },
    // Header cell
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: "#001A50",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    //Eliminar borda ao clicar no header
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none",
    },
    //Cor do checkbox do header
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    //Eliminar borda ao clicar no checkbox
    "& .MuiDataGrid-columnHeaderCheckbox:focus-within": {
      outline: "none",
    },
    "& .MuiDataGrid-cellCheckbox:focus-within": {
      outline: "none",
    },
    //Eliminar borda ao clicar nas cells
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    // Remover a borda azul ao clicar nos ícones de ação
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    // Remover borda azul especificamente nos botões de ícone
    "& .MuiIconButton-root:focus": {
      outline: "none",
    },
    // Remover borda azul especificamente nos ícones SVG
    "& .MuiSvgIcon-root:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "visible",
      whiteSpace: "normal",
      lineHeight: "1.5rem",
    },
    // Não quero que apareça sempre, só no clique da coluna
    "& .MuiDataGrid-sortIcon": {
      display: "none",
    },
    // Seta sort
    "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
      display: "block",
      color: "white",
    },
    // Row
    "& .MuiDataGrid-row": {
      backgroundColor: "#D9D9D9",
      borderRadius: "8px",
      borderBlock: "2px solid #FFF",

      "&:hover": {
        backgroundColor: "#C9C9C9",
      },
    },
    //Linha selecionada
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgb(0, 26, 80, 0.2)",
    },
    // Cell
    "& .MuiDataGrid-cell": {
      borderBottom: "1px solid #ddd",
      fontSize: "14px",
    },
    // Esconder o separador entre colunas
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    // Pagination
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "right",
      paddingRight: "50px",
      backgroundColor: "#f1f1f1",
    },
    "& .MuiTablePagination-displayedRows": {
      margin: 0,
    },
    "& .MuiTablePagination-root": {
      color: "#001A50",
    },
    "& .MuiTablePagination-selectLabel": {
      margin: 0,
    },
    // Estilos para a linha de "Sem dados"
    "& .MuiDataGrid-overlay": {
      backgroundColor: "#D9D9D9",
    },
  }));

  const renderSearchComponent = ({ value, onChange }) => (
    <Box sx={styles.search.box}>
      <Input
        type="text"
        placeholder="Pesquisar..."
        value={value}
        onChange={onChange}
        disableUnderline
        endAdornment={<Search sx={{ color: "gray" }} />}
        sx={styles.search.input}
      />
    </Box>
  );

  const CustomToolbar = () => {
    return (
      <Box sx={styles.toolbar}>
        {tableOptions && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <GridToolbarDensitySelector
              slotProps={{
                button: {
                  sx: {
                    color: "#001A50",
                  },
                },
              }}
            />
            <GridToolbarExport
              slotProps={{
                button: {
                  sx: {
                    color: "#001A50",
                  },
                },
              }}
            />
            {customToolbarExtraComponents?.map((component, index) => component(index))}
          </Box>
        )}
        {searchInput && <GridToolbarQuickFilter />}
      </Box>
    );
  };

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <GridPagination />
      </GridFooterContainer>
    );
  };

  const handleSelectionChange = (id) => {
    if (!setSelectedRows) {
      return;
    } else {
      setSelectedRows(id);
    }
  };

  const getColumns = () => {
    if (columns?.length === 0) {
      return [{ field: "", headerName: "Tabela", flex: 1, headerAlign: "center" }];
    }
    return columns?.map((item) => ({ ...item, headerAlign: "center" }));
  };

  return (
    <Box sx={styles.container}>
      <StyledTable
        autoHeight
        rows={data || []}
        columns={getColumns()}
        loading={loading}
        slots={{
          toolbar: CustomToolbar,
          footer: CustomFooter,
          baseTextField: renderSearchComponent, //trocar o input do MUI pelo nosso search
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        onRowSelectionModelChange={handleSelectionChange}
        disableSelectionOnClick={true} // Desativa a seleção de linha ao clicar
        pagination
        pageSizeOptions={[5, 10, 25, 50, 100]}
        checkboxSelection={setSelectedRows}
        disableColumnMenu
        localeText={{
          noRowsLabel: errorMsg || "Nenhum registro encontrado!",
          noResultsOverlayLabel: "Nenhum resultado encontrado.",
          errorOverlayDefaultLabel: "Ocorreu um erro.",
          MuiTablePagination: {
            showFirstButton: true,
            showLastButton: true,

            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
            labelRowsPerPage: "Linhas por página",
            getItemAriaLabel: (type) => {
              if (type == "first") return `Primeira página`;
              if (type == "previous") return `Página anterior`;
              if (type == "next") return `Próxima página`;
              if (type == "last") return `Última página`;
            },
          },
          toolbarDensity: "Densidade",
          toolbarDensityCompact: "Compacto",
          toolbarDensityStandard: "Padrão",
          toolbarDensityComfortable: "Confortável",
          toolbarExport: "Exportar",
          toolbarExportCSV: "Download CSV",
          toolbarExportPrint: "Imprimir",
        }}
      />
    </Box>
  );
};

export default Table;

const styles = {
  container: {
    height: "calc(100% - 62px)",
    position: "relative",
    width: "100%",
    padding: "0 1%",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBlock: 1,
    "@media (min-width: 600px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  search: {
    box: {
      width: "min(300px, 90%)",
      boder: "none",
      "@media (min-width: 600px)": {
        marginLeft: "auto",
      },
    },
    input: {
      width: "100%",
      height: "38px",
      fontSize: 14,
      fontFamily: "Montserrat",
      background: "#E6E6E6",
      borderRadius: "12px",
      border: "1px solid #000000",
      padding: "0px 10px",
      "& input::placeholder": {
        color: "gray",
        opacity: 1,
      },
      "&:hover": {
        border: "2px solid #000000",
      },
    },
  },
};
