import React from "react";
import useWindowSize from "~/presentation/hooks/useWindowSize";
import ModalBackground, {
  handleColorByWindowSize,
} from "~/presentation/views/LandingPage/Home/Modals/components/Bg";
import ModalContainer from "~/presentation/components/ModalContainer";
import {
  Header,
  Icon,
  Button,
  ContainerTextHeader,
} from "~/presentation/views/LandingPage/Home/Modals/components/Wrappers";
import { Box, Typography as Text } from "@mui/material";
import paperIcon from "~/presentation/views/LandingPage/Home/Modals/icons/paper.webp";

const ReinfInfoModal = () => {
  const { width: screenWidth } = useWindowSize();

  const bgColorSelector = () => {
    return handleColorByWindowSize(screenWidth);
  };

  const handleOnClickContactButton = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Vim%20do%20site%20Gest%C3%A3o%20Tribut%C3%A1ria%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20ferramenta%20GT-Reinf%20junto%20a%20um%20representante.",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <ModalContainer backgroundUrl="/assets/sample_photos/magnifying-glass-pc2.jpg">
      <ModalBackground color={bgColorSelector()} />
      <Header>
        <ContainerTextHeader>
          <Icon src={paperIcon} alt="Modal Icon" />
          <div>
            <span>GT-Reinf</span>
          </div>
        </ContainerTextHeader>
        <Button onClick={handleOnClickContactButton}>CONTRATE</Button>
      </Header>

      <Box sx={{ p: 6, color: "#fff", zIndex: 1 }}>
        Bem-vindo ao GT-Reinf: A Solução definitiva para o envio de Informações das Retenções na
        fonte para a Receita Federal do Brasil.
        <br />
        <br /> O GT-Reinf é um módulo avançado do sistema GT, especialmente desenvolvido para
        facilitar o envio das informações quanto às retenções através da EFD-Reinf. Nossa ferramenta
        foi projetada para tornar o processo de envio mais rápido, fácil e seguro, eliminando a
        complexidade e os erros comuns nesse procedimento. Com o GT-Reinf, você pode extrair dados
        diretamente dos arquivos XML de notas fiscais de serviços (NFS-e) e produtos (NF-e),
        automatizando o preenchimento dos campos necessários. Nossa solução define alíquotas e
        calcula retenções automaticamente, e ainda oferece uma interface intuitiva para
        preenchimento manual, reutilizando dados inseridos para futuros envios. <br />
        <br />O GT-Reinf também garante que todas as informações sejam verificadas antes do envio,
        assegurando precisão e segurança. Com um histórico detalhado de envios e uma ferramenta
        prática para retificações, você mantém controle total sobre seus dados. Não é apenas uma
        questão de conformidade; é sobre operar com tranquilidade e confiança. <br />
        <br />
        Transforme a maneira como sua empresa lida com a informação dos dados relativos às retenções
        na fonte. Experimente o GT-Reinf e veja a diferença que uma solução realmente eficaz pode
        fazer. Entre em contato e saiba mais. <br />
        <br />
        <Text sx={{ fontFamily: "Montserrat", fontWeight: 600 }}>
          GT-Reinf: Rápido, Fácil e Seguro!
        </Text>
      </Box>
    </ModalContainer>
  );
};

export default ReinfInfoModal;
