import React, { useState } from "react";
import { Badge, Box, Button, IconButton, MenuItem, Popover } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Grid from "@mui/material/Grid2";

export const ReinfTableFilter = ({ onFilter, tableFilterFields, initialValues }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState(initialValues);
  const [appliedFilters, setAppliedFilters] = useState([]);

  const handleSelectFilterChange = (value) => {
    setFilters({ ...filters, ...value });
  };

  const handleApplyFilter = () => {
    const formattedFilters = {
      ...filters,
    };
    onFilter(formattedFilters);
    setAnchorEl(null);
    updateAppliedFilters(formattedFilters);
  };

  const handleClearFilters = () => {
    setFilters(initialValues);
    onFilter({});
    setAppliedFilters([]);
    setAnchorEl(null);
  };

  const updateAppliedFilters = (currentFilters) => {
    const applied = [];
    Object.keys(currentFilters).forEach((key) => {
      if (currentFilters[key]) {
        applied.push(key);
      }
    });
    setAppliedFilters(applied);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" justifyContent="flex-end" flex={1}>
        <IconButton aria-describedby={id} variant="text" color="inherit" onClick={handleOpen}>
          <Badge badgeContent={appliedFilters.length} color="primary">
            <FilterAlt titleAccess={"Filtrar"} />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid container p={2}>
            <Grid size={12}>
              <h5 style={{ color: "#383f48" }}>Filtros</h5>
            </Grid>
            {tableFilterFields?.map((filterField, index) => {
              if (filterField.type === "select") {
                return (
                  <Grid size={12} key={`${index}-${filterField.title}`}>
                    <RButton
                      type="filterSelect"
                      title={filterField.title}
                      placeholder={filterField?.placeholder || ""}
                      value={filters[filterField.fieldValue] || ""}
                      onChange={(event) => {
                        handleSelectFilterChange(filterField.fieldOnChange(event.target.value));
                      }}
                    >
                      {filterField?.filterOptions?.map((filterOption) => (
                        <MenuItem key={filterOption.value} value={filterOption.value}>
                          {filterOption.label}
                        </MenuItem>
                      ))}
                    </RButton>
                  </Grid>
                );
              }
            })}
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
                mt: 2,
              }}
            >
              <Button variant="outlined" color="error" onClick={handleClearFilters}>
                Limpar filtros
              </Button>
              <Button variant="outlined" color="primary" onClick={handleApplyFilter}>
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </Box>
    </Box>
  );
};
