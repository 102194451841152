import { Box, Typography } from "@mui/material";
import React from "react";
import MotionDiv from "~/presentation/components/Motion/MotionDiv";

const TaxInfo = ({ id }) => {
  return (
    <Box sx={styles.outerContainer} id={id}>
      <Box sx={styles.container}>
        <Box sx={styles.container[".bgImg"]} />
        <Box sx={styles.container[".linearGradient"]} />
        <MotionDiv right once>
          <Box sx={styles.contentWrapper}>
            <Box sx={styles.contentWrapper[".line"]} />
            <Box sx={styles.contentWrapper[".title"]}>
              <Typography variant="h2">Informação Fiscal (IF)</Typography>
            </Box>
            <Box sx={styles.contentWrapper[".content"]}>
              <Typography variant="body1">
                Para lhe proporcionar sempre as melhores respostas, disponibilizamos um serviço
                chamado Informação Fiscal (ou simplesmente IF).
                <br></br>
                <br></br>
                Envie questionamentos e receba uma consultoria personalizada acerca da incidência de
                tributos: INSS, IRRF, CSLL, PIS/Pasep, COFINS e ISS sobre os pagamentos a seus
                contratados, sejam eles pessoas físicas ou jurídicas.
              </Typography>
            </Box>
          </Box>
        </MotionDiv>
      </Box>
    </Box>
  );
};

export default TaxInfo;

const styles = {
  outerContainer: {
    marginBottom: "-39px",
    paddingLeft: "30px",
    paddingRight: "30px",
    zIndex: 2,
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      backgroundColor: "#e97c03",
      height: "80%",
      top: "50%",
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      transform: "translateZ(20px) translateY(-50%)",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
    },
  },
  container: {
    position: "relative",
    backgroundColor: "#ffffff",
    display: "flex",
    padding: "10% 12%",
    boxShadow: "0px -5px 10px rgba(0, 0, 0, 0.2)",
    zIndex: 2,
    "@media screen and (min-width: 600px)": {
      padding: "6% 8%",
    },
    ".bgImg": {
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "75%",
      width: "609px",
      height: "415px",
      background: 'url("/assets/Business-people-working-on-laptop-462164.jpg")',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 2,
      "@media screen and (max-width: 960px)": {
        display: "none",
      },
      "@media screen and (max-width: 1280px)": {
        width: "434px",
      },
      "&::before": {
        content: '""',
        position: "absolute",
        top: "-5px",
        left: "-5px",
        right: "-5px",
        bottom: "-5px",
        zIndex: 1,
        transform: "translateZ(-20px)",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)",
      },
    },
    ".linearGradient": {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
    },
  },
  contentWrapper: {
    position: "relative",
    display: "grid",
    gridTemplateAreas: '"title" "content"',
    gridTemplateColumns: "auto",
    gap: "34px",
    width: "100%",
    gridTemplateRows: "min-content min-content",
    "@media screen and (min-width: 960px)": {
      gridTemplateAreas: '"line title" "line content"',
      gridTemplateColumns: "3px auto",
      width: "50%",
    },
    "@media screen and (min-width: 1160px)": {
      width: "45%",
    },
    ".line": {
      gridArea: "line",
      backgroundColor: "#1341a1",
      width: "3px",
      height: "100%",
      "@media screen and (max-width: 960px)": {
        display: "none",
      },
    },
    ".title": {
      fontFamily: "Montserrat",
      color: "#18294d",
      fontSize: "26px",
      gridArea: "title",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      "@media screen and (min-width: 960px)": {
        alignItems: "flex-start",
      },
      "> h2": {
        fontFamily: "Montserrat",
        fontSize: "26px",
        color: "#18294d",
        fontWeight: 500,
        textTransform: "uppercase",
        lineHeight: "10px",
        textAlign: "justify",
        "@media screen and (min-width: 960px)": {
          textAlign: "left",
        },
        "@media screen and (max-width: 600px)": {
          fontSize: "1rem",
        },
      },
    },
    ".content": {
      gridArea: "content",
      "> p": {
        fontFamily: "Montserrat",
        color: "#18294d",
        fontSize: "16px",
        lineHeight: "130%",
        textAlign: "left",
      },
    },
  },
};
