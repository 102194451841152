import React, { useState } from "react";
import {
  CloseBtn,
  Container,
  OverlayContainerModal,
} from "~/presentation/views/LandingPage/components/Modal/styles";
import { RemoveScroll } from "react-remove-scroll";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { Fade } from "@mui/material";

const Modal = ({ open, closeModal, resetModal, component: Component }) => {
  const [fullOpen, setFullOpen] = useState(false);

  useEffect(() => {
    if (open === true) setFullOpen(true);
    else {
      setFullOpen(false);
      resetModal();
    }
  }, [open]);

  return (
    <RemoveScroll enabled={fullOpen}>
      <OverlayContainerModal open={fullOpen}>
        <Container open={fullOpen}>
          <Fade in={open === true}>
            <div className="background">
              <CloseBtn title="Fechar" onClick={closeModal}>
                <CloseIcon />
              </CloseBtn>
              {/* component to be rendered inside modal */}
              {typeof Component === "function" ? <Component closeModal={closeModal} /> : Component}
            </div>
          </Fade>
        </Container>
      </OverlayContainerModal>
    </RemoveScroll>
  );
};

export default Modal;
