import React from "react";
import { motion } from "framer-motion";

function MotionDiv({ children, zoom, up, down, left, right, once, duration, ...props }) {
  const getProps = () => {
    let initial = { opacity: 0 };
    let transition = { duration: 1 };
    let whileInView = { opacity: 1, scale: 1, y: 0, x: 0 };
    let viewport = { once: false };

    if (zoom) initial = { scale: zoom.from || 0.8, ...initial };

    if (up) initial = { y: up.from || 50, ...initial };

    if (down) initial = { y: down.from || -50, ...initial };

    if (left) initial = { x: left.from || 100, ...initial };

    if (right) initial = { x: right.from || -100, ...initial };

    if (once) viewport = { once: true };

    if (duration) transition = { duration: duration };

    return {
      initial,
      whileInView,
      transition,
      viewport,
    };
  };

  // Get the props dynamically
  const movimentProps = getProps();

  return (
    <motion.div {...movimentProps} {...props}>
      {children}
    </motion.div>
  );
}

export default MotionDiv;
