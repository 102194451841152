import React, { useContext, useEffect, useState } from "react";
import { HeaderSectionPage } from "../components/HeaderSectionPage";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { formatDateToBrazilian, getDateEnd, getDateStart } from "../Utils/utilsDate";
import ActiveFilters from "./components/ActiveFilters/ActiveFilters";
import RButton from "../components/Common/Buttons/RButton";
import Input from "../components/Common/Inputs";
import { DeleteOutline, Search } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTableFilters } from "../Hooks/useTableFilters";
import Table from "../components/Table/Table";

function EventsHistory() {
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const [eventsData, setEventsData] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { handleSearchTable } = useTableFilters();

  //valores padrão
  const today = new Date();
  const sixMonths = new Date(new Date().setMonth(new Date().getMonth() - 6));

  // Filters
  const [filter, setFilter] = useState({
    start: sixMonths,
    end: today,
  });

  const handleChangeFilter = (newValues) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newValues,
    }));
  };

  //current time
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const handleClearFilters = () => {
    handleChangeFilter({ start: null, end: null });
  };

  useEffect(() => {
    handleFilters(filter); //fazer o 1º fetch padrão
  }, []);

  const handleFilters = () => {
    openBackdrop(true, "Aplicando filtros");

    const start = getDateStart(filter?.start);
    const end = getDateEnd(filter?.end);

    const params = {
      start,
      end,
    };

    setDataIntoState(params);
  };

  const setDataIntoState = async (params) => {
    try {
      openBackdrop(true, "Carregando informação dos eventos");
      const response = await getData(params);
      const data = response?.reverse(); //para o mais recente ficar em cima na tabela
      setEventsData(data);
      setStorageData(data);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const getData = async (params) => {
    const events = await apiReinf.getProtocolHistory(params);
    if (events) {
      return events;
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos eventos, verifique sua conexão",
        type: "error",
      });
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearchTable(value, storageData, setEventsData);
  };

  const minDate = new Date(currentYear - 10, currentMonth, 1); //6 anos atrás
  const maxDate = new Date(); //Hoje

  //ERRO: Data inválida (FUTURO)
  const isStartDateInTheFuture = filter?.start > maxDate;
  const isEndDateInTheFuture = filter?.end > maxDate;
  const isDateWrongText = "Selecione uma data no presente.";
  //ERRO: Data inicial maior do que final
  const isStartDateHigherThanEnd = filter?.start > filter?.end;
  const isStartDateHigherThanEndText =
    isStartDateHigherThanEnd && "Data inicial superior à data final.";
  // ERRO: Data inválida (não totalmente preenchida)
  const isStartDateInvalid = !filter?.start || filter?.start == "Invalid Date";
  const isEndDateInvalid = !filter?.end || filter?.end == "Invalid Date";

  //INVALIDAR BOTÃO APLICAR
  const disabledBtn =
    isStartDateInTheFuture ||
    isEndDateInTheFuture ||
    isStartDateHigherThanEnd ||
    isStartDateInvalid ||
    isEndDateInvalid;

  const columns = [
    {
      field: "protocol",
      headerName: "Protocolo",
      align: "center",
      renderCell: ({ value }) => value || "-",
    },
    {
      field: "receiptNumber",
      headerName: "Nº Recibo",
      align: "center",
      renderCell: ({ value }) => value || "-",
    },

    {
      field: "sendDate",
      headerName: "Data do envio",
      align: "center",
      renderCell: ({ value }) => formatDateToBrazilian(value) || "-",
    },
    {
      field: "userSend",
      headerName: "Email do usuário",
      align: "center",
      renderCell: ({ value }) => value || "-",
    },
    {
      field: "reinfEvent",
      headerName: "Evento",
      align: "center",
      renderCell: ({ value }) => value || "-",
    },
    {
      field: "situation",
      headerName: "Situação",
      align: "center",
      renderCell: ({ value }) => value || "-",
    },
  ];

  const data =
    eventsData.length > 0 ? eventsData.map((item, index) => ({ ...item, id: index })) : [];

  return (
    <>
      <HeaderSectionPage title="Histórico de Eventos" />
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 1,
          paddingInline: 2,
        }}
      >
        <Grid size="auto">
          <Tooltip title="Apagar todos os filtros" onClick={handleClearFilters}>
            <IconButton>
              <DeleteOutline sx={{ color: "#000000" }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid size="auto">
          <ActiveFilters filter={filter} handleChangeFilter={handleChangeFilter} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          paddingInline: 3,
        }}
      >
        <Grid size={{ xs: 12, sm: 6, lg: 3 }} sx={styles.filterContainer}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>Data Início</p>
          <Input
            styles={styles.dateInput}
            minDate={minDate}
            maxDate={filter?.end || maxDate}
            type="date"
            viewType="month-year"
            value={filter?.start}
            onChange={(date) => {
              handleChangeFilter({ start: date });
            }}
            styleControl={{ height: "47px" }}
            error={(isStartDateInTheFuture && isDateWrongText) || isStartDateHigherThanEndText}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 3 }} sx={styles.filterContainer}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>Data Final</p>
          <Input
            styles={styles.dateInput}
            minDate={filter?.start || minDate}
            maxDate={maxDate}
            type="date"
            viewType="month-year"
            value={filter?.end}
            onChange={(date) => {
              handleChangeFilter({ end: date });
            }}
            styleControl={{ height: "47px" }}
            error={(isEndDateInTheFuture && isDateWrongText) || isStartDateHigherThanEndText}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 3 }} sx={{ ...styles.filterContainer }}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>Pesquisa</p>
          <Box sx={styles.searchContainer}>
            <input
              style={styles.searchInput}
              type="text"
              onChange={handleSearch}
              placeholder="Pesquisar..."
              value={searchTerm}
            />
            <Search />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, lg: 3 }} sx={{ marginTop: "1.45rem" }}>
          <RButton
            disabled={disabledBtn}
            onClick={handleFilters}
            sx={{
              height: "40px",
            }}
          >
            APLICAR
          </RButton>
        </Grid>
      </Grid>
      <Table data={data} columns={columns} searchInput={false} rowPadding="12px" />
    </>
  );
}

export default EventsHistory;

const styles = {
  Button: {
    width: "fit-content",
    borderRadius: "8px",
    boxShadow: 0,
    textTransform: "uppercase",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#DCDCDC",
    paddingRight: 2,
    paddingLeft: 2,
    borderRadius: "5px",
    height: "40px",
    marginTop: "5px",
  },
  searchInput: {
    fontFamily: "Montserrat",
    fontSize: 14,
    border: "none",
    background: "transparent",
    width: "100%",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  serachIcon: {
    color: "#1341A1",
  },
  dateInput: {
    backgroundColor: "#DCDCDC",
    borderRadius: "5px",
    height: "40px",
  },
};
