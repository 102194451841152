import React from "react";
import { QuestionLabel } from "~/presentation/views/GTFacil/Simulator/components/QuestionLabel";
import { QuestionContainer } from "~/presentation/views/GTFacil/Simulator/components/QuestionContainer";
import { Box, FormControl, RadioGroup } from "@mui/material";

import { Radio, FormControlLabel, ErrorMessage } from "./styles";

export const RadioQuestion = ({
  name,
  text,
  excerpt,
  value,
  onChange,
  firstLabel = "SIM",
  secondLabel = "NÃO",
  thirdLabel = "Estou com dúvidas",
  firstValue = "true",
  secondValue = "false",
  thirdValue,
  onlyFirstValue = false,
  onlyTwoOptions = true,
}) => {
  return (
    <QuestionContainer>
      <QuestionLabel text={text} excerpt={excerpt} />
      <div>
        <Box sx={{ marginLeft: "1rem" }}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="status"
              value={value}
              onChange={(event) => onChange(event.target.value)}
            >
              <FormControlLabel
                value={firstValue}
                control={<Radio size="small" />}
                label={firstLabel}
              />
              {!onlyFirstValue && (
                <FormControlLabel
                  value={secondValue}
                  control={<Radio size="small" />}
                  label={secondLabel}
                />
              )}
              {!onlyTwoOptions && (
                <FormControlLabel
                  value={thirdValue}
                  control={<Radio size="small" />}
                  label={thirdLabel}
                />
              )}
            </RadioGroup>
          </FormControl>
        </Box>
        {name && <ErrorMessage name={name} />}
      </div>
    </QuestionContainer>
  );
};
