import React from "react";
import { Box } from "@mui/material";
import { IMaskInput } from "react-imask";

export const MoneyInput = ({
  onChange,
  value,
  disabled = false,
  prefix = "R$",
  styles,
  error,
  placeholder = "0,00",
}) => {
  return (
    <Box style={maskInputStyle(styles, error)}>
      <span>{prefix}</span>
      <IMaskInput
        radix=","
        thousandsSeparator="."
        mask={Number}
        unmask={true}
        value={value?.toString() || ""}
        onAccept={(value) => {
          if (onChange) {
            onChange(parseFloat(value));
          }
        }}
        lazy={false}
        disabled={disabled}
        placeholder={placeholder}
        style={amountInputStyles}
      />
    </Box>
  );
};

const amountInputStyles = {
  width: "80%",
  border: "none",
  outline: "none",
  fontSize: 14,
  fontFamily: "Montserrat",
  height: "100%",
  backgroundColor: "transparent",
  padding: "0px 6px",
};

const maskInputStyle = (styles, error) => ({
  width: "100%",
  border: "solid",
  borderColor: error ? "#d32f2f" : "#7D7D7D",
  borderWidth: "1px",
  fontSize: 14,
  fontFamily: "Montserrat",
  height: "36px",
  backgroundColor: "#FFFFFF",
  borderRadius: "3px",
  padding: "0px 10px",
  "&:hover": { borderColor: error ? "#a72626" : "#000" },
  ...styles,
});
