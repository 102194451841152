import React, { useState } from "react";
import TextEditor from "~/presentation/components/TextEditor";
import { Box, Typography } from "@mui/material";
import Input from "~/presentation/components/Common/Input";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";
import Table from "~/presentation/components/Table";
import PageListHeader from "../../../components/PageListHeader";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import TableActions from "~/presentation/components/TableActions";
import CreateEdit from "../../../Term/CreateEdit/CreateEdit";
import { Tab, TabPanel, Tabs, TabsList } from "~/presentation/components/Tabs/Tabs";

const AnnotationForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  cnaes,
  terms,
  fetchTerms,
}) => {
  //MODAL Lista de CNAE
  const [openCnaeList, setOpenCnaeList] = useState(false);
  const handleOpenCnaeList = () => setOpenCnaeList(true);
  const handleCloseCnaeList = () => setOpenCnaeList(false);

  const handleDeleteCnae = (params) => {
    if (values.cnaes.length === 1) {
      onChangeValue("cnaes", []);
    } else {
      const filtedValue = values?.cnaes?.filter((item) => item.id !== params.row.id);
      onChangeValue("cnaes", filtedValue);
    }
  };

  //MODAL Lista de Terms
  const [openTermsList, setOpenTermsList] = useState(false);
  const handleOpenTermsList = () => setOpenTermsList(true);
  const handleCloseTermsList = () => setOpenTermsList(false);

  //MODAL Modal Criação de Terms
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const handleOpenTermsModal = () => setOpenTermsModal(true);
  const handleCloseTermsModal = () => setOpenTermsModal(false);

  const handleDeleteTerm = (params) => {
    if (values.terms.length === 1) {
      onChangeValue("terms", []);
    } else {
      const filtedValue = values?.terms?.filter((item) => item.id !== params.row.id);
      onChangeValue("terms", filtedValue);
    }
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={{ ...formStyles.title, color: errors.name && "#d32f2f" }}>
          Nome da Anotação
        </Typography>
        <Input
          type="text"
          name="name"
          placeholder="Inserir nome"
          value={values.name}
          onChange={(e) => onChangeValue("name", e.target.value)}
          error={errors.name}
        />
        <Typography sx={{ ...formStyles.title, color: errors.text && "#d32f2f", marginTop: 2 }}>
          Texto
        </Typography>
        <TextEditor
          initialValue={values.text}
          name="text"
          height={300}
          handleEditorChange={(content) => {
            onChangeValue("text", content);
          }}
          error={errors.text}
        />
        {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
      </Box>

      <Tabs defaultValue={1}>
        <TabsList>
          <Tab value={1}>CNAES</Tab>
          <Tab value={2}>Termos de Pesquisa</Tab>
        </TabsList>
        <TabPanel value={1}>
          <PageListHeader title="Cnaes" titleAdd="Adicionar Cnaes" handleAdd={handleOpenCnaeList} />
          <Table
            tableOptions={false}
            columns={[
              { field: "code", headerName: "Código", flex: 1, align: "center" },
              { field: "description", headerName: "Descrição", flex: 6 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                flex: 1,
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDeleteCnae(params)} />
                ),
              },
            ]}
            data={values?.cnaes}
          />
          <ModalBox open={openCnaeList} onClose={handleCloseCnaeList} bg="white">
            <ListComponent
              disablePadding={false}
              data={cnaes}
              checkedData={values?.cnaes}
              title="Cnaes"
              onClose={handleCloseCnaeList}
              handleAdd={(values) => onChangeValue("cnaes", values)}
              itemsPerPage={5}
            />
          </ModalBox>
        </TabPanel>
        <TabPanel value={2}>
          <PageListHeader
            title="Termos de Pesquisa"
            titleAdd="Adicionar Termo de Pesquisa"
            handleAdd={handleOpenTermsList}
          />
          <Table
            tableOptions={false}
            columns={[
              { field: "term", headerName: "Termo de Pesquisa", flex: 1 },
              {
                field: "actions",
                headerName: "Ações",
                sortable: false,
                filterable: false,
                align: "center",
                renderCell: (params) => (
                  <TableActions handleDelete={() => handleDeleteTerm(params)} />
                ),
              },
            ]}
            data={values?.terms}
          />
          <ModalBox open={openTermsList} onClose={handleCloseTermsList} bg="white" maxWidth={500}>
            <ListComponent
              disablePadding={false}
              data={terms}
              checkedData={values?.terms}
              title="Termos de Pesquisa"
              onClose={handleCloseTermsList}
              handleAdd={(values) => onChangeValue("terms", values)}
              handleCreate={handleOpenTermsModal}
              titleAdd="Criar novo termo de pesquisa"
            />
          </ModalBox>
          <ModalBox open={openTermsModal} onClose={handleCloseTermsModal} bg="white">
            <CreateEdit data={null} onCloseForm={handleCloseTermsModal} refreshData={fetchTerms} />
          </ModalBox>
        </TabPanel>
      </Tabs>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default AnnotationForm;
