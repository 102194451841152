import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box, CircularProgress, Container } from "@mui/material";

import BlogLayout from "~/presentation/layouts/BlogLayout";
import LastPostsSection from "~/presentation/views/Blog/BlogHome/Sections/LastPosts";
import MoreContentSection from "~/presentation/views/Blog/BlogHome/Sections/MoreContent";
import blogNavbarItems from "~/presentation/views/Blog/data/blogNavbarItems";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { usePaginatedState } from "~/presentation/hooks/usePaginatedState";
import { getSafeArray } from "~/presentation/utils/getSafeArray";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import Categories from "../components/Categories/Categories";

const NUMBER_OF_LAST_POSTS = 9;
const NUMBER_OF_MOST_ACCESSED = 3;
const DEFAULT_IMAGE = "/assets/bg-contentblog.png";

const BlogHome = ({ blogUseCases, blogCategoryUseCases }) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const categoryId = query.get("categoryId");
  const [highlight, setHighlight] = useState([]);
  const [lastPosts, setLastPosts] = useState([]);
  const [blogSeries, setBlogSeries] = useState([]);
  const [mostAccessedPosts, setMostAccessedPosts] = useState();
  const [blogPosts, setBlogPosts] = useState([]);
  const [blogCategories, setBlogCategories] = useState();

  const dataLoaded = () => {
    return !!(highlight && lastPosts && blogSeries && mostAccessedPosts && blogPosts);
  };

  const getBlogPosts = async () => {
    const isClient = true;
    const queryParams = {
      pageIndex: 1,
      limit: 12,
    };
    const response = await blogUseCases.getBlogPosts(isClient, queryParams);
    setBlogPosts(response.posts);
  };

  const getLastBlogPosts = async () => {
    const { posts } = await blogUseCases.getLastBlogPosts(NUMBER_OF_LAST_POSTS);
    setLastPosts(posts);
  };

  const getHighlight = async () => {
    const { posts } = await blogUseCases.getHighlightBlogPost();
    setHighlight(posts[0]);
  };

  const getMostAccessedPosts = async () => {
    const { posts } = await blogUseCases.getMostAccessedPosts(NUMBER_OF_MOST_ACCESSED);
    setMostAccessedPosts(posts);
  };

  const getBlogSeries = async () => {
    const response = await blogCategoryUseCases.getBlogSeries();
    setBlogSeries(response);
  };
  const getBlogCategories = async () => {
    setBlogCategories(await blogCategoryUseCases.getBlogCategories());
  };
  const getBlogPostsByCategory = async (categoryId) => {
    const isClient = true;
    const queryParams = {
      pageIndex: 1,
      limit: 12,
      CategoryId: categoryId,
    };
    const response = await blogUseCases.getBlogPosts(isClient, queryParams);
    setContent(response.posts);
  };

  const handleNavigateToPost = (id) => {
    navigate("/blog/post/" + id);
  };

  const handleSelectCategory = (categoryId) => {
    if (categoryId) {
      handleFilterBycategory(categoryId);
    } else {
      getBlogPosts();
    }
  };

  const goToList = (serieId, search) => {
    const url = `/blog/list?`;
    const serie = serieId ? "serie=" + serieId : "";
    const searchStr = search ? "&search=" + search : "";
    const pageStr = "&page=1";
    const encodedUrl = encodeURI(url + serie + searchStr + pageStr);
    navigate(encodedUrl);
  };

  useEffect(() => {
    getHighlight();
    getLastBlogPosts();
    getMostAccessedPosts();
    getBlogPosts();
    getBlogSeries();
    getBlogCategories();
    if (categoryId) {
      getBlogPostsByCategory(categoryId);
    }
  }, [categoryId]);

  const getContent = (queryParams) => {
    const isClient = true;
    return blogUseCases.getBlogPosts(isClient, queryParams);
  };

  const {
    handleSearch,
    handlePage,
    numberOfPages,
    isLoading,
    content,
    setContent,
    page,
    handleFilterBycategory,
    clearCategory,
  } = usePaginatedState({
    getData: getContent,
    itemsPerPage: 9,
  });

  return (
    <BlogLayout
      className="blog"
      navbarItems={blogNavbarItems}
      loggedNavbarItems={clientNavbarItems()}
    >
      {() => (
        <>
          <SectionTitlePost
            redirectURL="/blog"
            title="BLOG"
            onSearch={handleSearch}
            image={"/assets/multimedia-banner.png"}
            searchButtonBg="#ff6829"
            blur={0.1}
          />
          {!dataLoaded() ? (
            <Box sx={styles.loading}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <Container maxWidth="lg">
              <Categories
                blogCategories={blogCategories}
                categoryId={categoryId}
                handleSelectCategory={handleSelectCategory}
                clearCategory={clearCategory}
              />
              <Box sx={styles.content}>
                {isLoading ? (
                  <Box sx={styles.loading}>
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  <>
                    <LastPostsSection
                      lastPosts={getSafeArray({ array: content?.slice(0, 3) })}
                      // lastPosts={[]}
                      goToPost={(id) => handleNavigateToPost(id)}
                      defaultImage={DEFAULT_IMAGE}
                    />

                    <MoreContentSection
                      blogPosts={getSafeArray({ array: content?.slice(1) })}
                      // blogPosts={[]}
                      goToPost={(id) => handleNavigateToPost(id)}
                      goToList={goToList}
                      defaultImage={DEFAULT_IMAGE}
                      numberOfPages={numberOfPages}
                      goToPage={(value) => handlePage(value, categoryId)}
                      page={page}
                    />
                  </>
                )}
              </Box>
            </Container>
          )}
        </>
      )}
    </BlogLayout>
  );
};

export default BlogHome;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "auto",
  },
  loading: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
  },
};
