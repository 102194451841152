import React from "react";
import {
  TextField,
  InputLabel,
  Box,
  TextareaAutosize,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import Select from "react-select";
import DateInput from "../DateInput";
import { PostAdd } from "@mui/icons-material";
import { IMaskInput } from "react-imask";

function renderInputComponent(type, props) {
  const label = (
    <Typography
      sx={{
        ...styles.title,
        fontWeight: props?.error ? 600 : 500,
        color: props?.error ? "#d32f2f" : "#222222",
      }}
    >
      {props.title}
    </Typography>
  );
  const errorMessage = <Typography sx={styles.ErrorText}>{props?.error}</Typography>;
  const placeholder = props?.placeholder || props?.title || "";

  switch (type) {
    case "text":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="text"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "password":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="password"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "email":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="email"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "number":
      return (
        <Box sx={styles.content}>
          {label}
          <TextField
            type="number"
            sx={InputStyles(props?.styles, props?.error)}
            variant="outlined"
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "textarea":
      return (
        <Box sx={styles.content}>
          {label}
          <TextareaAutosize
            minRows={props?.minRows}
            maxlength={props?.maxlength}
            style={TextareaStyles(props?.styles, props?.error)}
            placeholder={placeholder}
            {...props}
          />
          {props?.error && errorMessage}
        </Box>
      );

    case "mask": {
      return (
        <Box sx={styles.content} component="form">
          {label}
          <IMaskInput
            mask={props?.mask}
            value={props?.value || ""}
            unmask={true}
            onAccept={(value) => {
              if (props.onChange && value !== props.value) {
                props.onChange(value);
              }
            }}
            overwrite={true}
            disabled={props?.disabled}
            placeholder={props?.placeholder || props?.mask}
            style={maskInputStyle(props?.styles, props?.error)}
          />
          {props?.error && errorMessage}
        </Box>
      );
    }

    case "select":
      return (
        <Box sx={styles.content}>
          {label}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ width: "100%" }}>
              <Select
                styles={styleSelectInput(props?.style, props?.error, props?.scrollbar)}
                noOptionsMessage={() => "Não há opções"}
                isDisabled={props?.disabled}
                placeholder={props?.placeholder || "Selecione " + props?.title.toLowerCase()}
                maxMenuHeight={props?.height || 250}
                {...props}
              />
            </Box>
            {props?.icon && (
              <Box sx={styles.addIcon}>
                <Tooltip title={props?.icon?.title || `Adicionar ${props?.title}`}>
                  <IconButton onClick={props?.icon?.func}>
                    <PostAdd sx={styles.addIcon.icon} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          {props?.error && errorMessage}
        </Box>
      );

    case "amount": {
      return (
        <Box sx={styles.content}>
          {label}
          <Box style={maskInputStyle(props?.styles, props?.error)}>
            <span>{props.prefix || "R$"}</span>
            <IMaskInput
              radix=","
              thousandsSeparator="."
              mask={Number}
              unmask={true}
              value={props?.value?.toString() || ""}
              onAccept={(value) => {
                if (props?.onChange) {
                  props?.onChange(parseFloat(value));
                }
              }}
              scale={2}
              signed={false}
              padFractionalZeros={true}
              lazy={false}
              disabled={props?.disabled}
              placeholder={props?.placeholder || "0,00"}
              style={styles.amountInputStyles}
            />
            {props?.error && errorMessage}
          </Box>
        </Box>
      );
    }

    case "date":
      return (
        <Box sx={styles.content}>
          {label}
          <DateInput {...props} />
          {props?.error && errorMessage}
        </Box>
      );

    default:
      return (
        <Box sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: "#d32f2f" }}>
            {"Defina o Type Input: select, text, number, amount, mask, aliquot"}
          </InputLabel>
          <TextField sx={InputStyles()} variant="outlined" />
        </Box>
      );
  }
}

// ⇣⇣⇣⇣⇣⇣⇣⇣ SOBRE A ESTILIZAÇÃO DOS INPUTS: ⇣⇣⇣⇣⇣⇣⇣⇣
// styles={{}} e passar os styles adicionais que queremos

function Input({ type, ...props }) {
  return <>{renderInputComponent(type, props)}</>;
}

export default Input;

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: "left",
    fontFamily: "Montserrat",
    color: "#000000",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingLeft: "2px",
  },
  ErrorText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 12,
    color: "#d32f2f",
    paddingLeft: 1,
  },
  addIcon: {
    backgroundColor: "#001A50",
    borderRadius: "12px",
    icon: {
      color: "#fff",
      height: "22px",
      width: "22px",
    },
  },
  inputAndIconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  amountInputStyles: {
    border: "none",
    outline: "none",
    fontSize: 14,
    fontFamily: "Montserrat",
    height: "100%",
    backgroundColor: "transparent",
    padding: "0px 6px",
  },
};

const InputStyles = (styles, error) => ({
  "input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 1000px transparent inset",
  },
  ".MuiOutlinedInput-notchedOutline": {
    boxShadow: 0,
    border: styles?.border ? styles?.border : error ? "1px solid #a72626" : "1px solid #7D7D7D",
    "&:hover": {
      border: styles?.border ? styles?.border : error ? "1px solid #a72626" : "1px solid #000",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    opacity: 0.8,
  },
  ".MuiOutlinedInput-root": {
    width: "100%",
    fontSize: 14,
    fontFamily: "Montserrat",
    height: styles?.height || "38px !important",
    backgroundColor: styles?.backgroundColor || "#FFFFFF",
    borderRadius: styles?.borderRadius || "12px",
  },
});

const TextareaStyles = (styles, error) => ({
  width: "100%",
  fontSize: 14,
  fontFamily: "Montserrat",
  height: "38px !important",
  backgroundColor: "#FFFFFF",
  borderRadius: "12px",
  padding: "12px",
  resize: "none",
  border: "solid",
  borderColor: error ? "#d32f2f" : "#7D7D7D",
  borderWidth: "1px",
  "&:hover": { borderColor: error ? "#a72626" : "#000" },
  "& .MuiInputBase-root.Mui-disabled": {
    color: "#000",
  },
  ...styles,
});

const styleSelectInput = (styles, error, scrollbar = {}) => ({
  control: (provided, state) => ({
    width: "100%",
    border: "solid",
    borderColor: error ? "#d32f2f" : state.isFocused ? "#1769aa" : "#7D7D7D",
    borderWidth: state.isFocused ? "2px" : "1px",
    fontSize: 14,
    fontFamily: "Montserrat",
    height: "38px",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    "&:hover": { borderColor: error ? "#a72626" : "#000" },
    ...styles,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    background: styles?.backgroundColor || "#FBFBFB",
    borderRadius: styles?.borderRadius || "12px",
    border: "1px solid #7D7D7D",
    fontSize: 14,
  }),
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "12px",
    },
    "::-webkit-scrollbar-track": {
      background: "#c1c1c1",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    ...scrollbar,
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: styles?.color || "#000",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: styles?.color || "#000",
    textAlign: "left",
    ":hover": {
      background: styles?.hoverColor || "#CDCDCD",
      cursor: "pointer",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: styles?.color || "#000",
    padding: "6px 10px",
  }),
  dropdownIndicator: (styles) => ({
    color: styles.color || "#000000",
    cursor: "pointer",
    padding: "6px 10px 0px 6px",
  }),
});

const maskInputStyle = (styles, error) => ({
  width: "100%",
  border: styles?.border ? styles.border : error ? "1px solid #d32f2f" : "1px solid #7D7D7D",
  fontSize: 14,
  fontFamily: "Montserrat",
  height: "36px",
  backgroundColor: "#FFFFFF",
  borderRadius: "12px",
  padding: "0px 10px",
  ...styles,
  "&:hover": { border: error ? "2px solid #d32f2f" : "2px solid #000" },
});
