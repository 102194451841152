import React from "react";
import { Box, IconButton, Typography as Text, Tooltip } from "@mui/material";
import { PostAdd } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import MotionDiv from "~/presentation/components/Motion/MotionDiv";

export const HeaderSectionPage = ({
  title,
  buttonPrimary,
  buttonSecond,
  titleAdd,
  handleAdd,
  sx,
}) => {
  return (
    <Box sx={{ backgroundColor: "#E6E6E6", p: 3, ...sx }}>
      <MotionDiv once>
        <Grid container spacing={2}>
          <Grid
            size={{ xs: 12, md: "auto" }}
            sx={{ ...styles.titleContainer, marginRight: !handleAdd ? "auto" : "unset" }}
          >
            <Text sx={styles.title}>{title}</Text>
            {handleAdd && (
              <Box sx={styles.addIcon}>
                <Tooltip title={titleAdd || `Cadastrar novo`}>
                  <IconButton onClick={handleAdd}>
                    <PostAdd sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Grid>
          {buttonPrimary && <Grid size={{ xs: 12, md: "auto" }}>{buttonPrimary}</Grid>}
          {buttonSecond && (
            <Grid
              size={{ xs: 12, md: "auto" }}
              style={{ marginRight: !handleAdd ? "10px" : "unset" }}
            >
              {buttonSecond}
            </Grid>
          )}
        </Grid>
      </MotionDiv>
    </Box>
  );
};

const styles = {
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#242424",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  addIcon: {
    backgroundColor: "#1341A1",
    padding: "2px",
    borderRadius: "12px",
  },
};
