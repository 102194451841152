import * as Yup from "yup";
import {
  hasINSSInTheServiceOptions,
  witchArticleIN_RFB_971_2009Options,
} from "../../common/inss/formOptions";

export const validationSchema = Yup.object({
  hasINSSInTheService: Yup.string().oneOf([
    hasINSSInTheServiceOptions.true,
    hasINSSInTheServiceOptions.doubt,
  ]),
  witchArticleIN_RFB_971_2009: Yup.mixed().test(
    "witchArticleIN_RFB_971_2009",
    null,
    function (obj) {
      if (
        this.parent.hasINSSInTheService === hasINSSInTheServiceOptions.doubt &&
        !obj
      ) {
        return new Yup.ValidationError(
          "Campo obrigatório!",
          null,
          "witchArticleIN_RFB_971_2009"
        );
      }

      return true;
    }
  ).nullable(),
  art118Details: Yup.object({
    employeeSubordination: Yup.boolean(),
    continuousService: Yup.boolean(),
    inContractorDependenciesOrIndicated: Yup.boolean(),
  }).test("art118Details", null, function (obj) {
    const art118NotSelected =
      this.parent.witchArticleIN_RFB_971_2009 !==
      witchArticleIN_RFB_971_2009Options.art118;

    if (
      art118NotSelected ||
      (obj.employeeSubordination &&
        obj.continuousService &&
        obj.inContractorDependenciesOrIndicated)
    ) {
      return true;
    }

    return new Yup.ValidationError(
      "Sem essa opção, a cessão de mão de obra não está caracterizada e a retenção do INSS não é devida.",
      null,
      "art118Details"
    );
  }),
});
