import React, { useState, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { Box, Typography as Text } from "@mui/material";
import { SendR2010 } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendR2010";
import { SendR4020 } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendR4020";
import { SendR2098 } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendR2098";
import { SendR4099 } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendR4099";
import { SendR9000 } from "./SendR9000";
import { SendAutomated } from "~/presentation/views/Reinf/SendReinf/components/EventSend/SendAutomated";
import { formatDateToISO } from "../../../Utils/utilsDate";
import { SendR4010 } from "./SendR4010";

export const EventSend = ({
  data,
  closeModal,
  certificateData, // certificados array
  refreshData,
  receiptNumber,
  operation,
  handleVerifyEventStatus,
  filter,
}) => {
  const companyUseCases = new ApiCompanyUseCases(makeHttpClient());
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const CompanyUser = user.companyId;
  const [UserCompany, setUserCompany] = useState();
  const [eventData] = useState({});

  const eventSend = filter?.event;
  const DigitalCertificate = filter?.certificate;
  const selectedMonth = filter?.month;
  const selectedYear = filter?.year;

  // Filtro com base no id do certificado selecionado
  const certificate = certificateData.find((cert) => cert.id === DigitalCertificate);

  useEffect(() => {
    const getCompany = async () => {
      const result = await companyUseCases.getCompanyById(CompanyUser);
      setUserCompany(result);
    };

    getCompany();
  }, [CompanyUser]);

  const competence = formatDateToISO(selectedYear, selectedMonth);

  const renderEventSpecificContent = () => {
    switch (eventSend) {
      case "r2010":
        return (
          <SendR2010
            nfseDate={data}
            eventData={eventData}
            DigitalCertificate={certificate}
            UserCompany={UserCompany}
            CompanyUserId={CompanyUser}
            eventSend={eventSend}
            competence={competence}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            closeModal={closeModal}
            refreshData={refreshData}
            receiptNumber={receiptNumber}
            operation={operation}
          />
        );
      case "r4020":
        return (
          <SendR4020
            nfseDate={data}
            eventData={eventData}
            DigitalCertificate={certificate}
            UserCompany={UserCompany}
            CompanyUserId={CompanyUser}
            eventSend={eventSend}
            competence={competence}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            closeModal={closeModal}
            refreshData={refreshData}
            receiptNumber={receiptNumber}
            operation={operation}
          />
        );

      case "r4010":
        return (
          <SendR4010
            nfseDate={data}
            eventData={eventData}
            DigitalCertificate={certificate}
            UserCompany={UserCompany}
            CompanyUserId={CompanyUser}
            eventSend={eventSend}
            competence={competence}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            closeModal={closeModal}
            refreshData={refreshData}
          />
        );
      case "r2098":
        return (
          <SendR2098
            nfseDate={data}
            eventData={eventData}
            DigitalCertificate={certificate}
            UserCompany={UserCompany}
            CompanyUserId={CompanyUser}
            eventSend={eventSend}
            competence={competence}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            closeModal={closeModal}
            refreshData={refreshData}
            handleVerifyEventStatus={handleVerifyEventStatus}
          />
        );
      case "r4099":
        return (
          <SendR4099
            nfseDate={data}
            eventData={eventData}
            DigitalCertificate={certificate}
            UserCompany={UserCompany}
            CompanyUserId={CompanyUser}
            eventSend={eventSend}
            competence={competence}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            closeModal={closeModal}
            refreshData={refreshData}
            handleVerifyEventStatus={handleVerifyEventStatus}
          />
        );
      case "r9000":
        return (
          <SendR9000
            nfseDate={data}
            eventData={eventData}
            DigitalCertificate={certificate}
            UserCompany={UserCompany}
            CompanyUserId={CompanyUser}
            eventSend={eventSend}
            competence={competence}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            closeModal={closeModal}
            refreshData={refreshData}
          />
        );

      case "automated-r2010":
      case "automated-r4020":
        return (
          <SendAutomated
            nfseDate={data}
            eventData={eventData}
            DigitalCertificate={certificate}
            UserCompany={UserCompany}
            CompanyUserId={CompanyUser}
            eventSend={eventSend?.split("-r")[1]}
            competence={competence}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            closeModal={closeModal}
            refreshData={refreshData}
            receiptNumber={receiptNumber}
            operation={operation}
            filter={filter}
          />
        );
      default:
        return <Text>Nenhum evento selecionado para envio</Text>;
    }
  };

  return <Box sx={styles.Modal}>{renderEventSpecificContent()}</Box>;
};

const styles = {
  Modal: {
    p: 4,
  },
};
