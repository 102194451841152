import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
// functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box, Tooltip } from "@mui/material";
import PageListHeader from "../components/PageListHeader";
import CreateEditItemAnswer from "./CreateEdit/CreateEdit";
import { ChatOutlined } from "@mui/icons-material";

const ItemAnswer = ({ itemAnswerUseCases, typesAnswerUseCases }) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //data
  const [itemsAnswer, setItemsAnswer] = useState([]);

  //options
  const [typesAnswer, setTypesAnswer] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const apiItemsAnswer = await itemAnswerUseCases.getAll();
    setItemsAnswer(apiItemsAnswer);
    openBackdrop(false);
  };

  const columns = [
    {
      field: "gtAnswerItemType",
      headerName: "Tipo",
      renderCell: (params) => params?.value?.title || "-",
      align: "left",
      flex: 1,
    },
    { field: "title", headerName: "Título", flex: 5 },
    {
      field: "text",
      headerName: "Texto",
      flex: 0.3,
      renderCell: (params) => (
        <Tooltip
          title={<Box sx={{ maxWidth: 300 }} dangerouslySetInnerHTML={{ __html: params?.value }} />}
        >
          <ChatOutlined />
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo tipo de resposta");
    try {
      await itemAnswerUseCases.delete(id);
      setItemsAnswer((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir tipo de resposta: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este serviço?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchOptionsData = async () => {
    openBackdrop(true, "Carregando Tipos de Resposta");
    const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer();
    setTypesAnswer(apiTypesAnswer);
    openBackdrop(false);
  };

  return (
    <Box>
      {openForm ? (
        <CreateEditItemAnswer
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          fetchOptionsData={fetchOptionsData}
          typesAnswer={typesAnswer}
        />
      ) : (
        <>
          <PageListHeader title="Item de resposta" handleAdd={() => handleOpenForm()} />
          <Box sx={{ margin: 3 }}>
            <Table columns={columns} data={itemsAnswer} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ItemAnswer;
