import React, { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiServiceClassification } from "~/data/usecases/serviceClassification/apiServiceClassification";
import { ApiIncomeNature } from "~/data/usecases/incomeNature/incomeNature";
import { Box } from "@mui/material";
import Nota from "./Fields/Nota";
import INSS from "./Fields/INSS";
import IRPJ from "./Fields/IRPJ";
import IR from "./Fields/IR";
import CSRF from "./Fields/CSRF";
import CalculateRetentions from "./CalculateRetentions/CalculateRetentions";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";

function CollapseRenderLayout({
  item,
  index,
  onChangeItem,
  selectedEvent,
  nfseData,
  onChangeINSSitem,
  errors,
  disabled,
  QSO,
}) {
  const apiServiceclass = new ApiServiceClassification(makeHttpClient());
  const apiIncomeNature = new ApiIncomeNature(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  //SELECT INPUT OPTIONS
  const [serviceClassificationOptions, setServiceClassificationOptions] = useState([]); //select Classificação Serviço
  const [incomeNatureOptions, setIncomeNatureOptions] = useState([]); //select Natureza Rendimento

  const showError = (message) => {
    setFeedbackSnackbar({
      isOpen: true,
      message: message,
      type: "error",
    });
  };

  useEffect(() => {
    const fetchDataOptions = async () => {
      try {
        const serviceClassResponse = await apiServiceclass.listServiceClassification();
        const incomeNatureResponse = await apiIncomeNature.listIncomeNature();

        if (serviceClassResponse) {
          if (Array.isArray(serviceClassResponse)) {
            setServiceClassificationOptions(serviceClassResponse);
          } else {
            showError("Os dados de classificação de serviço não foram carregados");
          }
        }

        if (incomeNatureResponse) {
          if (Array.isArray(incomeNatureResponse)) {
            setIncomeNatureOptions(incomeNatureResponse);
          } else {
            showError("Os dados de Natureza de Rend. não foram carregados");
          }
        }
      } catch (error) {
        showError("Erro ao carregar dados" + error?.response?.data ? error?.response?.data : "");
      }
    };

    fetchDataOptions();
  }, []);

  // MODAL CALCULAR RETENÇÃO
  const [openRetentionsModal, setOpenRetentionsModal] = useState(false);
  const handleOpenRetentionsModal = () => {
    if (item.bcIR === 0) {
      return showError(
        "Precisa da atribuir um valor à base de cálculo de IR para efectuar o cálculo das retenções."
      );
    }
    setOpenRetentionsModal(true);
  };
  const handleCloseRetentionsModal = () => setOpenRetentionsModal(false);

  const modalCalculateRetention = (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <RButton
          sx={{ boxShadow: 0, borderRadius: "8px", width: "fit-content" }}
          onClick={handleOpenRetentionsModal}
        >
          Calcular Retenções
        </RButton>
      </Box>
      <ModalContainer open={openRetentionsModal} onClose={handleCloseRetentionsModal}>
        <CalculateRetentions
          onClose={handleCloseRetentionsModal}
          onChangeItem={onChangeItem}
          item={item}
          index={index}
          nfseData={nfseData}
        />
      </ModalContainer>
    </>
  );

  const NOTAcomponent = (
    <Nota
      item={item}
      onChangeItem={onChangeItem}
      index={index}
      nfseData={nfseData}
      disabled={disabled}
      errors={errors}
    />
  );

  const INSScomponent = (
    <INSS
      item={item}
      onChangeItem={onChangeItem}
      onChangeINSSitem={onChangeINSSitem}
      index={index}
      nfseData={nfseData}
      disabled={disabled}
      serviceClassificationOptions={serviceClassificationOptions}
      errors={errors}
    />
  );

  const IRPJcomponent = (
    <IRPJ
      item={item}
      modalCalculateRetention={modalCalculateRetention}
      onChangeItem={onChangeItem}
      index={index}
      nfseData={nfseData}
      disabled={disabled}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
    />
  );

  const IRcomponent = (
    <IR
      item={item}
      onChangeItem={onChangeItem}
      modalCalculateRetention={modalCalculateRetention}
      index={index}
      nfseData={nfseData}
      disabled={disabled}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
    />
  );

  const CSRFcomponent = (
    <CSRF
      item={item}
      onChangeItem={onChangeItem}
      index={index}
      nfseData={nfseData}
      disabled={disabled}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
    />
  );

  const SituationAndOrigin = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        color: "#1341A1",
        fontSize: 14,
      }}
    >
      <div>
        <em>
          <strong>Origem: </strong>
        </em>
        {item.origin}
      </div>
      <div>
        <em>
          <strong>Situação: </strong>
        </em>
        {item.situation}
      </div>
    </Box>
  );

  if (selectedEvent === "r2010") {
    return (
      <Box sx={styles.Container}>
        {NOTAcomponent}
        {INSScomponent}
        {SituationAndOrigin}
      </Box>
    );
  }

  if (selectedEvent === "r4020") {
    if (QSO === "A" || QSO === "B") {
      return (
        <Box sx={styles.Container}>
          {NOTAcomponent}
          {IRPJcomponent}
          {SituationAndOrigin}
        </Box>
      );
    }

    if (QSO === "C") {
      return (
        <Box sx={styles.Container}>
          {NOTAcomponent}
          {IRcomponent}
          {SituationAndOrigin}
        </Box>
      );
    }

    if (QSO === "D") {
      return (
        <Box sx={styles.Container}>
          {NOTAcomponent}
          {IRcomponent}
          {CSRFcomponent}
          {SituationAndOrigin}
        </Box>
      );
    }
  }
}

export default CollapseRenderLayout;

const styles = {
  Container: {
    backgroundColor: "transparent",
    padding: 3,
  },
};
