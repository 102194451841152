import React from "react";
import TextEditor from "~/presentation/components/TextEditor";
import { Box, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";

const DialogForm = ({ values, onChangeValue, handleSubmit, errors }) => {
  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={{ ...formStyles.subtitle, color: errors.text && "#d32f2f" }}>
          Texto do diálogo
        </Typography>
        <TextEditor
          initialValue={values.text}
          name="text"
          height={400}
          handleEditorChange={(content) => {
            onChangeValue("text", content);
          }}
        />
        {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
      </Box>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon onClick={() => handleSubmit(values)}>Publicar</ButtonCommon>
      </Box>
    </>
  );
};

export default DialogForm;
