import React from "react";
import { Box, Link } from "@mui/material";

const UnauthorizedModal = () => {
  return (
    <Link
      href="https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Sou%20assinante%20do%20GT%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20ferramenta%20GT-Reinf%20junto%20a%20um%20representante%20da%20Open%20Solu%C3%A7%C3%B5es%20Tribut%C3%A1rias"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box sx={styles.modal}>
        <Box
          component="img"
          src="/assets/reinfbanner.jpg"
          alt="Modal Background"
          sx={styles.image}
        />
      </Box>
    </Link>
  );
};

export default UnauthorizedModal;

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
    maxHeight: "80%",
    borderRadius: "18px",
    overflow: "auto",
    boxShadow: 24,
    bgcolor: "#E8E8E8",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "18px",
  },
};
