import React, { useContext, useState } from "react";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";

export const GenerateSimulatorReportDialog = ({
  reportData,
  onSubmit,
  setOpenGenerateReportDialog,
}) => {
  const { tabsData } = useContext(SimulatorContext);

  const [submitError, setSubmitError] = useState();

  const reportOptions = tabsData.reduce((acc, item) => {
    acc[item.title] = false;
    return acc;
  }, {});

  const initialValues = Object.keys(reportOptions).reduce((acc, item) => {
    acc[item] = reportData.some((reportItem) => {
      if (reportItem?.title) {
        if (reportItem.title === "IRRF" && item === "IRPJ") reportItem.title = "IRPJ";
        return reportItem.title === item;
      }
      return false;
    });
    return acc;
  }, {});

  const [values, setValues] = useState(initialValues);

  const isDisabled = (values) => !Object.keys(values).some((item) => values[item]) === true;

  const submit = async () => {
    try {
      const filterdReportData = reportData
        .filter((item) => values[item.title])
        .map((item) => {
          if (item.title === "IRPJ") item.title = "IRRF";
          return item;
        });
      await onSubmit(filterdReportData);
      setOpenGenerateReportDialog(false);
    } catch (err) {
      setSubmitError(err.message);
    }
  };

  return (
    <Box>
      <Card
        title="Gerar relatório"
        loading={!reportData}
        border={false}
        handleClose={() => setOpenGenerateReportDialog(false)}
      >
        {submitError ? (
          <Typography sx={styles.title}>{submitError}</Typography>
        ) : (
          <Box sx={{ margin: "0px 16px", display: "flex", flexFlow: "column", gap: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 1,
              }}
            >
              <Info />
              <Typography sx={styles.title}>Selecione os tributos para a geração do PDF</Typography>
            </Box>
            <Box sx={styles.checkboxesWrapper}>
              {Object.keys(reportOptions).length ? (
                Object.keys(reportOptions).map((item) => (
                  <FormControlLabel
                    disabled={
                      !reportData.some(
                        (reportItem) => reportItem?.title && reportItem?.title === item
                      )
                    }
                    key={item}
                    control={
                      <Checkbox
                        sx={{
                          "&.MuiCheckbox-root": {
                            color: "#1341a1",
                          },
                        }}
                        name={item}
                        checked={values[item]}
                        onChange={() => {
                          setValues((prev) => {
                            let newValues = { ...prev };
                            if (values[item]) {
                              newValues[item] = false;
                            } else {
                              newValues[item] = true;
                            }
                            return newValues;
                          });
                        }}
                      />
                    }
                    label={item}
                  />
                ))
              ) : (
                <Typography sx={styles.warning}>Não há dados para gerar o relatório</Typography>
              )}
            </Box>
            <Button sx={styles.button} disabled={isDisabled(values)} onClick={submit}>
              {isDisabled(values) ? "Selecione um Tributo" : "Gerar Relatório"}
            </Button>
          </Box>
        )}
      </Card>
    </Box>
  );
};

const styles = {
  title: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: "max(1.4vw, 20px)",
    fontWeight: "normal",
  },
  warning: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: "max(1.4vw, 16px)",
    fontWeight: "normal",
    marginBottom: "16px",
  },
  checkboxesWrapper: {
    display: "flex",
    flexFlow: "column",
  },
  button: {
    marginX: "auto",
    width: "min(200px, 100%)",
    backgroundColor: "#1341a1",
    color: "white",
    "&:hover": {
      backgroundColor: "#1331a1",
      color: "white",
    },
  },
};