import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import IssAnswerForm from "./Form";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  makeIssAnswerUseCases,
  fetchGroups,
  groups,
  typesAnswerUseCases,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    id: 0,
    isLegislation: true,
    isDispensedEROS: true,
    isDispensedCPOM: false,
    cpom: null,
    isSpecificSubstituition: true,
    cityId: 0,
    cityName: "",
    items: [],
  });

  //itens de Resposta
  const [itemsAnswer, setItemsAnswer] = useState([]);

  const fetchTypeAnswer = async () => {
    openBackdrop(true, "Carregando tipos de resposta");
    const apiTypeAnswers = await typesAnswerUseCases.getTypesAnswer();
    setItemsAnswer(apiTypeAnswers);
    openBackdrop(false);
  };

  const fetchData = async (id) => {
    const response = await makeIssAnswerUseCases.getIssAnswer(id);
    setValues(response.data);
  };

  useEffect(() => {
    if (data?.id) {
      fetchData(data.id);
    }
  }, [data]);

  useEffect(() => {
    if (groups.length === 0) {
      fetchGroups();
    }
    fetchTypeAnswer();
  }, []);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} resposta`);
    try {
      if (isCreate) {
        await makeIssAnswerUseCases.createIssAnswer(values);
      } else {
        await makeIssAnswerUseCases.updateIssAnswer(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Resposta editada com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + err?.data || err?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const deleteItemAnswer = async (id) => {
    try {
      openBackdrop(true, "Deletando item");
      await makeIssAnswerUseCases.deleteIssAnswerItem(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Item deletado com sucesso!",
        type: "success",
      });
      setItemsAnswer((prev) => prev.filter((item) => item.id !== id));
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao deletar o item",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader title={`Editar Resposta`} handleBack={onCloseForm} />
      {values && (
        <IssAnswerForm
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
          itemsAnswer={itemsAnswer}
          groups={groups}
          deleteItemAnswer={deleteItemAnswer}
          fetchTypeAnswer={fetchTypeAnswer}
        />
      )}
    </>
  );
}

export default CreateEdit;
