import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const getColor = (theme, color) => color || "#383f48";
const getDisabledBgColor = (theme, bgColor, title) => {
  if (bgColor) return bgColor;
  if (title === "dark") return "#e1e1e1";
  return "#bcbcbc";
};
const getBgColor = (bgColor) => bgColor || "transparent";
const getBorderColor = (theme, borderColor) => borderColor || "#383f48";

const StyledTextField = styled(MuiTextField)(
  ({ theme, borderRadius, height, backgroundColor, color, borderColor, title, fontSize }) => ({
    margin: "5px",
    width: "100%",
    borderRadius: borderRadius || 0,
    height: height || "unset",
    justifyContent: "center",
    backgroundColor: getBgColor(backgroundColor),

    "& label": {
      color: getColor(theme, color),
      "&.Mui-disabled": {
        color: getColor(theme, color),
      },
      "&.Mui-focused": {
        color: getColor(theme, color),
      },
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: getBorderColor(theme, borderColor),
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: getBorderColor(theme, borderColor),
      },
      "&:hover fieldset": {
        borderColor: getBorderColor(theme, borderColor),
      },
      "&.Mui-focused fieldset": {
        borderColor: getBorderColor(theme, borderColor),
      },
      "&.Mui-disabled fieldset": {
        borderColor: getBorderColor(theme, borderColor),
      },
    },

    "& .Mui-disabled": {
      color: getColor(theme, color),
      cursor: "not-allowed",
      backgroundColor: getDisabledBgColor(theme, backgroundColor, title),
    },

    "& .MuiInputBase-root": {
      color: getColor(theme, color),
      fontSize: fontSize || "1rem",
    },

    "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
      color: getColor(theme, color),
    },
  })
);

const TextInput = ({
  label,
  variant = "outlined",
  color,
  backgroundColor,
  borderRadius,
  borderColor,
  height,
  fontSize,
  ...props
}) => {
  return (
    <StyledTextField
      label={label}
      variant={variant}
      color={color}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
      height={height}
      fontSize={fontSize}
      {...props}
    />
  );
};

export default TextInput;
