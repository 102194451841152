import React, { useState } from "react";
import { Close, ExpandMore } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import ErrorIcon from "@mui/icons-material/Error";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Box } from "@mui/material";

function SetPeriodButton({
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  formatDateToBrazilian,
  placeholder,
}) {
  const [show, setShow] = useState(false);
  const date = initialDate && finalDate;
  const isDateWrong = initialDate > finalDate;
  const isDateInTheFuture = initialDate > new Date() || finalDate > new Date();
  const errorDate = isDateWrong || isDateInTheFuture;

  return (
    <Box>
      <p style={{ fontSize: 12, fontStyle: "italic", color: errorDate ? "#d32f2f" : "#222222" }}>
        Período
      </p>
      <button
        style={{ ...styles.Button, border: errorDate ? "2px solid #d32f2f" : "none" }}
        onClick={() => {
          setShow(!show);
        }}
      >
        {!date ? (
          <p style={{ color: "#000", fontStyle: "italic" }}>{placeholder}</p>
        ) : (
          `${formatDateToBrazilian(initialDate)} - ${formatDateToBrazilian(finalDate)}`
        )}
        <ExpandMore
          style={{
            position: "absolute",
            right: "5px",
            color: "#BBBBBB",
            transform: show ? "rotate(180deg)" : "unset",
          }}
        />
      </button>

      {show && (
        <ClickAwayListener
          onClickAway={() => {
            setShow(false);
          }}
        >
          <Box style={styles.Box}>
            <Box
              style={styles.Option}
              onClick={() => {
                setInitialDate(null);
                setFinalDate(null);
                setShow(false);
              }}
            >
              <em style={styles.Filter}>
                <Close fontSize="small" /> Apagar filtro
              </em>
            </Box>
            <Box style={styles.Option}>
              Inicial:
              <Input
                styles={{ height: "50px" }}
                type="date"
                value={initialDate || null}
                maxDate={finalDate ? finalDate : new Date()}
                onChange={(date) => {
                  setInitialDate(date);
                }}
              />
            </Box>
            <Box style={styles.Option}>
              Final:
              <Input
                styles={{ height: "50px" }}
                type="date"
                value={finalDate || null}
                minDate={initialDate ? initialDate : false}
                onChange={(date) => {
                  setFinalDate(date);
                }}
              />
            </Box>
            <Box>
              {isDateWrong && (
                <span style={styles.error}>
                  <ErrorIcon fontSize="small" sx={{ padding: 0, margin: 0 }} />
                  <span>Data Inicial superior à data Final!</span>
                </span>
              )}
              {isDateInTheFuture && (
                <span style={styles.error}>
                  <ErrorIcon fontSize="small" sx={{ padding: 0, margin: 0 }} />
                  <span>Selecione uma data no presente!</span>
                </span>
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
}

export default SetPeriodButton;

const styles = {
  Button: {
    width: "100%",
    height: "40px",
    maxHeight: "40px",
    backgroundColor: "#DCDCDC",
    boxShadow: "none",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "400",
    color: "#222222",
    cursor: "pointer",
    border: "none",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    borderRadius: "5px",
  },
  Box: {
    position: "absolute",
    top: "110%",
    zIndex: 999,
    backgroundColor: "#FBFBFB",
    padding: "5px 10px",
    borderRadius: "5px",
    transition: "all 0.3s",
  },
  Option: {
    display: "flex",
    fontSize: 14,
    alignItems: "center",
    gap: "3px",
    cursor: "pointer",
    padding: "0 6px",
  },
  error: {
    userSelect: "none",
    color: "#d32f2f",
    display: "flex",
    alignItems: "center",
    gap: 6,
    justifyContent: "center",
    paddingBlock: 4,
  },
  Filter: {
    fontSize: 14,
    opacity: 0.8,
    fontWeight: "400",
    color: "#d32f2f",
    display: "flex",
    alignItems: "center",
  },
};
