import React from "react";
import logos from "~/presentation/views/LandingPage/Home/Sections/Clients/logos.js";
import { Carousel } from "nuka-carousel";
import { Box, Typography } from "@mui/material";

const Clients = ({ id }) => {
  return (
    <Box sx={styles.container} id={id}>
      <Box sx={styles.textWrapper}>
        <Typography sx={styles.title}>Nossos Clientes</Typography>
        <Box sx={styles.separator} />
      </Box>
      <Box sx={styles.slide}>
        <Carousel showDots autoplay autoplayInterval={4000} dragging wrapAround>
          {logos.map((l, index) => (
            <Box
              component="img"
              src={`/assets/clients_logo/${l}`}
              alt={l}
              key={"landing-page-clients-carousel-image-" + index}
              sx={styles.image}
              draggable={false}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Clients;

const styles = {
  container: {
    position: "relative",
    backgroundImage: 'url("/assets/bg/houndstooth-pattern.png")',
    display: "grid",
    gridTemplateRows: "min-content auto",
    rowGap: "65px",
    alignItems: "center",
    padding: "65px 10% 45px 10%",
    "::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      background: "#fff",
    },
  },
  textWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "37px",
    lineHeight: "20px",
    textAlign: "justify",
    color: "#122141",
    mb: "15px",
    "@media (max-width: 600px)": {
      fontSize: "1.5rem",
    },
  },
  separator: {
    height: "2px",
    width: "176px",
    backgroundColor: "#cf9455",
  },
  slide: {
    outline: "none",
    paddingBottom: "50px",
  },
  image: {
    width: "100%",
    height: "auto",
    maxHeight: "200px",
    objectFit: "contain",
    margin: "10px 0px",
    "@media (max-width: 600px)": {
      maxHeight: "100px",
    },
  },
};
