import * as Yup from "yup";

export default Yup.object({
  name: Yup.string().min(4, "Digite um nome válido!").required("Campo obrigatório!"),
  numberOfUsers: Yup.number()
    .positive("Digite um número positivo.")
    .required("Campo obrigatório!")
    .integer("Digite um número inteiro")
    .typeError("Campo obrigatório!"),
  consultLimit: Yup.number()
    .positive("Digite um número positivo.")
    .required("Campo obrigatório!")
    .integer("Digite um número inteiro")
    .typeError("Campo obrigatório!"),
  isGenerateReport: Yup.boolean(),
  isCalculationSimulator: Yup.boolean(),
  isConsultISS: Yup.boolean(),
  isAllowReinf: Yup.boolean(),
  isSpecificSubstitutionAnalysis: Yup.boolean(),
  valor: Yup.number()
    .required("Campo obrigatório!")
    .positive("Campo obrigatório!")
    .typeError("Campo obrigatório!"),
  isAllowTaxInformation: Yup.boolean(),
  taxInformationNumber: Yup.number()
    .integer("Digite um número inteiro.")
    .when(["isAllowTaxInformation"], {
      is: true,
      then: (schema) =>
        schema
          .positive("Digite uma taxa positivo.")
          .required("Campo obrigatório!")
          .typeError("Campo obrigatório!"),
      otherwise: (schema) => schema.notRequired(),
    }),
});
