import React from "react";
import {
  AddShoppingCart,
  AppRegistration,
  Business,
  Category,
  Dashboard,
  Dvr,
  Explicit,
  LocationCity,
  PermIdentity,
  Quiz,
  RssFeed,
  InsertComment,
  Public,
  Announcement,
  FormatListBulleted,
  ListAlt,
  Note,
  PlayArrow,
  QuestionAnswer,
  Search,
  ShowChart,
  Sync,
  ViewList,
} from "@mui/icons-material";

const menus = [
  {
    id: 0,
    name: "Dashboard",
    icon: <Dashboard />,
    link: "/admin",
  },
  {
    id: 1,
    name: "Cadastro",
    icon: <AppRegistration />,
    subItems: [
      {
        name: "Plano",
        link: "/admin/plano",
        icon: <AddShoppingCart />,
      },
      {
        name: "Categoria Empresa",
        link: "/admin/empresa/categoria",
        icon: <Category />,
      },
      {
        name: "Empresa",
        link: "/admin/empresa",
        icon: <Business />,
      },
      {
        name: "Usuário",
        link: "/admin/usuario",
        icon: <PermIdentity />,
      },
      {
        name: "Município",
        link: "/admin/consulta-iss/atualizar-municipios",
        icon: <LocationCity />,
      },
      {
        name: "Variável de Valor",
        link: "/admin/variaveis-de-valor",
        icon: <Explicit />,
      },
      {
        name: "Enquete",
        link: "/admin/enquetes",
        icon: <Quiz />,
      },
    ],
  },
  {
    id: 2,
    name: "Conteúdo do site",
    icon: <Dvr />,
    subItems: [
      {
        name: "Categoria Blog",
        link: "/admin/blog/categoria",
        icon: <Category />,
      },
      {
        name: "Blog",
        link: "/admin/blog",
        icon: <RssFeed />,
      },
      {
        name: "Comentário",
        link: "/admin/comentarios",
        icon: <InsertComment />,
      },
      {
        name: "Conteúdo Selecionado",
        link: "/admin/conteudo",
        icon: <Public />,
      },
      {
        name: "Conteúdo Explicativo",
        link: "/admin/ajuda",
        icon: <PlayArrow />,
      },
      {
        name: "Categorias C. Explicativo",
        link: "/admin/ajuda/categoria",
        icon: <Category />,
      },
    ],
  },
  {
    id: 3,
    name: "GT-Fácil",
    icon: <Sync />,

    subItems: [
      {
        name: "CNAE",
        link: "/admin/cnaes",
        icon: <ListAlt />,
      },
      {
        name: "Código de Serviço",
        link: "/admin/servicos",
        icon: <ViewList />,
      },
      {
        name: "Anotações",
        link: "/admin/anotacoes",
        icon: <Note />,
      },
      {
        name: "Termo de Pesquisa",
        link: "/admin/termos-de-pesquisa",
        icon: <Search />,
      },
      {
        name: "Item de Resposta",
        link: "/admin/itens-de-resposta",
        icon: <FormatListBulleted />,
      },
      {
        name: "Tipo de Resposta",
        link: "/admin/tipos-de-resposta",
        icon: <Category />,
      },
      {
        name: "Resposta",
        link: "/admin/respostas",
        icon: <QuestionAnswer />,
      },
      {
        name: "Resposta do ISS",
        link: "/admin/respostas/respostas-iss",
        icon: <QuestionAnswer />,
      },
      // {
      //   name: "Importar Respostas",
      //   link: "/admin/gt-facil/importar/respostas",
      //   icon: <UploadFile />,
      // },
    ],
  },
  {
    id: 4,
    name: "Informação Fiscal",
    icon: <Announcement />,

    link: "/admin/informacoes-fiscais",
  },
  {
    id: 5,
    name: "Estatística",
    icon: <ShowChart />,

    subItems: [
      {
        name: "Informação Estatística",
        link: "/admin/estatistica",
        icon: <PermIdentity />,
      },
      {
        name: "Visualizações",
        link: "/admin/estatistica/contagem-de-visualizacoes",
        icon: <PermIdentity />,
      },
    ],
  },
];
export default menus;
