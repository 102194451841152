import * as Yup from "yup";
import { validarEmail } from "~/presentation/functions/emailValidator";
import removeMask from "~/presentation/functions/removeMask";
import { validatePassword } from "~/presentation/utils/validatePassword";

const validation = {
  companyId: Yup.string().required("Campo Obrigatório!"),
  name: Yup.string().required("Campo Obrigatório!"),
  role: Yup.string().required("Campo Obrigatório!").typeError("Campo Obrigatório!"),
  email: Yup.string()
    .test("email", "Email inválido!", (value) => validarEmail(value))
    .required("Campo Obrigatório!"),

  phone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(10, "Digite um número válido")
    .required("Campo Obrigatório!"),
  mobile_phone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(11, "Digite um número válido")
    .required("Campo Obrigatório!"),
};

export const validationCreate = Yup.object({
  ...validation,
  password: Yup.string()
    .test(
      "password",
      "A senha deve conter no mínimo 8 caracteres, letra maiúscula, letra minúscula, número e caractere especial.",
      (value) => validatePassword({ password: value, editing: false })
    )
    .required("Campo Obrigatório!"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "As senhas devem ser iguais.")
    .required("Campo Obrigatório!"),
});

export const validationEdit = Yup.object({
  ...validation,
});
