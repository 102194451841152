import React, { useContext, useState, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import { AddNewCertificate } from "~/presentation/views/Reinf/DigitalCertificate/New/index";
import { Tooltip, Switch } from "@mui/material";
import { EditCertificate } from "./Edit/EditCertificate";
import ModalContainer from "../components/ModalContainer/ModalContainer";
import TableActions from "~/presentation/components/TableActions";
import { formatDateToBrazilian } from "../Utils/utilsDate";
import Table from "../components/Table/Table";

export const ViewDigitalCertificate = () => {
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);

  //modal create
  const [open, setOpen] = useState(false);
  //modal edit
  const [openEdit, setOpenEdit] = useState(false);

  //todos os certificados
  const [certInfo, setCertInfo] = useState([]);

  //certificado clicado para modal
  const [editCertInfo, setEditCertInfo] = useState(null);

  const fetchCertInfo = async () => {
    try {
      openBackdrop(true, "Carregando dados");
      const certResult = await apiDigitalCert.getDigitalCerts();
      setCertInfo(certResult);
      if (certResult.length === 1) {
        //se um certificado no array, então esse é marcado isDefault
        const singleCert = certResult[0];
        if (!singleCert.isDefault) {
          singleCert.isDefault = true;
          await apiDigitalCert.updateDigitalCert(singleCert);
          setFeedbackSnackbar({
            isOpen: true,
            message: "Certificado único configurado como padrão",
            type: "success",
          });
        }
      }
    } catch (error) {
      console.error("Erro ao buscar certificados:", error);
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    fetchCertInfo();
  }, []);

  const handleOpen = () => setOpen(true);

  const handleOpenEdit = (cert) => {
    setEditCertInfo(cert);
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChangeSwitch = async (certId, isDefault) => {
    const updatedCert = certInfo.find((cert) => cert.id === certId);
    if (!updatedCert) {
      console.error("Certificado não encontrado!");
      return;
    }

    const updatedCertInfo = certInfo.map((cert) =>
      cert.id === certId ? { ...cert, isDefault: isDefault } : cert
    );
    setCertInfo(updatedCertInfo);
    updatedCert.isDefault = isDefault;

    try {
      await apiDigitalCert.updateDigitalCert(updatedCert);

      setFeedbackSnackbar({
        isOpen: true,
        message: isDefault ? "Certificado Padrão configurado" : "Certificado Padrão retirado",
        type: "success",
      });

      await fetchCertInfo();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar certificado: " + error.response.data,
        type: "error",
      });
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      align: "center",
    },
    { field: "ownerName", headerName: "Proprietário", align: "center" },

    {
      field: "expirationDate",
      headerName: "Data de validade",
      align: "center",
      renderCell: ({ value }) => formatDateToBrazilian(value) || "-",
    },
    {
      field: "isDefault",
      headerName: "Certificado Padrão",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: ({ row, value }) => {
        return (
          <Tooltip title="Defina como um certificado principal para as ações do sistema.">
            <Switch
              disabled={certInfo?.length === 1 || value}
              checked={value}
              onChange={(e) => handleChangeSwitch(row.id, e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: ({ row }) => <TableActions handleEdit={() => handleOpenEdit(row)} />,
    },
  ];

  return (
    <>
      <HeaderSectionPage title="Certificados digitais" handleAdd={handleOpen} />
      <Table data={certInfo} columns={columns} />
      <ModalContainer open={open} onClose={handleClose}>
        <AddNewCertificate handleClose={handleClose} refreshData={fetchCertInfo} />
      </ModalContainer>
      <ModalContainer open={openEdit} onClose={handleCloseEdit}>
        <EditCertificate
          data={editCertInfo}
          handleClose={handleCloseEdit}
          refreshData={fetchCertInfo}
          setCertInfo={setCertInfo}
        />
      </ModalContainer>
    </>
  );
};
