import React from "react";
import { Checkbox as MuiCheckbox } from "@mui/material";

const Checkbox = ({ color, ...props }) => {
  return (
    <MuiCheckbox
      sx={{
        color: color || "#383f48",
        "&.Mui-checked": {
          color: color || "#383f48",
        },
      }}
      {...props}
    />
  );
};

export default Checkbox;
