import React from "react";
import { IconButton, Snackbar, Slide, Alert, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const SimpleSnackbar = ({
  isOpen = false,
  message = "Message!",
  type = "warning", //error, warning, info, success
  onClose,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      action={
        <>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Close fontSize="small" />
          </IconButton>
        </>
      }
    >
      <Alert sx={styles.alert} variant="filled" onClose={handleClose} severity={type}>
        <Typography sx={styles.text}>{message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default SimpleSnackbar;

const styles = {
  alert: {
    "&.MuiAlert-root": {
      borderRadius: "40px",
    },
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: "0.9rem",
  },
};
