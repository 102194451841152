import React from "react";
import ContentCard from "~/presentation/views/Blog/components/ContentCard";
import { Box, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { getSafeArray } from "~/presentation/utils/getSafeArray";
const MoreContentSection = ({
  blogPosts,
  goToPost,
  defaultImage,
  numberOfPages,
  goToPage,
  page,
  categoryId,
}) => {
  const blogPostsData = getSafeArray({ array: blogPosts?.slice(2, 12) });
  return (
    <>
      <Grid
        container
        spacing={2.2}
        sx={{
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          maxWidth: { xs: "100%", md: 1440 },
        }}
      >
        {blogPosts.length === 0 ? (
          <Box
            sx={{ minHeight: 400, display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            Nenhuma publicação encontrada
          </Box>
        ) : blogPosts.length < 3 ? (
          <></>
        ) : (
          blogPostsData
            .filter((item, index) => index < 9)
            .map((post) => (
              <ContentCard
                key={post.id}
                imgSrc={post.image}
                title={post.title}
                author={post.autor}
                date={post.publishDate}
                numberOfComments={post.numberOfComments}
                tags={post.tags}
                text={post.text}
                onClick={() => goToPost(post.id)}
                defaultImage={defaultImage}
                size={4}
                imageHeight={200}
              />
            ))
        )}
      </Grid>
      <Pagination
        sx={styles.paggination}
        key="pagination"
        size="small"
        count={numberOfPages}
        onChange={(_, selectedPage) => {
          goToPage(selectedPage, categoryId);
        }}
        page={Number(page)}
      />
    </>
  );
};

export default MoreContentSection;

const styles = {
  paggination: {
    display: "flex",
    alignItems: "center",
    justifySelf: "center",
    padding: "30px",
    width: "fit-content",
    margin: "1rem auto 0 auto",
  },
};
