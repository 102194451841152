import React, { useContext, useEffect, useRef, useState } from "react";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import CalculateRetentions from "../CalculateRetentions/CalculateRetentions";
import ModalContainer from "../../../ModalContainer/ModalContainer";
import RButton from "../../../Common/Buttons/RButton";
import Nota from "../Fields/Nota/Nota";
import INSS from "../Fields/INSS/INSS";
import IR from "../Fields/IR/IR";
import CSRF from "../Fields/CSRF/CSRF";
import IRPJ from "../Fields/IRPJ/IRPJ";
import { Box, Typography } from "@mui/material";
import {
  validationSendR2010,
  validationSendR4020AB,
  validationSendR4020C,
  validationSendR4020D,
} from "./validationSchema";

function LogicAndValidation({
  values,
  onSubmit,
  serviceClassificationOptions,
  incomeNatureOptions,
  businessPartnerOptions,
  handleChangeFieldValue,
  handleChangeINSSfield,
  inputDisabled,
  selectedEvent,
  errors,
  setErrors,
  isEventClosed,
}) {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const QSOAB = QSO === "A" || QSO === "B";
  const QSOC = QSO === "C";
  const QSOD = QSO === "D";
  const R2010 = selectedEvent === "r2010";
  const R4020 = selectedEvent === "r4020";

  //se é nota de produto NFe
  const isNFe = values?.typeDoc === 1;

  const onChangeById = (props) => {
    const { newItem, byPass = false, field = null } = props;
    handleChangeFieldValue({
      newItem: newItem,
      id: values.id,
      field: field,
      byPass: byPass,
    });
  };

  const onChangeINSSById = (props) => {
    const { newItem, nestedIndex, byPass = false, field = null } = props;
    handleChangeINSSfield({
      newItem: newItem,
      id: values.id,
      nestedIndex: nestedIndex,
      field: field,
      byPass: byPass,
    });
  };

  //objeto zerado do itemsNFse
  const newItemsNFseObject = {
    id: 0,
    serviceClassificationId: 0,
    serviceClassification: "",
    bc: 0,
    amount: 0,
    aliquot: 0,
    principalRententionAmount: 0,
    subcontractorAmount: 0,
    annotation: "",
    serviceAmount15: 0,
    serviceAmount20: 0,
    serviceAmount25: 0,
    additionalRetentionAmount: 0,
    additionalNotRetentionAmount: 0,
  };

  const calculateAliquotIR = () => {
    if (QSOC || QSOD) {
      //Valor Aliquota IR = (Retenção IR / Base de calculo IR) * 100
      if (values?.amountIR >= 0 && values?.bcIR >= 0) {
        return (values?.amountIR / values?.bcIR) * 100;
      }
    }
    if (QSOAB) {
      if (values?.amountAggregate >= 0 && values?.amountOperation >= 0) {
        return (values?.amountAggregate / values?.amountOperation) * 100;
      }
    }
    return values?.aliquotIR;
  };

  const calculateAliquotCSRF = () => {
    //Aliquota CSRF = (Retenção CSRF / Base de calculo CSRF) * 100
    if (values?.bcCSRF >= 0 && values?.amountCSRF >= 0) {
      return (values?.amountCSRF / values?.bcCSRF) * 100;
    }
    return values?.aliquotCSRF;
  };

  const calculateAmountOperation = () => {
    return values?.amountBC - values?.unconditionalDiscount;
  };

  const calculateAmoutCSRF = () => values?.amountCSLL + values?.amountPIS + values?.amountCOFINS;

  //Valores totais de itemsNFse.bc
  const allBcTypeService = values?.itemsNFse?.reduce((total, curr) => total + curr.bc, 0);
  const calculateNewBcINSS = () => {
    if (allBcTypeService >= 0) {
      return allBcTypeService;
    }
    return values?.bcINSS;
  };

  //Valores totais de itemsNFse.amout
  const allAmountTypeService = values?.itemsNFse?.reduce((total, curr) => total + curr.amount, 0);
  const calculateNewAmountINSS = () => {
    if (allAmountTypeService >= 0) {
      return allAmountTypeService;
    }
    return values?.amountINSS;
  };

  const getCurrentItemNFse = () => {
    if (values?.itemsNFse === null) {
      return [newItemsNFseObject];
    }
    return values.itemsNFse;
  };

  const updateItemsNFseAliquot = (items) => {
    return items.map((item) => {
      const { bc, amount, aliquot } = item;

      //SE: bc e amount estejam preenchidos mas aliquot não

      if (bc > 0 && amount > 0 && aliquot === 0) {
        return {
          ...item,
          aliquot: (amount / bc) * 100,
        };
      }
      return item;
    });
  };

  const isFirstRender = useRef(true); //refs para função itemsnNFse[0] update campos bc e amout
  const isFirstRender2 = useRef(true); //refs para função itemsnNFse[0] update aliquot

  const getNewItemsNFse = () => {
    //Se itemsNFse === null -> ATRIBUIR: [{campos zerados para mostrar na tela ao usuário}]
    let newItemsNFse = getCurrentItemNFse();

    //ATRIBUIR: bcINSS a itemsNFse[0].bc e amountINSS a itemsNFse[0].amount
    if (allBcTypeService === 0 && allAmountTypeService === 0) {
      //SE: bc e amout do itemsNFse[0] sejam 0 e mas bcINSS e amountINSS sejam positivos
      if (values?.bcINSS > 0 && values?.amountINSS > 0) {
        //*Executar apenas no 1ºrender*
        if (isFirstRender.current) {
          newItemsNFse[0] = {
            ...newItemsNFse[0],
            bc: values?.bcINSS,
            amount: values?.amountINSS,
            aliquot: (values?.amountINSS / values?.bcINSS) * 100,
          };
          isFirstRender.current = false;
        }
      }
    }

    //*Executar apenas no 1ºrender*
    if (isFirstRender2.current) {
      //ATRIBUIR: calcular a aliquot de cada itemsNFse (SE: bc e amount > 0)
      newItemsNFse = updateItemsNFseAliquot(newItemsNFse);
      isFirstRender2.current = false;
    }

    return newItemsNFse;
  };

  useEffect(() => {
    onChangeById({
      newItem: {
        itemsNFse: getNewItemsNFse(),
        bcINSS: calculateNewBcINSS(),
        amountINSS: calculateNewAmountINSS(),
        aliquotINSS: values?.isCPRB ? 3.5 : 11,
        amountOperation: calculateAmountOperation(),
        amountCSRF: calculateAmoutCSRF(),
        aliquotCSRF: calculateAliquotCSRF(),
        aliquotIR: calculateAliquotIR(),
      },
      byPass: true, //para não contar como interação na nota
    });
  }, [
    allBcTypeService,
    allAmountTypeService,
    values?.itemsNFse,
    values?.amountCSLL,
    values?.amountPIS,
    values?.amountCOFINS,
    values?.amountCSRF,
    values?.bcCSRF,
    values?.isCPRB,
    values?.unconditionalDiscount,
    values?.amountBC,
    values?.amountIR,
    values?.bcIR,
    values?.amountAggregate,
    values?.amountOperation,
  ]);

  //ERRO: Data no futuro
  const isDateInTheFuture = new Date(values?.dateOfPayment) > new Date();

  //ERRO: Serie > 6 dígitos
  const [serieError, setSerieError] = useState(null);

  //ERRO: desconto incondiconal > do que valor bruto
  const isUnconditionalDiscountWrong = values?.unconditionalDiscount > values?.amountBC;

  //ERRO: amountBC tem de ser > do que qualquer base de cálculo
  const isBcINSSWrong = values?.bcINSS > values?.amountOperation;
  const isBcIRWrong = values?.bcIR > values?.amountOperation;
  const isBcSCRFWrong = values?.bcCSRF > values?.amountOperation;

  const showError = (message) => {
    setFeedbackSnackbar({
      isOpen: true,
      message: message,
      type: "error",
    });
  };

  const handleValidationErrors = (errors) => {
    setErrors(
      errors.inner.reduce((acc, error) => {
        acc[`[${values?.id}].${error.path}`] = error.message;
        return acc;
      }, {})
    );
    setFeedbackSnackbar({
      isOpen: true,
      message: "Erros nos campos, corrija para poder enviar!",
      type: "warning",
    });
    openBackdrop(false);
  };

  const processSubmit = async (values, validationSchema) => {
    openBackdrop(true, "Verificando dados");
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        const isItemsNFseNull =
          values?.itemsNFse.length == 1 &&
          JSON.stringify(values?.itemsNFse) === JSON.stringify([newItemsNFseObject]);

        const data = isItemsNFseNull
          ? {
              ...values,
              itemsNFse: null,
            }
          : values;
        setErrors({});
        onSubmit(data);
      })
      .catch((errors) => {
        handleValidationErrors(errors);
      });
  };

  const handleSubmit = async (values) => {
    setSerieError(null);

    //TRATAMENTO DE ERROS:

    if (isUnconditionalDiscountWrong) {
      return showError("O Desc. Incondicional não pode ser maior do que o Valor Bruto da Nota!");
    }

    if (!R2010) {
      if (isDateInTheFuture) {
        return showError("Escolha uma data no presente");
      }
    }

    if (!R4020) {
      if (isBcINSSWrong) {
        return showError(
          "Valor principal da base INSS não pode ser maior do que o Valor de Operação!"
        );
      }
    }

    if (QSOC || QSOD) {
      if (isBcIRWrong) {
        return showError(
          "Valor da base IR não pode ser maior do que o Valor de Operação do documento!"
        );
      }
    }

    if (QSOD) {
      if (isBcSCRFWrong) {
        return showError(
          "Valor da base CSRF não pode ser maior do que o Valor de Operação do documento!"
        );
      }
    }

    //Submeter nfse -> tabela Corrigir e Enviar SendReinf
    if (R2010) {
      return processSubmit(values, validationSendR2010);
    }

    if (R4020) {
      if (QSOAB) {
        return processSubmit(values, validationSendR4020AB);
      }
      if (QSOC) {
        return processSubmit(values, validationSendR4020C);
      }
      if (QSOD) {
        return processSubmit(values, validationSendR4020D);
      }
    }

    //Caso itemsNFse não tenha nenhum campo preenchido tem de ir null para o back
    const isItemsNFseNull =
      values?.itemsNFse.length == 1 &&
      JSON.stringify(values?.itemsNFse) === JSON.stringify([newItemsNFseObject]);

    const data = isItemsNFseNull ? { ...values, itemsNFse: null } : values;

    //Submeter nfse -> tabela Doc. Fiscais
    onSubmit(data);
    setErrors({});
  };

  // MODAL CALCULAR RETENÇÃO AUTOMÁTICO PARA DOCUMENTOS NF-e
  const [openRetentionsModal, setOpenRetentionsModal] = useState(false);
  const handleOpenRetentionsModal = () => {
    if (!values.bcIR > 0) {
      return showError("Precisa da atribuir um valor à Base de Cálculo para efectuar o cálculo.");
    }
    if (!values?.amountOperation > 0) {
      return showError(
        "Precisa da atribuir um valor ao Valor Total da Operação para efectuar o cálculo."
      );
    }
    setOpenRetentionsModal(true);
  };
  const handleCloseRetentionsModal = () => setOpenRetentionsModal(false);
  const modalCalculateRetention = (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <RButton
          sx={{ boxShadow: 0, borderRadius: "8px", width: "fit-content" }}
          onClick={handleOpenRetentionsModal}
        >
          Calcular Retenções
        </RButton>
      </Box>
      <ModalContainer open={openRetentionsModal} onClose={handleCloseRetentionsModal}>
        <CalculateRetentions
          onClose={handleCloseRetentionsModal}
          handleChangeFieldValue={onChangeById}
          values={values}
        />
      </ModalContainer>
    </>
  );

  const docOriginAndSaveButton = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ fontSize: 14, fontFamily: "Montserrat", color: "#1341A1" }}>
          <strong>Origem: </strong>
          {values?.origin}
        </Typography>
        <Typography sx={{ fontSize: 14, fontFamily: "Montserrat", color: "#1341A1" }}>
          <strong>Situação: </strong>
          {values?.situation}
        </Typography>
      </Box>
      <RButton
        sx={{ width: "180px", boxShadow: 0 }}
        onClick={() => {
          handleSubmit(values, selectedEvent);
        }}
        disabled={isEventClosed}
      >
        SALVAR
      </RButton>
    </Box>
  );

  const compNota = (
    <Nota
      values={values}
      businessPartnerOptions={businessPartnerOptions}
      errors={errors}
      serieError={serieError}
      setSerieError={setSerieError}
      handleChangeFieldValue={onChangeById}
      inputDisabled={inputDisabled}
      isUnconditionalDiscountWrong={isUnconditionalDiscountWrong}
    />
  );

  const compINSS = (
    <INSS
      values={values}
      serviceClassificationOptions={serviceClassificationOptions}
      errors={errors}
      handleChangeFieldValue={onChangeById}
      handleChangeINSSfield={onChangeINSSById}
      inputDisabled={inputDisabled}
      isBcINSSWrong={isBcINSSWrong}
    />
  );

  const compIR = (
    <IR
      values={values}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
      handleChangeFieldValue={onChangeById}
      isBcIRWrong={isBcIRWrong}
      inputDisabled={inputDisabled}
      modalCalculateRetention={modalCalculateRetention}
    />
  );

  const compCSRF = (
    <CSRF
      values={values}
      handleChangeFieldValue={onChangeById}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
      isBcSCRFWrong={isBcSCRFWrong}
      inputDisabled={inputDisabled}
    />
  );

  const compIRPJ = (
    <IRPJ
      values={values}
      handleChangeFieldValue={onChangeById}
      incomeNatureOptions={incomeNatureOptions}
      errors={errors}
      inputDisabled={inputDisabled}
      modalCalculateRetention={modalCalculateRetention}
    />
  );

  //Tela envio evento R2010

  if (R2010) {
    return (
      <>
        {compNota}
        {compINSS}
        {docOriginAndSaveButton}
      </>
    );
  }

  //Tela envio evento R4020

  if (R4020) {
    if (QSOAB) {
      return (
        <>
          {compNota}
          {compIRPJ}
          {docOriginAndSaveButton}
        </>
      );
    }
    if (QSOC) {
      return (
        <>
          {compNota}
          {compIR}
          {docOriginAndSaveButton}
        </>
      );
    }
    if (QSOD) {
      return (
        <>
          {compNota}
          {compIR}
          {compCSRF}
          {docOriginAndSaveButton}
        </>
      );
    }
  }

  //Tabela Importação Documentos Fiscais

  if (QSOAB) {
    return (
      <>
        {compNota}
        {!isNFe && compINSS}
        {compIRPJ}
        {docOriginAndSaveButton}
      </>
    );
  }

  if (QSOC) {
    return (
      <>
        {compNota}
        {!isNFe && compINSS}
        {compIR}
        {docOriginAndSaveButton}
      </>
    );
  }

  if (QSOD) {
    return (
      <>
        {compNota}
        {compINSS}
        {compIR}
        {compCSRF}
        {docOriginAndSaveButton}
      </>
    );
  }

  return (
    <Typography sx={{ fontSize: 14, fontFamily: "Montserrat", color: "#d32f2f", fontWeight: 700 }}>
      Erro ao carregar a linha QSO. Recarregue a página!
    </Typography>
  );
}

export default LogicAndValidation;
