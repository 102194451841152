import * as Yup from "yup";

const validation = Yup.object({
  legalName: Yup.string().required("Campo Obrigatório!"),
  personType: Yup.number().required("Campo obrigatório!"),
  cpfCnpj: Yup.string()
    .required("Campo obrigatório!")
    .when("personType", {
      is: 1,
      then: (schema) => schema.min(14, "Digite um CNPJ válido"),
      otherwise: (schema) => schema.min(11, "Digite um CPF válido"),
    }),
  number: Yup.string().max(20, "Digite um número válido até 20 caracteres"),
  homePhone: Yup.string().when("personType", {
    is: 1, // Ensuring we are targeting personType === 1
    then: (schema) => schema.min(10, "Digite um número válido"),
    otherwise: (schema) => schema.notRequired(),
  }),
  email: Yup.string().email("Digite um email válido"),
});

export default validation;
