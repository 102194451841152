import React, { useContext, useEffect, useState } from "react";
import { makeTermUseCases } from "~/main/factories/use-cases/term";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import TermForm from "./Form";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";

function CreateEdit({ data, onCloseForm, refreshData }) {
  const termUseCases = makeTermUseCases();
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ id: 0, term: "" });

  useEffect(() => {
    if (data?.id) {
      setValues(data);
    }
  }, [data]);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    try {
      openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} termo`);
      if (isCreate) {
        await termUseCases.createTerm(values);
      } else {
        await termUseCases.updateTerm(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Termo ${isCreate ? "criado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.data || error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Cadastrar" : "Editar"} Termo`}
        handleBack={onCloseForm}
      />
      <TermForm
        values={values}
        onChangeValue={handleChangeValue}
        handleSubmit={handleValidation}
        errors={errors}
      />
    </>
  );
}

export default CreateEdit;
