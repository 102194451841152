import { useContext } from "react";
import { SnackbarContext } from "../providers/SnackbarProvider";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import randomString from "../utils/randomString";

export function useUploadFile() {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION_BUCKET,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  const s3Client = new S3Client({
    region: config.region,
    credentials: {
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
    },
  });

  const importFile = (event, imageRef) => {
    const [file] = event.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = imageRef;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      const renamedFile = new File([file], `${randomString()}-${file.name}`);
      return renamedFile;
    }
  };

  const uploadFile = async (file, fileName) => {
    if (file && fileName) {
      const params = {
        Body: file,
        Bucket: config.bucketName,
        Key: fileName,
        ContentType: file.type,
        ACL: "public-read", // Permissão pública
      };
      try {
        const command = new PutObjectCommand(params);
        const data = await s3Client.send(command);
        if (data) {
          const fileUrl = `https://${config.bucketName}.s3.amazonaws.com/${fileName}`;
          return fileUrl;
        }
      } catch (error) {
        setFeedbackSnackbar({
          isOpen: true,
          message: error?.message || "Erro ao fazer upload.",
          type: "error",
        });
        return error;
      }
    }
  };

  return { importFile, uploadFile };
}
