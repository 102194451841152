import React from "react";
import { Box, Typography } from "@mui/material";

const ImageInput = ({ title, imageFile, onChange, uploadedImageRef, error }) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minHeight: "56px",
        maxHeight: "330px",
        overflow: "hidden",
      }}
      htmlFor="image"
      image={imageFile}
    >
      <Typography
        sx={{
          position: "absolute",
          top: "5px",
          bottom: "5px",
          left: "5px",
          right: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          borderRadius: "12px",
          opacity: imageFile ? "0.2" : "1",
          transition: "all 0.2s ease-in-out",
          border: error ? "solid 1px #d32f2f" : "solid 1px #383f48",
          fontSize: "16px",
          "&:hover": {
            opacity: 1,
          },
        }}
      >
        {title}
      </Typography>
      <input
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0,
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
        name="image"
        type="file"
        accept={"image/*"}
        multiple={false}
        onChange={onChange}
      />
      <Box component="img" sx={{ width: "100%" }} ref={uploadedImageRef} src={imageFile} />
    </Box>
  );
};

export default ImageInput;
