import React from "react";
import { htmlToText } from "~/presentation/functions/htmlToText";
import { Typography as Text, Box, Paper } from "@mui/material";
import { limitTextSize } from "~/presentation/utils/limitTextSize";
import Grid from "@mui/material/Grid2";

const ContentCard = ({
  id,
  imgSrc,
  title,
  author,
  autor,
  tags,
  text,
  onClick,
  defaultImage,
  size,
  imageHeight,
}) => {
  const titleLimited = limitTextSize(title, 100);
  return (
    <Grid size={{ xs: 12, sm: 6, md: size }} onClick={() => onClick(id)}>
      <Paper
        sx={{
          borderRadius: "10px",
          background: "#E1E1E1",
          height: 526, // Altura fixa
          cursor: "pointer",
          margin: 0,
          transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
          "&:hover": {
            transform: "scale(1.03)",
            backgroundColor: "#ededed",
          },
        }}
      >
        <img
          src={imgSrc || defaultImage}
          alt={title}
          style={{
            width: "100%",
            height: imageHeight || 200,
            objectPosition: "center",
            borderRadius: "10px 10px 0 0",
          }}
        />
        <Box
          sx={{
            paddingInline: 2,
            paddingBottom: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: `calc(100% - ${imageHeight}px)`, // Ajusta o restante do espaço
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              overflow: "hidden",
            }}
          >
            <Box sx={{ display: "flex", gap: 2, paddingBlock: 2, flexWrap: "wrap" }}>
              {tags.map((i, e) => (
                <Box
                  key={e}
                  sx={{
                    background: "#FF6829",
                    width: "fit-content",
                    borderRadius: 39,
                    paddingInline: 2,
                    paddingBlock: 0.3,
                    color: "#FFF",
                    height: "fit-content",
                  }}
                >
                  <Text sx={{ fontSize: 15, fontWeight: 300 }}>{i.name}</Text>
                </Box>
              ))}
            </Box>
            <Text sx={{ color: "#3A3A3A", fontSize: 18, fontWeight: 500, lineHeight: 1.4 }}>
              {titleLimited}
            </Text>
            <Text
              sx={{
                color: "#3A3A3A",
                fontSize: 16,
                fontWeight: 300,
                lineHeight: 1.4,
                paddingBlock: "20px",
                overflowWrap: "break-word",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 4,
                WebkitBoxOrient: "vertical",
              }}
            >
              {htmlToText(text ? limitTextSize(text, 250) : "")}
            </Text>
          </Box>
          <Text sx={{ color: "#3A3A3A", fontSize: 15, fontStyle: "italic", fontWeight: 400 }}>
            Publicado por: {author || autor}
          </Text>
        </Box>
      </Paper>
    </Grid>
  );
};

export default ContentCard;
