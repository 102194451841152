import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { MostAccessedSection } from "~/presentation/views/Client/Article/components/MostAccessedSection/MostAccessedSection";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import CardsList from "~/presentation/components/CardsList";
import { resetScroll } from "~/presentation/functions/resetScroll";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import { Box, Container } from "@mui/material";
import { ContentCard } from "../components/ContentCard/ContentCard";
import { usePaginatedState } from "~/presentation/hooks/usePaginatedState";
import Grid from "@mui/material/Grid2";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";

const ITEMS_PER_PAGE = 6;
const DEFAULT_IMAGE = "/assets/bgartigos.png";
const TELEGRAM_IMG = "/assets/canalTelegram.jpg";

const Article = ({ contentUseCases }) => {
  const navigate = useNavigate();
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [mostAccessedPosts, setMostAccessedPosts] = useState([]);

  const getMostAccessedPosts = async () => {
    try {
      const { contents } = await contentUseCases.getMostAccessedArticles();
      setMostAccessedPosts(contents);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar as publicações mais acessadas.",
        type: "error",
      });
    }
  };

  const getContent = async (queryParams) => {
    return contentUseCases.getArticle({
      IsSummaryOnly: false,
      ...queryParams,
    });
  };

  const { handleSearch, handlePage, numberOfPages, isLoading, content, page, scrollToRef } =
    usePaginatedState({
      getData: getContent,
      itemsPerPage: ITEMS_PER_PAGE,
    });

  const goToPost = (id) => {
    navigate("/cliente/conteudo-selecionado/artigo/post/" + id);
  };

  useEffect(() => {
    getMostAccessedPosts();
    resetScroll();
  }, []);

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {({ mobile }) => (
        <>
          <SectionTitlePost
            mobile={mobile}
            onSearch={handleSearch}
            image={DEFAULT_IMAGE}
            title="Artigos"
            redirectURL="/cliente/conteudo-selecionado/artigo"
            searchPlaceholder="Pesquisar Artigo"
          />
          <Container maxWidth="lg" sx={{ marginTop: 3 }} ref={scrollToRef}>
            <Grid container spacing={3}>
              <Grid size={{ xs: 12, md: 8 }}>
                <CardsList
                  content={content}
                  numberOfPages={numberOfPages}
                  goToPage={handlePage}
                  goToPost={goToPost}
                  page={page}
                  dataLoaded={!isLoading}
                  notFoundMessage="Não foram encontrados Artigos correspondentes à pesquisa."
                  cardComponent={ContentCard}
                  itemsPerPage={ITEMS_PER_PAGE}
                  defaultImage={DEFAULT_IMAGE}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={styles.MostAccessed}>
                <MostAccessedSection
                  mobile={mobile}
                  mostAccessedPosts={mostAccessedPosts}
                  goToPost={goToPost}
                  defaultImage={DEFAULT_IMAGE}
                />
                <Box
                  component="a"
                  href="https://t.me/+j00d6m6nZ1tkMGYx"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: "block",
                    cursor: "pointer",
                    margin: "0 auto 3rem auto",
                  }}
                >
                  <Box component="img" src={TELEGRAM_IMG} sx={styles.flyer} />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </HomeLayout>
  );
};

export default Article;

const styles = {
  MostAccessed: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  flyer: {
    justifySelf: "center",
    height: 500,
    borderRadius: "6px",
    objectFit: "cover",
    transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
    "&:hover": {
      transform: "scale(1.01)",
    },
  },
};
