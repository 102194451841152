import { apiURL } from "../../usecases/api";
export class ApiDeductionItemUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getDeductionItem(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DeductionItem?paymentRecordId=${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDeductionItemById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DeductionItem/${id}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Falhou: " + response.status);
      }
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  }

  async updateDeductionItem(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DeductionItem`,
        method: "PUT",
        data, 
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async createDeductionItem(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DeductionItem`,
        method: "POST",
        data,
      });

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async deleteDeductionItem(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/DeductionItem/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
