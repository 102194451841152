import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import Form from "./Form";
import removeMask from "~/presentation/functions/removeMask";
import PageListHeader from "../../components/PageListHeader";
import { validationCreate, validationEdit } from "./Form/validationSchema";

function RegisterEdit({
  data,
  onCloseForm,
  refreshData,
  userUseCases,
  companies,
  userGroups,
  fetchCompanys,
  companyId,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    id: 0,
    companyId: companyId || "",
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone: "",
    mobile_phone: "",
    role: null,
  });

  const handleChangeValue = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (companies.length === 0 || userGroups.length === 0) {
      fetchCompanys();
    }
  }, []);

  const handleValidation = async (values) => {
    const submitValues = {
      id: values.id,
      companyId: Number(values.companyId),
      name: values.name,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password,
      role: values.role,
      homePhone: removeMask(values.phone),
      mobilePhone: removeMask(values.mobile_phone),
    };

    const schema = isCreate ? validationCreate : validationEdit;

    schema
      .validate(values, { abortEarly: false })
      .then(async () => handleSubmit(submitValues))
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
    openBackdrop(false);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Registrando" : "Editando"}  usuário`);
    const { companyId, name, email, homePhone, mobilePhone, role } = values;
    try {
      if (isCreate) {
        await userUseCases.register(values);
      } else {
        await userUseCases.updateUser(data.id, {
          companyId,
          name,
          email,
          homePhone,
          mobilePhone,
          role,
        });
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Usuário ${isCreate ? "registrado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.data || error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    if (data?.id) {
      setValues(() => {
        return {
          id: data.id,
          name: data.name,
          email: data.email,
          role: data.role,
          companyId: data.companyId,
          password: "********************",
          confirm_password: "********************",
          phone: data.homePhone,
          mobile_phone: data.mobilePhone,
        };
      });
    }
  }, [data]);

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Registrar" : "Editar"} usuário`}
        handleBack={onCloseForm}
        fontSize={26}
      />
      {values && (
        <Form
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          values={values}
          editing={data?.id}
          companies={companies}
          userGroups={userGroups}
          errors={errors}
        />
      )}
    </>
  );
}

export default RegisterEdit;
