export const tableStyles = {
  container: {
    overflow: "hidden",
    borderRadius: "8px",
  },
  headCell: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 14,
    position: "relative",
    "&:not(:first-of-type)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "2px",
      backgroundColor: "#142F68",
    },
  },
  row: {
    "&:hover": {
      backgroundColor: "#C9C9C9",
    },
  },
  cell: {
    borderBottom: "4px solid #F2F2F2",
    fontFamily: "Montserrat ",
    fontSize: 14,
    padding: "6px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
  cellCollapse: {
    paddingBlock: "0px",
    backgroundColor: "#F2F2F2",
  },
  cellError: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
    height: "45px",
  },
  pagination: {
    background: "#F2F2F2",
    paddingRight: 8,
    borderRadius: "0px 0px 8px 8px",
    ".MuiTablePagination-selectLabel ": {
      fontFamily: "Montserrat ",
      margin: 0,
    },
    ".MuiTablePagination-displayedRows": {
      fontFamily: "Montserrat ",
      margin: 0,
    },
  },
  search: {
    box: {
      width: "min(300px, 90%)",
      boder: "none",
      "@media (min-width: 600px)": {
        marginLeft: "auto",
      },
    },
    input: {
      width: "100%",
      height: "40px",
      fontSize: 14,
      fontFamily: "Montserrat",
      background: "#E6E6E6",
      borderRadius: "8px",
      border: "none",
      padding: "0px 10px",
      "& input::placeholder": {
        color: "gray",
        opacity: 1,
      },
    },
    icon: { color: "gray" },
  },
  addIcon: {
    backgroundColor: "#1341A1",
    padding: "2px",
    borderRadius: "12px",
  },
};
