import React, { useState, useContext } from "react";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";

export const GenerateReportModal = ({ answer, onSubmit }) => {
  const { openDialogBox } = useContext(DialogContext);

  const [submitError, setSubmitError] = useState();
  const [values, setValues] = useState(answer[0]);

  const handleSubmit = async () => {
    const reportData = { ...values };
    Object.keys(values).forEach((key) => {
      if (!values[key] && reportData[key]) {
        reportData[key] = values[key];
      }
    });
    try {
      await onSubmit(reportData);
    } catch (err) {
      setSubmitError(err.message);
    } finally {
      openDialogBox({ ...openDialogBox, open: false });
    }
  };

  const mappedAnswer = answer.map((item) => {
    return [
      {
        title: item.inss?.title,
        type: "inss",
        isDisabled: !item.inss?.content,
      },
      {
        title: item.irrf?.title,
        type: "irrf",
        isDisabled: !item.irrf?.content,
      },
      {
        title: item.csll?.title,
        type: "csll",
        isDisabled: !item.csll?.content,
      },
      {
        title: item.iss?.title,
        type: "iss",
        isDisabled: !item.iss?.content,
      },
    ];
  });

  const isDisabled = (values) => !Object.values(values).some((value) => value);

  return (
    <Box>
      <Card
        title="Gerar relatório"
        loading={!answer}
        border={false}
        handleClose={() => openDialogBox({ ...openDialogBox, open: false })}
      >
        {submitError ? (
          <Typography sx={styles.title}>{submitError}</Typography>
        ) : (
          <>
            <Typography sx={styles.title}>Selecione os tributos para a geração do PDF</Typography>
            <Box sx={styles.checkboxesWrapper}>
              {mappedAnswer.length ? (
                mappedAnswer.flat().map((categoryAnswer) => (
                  <FormControlLabel
                    disabled={categoryAnswer.isDisabled}
                    key={categoryAnswer.type}
                    control={
                      <Checkbox
                        sx={styles.checkbox}
                        name={categoryAnswer.type}
                        checked={values[categoryAnswer.type]}
                        onChange={() => {
                          setValues((prev) => ({
                            ...prev,
                            [categoryAnswer.type]: !values[categoryAnswer.type],
                          }));
                        }}
                      />
                    }
                    label={
                      categoryAnswer.title
                        ? categoryAnswer.title
                        : categoryAnswer.type.toUpperCase()
                    }
                  />
                ))
              ) : (
                <Typography sx={styles.warning}>Não há dados para gerar o relatório</Typography>
              )}
            </Box>
            {answer.length ? (
              <Button
                sx={styles.button}
                variant="contained"
                disabled={isDisabled(values)}
                onClick={handleSubmit}
              >
                {answer.length && isDisabled(values) ? "Selecione um Tributo" : "Gerar Relatório"}
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </Card>
    </Box>
  );
};

const styles = {
  title: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: "max(1.4vw, 20px)",
    fontWeight: "normal",
    marginBottom: "16px",
  },
  warning: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: "max(1.4vw, 16px)",
    fontWeight: "normal",
    marginBottom: "16px",
  },
  checkbox: {
    root: {
      color: "#1341a1",
    },
    checked: {
      color: "#1341a1",
    },
  },
  checkboxesWrapper: {
    display: "flex",
    flexFlow: "column",
  },
  button: {
    backgroundColor: "#1341a1",
    size: "small",
    root: {
      alignSelf: "center",
    },
  },
};
