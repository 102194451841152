import React, { useContext, useEffect, useState } from "react";
import { PlanForm } from "./Form";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formatRealStringToNumber } from "~/presentation/utils/formatCurrency";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";
import { makePlanUseCases } from "~/main/factories/use-cases/plan";

const initialValues = {
  id: 0,
  name: "",
  isAllowReinf: false,
  isConsultISS: true,
  isGenerateReport: true,
  consultLimit: null,
  taxInformationNumber: null,
  numberOfUsers: null,
  isCalculationSimulator: true,
  isSpecificSubstitutionAnalysis: true,
  totalSubstituteOrDismissed: "",
  isCustom: true,
  valor: null,
  isAllowTaxInformation: true,
};

const RegisterEdit = ({ data, onCloseForm, refreshData }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const planUseCases = makePlanUseCases();
  const isCreate = !data;

  //input value and errors
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (data?.id) {
      setValues(() => {
        return {
          id: data.id,
          name: data.name,
          isAllowReinf: data.isAllowReinf,
          isConsultISS: data.isConsultISS,
          isGenerateReport: data.isGenerateReport,
          isAllowTaxInformation: data.isAllowTaxInformation,
          consultLimit: data.consultLimit,
          taxInformationNumber: data.taxInformationNumber,
          numberOfUsers: data.numberOfUsers,
          isCalculationSimulator: data.isCalculationSimulator,
          isSpecificSubstitutionAnalysis: data.isSpecificSubstitutionAnalysis,
          isCustom: data.isCustom,
          valor: data.valor,
        };
      });
    }
  }, [data]);

  const handleValidation = (values) => {
    const submitValues = {
      ...values,
      taxInformationNumber: values?.isAllowTaxInformation ? values?.taxInformationNumber : 0,
      valor: values.valor ? formatRealStringToNumber(values.valor) : 0,
    };

    validationSchema
      .validate(submitValues, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(submitValues);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} plano`);
    try {
      if (isCreate) {
        await planUseCases.createPlan(values);
      } else {
        await planUseCases.updatePlan(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Plano ${isCreate ? "cadastrado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro. ${error?.data || error?.response?.data}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };
  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Cadastrar" : "Editar"} Plano`}
        handleBack={onCloseForm}
        titleBack="Voltar para Planos"
      />
      {values && (
        <PlanForm
          handleSubmit={handleValidation}
          values={values}
          onChangeValue={handleChangeValue}
          errors={errors}
        />
      )}
    </>
  );
};

export default RegisterEdit;
