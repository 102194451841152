import React, { useState } from "react";
import TextEditor from "~/presentation/components/TextEditor";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import { Box, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import CreateEdit from "../../../TypeAnswer/CreateEdit/CreateEdit";
import Grid from "@mui/material/Grid2";

const ItemAnswerForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  typesAnswer = [],
  getTypesAnswer,
}) => {
  //MODAL criar tipo resposta
  const [openTypeAnswer, setOpenTypeAnswer] = useState(false);
  const handleOpenTypeAnswer = () => setOpenTypeAnswer(true);
  const handleCloseTypeAnswer = () => setOpenTypeAnswer(false);

  return (
    <>
      {" "}
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Item de Resposta</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, lg: 6 }}>
            <Input
              type="text"
              name="title"
              title="Título"
              value={values.title}
              onChange={(e) => onChangeValue("title", e.target.value)}
              error={errors.title}
            />
          </Grid>
          <Grid size={{ xs: 12, lg: 6 }}>
            <Input
              type="select"
              name="gtAnswerItemType"
              title="Tipo de Resposta"
              value={typesAnswer.find((item) => item.id === values.gtAnswerItemType.id)}
              onChange={(e) => onChangeValue("gtAnswerItemType", { id: e.id, title: e.title })}
              error={errors.gtAnswerItemType}
              options={typesAnswer}
              getOptionLabel={(option) => option.title}
              icon={{
                title: "Criar tipo de resposta",
                func: () => handleOpenTypeAnswer(),
              }}
            />
            <ModalBox
              open={openTypeAnswer}
              onClose={handleCloseTypeAnswer}
              bg="white"
              maxWidth={700}
            >
              <CreateEdit
                data={null}
                onCloseForm={handleCloseTypeAnswer}
                refreshData={getTypesAnswer}
              />
            </ModalBox>
          </Grid>

          <Grid size={12}>
            <Typography sx={{ ...formStyles.subtitle, color: errors.text && "#d32f2f" }}>
              Texto
            </Typography>
            <TextEditor
              initialValue={values.text}
              name="text"
              height={400}
              handleEditorChange={(content) => {
                onChangeValue("text", content);
              }}
              error={errors.text}
            />
            {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default ItemAnswerForm;
