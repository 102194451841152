import React, { useState } from "react";
import Input from "~/presentation/components/Common/Input";
import TextEditor from "~/presentation/components/TextEditor";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";
import { Box, Typography } from "@mui/material";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import CreateEdit from "../../../ExplanatoryCategory/CreateEdit/CreateEdit";
import PageListHeader from "../../../components/PageListHeader";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import ListComponent from "~/presentation/components/ListComponent/ListComponent";
import Grid from "@mui/material/Grid2";

const ExplanatoryVideoForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  getCategories,
  categories,
}) => {
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const handleOpenModalCategory = () => setOpenModalCategory(true);
  const handleCloseModalCategory = () => {
    setOpenModalCategory(false);
  };

  //MODAL Lista de explanatoryVideoCategories
  const [openList, setOpenList] = useState(false);
  const handleOpenList = () => setOpenList(true);
  const handleCloseList = () => setOpenList(false);

  const handleDeleteSubstitute = (params) => {
    if (values.explanatoryVideoCategories.length === 1) {
      onChangeValue("explanatoryVideoCategories", []);
    } else {
      const filtedValue = values?.explanatoryVideoCategories?.filter(
        (item) => item.id !== params.row.id
      );
      onChangeValue("explanatoryVideoCategories", filtedValue);
    }
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Informações</Typography>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 7, md: 8, lg: 9 }}>
            <Input
              type="text"
              onChange={(e) => onChangeValue("title", e.target.value)}
              value={values.title}
              name="title"
              title="Título"
              error={errors.title}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 5, md: 4, lg: 3 }}>
            <Input
              type="date"
              getHours
              onChange={(value) => onChangeValue("publishDate", value)}
              value={values.publishDate}
              name="publishDate"
              title="Data da publicação "
              error={errors.publishDate}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={formStyles.container}>
        <PageListHeader
          title="Categorias"
          titleAdd="Adicionar categoria"
          handleAdd={handleOpenList}
          fontSize={22}
        />
        <Table
          tableOptions={false}
          columns={[
            { field: "title", headerName: "Título", flex: 2 },
            { field: "description", headerName: "Descrição", flex: 5 },
            {
              field: "actions",
              headerName: "Ações",
              sortable: false,
              filterable: false,
              align: "center",
              flex: 1,
              renderCell: (params) => (
                <TableActions handleDelete={() => handleDeleteSubstitute(params)} />
              ),
            },
          ]}
          data={values?.explanatoryVideoCategories}
        />
        <ModalBox open={openList} onClose={handleCloseList} bg="white" maxWidth={600}>
          <ListComponent
            data={categories}
            checkedData={values?.explanatoryVideoCategories}
            title="Categorias"
            onClose={handleCloseList}
            handleAdd={(values) => onChangeValue("explanatoryVideoCategories", values)}
            disablePadding={false}
            handleCreate={handleOpenModalCategory}
            titleAdd="Criar nova categoria"
          />
        </ModalBox>
        <ModalBox open={openModalCategory} onClose={handleCloseModalCategory} bg="white">
          <CreateEdit
            data={null}
            refreshData={getCategories}
            onCloseForm={handleCloseModalCategory}
          />
        </ModalBox>
      </Box>
      <Box sx={formStyles.container}>
        <Typography sx={{ ...formStyles.title, color: errors.text && "#d32f2f" }}>
          Conteúdo do tutorial
        </Typography>
        <TextEditor
          initialValue={values.text}
          name="text"
          height={400}
          handleEditorChange={(content) => {
            onChangeValue("text", content);
          }}
          error={errors.text}
        />
        {errors.text && <Typography sx={formStyles.errorMsg}>{errors.text}</Typography>}
      </Box>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default ExplanatoryVideoForm;
