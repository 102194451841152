import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { scrollToElement } from "../functions/scrollToElement";
import useMobile from "./useMobile";

export const usePaginatedState = ({ getData, itemsPerPage }) => {
  const scrollToRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobile] = useMobile(960);
  const [numberOfPages, setNumberOfPages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState();

  const query = new URLSearchParams(location.search);
  const filter = query.get("filter");
  const categoryId = query.get("categoryId");
  const search = query.get("search") ? decodeURIComponent(query.get("search")) : "";
  const queryPage = query.get("page");
  const page = queryPage || 1;
  const isSummaryOnly = query.get("isSummaryOnly") || false;

  const fetchData = async () => {
    scrollToContent();
    setIsLoading(true);
    try {
      const response = await getData({
        categoryId: categoryId ? categoryId : filter,
        text: search,
        pageIndex: page,
        limit: itemsPerPage,
        IsSummaryOnly: isSummaryOnly,
      });
      const { contents, totalPages, multimidias } = response;
      if (response) {
        let items;
        if (Array.isArray(contents)) {
          items = contents;
        } else if (Array.isArray(contents?.posts)) {
          items = contents.posts;
        } else if (Array.isArray(multimidias)) {
          items = multimidias;
        } else if (Array.isArray(response?.posts)) {
          items = response?.posts;
        } else {
          items = response;
        }

        setContent(items);
        setNumberOfPages(totalPages || 1);

        //⇣⇣⇣ este erro esta a colocar as páginas erradas, porque esta a filtrar o que ja vem filtrado do back.

        // let quantityPages = totalPages || Math.ceil(items.length / itemsPerPage);
        // if (filter || search) {
        //   const filteredContent = items?.filter((item) => {
        //     if (filter) {
        //       return item?.category?.id === parseInt(filter);
        //     }
        //     if (search) {
        //       const searchLower = search.toLowerCase();
        //       // if (filterByTitleAndSummary) {
        //       //   return (
        //       //     item?.title?.toLowerCase().includes(searchLower) ||
        //       //     item?.summary?.toLowerCase().includes(searchLower)
        //       //   );
        //       // }
        //       return (
        //         item?.title?.toLowerCase().includes(searchLower) ||
        //         item?.text?.toLowerCase().includes(searchLower) ||
        //         item?.label?.toLowerCase().includes(searchLower) ||
        //         item?.description?.toLowerCase().includes(searchLower)
        //       );
        //     }
        //   });
        //   setContent(filteredContent);
        //   setNumberOfPages(quantityPages);
        // } else {
        // setContent(items);
        // setNumberOfPages(totalPages);
        // }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToContent = () => {
    if (scrollToRef.current && (filter || search || queryPage || categoryId)) {
      scrollToElement(scrollToRef.current, -75, mobile);
    }
  };

  const handleFilter = (nextFilter) => {
    handlePath(1, search, nextFilter, categoryId);
  };

  const handleFilterBycategory = (nextCategoryId) => {
    handlePath(1, search, filter, nextCategoryId);
  };

  const handleSearch = (nextSearch) => {
    handlePath(1, nextSearch, filter, categoryId);
  };

  const handlePage = (nextPage) => {
    handlePath(nextPage, search, filter, categoryId);
  };

  const clearSearch = () => {
    handlePath(1, "", "", "");
  };

  const clearCategory = () => {
    handlePath(1, search, "", "");
  };

  const handlePath = (nextPage, nextSearch, nextFilter, nextCategoryId) => {
    const url = location.pathname;
    const filterStr = nextCategoryId
      ? "categoryId=" + nextCategoryId
      : "filter=" + (nextFilter ? nextFilter : "");
    const searchStr = "&search=" + (nextSearch ? encodeURIComponent(nextSearch) : "");
    const pageStr = "&page=" + (nextPage ? nextPage : "");
    const encodedUrl = encodeURI(url + "?" + filterStr + searchStr + pageStr);
    navigate(encodedUrl);
  };

  useEffect(() => {
    fetchData();
  }, [page, search, filter, categoryId]);

  return {
    handleFilter,
    handleSearch,
    handlePage,
    numberOfPages,
    isLoading,
    content,
    setContent,
    filter,
    search,
    page,
    scrollToRef,
    clearSearch,
    handleFilterBycategory,
    clearCategory,
  };
};
