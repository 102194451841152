import React from "react";
import { Box, Pagination, Skeleton, Typography } from "@mui/material";
import { Inbox } from "@mui/icons-material";

const CardsList = ({
  content,
  numberOfPages,
  goToPage,
  goToPost,
  page,
  dataLoaded,
  notFoundMessage,
  cardComponent: CardComponent,
  defaultImage,
}) => {
  const skeletonArray = Array.from({ length: 7 });
  return (
    <Box sx={{ minHeight: "80vh", height: "auto", width: "100%", padding: 0, margin: 0 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 0",
          justifyContent: "center",
          gridRowGap: "max(2vw, 16px)",
        }}
      >
        {!dataLoaded ? (
          skeletonArray.map((_, index) => <Skeleton key={index} variant="rounded" height={160} />)
        ) : content?.length > 0 ? (
          [
            content.map((c) => (
              <CardComponent
                item={c}
                key={`content-card-${c.id}`}
                imageSrc={c.image || defaultImage}
                // title={limitTextSize(c.title, 40)}
                onClick={() => goToPost(c.id)}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultImage;
                }}
              />
            )),
            <Pagination
              sx={styles.pagination}
              key="pagination"
              size="small"
              count={numberOfPages}
              onChange={(_, selectedPage) => {
                goToPage(selectedPage);
              }}
              page={Number(page)}
            />,
          ]
        ) : (
          <Box sx={styles.notFound}>
            <Inbox sx={{ color: "#1341a1" }} />
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: 400,
                fontFamily: "Montserrat",
              }}
            >
              {notFoundMessage}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CardsList;

const styles = {
  pagination: {
    justifySelf: "center",
    width: "fit-content",
    margin: "0 auto 2rem auto",

    "@media screen and (min-width: 960px)": {
      gridColumn: "1/4",
    },

    "&.MuiPagination-root": {
      display: "flex",
      alignItems: "center",

      ".MuiPaginationItem-root": {
        "&.Mui-selected": {
          fontSize: "1rem",
        },
        "&:hover": {
          backgroundColor: "#CDCDCD",
        },
      },
    },
  },
  notFound: {
    justifySelf: "center",
    display: "flex",
    alignItems: "center",
    fontSize: "max(18px, 1.4vw)",
    gap: "10px",
    marginTop: "max(36px, 5vw)",
    padding: "max(80px, 8vw) 0",
    flexFlow: "column",
    textAlign: "center",

    "@media screen and (min-width: 768px)": {
      flexFlow: "row",
    },

    "@media screen and (min-width: 960px)": {
      gridColumn: "1/4",
    },
  },
};
