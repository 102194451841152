import React from "react";
import ContentCard from "~/presentation/views/Blog/components/ContentCard";
import Grid from "@mui/material/Grid2";

const LastPosts = ({ lastPosts, defaultImage, goToPost }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        maxWidth: { xs: "100%", md: 1440 },
        marginBlock: 2,
      }}
    >
      {lastPosts.map((post) => (
        <ContentCard
          key={post.id}
          imgSrc={post.image}
          title={post.title}
          author={post.author}
          autor={post.autor}
          date={post.publishDate}
          numberOfComments={post.numberOfComments}
          tags={post.tags}
          text={post.text}
          onClick={() => goToPost(post.id)}
          defaultImage={defaultImage}
          size={4}
          imageHeight={200}
        />
      ))}
    </Grid>
  );
};

export default LastPosts;
