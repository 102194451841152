import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

export const Card = ({ children, title, loading, border = true, handleClose }) => {
  return (
    <Box
      sx={{
        border: border ? "solid 1px #dedede" : "none",
        borderRadius: "10px",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            padding: "1rem",
            background: "#1341a1",
            color: "#fafafa",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              borderLeft: "solid 4px #ff6829",
              paddingLeft: "8px",
              fontFamily: "Montserrat, sans-serif",
            }}
            variant="subtitle1"
          >
            {title}
          </Typography>
        </Box>
        {handleClose && (
          <Button style={{ backgroundColor: "#1341a1", borderRadius: 0 }} onClick={handleClose}>
            <CloseIcon style={{ color: "#fff" }} />
          </Button>
        )}
      </Box>
      <Box
        sx={{
          padding: "1.5rem",
          position: "relative",
        }}
      >
        {loading ? (
          <Box className="absolute-center">
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};
