import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import AnnotationForm from "./Form";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../components/PageListHeader";

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  annotationUseCases,
  fetchTerms,
  fetchCnaes,
  cnaes,
  terms,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    id: 0,
    name: "",
    text: "",
    cnaes: [],
    terms: [],
  });

  useEffect(() => {
    if (cnaes.length === 0) {
      fetchCnaes();
    }
    if (terms.length === 0) {
      fetchTerms();
    }
  }, []);

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    const fetch = async () => {
      openBackdrop(true, "Carregando informação");
      const result = await annotationUseCases.getAnnotation(data.id);
      setValues(result.data);
      openBackdrop(false);
    };
    if (data?.id) {
      fetch();
    }
  }, [data]);

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} anotação`);

    const submitValues = {
      id: values.id,
      name: values.name,
      text: values.text,
      cnaes:
        values.cnaes?.map((item) => {
          let cnae = {
            id: item.id,
            code: item.code,
            description: item.description,
            serviceCodes: item.serviceCodes,
          };
          return cnae;
        }) || null,
      terms:
        values.terms?.map((term) => {
          return {
            id: term.id,
            term: term.term,
            annotation: term.annotation || "",
            synonym: term.synonym || "",
          };
        }) || null,
    };

    try {
      if (isCreate) {
        await annotationUseCases.createAnnotation(submitValues);
      } else {
        await annotationUseCases.updateAnnotation(submitValues);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Anotação ${isCreate ? "criada" : "editada"}.`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.data || error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Criar" : "Editar"} Anotação`}
        handleBack={onCloseForm}
      />
      {values && (
        <AnnotationForm
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
          cnaes={cnaes}
          terms={terms}
          fetchTerms={fetchTerms}
        />
      )}
    </>
  );
}

export default CreateEdit;
