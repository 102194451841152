import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

export const Container = styled("div")(({ open }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(1480px, 90%)",
  borderRadius: "24px",
  display: open ? "block" : "none",
  zIndex: 4,
  boxShadow: 24,
  scrollBehavior: "smooth",
  maxHeight: "90vh",
  overflowY: "auto",
  "& > div.background": {
    background: "#14213d",
    "& .MuiSvgIcon-root": {
      color: "#fafafa",
    },
  },
}));

export const CloseBtn = styled(IconButton)({
  color: "#fafafa",
  position: "absolute",
  zIndex: 5,
  right: "1%",
  top: "1%",
});

export const OverlayContainerModal = styled("div")(({ open }) => ({
  transition: "all 0.5s ease",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100svw",
  height: "100svh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9998,
  opacity: open ? 1 : 0,
  visibility: open ? "visible" : "hidden",
  overflow: "hidden",
}));
