import React, { useState } from "react";
import validation from "./schema";
import { Box, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import ButtonCommon from "~/presentation/components/Common/Button";

const FormConfirmPassword = ({ handleClose, handleConfirm }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState();

  const onSubmit = () => {
    validation
      .validate(value, { abortEarly: false })
      .then(async () => {
        handleConfirm(value);
        handleClose();
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.subtitle}>Confirme sua senha para alterar o usuário</Typography>
        <Input
          type="password"
          placeholder="Ensira a senha"
          title=""
          onChange={(e) => setValue(e.target.value)}
          value={value}
          error={error}
        />
      </Box>
      <Box sx={formStyles.doubleButtonContainer}>
        <ButtonCommon
          sx={{ ...formStyles.button, width: "min(150px, 90%)" }}
          color="secondary"
          onClick={handleClose}
        >
          Cancelar
        </ButtonCommon>
        <ButtonCommon sx={{ ...formStyles.button, width: "min(150px, 100%)" }} onClick={onSubmit}>
          Confirmar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default FormConfirmPassword;
