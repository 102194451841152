import React, { useContext, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import removeMask from "~/presentation/functions/removeMask";
import { Container, Typography as Text, Checkbox, Tooltip, FormControlLabel } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import Grid from "@mui/material/Grid2";
export const EditCertificate = ({ handleClose, data, refreshData, setCertInfo }) => {
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { openDialogBox } = useContext(DialogContext);
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

  const [certificate, setCertificate] = useState({
    id: data.id,
    name: data?.name || "",
    ownerName: data?.ownerName || "",
    password: data?.password || "",
    confirmPassword: data?.confirmPassword || "",
    serialNumber: data?.serialNumber || "",
    companyId: companyId,
    cpfCnpj: data?.cpfCnpj || "",
    isDefault: data?.isDefault || false,
    expirationDate: data?.expirationDate || null,
  });

  const [isChecked, setIsChecked] = useState(false);
  const [isPerson, setIsPerson] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCertificate((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (certificate) => {
    openBackdrop(true, "Atualizando certificado");
    const updateData = {
      id: certificate.id,
      name: certificate.name,
      ownerName: certificate.ownerName,
      password: certificate.password,
      serialNumber: certificate.serialNumber,
      companyId: certificate.companyId,
      cpfCnpj: certificate.cpfCnpj,
      isDefault: certificate.isDefault,
    };
    try {
      const response = await apiDigitalCert.updateDigitalCert(updateData);
      if (response) {
        setFeedbackSnackbar({
          isOpen: true,
          message: "Certificado atualizado com sucesso!",
          type: "success",
        });
        handleClose();
        refreshData();
      } else {
        throw new Error("Erro ao atualizar o certificado");
      }
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar certificado: " + err.response.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleDelete = async () => {
    openDialogBox({
      message: `Deseja mesmo excluir o certificado?`,
      callback: async () => {
        try {
          openBackdrop(true, "Excluindo certificado");
          await apiDigitalCert.deleteDigitalCert(certificate.id);
          setFeedbackSnackbar({
            isOpen: true,
            message: "Certificado deletado com sucesso!",
            type: "success",
          });
          //eliminar da tabela
          setCertInfo((prev) => prev.filter((item) => item.id !== certificate.id));
          handleClose();
        } catch (error) {
          setFeedbackSnackbar({
            isOpen: true,
            message: "Erro ao deletar certificado " + error.message,
            type: "error",
          });
        } finally {
          openBackdrop(false);
        }
      },
    });
  };

  const handleDownload = async () => {
    try {
      const response = await apiDigitalCert.downloadDigitalCert(certificate.id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `certificado_${certificate.id}.pfx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Falha no download do certificado:", error);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setCertificate((prev) => ({ ...prev, cpfCnpj: "" }));
    }
  };

  const handleIsPerson = () => {
    setIsPerson(!isPerson);
  };

  const senhaErrada = certificate?.password !== certificate?.confirmPassword;
  const senhaErroMsg = senhaErrada && "As senhas devem ser iguais";
  const errorMsg = "Campo Obrigatório";

  return (
    <Container style={styles.Form}>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 8 }}>
          <Text style={styles.Title}>Detalhes do certificado</Text>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <RButton startIcon={<DownloadIcon />} variant="outlined" onClick={handleDownload}>
            Download
          </RButton>
        </Grid>
        <Grid size={12}>
          <Input
            title="Nome"
            name="name"
            type="text"
            placeholder="Nome do certificado"
            value={certificate.name}
            onChange={handleChange}
            error={!certificate.name && errorMsg}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            title="Nome do proprietário"
            name="ownerName"
            type="text"
            placeholder="Proprietário do certificado"
            value={certificate.ownerName}
            onChange={handleChange}
            error={!certificate.ownerName && errorMsg}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            title="Data de validade"
            name="Data de validade"
            type="text"
            placeholder="Data de validade"
            value={new Date(certificate.expirationDate).toLocaleDateString("pt-BR")}
            onChange={handleChange}
            disabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            title="Senha"
            name="password"
            type="password"
            placeholder="Senha"
            value={certificate.password}
            onChange={handleChange}
            error={!certificate.password && errorMsg}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            title="Confirmar Senha"
            name="confirmPassword"
            type="password"
            placeholder="Confirmar senha"
            value={certificate.confirmPassword}
            onChange={handleChange}
            error={(!certificate.confirmPassword && errorMsg) || senhaErroMsg}
          />
        </Grid>
        <Grid size={12}>
          <Tooltip
            title="Marque se o certificado pertence a um CPF/CNPJ procurador que não seja o da sua empresa"
            placement="top"
          >
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
              label={
                <Text sx={{ fontSize: "14px" }}>Meu certificado pertence a um procurador</Text>
              }
            />
          </Tooltip>
          {isChecked && (
            <>
              <FormControlLabel
                control={<Checkbox checked={isPerson} onChange={handleIsPerson} />}
                label={<Text sx={{ fontSize: "14px" }}>Procurador pessoa física</Text>}
              />
              <Input
                type="mask"
                title="CPF/CNPJ do proprietário terceiro"
                mask={isPerson ? "000.000.000-00" : "00.000.000/0000-00"}
                name="cpfCnpj"
                value={certificate.cpfCnpj}
                onChange={(value) => setCertificate((prev) => ({ ...prev, cpfCnpj: value }))}
              />
            </>
          )}
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }} sx={styles.ButtonsContainer}>
          <RButton
            type="button"
            variant="outlined"
            color="error"
            startIcon={<DeleteOutlineIcon />}
            onClick={handleDelete}
            sx={{
              borderColor: "red",
              marginRight: "auto",
            }}
          >
            Deletar
          </RButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }} sx={styles.ButtonsContainer}>
          <RButton type="button" variant="outlined" onClick={handleClose}>
            Cancelar
          </RButton>
          <RButton
            disabled={
              Object.entries(certificate)
                .filter(([key]) => key !== "cpfCnpj") // exceto cpfCnpj
                .some(([, value]) => value === "" || value === null) || senhaErrada
            }
            type="submit"
            onClick={() => {
              handleSubmit(certificate);
            }}
          >
            Atualizar
          </RButton>
        </Grid>
      </Grid>
    </Container>
  );
};

const styles = {
  Form: {
    padding: "2rem",
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  ButtonsContainer: {
    display: "flex",
    gap: "10px",
    fontFamily: "Montserrat",
    marginTop: 2,
  },
};
