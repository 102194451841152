import React from "react";
import { Backdrop, CircularProgress, styled } from "@mui/material";

const BackdropComponent = styled(Backdrop)({
  zIndex: 1301,
  color: "#fff",

  ".content": {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },

  ".circular-progress": {
    marginBottom: "20px",
  },
});

export default function SimpleBackdrop({ open, message = "", openBackdrop }) {
  const handleClose = () => {
    openBackdrop(false);
  };

  return (
    <BackdropComponent className={"root"} open={open} onClick={handleClose}>
      <div className={"content"}>
        <CircularProgress className={"circular-progress"} color="inherit" />
        <p>{message}...</p>
      </div>
    </BackdropComponent>
  );
}
