import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  LinearProgress,
  Checkbox,
} from "@mui/material";
import { format } from "date-fns";
import parse from "html-react-parser";

const MessageInfoList = ({
  messages,
  selectedMessages,
  handleSelectMessage,
  disableGutters, // if true, disable the padding of ListItem components (used to free space on mobile views)
  onClick,
  rightSmall = "date",
  noMessagesText = "Não há mensagens",
}) => {
  const isMessageSelected = (messageId) =>
    selectedMessages.map((msg) => msg.id).includes(messageId);

  return (
    <Box sx={{ paddingInline: 3, paddingBlock: 2 }}>
      <List disablePadding={disableGutters}>
        {!messages ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <LinearProgress sx={{ width: 500, height: 8, borderRadius: 5 }} />
          </Box>
        ) : !messages.length ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
              width: "100%",
            }}
          >
            {noMessagesText}
          </Box>
        ) : (
          messages.map((message) => (
            <ListItem
              key={message.id}
              disableGutters={disableGutters}
              button
              onClick={() => onClick(message)}
            >
              <Checkbox
                edge="start"
                checked={isMessageSelected(message.id)}
                tabIndex={-1}
                disableRipple
                onClick={() => handleSelectMessage(message)}
                inputProps={{ "aria-labelledby": `checkbox-list-label-${message.id}` }}
              />
              <ListItemText
                primary={parse(message.subject)}
                secondary={`${message.email} - ${format(
                  new Date(message.publishDate),
                  "dd/MM/yyyy"
                )}`}
              />
              <Box
                component="span"
                sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", ml: 2 }}
              >
                <Typography variant="caption">{message[rightSmall]}</Typography>
              </Box>
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );
};

export default MessageInfoList;
