import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import {AnswerFilters} from "~/presentation/views/Admin/components/CustomToolbar/AnswerFilters";
//functions
//utils
import typeTax from "~/presentation/utils/typeTax";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";
import PageListHeader from "../components/PageListHeader";

const Answer = ({
  answerUseCases,
  itemAnswerUseCases,
  companyUseCases,
  cnaesUseCases,
  termUseCases,
}) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [answers, setAnswers] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});

  //Data Select
  const [cnaes, setCnaes] = useState([]);
  const [itemsAnswer, setItemsAnswer] = useState([]);
  const [categories, setCategories] = useState([]);
  const [terms, setTerms] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null, isCopy = false) => {
    setOpenForm(true);
    if (isCopy) {
      setFormProps({ ...data, isCopy: true });
    } else {
      setFormProps(data);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async (filters) => {
    openBackdrop(true, "Carregando dados da tabela");
    const { data = [] } = await answerUseCases.listAnswersSummary(filters);
    setAnswers(data);
    openBackdrop(false);
  };

  const columns = [
    {
      field: "gtAnswerName",
      headerName: "Nome",
      align: "center",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 4,
    },
    {
      field: "typeTax",
      headerName: "Taxa",
      align: "center",
      flex: 1,
      renderCell: (params) => typeTax?.find((item) => item.value === params.value).label,
    },
    {
      field: "isSimple",
      headerName: "Simples Nacional",
      renderCell: (params) => (params.value === true ? "Sim" : "Não"),
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
            handleCopy={() => handleOpenForm(params.row, true)}
          />
        ) : (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleCopy={() => handleOpenForm(params.row, true)}
          />
        ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo resposta");
    try {
      await answerUseCases.deleteAnswer(id);
      setAnswers((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir resposta: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData({ itemContent: "" });
  }, []);

  const fetchTerms = async () => {
    openBackdrop(true, "Carregando termos de pesquisa");
    const terms = await termUseCases.getAll();
    setTerms(terms);
    openBackdrop(false);
  };

  const fetchCNAE = async () => {
    openBackdrop(true, "Carregando cnae");
    const { data } = await cnaesUseCases.listCnaes();
    setCnaes(data);
    openBackdrop(false);
  };

  const fetchItemAnswer = async () => {
    openBackdrop(true, "Carregando items de resposta");
    const apiTypeAnswers = await itemAnswerUseCases.getAll();
    setItemsAnswer(apiTypeAnswers);
    openBackdrop(false);
  };

  const fetchCategories = async () => {
    openBackdrop(true, "Carregando categorias");
    const categories = await companyUseCases.getCompanyCategories();
    setCategories(categories);
    openBackdrop(false);
  };

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          fetchTerms={fetchTerms}
          fetchCNAE={fetchCNAE}
          fetchItemAnswer={fetchItemAnswer}
          fetchCategories={fetchCategories}
          cnaes={cnaes}
          itemsAnswer={itemsAnswer}
          categories={categories}
          terms={terms}
        />
      ) : (
        <>
          <PageListHeader title="Resposta" handleAdd={() => handleOpenForm()} />
          <Box sx={{ margin: 3 }}>
            <Table
              columns={columns}
              data={answers}
              customToolbarExtraComponents={[
                (index) => (
                  <AnswerFilters
                    key={index}
                    onFilter={fetchData}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                  />
                ),
              ]}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Answer;
