import React, { useState, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiExplanatoryVideoCategoryUseCases } from "~/data/usecases/explanatory-video-category/api-explanatory-video-category";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { parseISO, format } from "date-fns";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Container,
  Paper,
  Stack,
  Pagination,
  Skeleton,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

export const ExplanatoryVideos = () => {
  const postsUseCases = new ApiExplanatoryVideoCategoryUseCases(makeHttpClient());
  const [posts, setPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const postPerPage = 8;
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3; // Número de cards exibidos por vez

  const handleMouseEnter = (id) => {
    setHovered(id);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const handleChange = (isExpanded, panel) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getPosts = async (data) => {
    setLoading(true);
    const result = await postsUseCases.get(data);
    setPosts(result);
    setLoading(false);
  };

  useEffect(() => {
    getPosts();
  }, []);

  useEffect(() => {
    if (posts.length > 0) {
      setSelectedCategory(posts[0]); // Define a primeira categoria como selecionada
    }
  }, [posts]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + itemsPerPage, posts.length - itemsPerPage));
  };

  //lógica de páginação
  const handlePageChange = (event, value) => {
    setPage(value);
    setExpanded();
  };

  // lógica de filtro de pesquisa
  const filteredPosts = selectedCategory
    ? selectedCategory.explanatoryVideos.filter(
        (post) =>
          post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          post.text.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const startIndex = (page - 1) * postPerPage;
  const endIndex = startIndex + postPerPage;
  const currentPosts = filteredPosts.slice(startIndex, endIndex);

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#0d62a5",
              paddingBlock: 8,
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={0}>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Stack spacing={2} sx={{ textAlign: "left" }}>
                    <Typography variant="h1" sx={styles.h1}>
                      Central de Ajuda
                    </Typography>
                    <Typography variant="body1" sx={styles.paragraph}>
                      Aqui você encontrará respostas para as perguntas mais frequentes e guias
                      detalhados para solucionar qualquer problema que possa encontrar em nosso
                      sistema. Explore nossos artigos e descubra como tirar o máximo proveito de
                      nossas funcionalidades.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box sx={{ paddingBlock: 3, background: "#fff" }}>
            <Container maxWidth="lg">
              <Grid container spacing={4} alignItems="center">
                <Grid size={12}>
                  <Typography variant="h2" sx={styles.title}>
                    Selecionar categoria
                  </Typography>
                </Grid>
                <Grid size={{ xs: 1, sm: 0.5 }}>
                  <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Grid>
                <Grid size={{ xs: 10, sm: 11 }}>
                  <Grid container spacing={2} justifyContent="center">
                    {loading
                      ? Array.from(new Array(itemsPerPage)).map((_, index) => (
                          <Grid size={{ xs: 12, sm: 12 / itemsPerPage }} key={index}>
                            <Skeleton
                              variant="rectangular"
                              width={300}
                              height={"10rem"}
                              sx={{ borderRadius: 5 }}
                            />
                          </Grid>
                        ))
                      : posts
                          .slice(currentIndex, currentIndex + itemsPerPage)
                          .map((category, index) => (
                            <Grid size={{ xs: 12, sm: 12 / itemsPerPage }} key={index}>
                              <List
                                sx={{
                                  ...styles.textBox,

                                  transform: hovered === category.id ? "scale(1.05)" : "scale(1)",
                                  transition: "transform 0.3s ease-out",
                                  backgroundColor:
                                    selectedCategory === category
                                      ? "#0d62a5"
                                      : hovered === category.id
                                      ? "#ccc"
                                      : "#ededed",
                                }}
                                onMouseEnter={() => handleMouseEnter(category.id)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => {
                                  setSelectedCategory(category);
                                  setPage(1);
                                  setExpanded();
                                }}
                              >
                                <ListItem>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      color: selectedCategory === category ? "#fff" : "#000",
                                      ...styles.title,
                                      pb: 0.5,
                                    }}
                                    secondaryTypographyProps={{
                                      color: selectedCategory === category ? "#fff" : "#000",
                                    }}
                                    primary={category.title}
                                    secondary={category.description}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          ))}
                  </Grid>
                </Grid>
                <Grid size={{ xs: 1, sm: 0.5 }}>
                  <IconButton
                    onClick={handleNext}
                    disabled={currentIndex >= posts.length - itemsPerPage}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Grid>
                <Grid size={12}>
                  <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#E9E9E9",
                      paddingInline: "30px",
                      fontSize: "30px",
                      fontWeight: 500,
                      border: "none",
                      borderRadius: "20px",
                      height: 50,
                    }}
                  >
                    <InputBase
                      sx={{ flex: 1, color: "#000000" }}
                      placeholder="Pesquisar"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <SearchIcon />
                  </Paper>
                </Grid>
                <Grid size={12} sx={styles.accordionsContainer}>
                  {loading ? (
                    Array.from(new Array(8)).map((_, index) => (
                      <Box key={index} sx={styles.accordion}>
                        <Skeleton variant="rectangular" width="100%" height={50} />
                      </Box>
                    ))
                  ) : selectedCategory && selectedCategory.explanatoryVideos.length < 1 ? (
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Nenhum vídeo encontrado para esta categoria.
                    </Typography>
                  ) : selectedCategory ? (
                    currentPosts.map((item, index) => (
                      <Accordion
                        key={index}
                        sx={styles.accordion}
                        expanded={expanded === `panel${index + 1}`}
                        onChange={(event, isExpanded) =>
                          handleChange(isExpanded, `panel${index + 1}`)
                        }
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index + 1}-content`}
                          id={`panel${index + 1}-header`}
                        >
                          <Typography sx={{ ...styles.title, fontSize: 16, color: "#000" }}>
                            {item.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={styles.accordionDetails}>
                          <Grid container>
                            <Grid size={12}>
                              <Typography
                                sx={{ ...styles.description, textAlign: "right", fontSize: 14 }}
                              >
                                Última atualização -{" "}
                                {format(parseISO(item.publishDate), "dd/MM/yyyy")}
                              </Typography>
                            </Grid>
                            <Grid size={12}>
                              <Typography
                                sx={{
                                  ...styles.innerHTMLContentStyle,
                                }}
                                dangerouslySetInnerHTML={{ __html: item.text }}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : (
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Selecione uma categoria para ver os vídeos explicativos.
                    </Typography>
                  )}
                </Grid>
                <Grid size={12} alignItems="center">
                  <Stack spacing={2}>
                    <Pagination
                      count={
                        selectedCategory
                          ? Math.ceil(selectedCategory.explanatoryVideos.length / postPerPage)
                          : 0
                      }
                      page={page}
                      onChange={handlePageChange}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        "& .MuiPaginationItem-root": {
                          color: "#1341A1",
                          "&.Mui-selected": {
                            backgroundColor: "#1341A1",
                            color: "#fff",
                          },
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </HomeLayout>
  );
};

const styles = {
  h1: {
    fontSize: 40,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    paddingBottom: 2,
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 20,
    fontWeight: "bold",
  },
  innerHTMLContentStyle: {
    // estilos da descrição HTML
    "&& strong": {
      "font-weight": "bold",

      "& *": {
        "font-weight": "bold",
      },
    },
  },
  paragraph: { fontSize: 18, color: "#94BEEA" },
  textBox: {
    borderRadius: 5,
    height: "10rem",
    width: 300,
    p: 2,
    cursor: "pointer",
    overflow: "hidden",
  },
  description: { color: "#3A3A3A", fontSize: 15, textAlign: "left" },

  accordionsContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: 320,
    height: "100%",
  },

  accordion: {
    backgroundColor: "#fff",
    border: "1px solid #0d62a5",
    width: "100%",
    borderRadius: "15px !important",

    "&:before": {
      display: "none",
    },
    color: "#7F7F7F",
    marginBlock: 2,
  },
  accordionDetails: {
    overflow: "auto",
    paddingBlock: 2,
    paddingInline: 5,
    color: "#000",
  },
};
