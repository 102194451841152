import React from "react";
import { motion } from "framer-motion";

function Shake({ children }) {
  const variants = {
    shake: {
      x: [0, -2, 2, -2, 2, -2, 2, 0],
      transition: {
        x: { duration: 1 },
      },
    },
  };

  return (
    <motion.div variants={variants} animate="shake">
      {children}
    </motion.div>
  );
}

export default Shake;
