import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import CnaeForm from "./Form";
import { makeCnaesUseCases } from "~/main/factories/use-cases/cnaes";
import PageListHeader from "../../components/PageListHeader";
import validationSchema from "./Form/validationSchema";

const newObj = {
  id: 0,
  code: "",
  description: "",
  detailCode: "",
  synonym: "",
  comment: "",
  cnaeAnswersLinked: null,
  serviceCodes: null,
};

function CreateEdit({
  data,
  onCloseForm,
  refreshData,
  fetchCNAE,
  fetchServices,
  services,
  cnaesList,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const cnaesUseCases = makeCnaesUseCases();
  const isCreate = !data;

  const [values, setValues] = useState(newObj);
  const [errors, setErrors] = useState({});

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (services.length === 0) {
      fetchServices();
    }
    if (cnaesList.length === 0) {
      fetchCNAE();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      openBackdrop(true, "Carregando informação");
      const result = await cnaesUseCases.getCnaeById(data.id);
      setValues(result.data);
      openBackdrop(false);
    };
    if (data?.id) {
      fetch();
    }
  }, [data]);

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const codeMask = (code) => {
    if (code.length === 7) {
      return `${code.slice(0, 4)}-${code[4]}/${code.slice(-2)}`;
    }
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} cnae`);

    const submitValues = {
      id: values.id,
      code: codeMask(values.code),
      description: values.description,
      detailCode: values.detailCode,
      synonym: values.synonym,
      comment: values.comment,
      serviceCodes:
        values.serviceCodes?.map((item) => {
          let service = {
            id: item.id,
            code: item.code,
            description: item.description,
          };
          return service;
        }) || null,
      cnaeAnswersLinked: values.cnaeAnswersLinked?.map((item) => item.id) || null,
    };

    try {
      if (isCreate) {
        await cnaesUseCases.createCnae(submitValues);
      } else {
        await cnaesUseCases.updateCnae(submitValues);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Cnae ${isCreate ? "cadastrado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refreshData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.data || error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <PageListHeader title={`${isCreate ? "Criar" : "Editar"} CNAE`} handleBack={onCloseForm} />
      {values && (
        <CnaeForm
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
          services={services}
          cnaes={cnaesList}
          fetchCNAE={fetchCNAE}
          fetchServices={fetchServices}
        />
      )}
    </>
  );
}

export default CreateEdit;
