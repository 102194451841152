import React from "react";
import { BrowserRouter as Router, Routes as RoutesContainer, Route } from "react-router-dom";
import PrivateRoute from "~/presentation/routes/PrivateRoute";
import AdminRoutes from "~/presentation/routes/AdminRoutes";
import LandingPageRoutes from "~/presentation/routes/LandingPageRoutes";
import BlogRoutes from "~/presentation/routes/BlogRoutes";
import ClientRoutes from "~/presentation/routes/ClientRoutes";
import GTFacilRoutes from "~/presentation/routes/GTFacilRoutes";
import ReinfRoutes from "~/presentation/routes/ReinfRoutes";

const Routes = () => (
  <Router>
    <RoutesContainer>
      <Route
        path="/cliente/*"
        element={
          <PrivateRoute roles={["user", "manager", "admin", "superAdmin"]}>
            <ClientRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/gt-facil/*"
        element={
          <PrivateRoute roles={["user", "manager", "admin", "superAdmin"]}>
            <GTFacilRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/*"
        element={
          <PrivateRoute roles={["admin", "superAdmin"]}>
            <AdminRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/blog/*"
        element={
          <PrivateRoute role={["user", "manager", "admin", "superAdmin"]}>
            <BlogRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/reinf/*"
        element={
          <PrivateRoute roles={["user", "manager", "admin", "superAdmin"]}>
            <ReinfRoutes />
          </PrivateRoute>
        }
      />

      <Route path="/" element={<LandingPageRoutes />} />
      <Route path="*" element={() => <h1>404 Not found</h1>} />
    </RoutesContainer>
  </Router>
);

export default Routes;
