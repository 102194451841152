import * as Yup from "yup";

export const validate = Yup.object({
  categoryId: Yup.mixed().required("Campo Obrigatório!"),
  companyType: Yup.mixed().required("Campo Obrigatório!"),
  isCity: Yup.boolean(),
  cityHireId: Yup.number().when("isCity", {
    is: true,
    then: (schema) => schema.required("Campo Obrigatório!").typeError("Campo obrigatório"),
    otherwise: (schema) => schema.notRequired(),
  }),
  cityServiceId: Yup.number().when("isCity", {
    is: true,
    then: (schema) => schema.required("Campo Obrigatório!").typeError("Campo obrigatório"),
    otherwise: (schema) => schema.notRequired(),
  }),
  cityContractorId: Yup.number().when("isCity", {
    is: true,
    then: (schema) => schema.required("Campo Obrigatório!").typeError("Campo obrigatório"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
