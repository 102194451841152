import React, { useContext, useEffect, useState } from "react";
import { formatDateToBrazilian } from "../Utils/utilsDate";
import { formatNumberToRealString } from "../Utils/utilsCurrencyvalue";
import { formatCpfCnpj } from "../Utils/utilsReinf";
import { ApiIncomeNature } from "~/data/usecases/incomeNature/incomeNature";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  DeleteForever,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Tooltip,
  TableRow,
  Box,
  Checkbox,
} from "@mui/material";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";
import RenderPaymentLayout from "../components/Layouts/paymentLayout/RenderPaymentLayout";

function PaymentTable({ onDelete, data, selectedRows, setSelectedRows, setData }) {
  const apiIncomeNature = new ApiIncomeNature(makeHttpClient());
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());

  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  //SELECT INPUT OPTIONS
  const [incomeNatureOptions, setIncomeNatureOptions] = useState([]); //select Natureza Rendimento
  const [businessPartnerOptions, setBusinessPartnerOptions] = useState([]); //lista de contratados

  //ABERTURA E FECHO DE CADA FICHA (COLLAPSE)
  const [openCollapse, setOpenCollapse] = useState({});
  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = data.length > 0 && data.slice(startIndex, endIndex);

  //Ordenação
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState(""); // armazena a coluna clicada

  const handleSort = (field) => {
    if (field) {
      const isAsc = orderBy === field && orderDirection === "asc";
      setOrderDirection(isAsc ? "desc" : "asc");
      setOrderBy(field);
      const sortedData = [...data].sort((a, b) => {
        if (a[field] < b[field]) {
          return orderDirection === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return orderDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
      setData(sortedData); // Atualiza o estado com os dados ordenados
    }
  };

  // Função de abertura do Collapse com envio dos dados da linha clicada
  const handleOpenCollapse = (index, isOpen, item) => {
    const targetTagName = event.target.tagName.toLowerCase();
    if (targetTagName === "svg" || targetTagName === "path") {
      return; // Impedir a abertura do collapse se clicar no ícone de exclusão
    }
    setOpenCollapse((prev) => ({
      ...prev,
      [index]: {
        isOpen: !isOpen,
        itemData: item, // Armazena os dados do item clicado
      },
    }));
  };

  const handleChangePage = (event, newPage) => {
    setOpenCollapse({});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Selecionar TODAS as linhas
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(currentData);
    } else {
      setSelectedRows([]);
    }
  };

  // Selecionar nota individual
  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, item]);
    } else {
      setSelectedRows((prev) => prev.filter((selectedItem) => selectedItem.id !== item.id));
    }
  };

  // Verificar se a linha está selecionada
  const isSelected = (item) => selectedRows.some((i) => i.id === item.id);

  const renderDeleteIcon = (sent = false, id = null) => {
    if (sent) {
      return (
        <div>
          <Tooltip title="Doc. Fiscal vinculado a um evento enviado">
            <DeleteForever color="action" style={{ cursor: "auto" }} />
          </Tooltip>
        </div>
      );
    } else {
      return (
        <Tooltip title="Excluir Doc. fiscal">
          <Delete
            color="action"
            onClick={() => {
              onDelete(id);
            }} // Passa o ID da NFSe para a função de exclusão
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    const fetchDataOptions = async () => {
      try {
        const incomeNatureResponse = await apiIncomeNature.listIncomeNature();
        const businessPartnerResponse = await apiBusinessPartner.listBusinessPartner();

        if (incomeNatureResponse) {
          if (Array.isArray(incomeNatureResponse)) {
            setIncomeNatureOptions(incomeNatureResponse);
          } else {
            setFeedbackSnackbar({
              isOpen: true,
              message: "Os dados de Natureza de Rend. não foram carregados",
              type: "error",
            });
          }
        }
        if (businessPartnerResponse) {
          if (Array.isArray(businessPartnerResponse)) {
            setBusinessPartnerOptions(businessPartnerResponse);
          } else {
            setFeedbackSnackbar({
              isOpen: true,
              message: "os dados do contratado não foram carregados",
              type: "error",
            });
          }
        }
      } catch (error) {
        setFeedbackSnackbar({
          isOpen: true,
          message: "Erro ao buscar as opções de seleção.",
          type: "error",
        });
      }
    };
    fetchDataOptions();
  }, []);

  const columns = [
    { field: "businessPartnerCNPJ", headerName: "CPF do Beneficiário", sortable: false },
    { field: "businessPartnerName", headerName: "Nome do Beneficiário" },
    { field: "competence", headerName: "Data do emissão" },
    { field: "datePayment", headerName: "Data de pagamento" },
    { field: "totalAmount", headerName: "Valor Bruto" },
    { field: "taxedAmount", headerName: "Valor Rend. Tributável" },
    { field: "amountIR", headerName: "Valor IRRF" },
    { field: "companyCNPJ", headerName: "CPNJ do Contratante", sortable: false },
    { headerName: "Tipo de Doc.", sortable: false },
    { headerName: <Delete />, sortable: false },
  ];

  return (
    <Box sx={{ borderRadius: "10px", overflow: "hidden", marginInline: 3, marginBlock: 2 }}>
      <Table sx={{ borderCollapse: "separate", borderSpacing: "0" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.TableCellHead}></TableCell>
            <TableCell sx={{ ...styles.TableCellHead, padding: 0 }}>
              <Checkbox
                color="default"
                indeterminate={selectedRows.length > 0 && selectedRows.length < currentData.length}
                checked={currentData.length > 0 && selectedRows.length === currentData.length}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            {columns.map((col, i) => {
              if (col?.headerName === null) return;
              return (
                <TableCell
                  sx={{
                    ...styles.TableCellHead,
                    cursor: col.sortable === false ? "auto" : "pointer",
                  }}
                  key={i}
                  onClick={() => {
                    if (col.sortable === false) return; //precisa ser === false
                    handleSort(col.field);
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {col.headerName}
                    {orderBy === col.field ? (
                      orderDirection === "asc" ? (
                        <ArrowUpward fontSize="small" />
                      ) : (
                        <ArrowDownward fontSize="small" />
                      )
                    ) : null}
                  </Box>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentData.length > 0 ? (
            currentData?.map((item, index) => {
              const isOpen = openCollapse[index] || false;
              const sent = item?.isR2010 || item?.isR4020;
              //saber se a nota já teve alteração
              const nfseChanged = item?.hadChanges;
              //style para nos alteradas
              const styledColor = "#FAE4A2"; //amarelo claro
              const styledBorder = `2px solid #F3B80C`; //amarelo torrado (escuro)
              const styledCell = {
                fontFamily: "Montserrat ",
                fontSize: 14,
                padding: "6px",
                color: "#000000",
                textAlign: "center",
                borderBlock: nfseChanged ? styledBorder : "none",
              };

              return (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{
                      cursor: "pointer",
                      backgroundColor: nfseChanged ? styledColor : "#D9D9D9",
                    }}
                    onClick={() => {
                      handleOpenCollapse(index, isOpen);
                    }}
                  >
                    <TableCell
                      sx={{ ...styledCell, borderLeft: nfseChanged ? styledBorder : "none" }}
                      onClick={() => {
                        setOpenCollapse((prev) => ({
                          ...prev,
                          [index]: !isOpen,
                        }));
                      }}
                    >
                      <IconButton aria-label="expand row" size="small">
                        {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ ...styledCell, padding: 0 }}>
                      <Checkbox
                        color="default"
                        checked={isSelected(item)}
                        onChange={(event) => handleCheckboxChange(event, item)}
                      />
                    </TableCell>
                    <TableCell sx={styledCell}>{formatCpfCnpj(item.businessPartnerCNPJ)}</TableCell>
                    <TableCell sx={styledCell}>{item?.businessPartnerName}</TableCell>
                    <TableCell sx={styledCell}>{formatDateToBrazilian(item.competence)}</TableCell>
                    <TableCell sx={styledCell}>
                      {item.datePayment ? formatDateToBrazilian(item.datePayment) : "-"}
                    </TableCell>
                    <TableCell sx={styledCell}>
                      {formatNumberToRealString(item.totalAmount)}
                    </TableCell>
                    <TableCell sx={styledCell}>
                      {formatNumberToRealString(item.taxedAmount)}
                    </TableCell>
                    <TableCell sx={styledCell}>{formatNumberToRealString(item.amountIR)}</TableCell>
                    <TableCell sx={styledCell}>{formatCpfCnpj(item.companyCNPJ)}</TableCell>
                    {/* <TableCell sx={styledCell}>{formatCpfCnpj(item.companyId)}</TableCell> */}
                    <TableCell sx={styledCell}>{"Pgto pessoa física"}</TableCell>
                    <TableCell
                      sx={{ ...styledCell, borderRight: nfseChanged ? styledBorder : "none" }}
                    >
                      {renderDeleteIcon(sent, item?.id)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={styles.CollapseContainer} colSpan="100%">
                      <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <RenderPaymentLayout
                          data={item} // Passando o objeto da linha para o layout
                          businessPartnerOptions={businessPartnerOptions}
                          incomeNatureOptions={incomeNatureOptions}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell sx={styles.TableCell} colSpan="100%">
                <p style={{ textAlign: "center" }}>Nenhuma informação encontrada...</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {data.length > 9 && (
        <Stack
          spacing={1}
          sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
        >
          <TablePagination
            component="div"
            count={data?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Informações por página:"
            rowsPerPageOptions={[10, 15, 20]}
            sx={{
              ".MuiTablePagination-selectLabel ": {
                margin: 0,
              },
              ".MuiTablePagination-displayedRows": {
                margin: 0,
              },
            }}
          />
        </Stack>
      )}
    </Box>
  );
}

export default PaymentTable;

const styles = {
  //LINHA TÍTULOS COLUNAS
  HeadTableRow: {},
  //LINHA TÍTULOS DAS COLUANS
  TableCellHead: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    //DIVISOR COLUNAS HEAD
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  TableCell: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    padding: "10px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
  //CADA LINHA
  TableRow: {},
  //COLAPSE
  CollapseContainer: {
    paddingBlock: "3px",
    backgroundColor: "#F2F2F2",
  },
};
