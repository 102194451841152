import React, { useContext, useEffect, useState } from "react";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Stack,
  Box,
  Checkbox,
  Divider,
  Typography as Text,
} from "@mui/material";
import SentTableDocs from "./components/SentTableDocs";
import RButton from "../../../components/Common/Buttons/RButton";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { Tooltip } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { formatDateToBrazilian } from "../../../Utils/utilsDate";
import { EventSend } from "../EventSend/EventSend";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import { useTableFilters } from "../../../Hooks/useTableFilters";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { EventDetailsModal } from "../EventSend/EventDetailsModal";
import { tableStyles } from "../../../components/Styles/TableStyles";
import SearchInput from "../../../components/Search/SearchInput";

function SentTableEvents({
  certificateData,
  filter,
  refreshData,
  currentEventsData,
  validNfseData,
  isEventClosed,
}) {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const apiNfse = new ApiNfseUseCases(makeHttpClient());
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { handleSearchTable } = useTableFilters();
  const [eventsData, setEventsData] = useState([]);
  const [, setNotasValues] = useState([]);
  const [totalAmountBC, setTotalAmountBC] = useState([]);
  const [totalAmountIR, setTotalAmountIR] = useState([]);
  const [totalAmountCSRF, setTotalAmountCSRF] = useState([]);
  const [totalAmountINSS, setTotalAmountINSS] = useState([]);
  const [protocolTotals, setProtocolTotals] = useState({}); //state totalizador c/protocolo
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [nfseDataWithEventSent, setNfseDataWithEventSent] = useState([]);
  const selectedEvent = filter?.event;

  useEffect(() => {
    setEventsData(currentEventsData);
    currentEventsData.forEach(async (event, index) => {
      handleOpenCollapse(index, true);
      await fetchNotas(event.protocol);
    });
    handleSelectAllClick({ target: { checked: false } });
  }, [currentEventsData]);

  //ABERTURA E FECHO DE CADA FICHA (COLLAPSE)
  const [openCollapse, setOpenCollapse] = useState({});
  //Open/Close Collapse
  const handleOpenCollapse = (index, isOpen) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [index]: !isOpen,
    }));
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearchTable(value, currentEventsData, setEventsData);
  };

  const dataAbledToCheck = eventsData?.filter((item) => item.receiptNumber);
  //para o checkbox "titulo" do head
  const numSelected = selectedRows.length;
  const rowCount = dataAbledToCheck.length;

  //Ordenação
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState(""); // armazena a coluna clicada

  const handleSort = (field) => {
    if (field) {
      const isAsc = orderBy === field && orderDirection === "asc";
      setOrderDirection(isAsc ? "desc" : "asc");
      setOrderBy(field);
      let sortedData = [];
      if (field === "totalAmountIR") {
        sortedData = "";
      }
      if (field === "totalAmountCSRF") {
        sortedData = "";
      }
      if (field === "totalAmountINSS") {
        sortedData = "";
      }
      sortedData = [...eventsData].sort((a, b) => {
        if (a[field] < b[field]) {
          return orderDirection === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return orderDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
      setEventsData(sortedData); // Atualiza o estado com os dados ordenados
    }
  };

  //Selecionar TODAS as documentos
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(dataAbledToCheck);
    } else {
      setSelectedRows([]);
    }
  };

  //Selecionar nota individual
  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, item]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((selectedItem) => selectedItem.protocol !== item.protocol)
      );
    }
  };

  //para por no checkbox de cada linha
  const isSelected = (item) => selectedRows.some((i) => i.protocol === item.protocol);

  // OPEN SEND MODAL
  const [openEventSendModal, setOpenEventSendModal] = useState(false);

  // ⇣⇣⇣ abrir e fechar modal de envio de documentos
  const handleOpen = () => setOpenEventSendModal(true);
  const handleClose = () => setOpenEventSendModal(false);

  // CONSULT MODAL
  const [openConsultDetails, setOpenConsultDetails] = useState(false);
  const [consultDetails, setConsultDetails] = useState([]);

  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = eventsData.length > 0 && eventsData.slice(startIndex, endIndex);

  const renderStatus = (status, message) => {
    if (status === 2) {
      return (
        <Tooltip title={message}>
          <IconButton>
            <CheckCircleOutlineOutlinedIcon color="success" />
          </IconButton>
        </Tooltip>
      );
    }

    if (status > 2) {
      return (
        <Tooltip title={message}>
          <IconButton>
            <ErrorOutlineOutlinedIcon color="error" />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={message || "Aguardando consulta..."}>
        <IconButton>
          <PendingOutlinedIcon color="primary" />
        </IconButton>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (selectedEvent !== "r9000") {
      setNfseDataWithEventSent(
        validNfseData.filter(
          (nfse) => !!currentEventsData.some((event) => event.tag === nfse.businessPartnerCNPJ)
        )
      );
    }
  }, [currentEventsData, validNfseData]);

  const fetchNotas = async (protocol) => {
    try {
      const response = await apiNfse.getNfseByProtocol(protocol);
      if (response) {
        setNotasValues((prevValues) => {
          const newValues = [...prevValues, ...response];
          const totalAmountBC = response.reduce((total, curr) => total + curr.amountBC, 0);
          const totalAmountIR = response.reduce((total, curr) => total + curr.amountIR, 0);
          const totalAmountCSRF = response.reduce((total, curr) => total + curr.amountCSRF, 0);
          const totalAmountINSS = response.reduce((total, curr) => total + curr.amountINSS, 0);

          setProtocolTotals((prevTotals) => ({
            ...prevTotals,
            [protocol]: {
              amountBC: totalAmountBC,
              amountIR: totalAmountIR,
              amountCSRF: totalAmountCSRF,
              amountINSS: totalAmountINSS,
            },
          }));
          return newValues;
        });
        return response;
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    }
  };

  const handleAutomatedRectification = async () => {
    const promises = eventsData.map(async (item) => {
      const nfseData = await fetchNotas(item.protocol);
      return { ...item, nfseData };
    });

    try {
      openBackdrop(true, "Adicionando documentos fiscais que não foram enviados");
      setSelectedRows([]);
      const response = await Promise.all(promises);
      currentEventsData.forEach((event, index) => {
        handleOpenCollapse(index, true);
      });
      const eventsCollapseStatus = [];
      const updatedEvents = response.map((item, index) => {
        const nfseData = validNfseData.filter((nfse) => nfse.businessPartnerCNPJ === item.tag);
        if (nfseData.length > 0) {
          eventsCollapseStatus.push({ index: index, isOpen: false });
          return {
            ...item,
            nfseData: [...item.nfseData, ...nfseData],
          };
        }
        eventsCollapseStatus.push({ index: index, isOpen: true });
        return item;
      });

      setEventsData(updatedEvents);
      eventsCollapseStatus.forEach((item) => {
        if (!item.isOpen) {
          setSelectedRows((prev) => [...prev, updatedEvents[item.index]]);
        }
        handleOpenCollapse(item.index, item.isOpen);
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleSubmit = async () => {
    if (selectedEvent === "r2010" || selectedEvent === "r4020") {
      const promises = selectedRows.map(async (item) => {
        if (!item.nfseData) {
          const nfseData = await fetchNotas(item.protocol);
          return { ...item, nfseData };
        }

        return item;
      });

      setSelectedRows(await Promise.all(promises));
    }

    handleOpen();
  };

  useEffect(() => {
    const totals = { amountBC: 0, amountIR: 0, amountCSRF: 0, amountINSS: 0 };
    Object.values(protocolTotals).forEach((protocolTotal) => {
      totals.amountBC += protocolTotal.amountBC || 0;
      totals.amountIR += protocolTotal.amountIR || 0;
      totals.amountCSRF += protocolTotal.amountCSRF || 0;
      totals.amountINSS += protocolTotal.amountINSS || 0;
    });
    setTotalAmountBC(totals.amountBC);
    setTotalAmountIR(totals.amountIR);
    setTotalAmountCSRF(totals.amountCSRF);
    setTotalAmountINSS(totals.amountINSS);
  }, [protocolTotals]);

  const anyStatus1 = eventsData?.filter((item) => item.codeStatus === 1);

  const handleProtocolConsult = async () => {
    const promises = anyStatus1?.map(async (event) => {
      // Define o isProduction com base no primeiro dígito de event.protocol
      const isProduction = event.protocol.startsWith("1");

      const params = {
        DigitalCertId: filter.certificate,
        ProtocolNumber: event.protocol,
        IsProduction: isProduction,
      };
      const result = await apiReinf.getReinfConsult(params);
      return result;
    });

    try {
      openBackdrop(true, "Consultando protocolos");
      const response = await Promise.all(promises);
      if (response.length > 0) {
        setConsultDetails(response);
        setOpenConsultDetails(true);
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data || error?.response,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const getColumns = () => {
    const columns = [
      { field: "protocol", headerName: "Protocolo" },
      { field: "receiptNumber", headerName: "Nº Recibo" },
      { field: "sendingDate", headerName: "Data do envio" },
      { field: "userSent", headerName: "Email do usuário" },
      { field: "reinfEvent", headerName: "Evento" },
      { headerName: "Totalizador", sortable: false },
    ];

    if (selectedEvent === "r4020") {
      columns.push({ headerName: "Total IR", sortable: false });
      if (QSO === "D") columns.push({ headerName: "Total CSRF", sortable: false });
    }

    if (selectedEvent === "r2010") columns.push({ headerName: "Total INSS", sortable: false });

    columns.push({ headerName: "Situação", sortable: false });

    return columns;
  };

  const headStyle = { ...tableStyles.headCell, padding: "12px" };

  return (
    <>
      <Box sx={{ paddingInline: 3 }}>
        <Box sx={styles.buttonsContainer}>
          <Box sx={styles.buttonsContainer.buttons}>
            {selectedEvent !== "r9000" && (
              <RButton
                disabled={eventsData?.length === 0 || nfseDataWithEventSent.length === 0}
                sx={styles.Button}
                onClick={handleAutomatedRectification}
              >
                Adicionar Documentos
              </RButton>
            )}
            {anyStatus1.length > 0 && (
              <Tooltip
                title="Consulta a situação dos protocolos enviados que ainda não possuem confirmação / Recibo."
                placement="right-start"
              >
                <RButton sx={styles.Button} onClick={handleProtocolConsult}>
                  Consultar protocolos
                </RButton>
              </Tooltip>
            )}
          </Box>
          <SearchInput value={searchTerm} onChange={handleSearch} />
        </Box>
        <Box sx={{ paddingTop: 2, display: "flex", justifyContent: "right" }}>
          <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
            <Text sx={styles.text}>
              Totalizador: <span>{formatNumberToRealString(totalAmountBC)}</span>
            </Text>
            {selectedEvent === "r4020" && (
              <>
                <Text sx={styles.text}>
                  Totalizador IR: <span>{formatNumberToRealString(totalAmountIR)}</span>
                </Text>
                {QSO === "D" && (
                  <Text sx={styles.text}>
                    Totalizador CSRF: <span>{formatNumberToRealString(totalAmountCSRF)}</span>
                  </Text>
                )}
              </>
            )}
            {selectedEvent === "r2010" && (
              <Text sx={styles.text}>
                Totalizador INSS: <span>{formatNumberToRealString(totalAmountINSS)}</span>
              </Text>
            )}
          </Stack>
        </Box>
      </Box>
      <Box sx={{ ...tableStyles.container, margin: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              {selectedEvent !== "r9000" && <TableCell sx={headStyle}></TableCell>}
              <TableCell sx={{ ...headStyle, padding: 0 }}>
                <Checkbox
                  color="default"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {getColumns().map((col, i) => (
                <TableCell
                  sx={{
                    ...headStyle,
                    cursor: col.sortable === false ? "auto" : "pointer",
                  }}
                  key={i}
                  onClick={() => {
                    if (col.sortable === false) return; //precisa ser === false
                    handleSort(col.field);
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {col.headerName}
                    {orderBy === col.field ? (
                      orderDirection === "asc" ? (
                        <ArrowUpward fontSize="small" />
                      ) : (
                        <ArrowDownward fontSize="small" />
                      )
                    ) : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.length > 0 ? (
              currentData?.map((item, index) => {
                const isOpen = openCollapse[index] || false;
                const isRowSelected = isSelected(item);
                const hasReceiptNumber = item?.receiptNumber;
                return (
                  <React.Fragment key={index}>
                    <TableRow sx={{ cursor: selectedEvent === "r9000" ? "auto" : "pointer" }}>
                      {selectedEvent !== "r9000" && (
                        <TableCell sx={tableStyles.cell}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              if (!isRowSelected && !isOpen)
                                handleCheckboxChange({ target: { checked: true } }, item);
                              handleOpenCollapse(index, isOpen);
                            }}
                          >
                            {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                          </IconButton>
                        </TableCell>
                      )}
                      <TableCell sx={{ ...tableStyles.cell, padding: 0 }}>
                        <Checkbox
                          color="default"
                          onChange={(event) => handleCheckboxChange(event, item)}
                          checked={isRowSelected}
                          disabled={!hasReceiptNumber}
                        />
                      </TableCell>
                      <TableCell sx={tableStyles.cell}>{item.protocol}</TableCell>
                      <TableCell sx={tableStyles.cell}>{item.receiptNumber}</TableCell>
                      <TableCell sx={tableStyles.cell}>
                        {formatDateToBrazilian(item.sendingDate)}
                      </TableCell>
                      <TableCell sx={tableStyles.cell}>{item.userSent}</TableCell>
                      <TableCell sx={tableStyles.cell}>{`R-${item?.reinfEvent.slice(
                        1,
                        5
                      )}`}</TableCell>
                      <TableCell sx={tableStyles.cell}>
                        {formatNumberToRealString(protocolTotals[item.protocol]?.amountBC || 0)}
                      </TableCell>
                      {selectedEvent === "r4020" && (
                        <>
                          <TableCell sx={tableStyles.cell}>
                            {formatNumberToRealString(protocolTotals[item.protocol]?.amountIR || 0)}
                          </TableCell>
                          {QSO === "D" && (
                            <TableCell sx={tableStyles.cell}>
                              {formatNumberToRealString(
                                protocolTotals[item.protocol]?.amountCSRF || 0
                              )}
                            </TableCell>
                          )}
                        </>
                      )}
                      {selectedEvent === "r2010" && (
                        <TableCell sx={tableStyles.cell}>
                          {formatNumberToRealString(protocolTotals[item.protocol]?.amountINSS || 0)}
                        </TableCell>
                      )}
                      <TableCell sx={tableStyles.cell}>
                        {renderStatus(item.codeStatus, item.status)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ ...tableStyles.cellCollapse, padding: "2px " }}
                        colSpan="100&"
                      >
                        {isOpen && (
                          <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <SentTableDocs
                              protocol={item.protocol}
                              filter={filter}
                              validNfseData={validNfseData}
                              status={item.codeStatus}
                              certificateData={certificateData}
                              receiptNumber={item.receiptNumber}
                              refreshData={refreshData}
                              isEventClosed={isEventClosed}
                              automatedData={eventsData[index]?.nfseData || null}
                              selecetedRowsData={setSelectedRows}
                              setEventsData={setEventsData}
                            />
                          </Collapse>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell sx={tableStyles.cellError} colSpan="100%">
                  <p style={{ textAlign: "center" }}>Não existe eventos no período escolhido.</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {eventsData.length > 9 && (
          <Stack spacing={1} sx={tableStyles.pagination}>
            <TablePagination
              component="div"
              count={eventsData?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Informações por página:"
              rowsPerPageOptions={[10, 15, 20]}
            />
          </Stack>
        )}
      </Box>
      <Box sx={{ paddingRight: 3, display: "flex", justifyContent: "right" }}>
        <RButton
          sx={{ width: "200px" }}
          onClick={handleSubmit}
          disabled={selectedRows.length === 0}
        >
          Enviar
        </RButton>
      </Box>
      <ModalContainer open={openEventSendModal} onClose={handleClose}>
        <EventSend
          data={selectedRows}
          filter={{
            ...filter,
            event:
              selectedEvent === "r2010" || selectedEvent === "r4020"
                ? `automated-${selectedEvent}`
                : selectedEvent,
          }}
          certificateData={certificateData}
          closeModal={handleClose}
          refreshData={refreshData}
        />
      </ModalContainer>
      <ModalContainer open={openConsultDetails} onClose={() => setOpenConsultDetails(false)}>
        <EventDetailsModal
          onClose={() => setOpenConsultDetails(false)}
          eventDetails={consultDetails}
        />
      </ModalContainer>
    </>
  );
}

export default SentTableEvents;

const styles = {
  Button: {
    width: "min(300px, 100%)",
    borderRadius: "8px",
    boxShadow: 0,
    textTransform: "uppercase",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 600,
    color: "#404040",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    "@media(min-width: 900px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    buttons: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 1,
      "@media(min-width: 600px)": {
        flexDirection: "row",
      },
      "@media(min-width: 900px)": {
        justifyContent: "start",
      },
    },
  },
};
