import React, { useEffect, useState } from "react";
import {
  Box,
  Table as MuiTable,
  Stack,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  TableBody,
  IconButton,
  Collapse,
  Tooltip,
} from "@mui/material";
import { tableStyles } from "../Styles/TableStyles";
import SearchInput from "../Search/SearchInput";
import { useTableFilters } from "../../Hooks/useTableFilters";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PostAdd,
} from "@mui/icons-material";

const Table = ({
  columns = [], //colunas
  data = [], //linhas
  errorMsg = false, //errorMsg costumizado
  setSelectedRows = null, //entrega os id das linhas selecionadas
  searchInput = true, //caso false não mostra o input de search
  loading = false,
  collapse = null,
  rowPadding = "6px",
  titleTable,
  handleAdd,
}) => {
  //DATA
  const [currentData, setCurrentData] = useState([]);
  //Collapse
  const [openRows, setOpenRows] = useState([]); // para gerenciar quais linhas estão colapsadas
  //Row Select
  const [selectedRows, setSelectedRowsInternal] = useState([]);
  //Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //Search
  const [searchTerm, setSearchTerm] = useState("");
  const { handleSearchTable } = useTableFilters();

  //Select Rows
  const handleRowSelect = (id) => {
    let updatedSelectedRows = [];
    if (selectedRows.includes(id)) {
      updatedSelectedRows = selectedRows.filter((rowId) => rowId !== id);
    } else {
      updatedSelectedRows = [...selectedRows, id];
    }
    setSelectedRowsInternal(updatedSelectedRows);
    if (setSelectedRows) {
      setSelectedRows(updatedSelectedRows);
    }
  };

  // Função para alternar colapso de uma linha específica com base no índice real
  const handleToggleRow = (index) => {
    const realIndex = page * rowsPerPage + index; // Calcula o índice real da linha
    setOpenRows((prevOpenRows) => {
      const newOpenRows = [...prevOpenRows];
      if (newOpenRows?.length > 0 && newOpenRows.includes(realIndex)) {
        // Se o índice já estiver no array, remove (fecha a linha)
        return newOpenRows.filter((i) => i !== realIndex);
      } else {
        // Se não estiver, adiciona (abre a linha)
        return [...newOpenRows, realIndex];
      }
    });
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = currentData?.slice(startIndex, endIndex);

  //Ordenação
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState(""); // armazena a coluna clicada

  const handleSort = (field) => {
    if (field) {
      const isAsc = orderBy === field && orderDirection === "asc";
      setOrderDirection(isAsc ? "desc" : "asc");
      setOrderBy(field);
      const sortedData = [...currentData].sort((a, b) => {
        if (a[field] < b[field]) {
          return orderDirection === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return orderDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
      setCurrentData(sortedData); // Atualiza o estado com os dados ordenados
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearchTable(value, data, setCurrentData);
  };

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  return (
    <Box sx={{ margin: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 2, mb: 2 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {titleTable && (
            <Typography variant="h6" component="div" sx={{pr: 1}}>
              {titleTable}
            </Typography>
          )}
          {handleAdd && (
            <Box sx={tableStyles.addIcon}>
              <Tooltip title={"Cadastrar novo"}>
                <IconButton onClick={handleAdd}>
                  <PostAdd sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>

        {/* Campo de busca alinhado à direita */}
        {searchInput && (
          <Box sx={{ marginBottom: 1, width: "100%" }}>
            <SearchInput onChange={handleSearch} value={searchTerm} />
          </Box>
        )}
      </Stack>
      <Box sx={tableStyles.container}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {collapse && <TableCell sx={{ ...tableStyles.headCell, padding: 0 }}></TableCell>}
              {setSelectedRows && (
                <TableCell sx={{ ...tableStyles.headCell, padding: 0 }}>
                  <Checkbox value={searchTerm} onChange={handleSearch} />
                </TableCell>
              )}
              {columns.map((col, index) => {
                return (
                  <TableCell
                    key={index}
                    sx={{
                      ...tableStyles.headCell,
                      cursor: col.sortable === false ? "auto" : "pointer",
                    }}
                    onClick={() => {
                      if (col.sortable === false) return; //precisa ser === false
                      handleSort(col.field);
                    }}
                  >
                    {col.headerName}
                    {orderBy === col.field ? (
                      orderDirection === "asc" ? (
                        <ArrowUpward fontSize="small" />
                      ) : (
                        <ArrowDownward fontSize="small" />
                      )
                    ) : null}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedData.length > 0 ? (
              slicedData.map((row, rowIndex) => {
                const realIndex = page * rowsPerPage + rowIndex;
                const isOpen = openRows.includes(realIndex);
                return (
                  <React.Fragment key={realIndex}>
                    <TableRow>
                      {collapse && (
                        <TableCell sx={{ ...tableStyles.cell, padding: 0 }}>
                          <IconButton
                            size="small"
                            onClick={() => handleToggleRow(realIndex)}
                            disabled={loading}
                          >
                            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                      )}
                      {setSelectedRows && (
                        <TableCell sx={{ ...tableStyles.cell, padding: 0 }}>
                          <Checkbox
                            checked={selectedRows.includes(row.id)}
                            onChange={() => handleRowSelect(row.id)}
                          />
                        </TableCell>
                      )}
                      {columns.map((col, colIndex) => (
                        <TableCell
                          key={colIndex}
                          sx={{
                            ...tableStyles.cell,
                            padding: rowPadding,
                          }}
                          align={col.align || "left"}
                        >
                          {col.renderCell
                            ? col.renderCell({ value: row[col.field], row })
                            : row[col.field]}
                        </TableCell>
                      ))}
                    </TableRow>
                    {collapse && (
                      <TableRow>
                        <TableCell sx={tableStyles.cellCollapse} colSpan="100%">
                          <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            {collapse(row)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan="100%" sx={tableStyles.cellError}>
                  {loading ? (
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: 14 }}>
                      Carregando informações...
                    </Typography>
                  ) : (
                    <Typography sx={{ fontFamily: "Montserrat", fontSize: 14 }}>
                      {errorMsg || "Nenhum dado encontrado."}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
        {data.length > 4 && (
          <Stack spacing={1} sx={tableStyles.pagination}>
            <TablePagination
              component="div"
              count={data.length}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
              labelRowsPerPage="Linhas por página:"
              rowsPerPageOptions={[10, 15, 20]}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default Table;
