import React from "react";
import Input from "~/presentation/components/Common/Input";
import { Box, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Grid from "@mui/material/Grid2";

const UserForm = ({
  handleSubmit,
  editing,
  companies,
  userGroups,
  onChangeValue,
  values,
  errors,
}) => {
  return (
    <Box sx={formStyles.container}>
      <Grid container spacing={1}>
        <Grid size={12}>
          <Typography sx={formStyles.title}>Dados do Usuário</Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="select"
            title="Empresa"
            name="companyId"
            placeholder="Selecione a empresa..."
            value={companies.find((item) => item.id === values?.companyId)}
            onChange={(option) => onChangeValue("companyId", option.id)}
            options={companies}
            error={errors.companyId}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Nome"
            name="name"
            placeholder="Insira o nome..."
            value={values?.name}
            onChange={(e) => onChangeValue("name", e.target.value)}
            error={errors.name}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="text"
            title="Email"
            name="email"
            placeholder="Insira o email..."
            value={values?.email}
            onChange={(e) => onChangeValue("email", e.target.value)}
            error={errors.email}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="mask"
            mask="(00) 0000-0000"
            title="Telefone"
            name="phone"
            placeholder="Insira o telefone..."
            value={values?.phone}
            onChange={(e) => onChangeValue("phone", e)}
            error={errors.phone}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="mask"
            mask="(00) 00000-0000"
            title="Celular"
            name="mobile_phone"
            placeholder="Insira o celular..."
            value={values?.mobile_phone}
            onChange={(e) => onChangeValue("mobile_phone", e)}
            error={errors.mobile_phone}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="password"
            title="Senha"
            name="password"
            placeholder="Insira a senha..."
            value={values?.password}
            onChange={(e) => onChangeValue("password", e.target.value)}
            error={errors.password}
            disabled={editing}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="password"
            title="Confirmar a Senha"
            name="confirm_password"
            placeholder="Confirme a senha..."
            value={values?.confirm_password}
            onChange={(e) => onChangeValue("confirm_password", e.target.value)}
            error={errors.confirm_password}
            disabled={editing}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            type="select"
            title="Grupo de Usuário"
            name="role"
            placeholder="Selecione o grupo..."
            value={userGroups.find((item) => item.value === values?.role)}
            onChange={(option) => onChangeValue("role", option.value)}
            options={userGroups}
            error={errors.role}
            height={120}
          />
        </Grid>
        <Box sx={formStyles.buttonContainer}>
          <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
            Salvar
          </ButtonCommon>
        </Box>
      </Grid>
    </Box>
  );
};

export default UserForm;
