import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validationSchema from "./Form/validationSchema";
import PageListHeader from "../../../components/PageListHeader";
import DialogForm from "./Form";

function CreateEditDialog({ data, onCloseForm, refreshData, gtFacildialogUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const [values, setValues] = useState({
    text: "",
  });
  const [errors, setErrors] = useState({});

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);

  const handleValidation = (values) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        setErrors({});
        handleSubmit(values);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        openBackdrop(false);
      });
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} novo diálogo`);
    try {
      if (isCreate) {
        await gtFacildialogUseCases.createDialog(values);
      } else {
        await gtFacildialogUseCases.update(values.id, values);
      }

      await refreshData();
      onCloseForm();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data || error?.response,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <PageListHeader
        title={`${isCreate ? "Cadastrar" : "Editar"} Diálogo`}
        handleBack={onCloseForm}
      />
      {values && (
        <DialogForm
          values={values}
          onChangeValue={handleChangeValue}
          handleSubmit={handleValidation}
          errors={errors}
        />
      )}
    </>
  );
}

export default CreateEditDialog;
