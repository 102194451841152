import React from "react";
import { Box, Typography } from "@mui/material";

export const Title = ({ children, ...props }) => (
  <Typography
    {...props}
    sx={{
      fontFamily: "Montserrat",
      fontSize: "22px",
      fontWeight: 700,
      color: "#000",
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      userSelect: "none",
      paddingBlock: "5px",
      gap: "4px",
    }}
  >
    {children}
  </Typography>
);

export const Subtitle = ({ children, ...props }) => (
  <Typography
    {...props}
    sx={{
      fontFamily: "Montserrat",
      fontWeight: 700,
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      userSelect: "none",
      paddingBlock: "5px",
      gap: "4px",
      fontSize: "16px",
      cursor: "pointer",
      paddingTop: 0,
      color: "#676767",
    }}
  >
    {children}
  </Typography>
);

export const GrayContainer = ({ children, ...props }) => (
  <Box
    {...props}
    sx={{ display: "flex", flexDirection: "column", margin: "5px auto", borderRadius: "5px" }}
  >
    {children}
  </Box>
);
