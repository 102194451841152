import React from "react";
import { Pagination, Loading, NoPostsFound, InboxIcon } from "./styles";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

const PaginatedList = ({
  isLoading,
  items = [],
  itemComponent: ItemComponent,
  page,
  numberOfPages,
  onPageChange,
  notFoundMessage = "Nenhum item encontrado.",
}) => {
  const renderLoading = () => (
    <Loading>
      <p>Carregando...</p>
    </Loading>
  );

  const renderItems = () => {
    if (items.length === 1) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              marginLeft: { xs: "8px", md: "0" },
              width: { xs: "100%", md: "49%" },
            }}
          >
            {ItemComponent(items[0])}
          </Box>
        </Box>
      );
    }

    return items.map((item, index) => (
      <Grid size={{ xs: 12, md: 6 }} key={index}>
        {ItemComponent(item)}
      </Grid>
    ));
  };

  const renderNoPostsFound = () => (
    <NoPostsFound>
      <InboxIcon />
      <p>{notFoundMessage}</p>
    </NoPostsFound>
  );

  const renderPagination = () => (
    <Pagination
      key="pagination"
      size="small"
      count={numberOfPages}
      onChange={(_, selectedPage) => {
        onPageChange(selectedPage);
      }}
      page={Number(page)}
    />
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        paddingBlock: 2,
      }}
    >
      <Grid container spacing={1}>
        {isLoading ? renderLoading() : items.length ? renderItems() : renderNoPostsFound()}
        {items.length > 0 && <Grid size={12}>{renderPagination()}</Grid>}
      </Grid>
    </Box>
  );
};

export default PaginatedList;
