import React from "react";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";

export const StatsForm = ({
  errors,
  values,
  onChangeValue,
  companiesData,
  usersData,
  handleSubmit,
  setStatsData,
  searchType,
  setSearchType,
}) => {
  const searchOptions = dataToSelectMapper([
    { id: "search", name: "Pesquisas no GT-Fácil realizadas pela empresa" },
    { id: "search-summary", name: "Resumo das pesquisas realizadas no GT-Fácil" },
    { id: "content-access", name: "Conteúdos acessados pela empresa" },
  ]);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Input
            type="select"
            title="Tipo de busca"
            name="searchType"
            value={searchOptions.find((item) => item.value === values?.searchType)}
            onChange={(option) => {
              onChangeValue("searchType", option.value);
              setSearchType(option.value);
              setStatsData([]);
            }}
            options={searchOptions}
            error={errors.searchType}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Input
            type="select"
            title="Empresa"
            name="companyId"
            placeholder="Selecione a empresa"
            value={companiesData.find((item) => item.id === values?.companyId)}
            onChange={(option) => onChangeValue("companyId", option.id)}
            options={companiesData}
            error={errors.companyId}
          />
        </Grid>
        {searchType === "search" && (
          <>
            <Grid size={{ xs: 12, md: 6 }}>
              <Input
                type="select"
                title="Usuário"
                name="userId"
                value={usersData.find((item) => item.id === values?.userId)}
                onChange={(option) => onChangeValue("userId", option.id)}
                options={usersData}
                error={errors.userId}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Input
                type="date"
                title="Data Inicial"
                name="initialDate"
                value={values?.initialDate}
                onChange={(date) => onChangeValue("initialDate", date)}
                error={errors.initialDate}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Input
                type="date"
                title="Data Final"
                name="finalDate"
                value={values?.finalDate}
                onChange={(date) => onChangeValue("finalDate", date)}
                error={errors.finalDate}
              />
            </Grid>
          </>
        )}
        {searchType === "content-access" && (
          <Grid size={{ xs: 12, lg: "auto" }} sx={formStyles.checkBoxContainer}>
            <FormControlLabel
              sx={formStyles.controlLabel}
              control={
                <Checkbox
                  sx={formStyles.icon}
                  checked={values?.isDetail || null}
                  onChange={() => onChangeValue("isDetail", !values?.isDetail)}
                  name="isDetail"
                />
              }
              label="Exibir conteúdo detalhado"
            />
          </Grid>
        )}
      </Grid>
      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Pesquisar
        </ButtonCommon>
      </Box>
    </Box>
  );
};
