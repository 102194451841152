import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExitToApp, AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import AppContext from "~/presentation/AppContext";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  Drawer,
  IconButton,
} from "@mui/material";
import { HashLink as RouterLink } from "react-router-hash-link";

const MobileNavigation = ({ open, openLoginDialog, navbarItems, toggleMobileDrawer, isLogged }) => {
  const [selectedLink, setSelectedLink] = useState();
  const location = useLocation();
  const listItemCount = useRef(0);
  const { handleLogout } = useContext(AppContext);

  const isItemActive = (name) => {
    const { hash, pathname } = location;
    if (pathname === "/" && name === "home" && hash === "") return true;

    if (hash === "" && pathname.includes("/cliente")) {
      if (pathname === "/cliente") {
        return name === "cliente";
      }
      return pathname.replace("/cliente", "").includes(name);
    }

    if (hash === "") {
      return location.pathname.includes(name);
    }

    return hash.includes(name);
  };

  const handleOpenSubLinks = (linkId) => {
    if (linkId === selectedLink) {
      setSelectedLink(undefined);
    } else {
      setSelectedLink(linkId);
    }
  };

  useEffect(() => {
    setSelectedLink(undefined);
  }, [open]);

  return (
    <Drawer open={open} anchor="right" sx={styles.container} onClose={toggleMobileDrawer}>
      <Box sx={styles.closeButton}>
        <IconButton onClick={toggleMobileDrawer}>
          <CloseIcon sx={styles.closeIcon} />
        </IconButton>
      </Box>
      <List sx={styles.list}>
        {navbarItems.map((item, index) => {
          listItemCount.current = index;
          return (
            <React.Fragment key={index}>
              <ListItem
                component={RouterLink}
                to={item.link}
                sx={styles.listItem}
                onClick={() => {
                  item?.subLinks && handleOpenSubLinks(item.id);
                  !item?.subLinks && toggleMobileDrawer();
                }}
                className={isItemActive(item.name) ? "active" : ""}
              >
                <ListItemText sx={styles.ListItemText} primary={item.label} />
              </ListItem>
              {item.subLinks && (
                <Collapse in={selectedLink === item.id} timeout="auto" unmountOnExit>
                  <Box sx={styles.subLinksWrapper(item.subLinks.length, selectedLink === item.id)}>
                    {item.subLinks.map((subLink, subIndex) => (
                      <ListItem
                        key={"subLink-" + subIndex}
                        component={RouterLink}
                        to={subLink.link}
                        sx={styles.listItem}
                      >
                        <ListItemText sx={styles.ListItemText} primary={subLink.label} />
                      </ListItem>
                    ))}
                  </Box>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}

        {isLogged ? (
          <Button
            sx={styles.ButtonLogin}
            onClick={handleLogout}
            startIcon={<ExitToApp style={{ fontSize: 22 }} color="#0d2338" />}
          >
            Sair
          </Button>
        ) : (
          <Button
            sx={styles.ButtonLogin}
            onClick={openLoginDialog}
            startIcon={<AccountCircleIcon style={{ fontSize: 25 }} color="#0d2338" />}
          >
            Área do Cliente
          </Button>
        )}
      </List>
    </Drawer>
  );
};

export default MobileNavigation;

const styles = {
  container: {
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      boxSizing: "border-box",
      backgroundColor: "#1341a1",
      color: "white",
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      overflowX: "hidden",
      justifyContent: "center",
    },
  },
  closeButton: {
    width: "100%",
    mr: 4,
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  closeIcon: {
    color: "#FFF",
    fontSize: 40,
  },
  list: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
  },
  listItem: {
    width: "100%",
    padding: "16px 32px",
    display: "grid",
    gap: "6px",
    color: "#fafafa",
    textDecoration: "none",
    textTransform: "capitalize",
    fontWeight: 400,
    position: "relative",

    "&.active": {
      color: "#cf9455",
      fontWeight: 700,
    },
  },
  ListItemText: {
    "&::after": {
      content: '" "',
      position: "absolute",
      left: 0,
      top: "25px",
      width: "100%",
      height: "2px",
      backgroundColor: "white",
      borderRadius: "2px",
      transform: "scaleX(0)",
      transition: "transform 0.2s",
      transformOrigin: "right",
    },
    "&:hover": {
      fontWeight: 700,
      "&::after": {
        transformOrigin: "left",
        transform: "scaleX(1)",
      },
    },
  },
  subLinksWrapper: (numberOfItems, open) => ({
    paddingLeft: "14px",
    height: open ? numberOfItems * 60 : 0,
    overflow: "hidden",
    transition: "all 0.2s ease-in-out",
  }),
  ButtonLogin: {
    borderRadius: "26px",
    background: "#fff", //#0d62a5
    color: "#1341a1",
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 700,
    paddingBlock: "8px",
    paddingInline: "18px",
    display: "flex",
    alignItems: "center",
    marginTop: 3,
    "&:hover": {
      background: "#FDFDFD",
      color: "#1341a1",
    },
  },
};
