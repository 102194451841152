import React from "react";
import { Box, Paper, Typography } from "@mui/material";

export const DescriptionPost = ({ post, handleOnClick }) => {
  const { title, publishDate } = post;

  return (
    <Paper
      elevation={1}
      sx={{
        background: "#e1e1e1",
        borderRadius: 2,
        cursor: "pointer",
        padding: 0,
        "&:hover": {
          transform: "scale(1.03)",
          backgroundColor: "#ededed",
        },
      }}
    >
      <Box sx={{ borderRadius: 2 }} onClick={handleOnClick}>
        {post.videoUrl && (
          <Box sx={styles.videoContainer}>
            <iframe src={post.videoUrl} width="100%" height="100%" />
          </Box>
        )}

        {!post.videoUrl && post.image && (
          <Box component="img" sx={styles.imageContainer} src={post.image} alt="post-image" />
        )}

        <Typography sx={styles.title}>{title}</Typography>
        <Typography sx={styles.date}>
          Publicado: {`${new Date(publishDate).toLocaleDateString()}`}
        </Typography>
      </Box>
    </Paper>
  );
};

const styles = {
  container: {
    margin: "1rem",
  },
  title: {
    color: "#3a3a3a",
    paddingInline: "14px",
    paddingBlock: "12px",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "128.9%",
    cursor: "pointer",
  },
  divider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    inner: {
      width: "80%",
      height: "0.6rem",
      backgroundColor: "#e15b23",
      borderRadius: "1rem",
    },
    span: {
      display: "block",
      width: "20%",
      backgroundColor: "#e15b23",
      height: "2px",
    },
  },
  date: {
    color: "#3a3a3a",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: 300,
    lineHeight: "128.9%",
    paddingInline: "14px",
    paddingBlock: "8px",
  },
  videoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingInline: "2rem",
    borderRadius: "12px 12px 0 0",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    objectFit: "contain",
    borderRadius: "12px 12px 0 0",
  },
};
