import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
// layout
import ReinfDashboardLayout from "~/presentation/layouts/ReinfDashboardLayout";

import Reinf from "~/presentation/views/Reinf/Home/index";
import { ViewDigitalCertificate } from "~/presentation/views/Reinf/DigitalCertificate";
import { SendReinf } from "../views/Reinf/SendReinf";
//R-1000
import { CreateNfse } from "~/presentation/views/Reinf/DocPanel/Create/CreateNfse";
//R-2010
import { ViewHomeNf } from "~/presentation/views/Reinf/DocPanel/Home";
import { PanelR1000 } from "../views/Reinf/Establishments";
import EventsHistory from "../views/Reinf/EventsHistory/EventsHistory";
import BusinessPartner from "~/presentation/views/Reinf/BusinessPartner/BusinessPartner";
import Reports from "~/presentation/views/Reinf/Reports/Reports";

const ReinfRoutes = () => {
  return (
    <Routes>
      <Route element={<ReinfDashboardLayout />}>
        <Route path="/*" element={<Reinf />} />
        {/*Certificado Digital */}
        <Route path="/certificado-digital" element={<ViewDigitalCertificate />} />
        {/*Série R-1000 + Estabelecimentos */}
        <Route path="/estabelecimentos-r1000" element={<PanelR1000 />} />
        {/*Parceiro de negócio*/}
        <Route path="/parceiros-negocio" element={<BusinessPartner />} />
        {/*Painel doc fiscais + importação*/}
        <Route path="/painel-doc-fiscais" element={<ViewHomeNf />} />
        {/*Criar Doc Fiscal*/}
        <Route path="/criar-doc-fiscal" element={<CreateNfse />} />
        {/*Tabela Correção e Envio de Notas + Evetnos enviados*/}
        <Route path="/eventos-efd-reinf" element={<SendReinf />} />
        {/*Relatórios*/}
        <Route path="/relatorios" element={<Reports />} />
        {/*Histórico Eventos 98 99*/}
        <Route path="/historico-eventos" element={<EventsHistory />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ReinfRoutes;
