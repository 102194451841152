import React, { useContext, useState } from "react";
import ModalCreateEdit from "./ModalCreateEdit";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import TableActions from "~/presentation/components/TableActions";
import Table from "~/presentation/views/Reinf/components/Table/Table";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";

function Establishments({
  matrizCompanyId,
  refreshData,
  establishmentsData,
  setEstablishmentsData,
  establishmentsUseCases,
}) {
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //data para modal
  const [establishment, setEstablishment] = useState([]);

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const currentData = establishmentsData.length > 0 ? establishmentsData.reverse() : [];

  //modal edit/creat
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (item) => {
    setEstablishment(item);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async (id) => {
    openDialogBox({
      message: "Deseja eliminar permanentemente este Estabelecimento?",
      callback: () => {
        establishmentsUseCases
          .deleteEstablishmentById(id)
          .then(() => {
            // Remover da tabela
            setEstablishmentsData((prev) => prev.filter((item) => item.id !== id));
            setFeedbackSnackbar({
              isOpen: true,
              message: "Estabelecimento eliminado com sucesso!",
              type: "success",
            });
          })
          .catch((error) => {
            setFeedbackSnackbar({
              isOpen: true,
              message: `Erro ao excluir Estabelecimento: ${error?.message}`,
              type: "error",
            });
          });
      },
    });
  };

  const columns = [
    {
      field: "cnoCnpj",
      headerName: "CNO/CNPJ do Estabelecimento",
    },
    { field: "name", headerName: "Nome do Estabelecimento" },
    {
      field: "codeUG",
      headerName: "Código da Unidade Gestora",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <TableActions
          handleEdit={() => handleOpenModal(params.row)}
          handleDelete={() => handleDelete(params.row.id)}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderSectionPage
        title="Estabelecimentos"
        titleAdd="Incluir"
        handleAdd={handleOpenModal}
        sx={{ backgroundColor: "transparent", paddingInLine: 3, paddingTop: 3, paddingBottom: 0 }}
      />
      <Table data={currentData} columns={columns} />
      <ModalContainer open={openModal} onClose={handleCloseModal}>
        <ModalCreateEdit
          matrizCompanyId={matrizCompanyId}
          data={establishment}
          closeModal={handleCloseModal}
          establishmentsUseCases={establishmentsUseCases}
          refreshData={refreshData}
        />
      </ModalContainer>
    </>
  );
}

export default Establishments;
