import { Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { floatToReais } from "~/presentation/utils/reais-parser";
import {
  Button,
  FormWrapper,
  MoneyQuestion,
  RadioQuestion,
  SelectQuestion,
  SimulatorResultCard,
} from "../../../components";
import { SimulatorContext } from "../../../context/simulator-context";
import {
  getBaseCalculusPatonalContribution,
  getBaseCalculusPrevidenciaryRetention,
  getInssRetentionValue,
  getPatronalContributionValue,
} from "./calculator";
import { reportMapper } from "./reportMapper";

const aliquotPatronalContributionOptions = [
  { label: "20%", value: 0.2 },
  { label: "22,5%", value: 0.225 },
];

const aliquotRetentionOptions = [
  { label: "11%", value: 0.11 },
  { label: "20%", value: 0.2 },
];

const aliquotRetentionVehicleDriverOptions = [
  { label: "13,5% (11% INSS + 2,5% SEST/SENAT)", value: 0.135 },
  { label: "22,5% (20% INSS + 2,5% SEST/SENAT)", value: 0.225 },
];

const initialValues = {
  aliquotPatronalContribution: aliquotPatronalContributionOptions[0].value,
  hasInssFontRetention: "false",
  isVehicleDriverOrAuxiliar: "false",
  isDentist: "false",
  isCPP: "true",
  hasOtherPaymentFont: "false",
  otherPaymentFontValue: 0,
  aliquotRetention: aliquotRetentionOptions[0],
};

export const InssIndividual = () => {
  const resultCardRef = useRef();
  const {
    simulatorValue,
    setInvalidSimulatorValue,
    handleGenerateReport,
    setInssIndividualValue,
    handleCacheReport,
  } = useContext(SimulatorContext);
  const [patronalResult, setPatronalResult] = useState();
  const [inssRetentionResult, setInssRetentionResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState("");

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  useEffect(() => {
    if ((patronalResult || inssRetentionResult) && resultCardRef.current) {
      resultCardRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [patronalResult, inssRetentionResult, resultCardRef]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }

        action.setSubmitting(true);
        setLastSubmitValues(values);

        const patronalResultValue = getPatronalContributionValue(values, simulatorValue);
        setPatronalResult(patronalResultValue);

        const inssRetentionValue = getInssRetentionValue(values, simulatorValue);
        setInssRetentionResult(inssRetentionValue);

        setInssIndividualValue(values.hasInssFontRetention === "true" ? inssRetentionValue : 0);

        action.setSubmitting(false);

        const response = reportMapper(values, patronalResultValue, inssRetentionValue);

        handleCacheReport(response);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <FormWrapper>
            <RadioQuestion
              name="isCPP"
              text="Incide a Contribuição Patronal Previdenciária - CPP?"
              value={values.isCPP}
              firstLabel="Sim"
              firstValue="true"
              secondLabel="Não"
              secondValue="false"
              onChange={(value) => {
                setFieldValue("isCPP", value);
                setFieldValue(
                  "aliquotRetention",
                  values === "true" ? aliquotRetentionOptions[0] : aliquotRetentionOptions[1]
                );
              }}
            />
            {values.isCPP === "false" ? (
              <></>
            ) : (
              <RadioQuestion
                name="aliquotPatronalContribution"
                text="Alíquota da contribuição patronal:"
                excerpt="As alíquotas da contribuição patronal incidentes sobre a base de cálculo são de 20% para as empresas em geral e equiparadas (art. 22, III, da Lei n° 8.212/91); ou 22,5% para as instituições financeiras (art. 22, § 1°, da Lei n° 8.212/91)."
                onChange={(value) =>
                  setFieldValue("aliquotPatronalContribution", parseFloat(value))
                }
                value={values.aliquotPatronalContribution}
                firstLabel={aliquotPatronalContributionOptions[0].label}
                firstValue={aliquotPatronalContributionOptions[0].value}
                secondLabel={aliquotPatronalContributionOptions[1].label}
                secondValue={aliquotPatronalContributionOptions[1].value}
              />
            )}

            <RadioQuestion
              name="hasInssFontRetention"
              text="Tem retenção na fonte de INSS?"
              onChange={(value) => {
                setFieldValue("hasInssFontRetention", value);
                setFieldValue("isVehicleDriverOrAuxiliar", "false");
                setFieldValue("isDentist", "false");
                setFieldValue("hasOtherPaymentFont", "false");
                setFieldValue("otherPaymentFontValue", 0);
              }}
              value={values.hasInssFontRetention}
            />
            {values.hasInssFontRetention === "true" && (
              <>
                <RadioQuestion
                  name="isVehicleDriverOrAuxiliar"
                  text="O prestador é um condutor autônomo de veículo rodoviário ou seu auxiliar?"
                  excerpt="Em se tratando de prestação de servicos por condutor autônomo de veículo rodoviário ou seu auxiliar, a base de cálculo deve ser o equivalente a 20% da remuneração paga ou creditada ao prestador. O art. 201, § 4° do Regulamento da Previdência Social - RPS, aprovado pelo Decreto n° 3.048/99, estabelece que a contribuição patronal também incidirá sobre a mesma base de cálculo."
                  onChange={(value) => {
                    setFieldValue("isVehicleDriverOrAuxiliar", value);
                    if (value === "true") {
                      setFieldValue("isDentist", "false");
                      setFieldValue("aliquotRetention", aliquotRetentionVehicleDriverOptions[0]);
                    } else {
                      setFieldValue("aliquotRetention", aliquotRetentionOptions[0]);
                    }
                  }}
                  value={values.isVehicleDriverOrAuxiliar}
                />
                <RadioQuestion
                  name="isDentist"
                  text="O prestador é odontólogo que executa serviços com fornecimento de material?"
                  excerpt="No pagamento de pessoa jurídica a pessoa física pela prestação de serviços odontológicos, estabelece o art. 178 da IN RFB 2.110/2022 que a base de cálculo deverá corresponder a 60% do montante bruto, quando impossível a segregação do valor do serviço e dos materiais empregados."
                  onChange={(value) => {
                    setFieldValue("isDentist", value);
                    if (value === "true") {
                      setFieldValue("isVehicleDriverOrAuxiliar", "false");
                    }
                  }}
                  value={values.isDentist}
                />
                <RadioQuestion
                  name="hasOtherPaymentFont"
                  text="O prestador informou receber remuneração de outra fonte pagadora na mesma competência?"
                  excerpt="O limite da retenção do INSS dos contribuintes individuais é o teto vigente para o ano corrente. Se alguma outra fonte pagadora já reteve sobre o teto, não deve haver mais nenhuma retenção previdenciária do contribuinte no mês. Caso o pagamento efetuado por outra fonte pagadora não alcance o teto, a base de cálculo será a diferença entre o teto vigente e o que já foi pago."
                  onChange={(value) => {
                    setFieldValue("hasOtherPaymentFont", value);
                    setFieldValue("otherPaymentFontValue", 0);
                  }}
                  value={values.hasOtherPaymentFont}
                />
                {values.hasOtherPaymentFont === "true" && (
                  <MoneyQuestion
                    text="Informar valor da remuneração de outra fonte pagadora:"
                    value={values.otherPaymentFontValue}
                    onChange={(value) => {
                      setFieldValue("otherPaymentFontValue", value);
                    }}
                  />
                )}
                <MoneyQuestion
                  text="Base de cálculo da retenção previdenciária:"
                  value={getBaseCalculusPrevidenciaryRetention(values, simulatorValue)}
                  excerpt="A base de cálculo da retenção previdenciária corresponde ao valor da remuneração bruta do prestador (salvo no caso de condutor autônomo ou odontólogo), observado o limite máximo do salário-de-contribuição."
                  allowNegative
                  disabled
                />
              </>
            )}
            {values.isCPP === "false" ? (
              <></>
            ) : (
              <MoneyQuestion
                text="Base de cálculo da contribuição patronal"
                value={getBaseCalculusPatonalContribution(values, simulatorValue)}
                excerpt="A base de cálculo da contribuicão patronal corresponde ao valor da remuneração do prestador, com exceção dos pagamentos a condutor autônomo ou odontólogo em que a base de cálculo corresponde a 20% ou 60% da remuneração, respectivamente, sem limitação."
                allowNegative
                disabled
              />
            )}

            <SelectQuestion
              text="Alíquota da retenção:"
              excerpt={
                values.isVehicleDriverOrAuxiliar === "true"
                  ? "A alíquota da retenção será de 22,5% para aqueles que prestam serviço exclusivamente a pessoa jurídica isenta das contribuições sociais, nos termos da Lei n° 12.101/2009. Para as empresas em geral, de acordo com o art. 4° da Lei n° 10.666/2003, o tomador deve proceder à retenção com alíquota de 13,5%, em face do que prevê o § 4°, do art. 30 da Lei n° 8.212/91."
                  : "A alíquota da retenção será de 20% para aqueles que prestam serviço exclusivamente a pessoa jurídica isenta das contribuições sociais, nos termos da Lei n° 12.101/2009. Para as empresas em geral, de acordo com o art. 4° da Lei n° 10.666/2003, o tomador deve proceder à retenção com alíquota de 11%, em face do que prevê o § 4°, do art. 30 da Lei n° 8.212/91."
              }
              onChange={(value) => setFieldValue("aliquotRetention", value)}
              value={values.aliquotRetention.value}
              options={
                values.isVehicleDriverOrAuxiliar === "true"
                  ? aliquotRetentionVehicleDriverOptions
                  : aliquotRetentionOptions
              }
            />

            <Button>Ver Resultado</Button>

            {patronalResult && inssRetentionResult && valuesNotChangedSinceLastSubmit(values) ? (
              <div>
                {values.isCPP === "false" ? (
                  <></>
                ) : (
                  <SimulatorResultCard
                    componentRef={resultCardRef}
                    title="Valor da contribuição patronal:"
                    value={floatToReais(patronalResult)}
                    description="O valor da contribuição patronal é aquele correspondente ao resultado da multiplicação da alíquota da contribuicão pela base de cálculo."
                  />
                )}
                {values.hasInssFontRetention === "true" && (
                  <SimulatorResultCard
                    componentRef={resultCardRef}
                    title="Valor da retenção do INSS:"
                    value={floatToReais(inssRetentionResult)}
                    description="O valor da retenção é aquele que deve ser abatido do valor a ser pago para o contratado e corresponde ao resultado da multiplicação da alíquota de retenção pela base de cálculo."
                  />
                )}
              </div>
            ) : null}
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};
