import { styled } from "@mui/material/styles";
import { Pagination as MuiPagination } from "@mui/material";
import { Inbox } from "@mui/icons-material";

export const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
});

export const ItemsWrapper = styled("div")(({ rowGap, maxColumns, columnGap }) => ({
  display: "grid",
  justifyContent: "center",
  gridRowGap:
    rowGap === 1
      ? "max(1vw, 8px)"
      : rowGap === 2
        ? "max(2vw, 16px)"
        : rowGap === 3
          ? "max(3vw, 32px)"
          : "0",
  gridTemplateColumns: "100%",

  "@media screen and (min-width: 600px)": {
    gridTemplateColumns: "70%",
  },

  "@media screen and (min-width: 960px)": {
    gridTemplateColumns: maxColumns ? `repeat(${maxColumns}, 1fr)` : "1fr",
    gridColumnGap:
      columnGap === 1
        ? "max(1vw, 8px)"
        : columnGap === 2
          ? "max(2vw, 16px)"
          : columnGap === 3
            ? "max(4vw, 32px)"
            : "0",
  },
}));

export const Pagination = styled(MuiPagination)({
  marginTop: "30px",
  alignSelf: "center",
  padding: "30px",

  "&.MuiPagination-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiPaginationItem-root": {
      color: "inherit",

      "&.Mui-selected": {
        backgroundColor: "#1341a1",
        color: "#fafafa",
      },
    },
  },
});

export const Loading = styled("div")({
  gridColumn: "1/4",
  minHeight: "80vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const NoPostsFound = styled("div")({
  justifySelf: "center",
  display: "flex",
  alignItems: "center",
  fontSize: "max(18px, 1.4vw)",
  gap: "max(16px, 1.2vw)",
  margin: "max(36px, 5vw) 0",
  padding: "max(80px, 8vw) 0",
  flexFlow: "column",
  textAlign: "center",

  "@media screen and (min-width: 768px)": {
    flexFlow: "row",
  },

  "@media screen and (min-width: 960px)": {
    gridColumn: "1/4",
  },
});

export const InboxIcon = styled(Inbox)({
  "&.MuiSvgIcon-root": {
    fill: "#1341a1",
  },
});
