import React, { useState } from "react";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import PageListHeader from "../../../components/PageListHeader";
import ModalBox from "~/presentation/components/ModalBox/ModalBox";
import Item from "./Item/Item";
import ButtonCommon from "~/presentation/components/Common/Button";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import Input from "~/presentation/components/Common/Input";
import Grid from "@mui/material/Grid2";

const cpomList = [
  {
    id: 0,
    title: "Nenhum",
  },
  {
    id: 1,
    title: "CPOM",
  },
  {
    id: 2,
    title: "CM",
  },
];

const IssAnswerForm = ({
  values,
  onChangeValue,
  handleSubmit,
  errors,
  itemsAnswer,
  groups,
  fetchTypeAnswer,
}) => {
  //Abrir forms criar / editar
  const [openFormItem, setOpenFormItem] = useState(false);
  const [formItemProps, setFormItemProps] = useState(false);

  const handleOpenFormItem = (data = null) => {
    setOpenFormItem(true);
    setFormItemProps(data);
  };

  const handleCloseFormItem = () => {
    setOpenFormItem(false);
    setFormItemProps(null);
  };

  const handleDelete = (id) => {
    if (values?.items?.length === 1) {
      onChangeValue("items", []);
    } else {
      const filtedValue = values?.items?.filter((item) => item.id !== id);
      onChangeValue("items", filtedValue);
    }
  };

  const handleAddNewItem = (item, isCreate) => {
    let updatedItem = [];
    const valuesItems = values?.items;
    if (valuesItems) updatedItem = [...valuesItems];
    if (isCreate) {
      updatedItem = [...updatedItem, item];
    } else {
      const index = updatedItem.indexOf(item);
      updatedItem.splice(index, 1, item);
    }
    onChangeValue("items", updatedItem);
  };

  return (
    <>
      <Box
        sx={{
          margin: 1,
          paddingTop: 1,
          paddingInline: 3,
          backgroundColor: "#E6E6E6",
          borderRadius: "12px",
          "@media (min-width: 900px)": {
            margin: 3,
          },
        }}
      >
        <Typography sx={formStyles.title}>
          Cidade selecionada: <em>{values.cityName}</em>
        </Typography>
      </Box>
      <Box sx={formStyles.container}>
        <Typography sx={formStyles.title}>Resposta</Typography>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Input
              type="select"
              name="cpom"
              title="Tipo de Resposta"
              value={cpomList.find((item) => item.id === values.cpom)}
              onChange={(item) => onChangeValue("cpom", item.id)}
              error={errors.cpom}
              options={cpomList}
              getOptionLabel={(option) => option.title}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }} sx={formStyles.radioContainer}>
            <Typography sx={formStyles.subtitle}>CPOM Dispensado</Typography>
            <FormControl sx={formStyles.controlLabel}>
              <RadioGroup
                name="isDispensedCPOM"
                value={values.isDispensedCPOM}
                row
                onChange={(event) => {
                  const value = event.target.value === "true";
                  onChangeValue("isDispensedCPOM", value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }} sx={formStyles.radioContainer}>
            <Typography sx={formStyles.subtitle}>Legislação</Typography>
            <FormControl sx={formStyles.controlLabel}>
              <RadioGroup
                name="isLegislation"
                aria-label="isLegislation"
                row
                value={values.isLegislation}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  onChangeValue("isLegislation", value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }} sx={formStyles.radioContainer}>
            <Typography sx={formStyles.subtitle}>ERO Dispensado</Typography>
            <FormControl sx={formStyles.controlLabel}>
              <RadioGroup
                value={values.isDispensedEROS}
                name="isDispensedEROS"
                row
                onChange={(event) => {
                  const value = event.target.value === "true";
                  onChangeValue("isDispensedEROS", value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: "auto" }} sx={formStyles.radioContainer}>
            <Typography sx={formStyles.subtitle}>Substituição específica</Typography>
            <FormControl sx={formStyles.controlLabel}>
              <RadioGroup
                name="isSpecificSubstituition"
                row
                value={values.isSpecificSubstituition}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  onChangeValue("isSpecificSubstituition", value);
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio sx={formStyles.icon} />}
                  label="Sim"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio sx={formStyles.icon} />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ ...formStyles.container, border: errors.items && "1px solid #d32f2f" }}>
        <PageListHeader
          title="Itens de resposta"
          titleAdd="Criar nova resposta"
          handleAdd={() => handleOpenFormItem()}
          error={errors.items}
        />
        <Table
          columns={[
            {
              field: "itemTypeName",
              headerName: "Tipo",
              align: "center",
              flex: 2,
            },
            {
              field: "blockCode",
              headerName: "Bloco",
              align: "center",
              flex: 1,
              renderCell: ({ value }) => value || "-",
            },
            {
              field: "servicesCode",
              headerName: "Código de Serviço",
              align: "center",
              flex: 1,
              renderCell: ({ value }) => value || "-",
            },
            {
              field: "isDefault",
              headerName: "Padrão",
              align: "center",
              flex: 1,
              renderCell: ({ value }) => (value ? "Sim" : "Não"),
            },
            {
              field: "actions",
              headerName: "Ações",
              sortable: false,
              filterable: false,
              align: "center",
              flex: 1,
              renderCell: ({ row }) => (
                <TableActions
                  handleEdit={() => handleOpenFormItem(row)}
                  handleDelete={() => handleDelete(row.id)}
                />
              ),
            },
          ]}
          data={values?.items}
        />
        <ModalBox bg="white" open={openFormItem} onClose={handleCloseFormItem}>
          <Item
            onClose={handleCloseFormItem}
            item={formItemProps}
            itemAnswers={itemsAnswer}
            handleAddNewItem={handleAddNewItem}
            groups={groups}
            fetchTypeAnswer={fetchTypeAnswer}
          />
        </ModalBox>
        {errors.items && <Typography sx={formStyles.errorMsg}>{errors.items}</Typography>}
      </Box>

      <Box sx={formStyles.buttonContainer}>
        <ButtonCommon sx={formStyles.button} onClick={() => handleSubmit(values)}>
          Salvar
        </ButtonCommon>
      </Box>
    </>
  );
};

export default IssAnswerForm;
