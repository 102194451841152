import React from "react";
import {Box, Typography} from "@mui/material";
import {InfoIcon} from "~/presentation/views/GTFacil/Search/components/SearchForm/styles";

export const SimulatorResultCard = ({
  title,
  value,
  description,
  componentRef,
  noTitleOrValue = false
}) => {
  return (
      <Box
        ref={componentRef}
        sx={{
          margin: "0.5rem auto",
          width: "75%",
          padding: "1.5rem 1rem",
          borderRadius: "1rem",
          backgroundColor: "#f9f9f9",
        }}
      >
        {noTitleOrValue === false && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              marginBottom: "1rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              {title}
            </Typography>
            <Typography>
              {value}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "0.5rem",
          }}
        >
          <InfoIcon fontSize="small" />
          <Typography
           sx={{
             fontSize: "0.8rem",
           }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
  );
};
