import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box } from "@mui/material";
import { formatDateToTable } from "~/presentation/functions/date";
import CreateEdit from "./CreateEdit/CreateEdit";

const Survey = ({ surveyUseCases }) => {
  const { role } = JSON.parse(localStorage.getItem("GT_user"));
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [surveys, setSurveys] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const apiSurveys = await surveyUseCases.getAll();
    setSurveys(apiSurveys);
    openBackdrop(false);
  };

  const columns = [
    { field: "question", headerName: "Pergunta", flex: 2 },
    {
      field: "publishDate",
      headerName: "Data",
      flex: 1,
      align: "center",
      renderCell: (params) => formatDateToTable(params.value),
    },
    {
      field: "draft",
      headerName: "Rascunho",
      flex: 1,
      renderCell: (params) => (params.value ? "Sim" : "Não"),
      sortable: false,
      align: "center",
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) =>
        role === "superAdmin" ? (
          <TableActions
            handleEdit={() => handleOpenForm(params.row)}
            handleDelete={() => deleteDialog(params.row.id)}
          />
        ) : (
          <TableActions handleEdit={() => handleOpenForm(params.row)} />
        ),
    },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo enquete");
    try {
      await surveyUseCases.delete(id);
      setSurveys((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir enquete: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta enquete?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={fetchData}
          surveyUseCases={surveyUseCases}
        />
      ) : (
        <>
          <PageListHeader title="Enquete" handleAdd={() => handleOpenForm()} />
          <Box sx={{ margin: 3 }}>
            <Table columns={columns} data={surveys} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Survey;
