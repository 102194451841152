import React, { useState } from "react";
import { Drawer, IconButton, CssBaseline, Toolbar, AppBar, Box } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { styled } from "@mui/system";
import useWindowSize from "~/presentation/hooks/useWindowSize";
// components
import DashboardToolbar from "~/presentation/components/DashboardToolbar";
import DashboardDrawer from "~/presentation/components/DashboardDrawer";
import adminMenuOptions from "~/presentation/layouts/adminMenuOptions";
import { Outlet } from "react-router-dom";

const widthToolbar = 265;

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ open }) => ({
  boxShadow: "inset 0px 8px 10px -5px rgba(0,0,0,0.17)",
  width: "100%",
  zIndex: 1201,
  transition: "width 195ms cubic-bezier(0.4, 0.0, 0.6, 1)",
  background: "#001a50",
  "@media (min-width: 600px)": {
    width: open ? `calc(100% - ${widthToolbar}px)` : `calc(100% - 73px)`,
  },
}));

const StyledDrawer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ open }) => ({
  width: widthToolbar,
  transition: "width 225ms cubic-bezier(0.4, 0.0, 0.6, 1)",
  "@media (min-width: 600px)": {
    width: open ? widthToolbar : 73,
  },
}));

const StyledDrawerPaper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ open }) => ({
  boxShadow: "inset 0px 8px 10px -5px rgba(0,0,0,0.17)",
  width: widthToolbar,
  background: "#fff",
  borderRight: "2px solid #e6e6e6",
  height: "100vh",
  whiteSpace: "nowrap",
  overflow: "hidden",
  transition: "width 225ms cubic-bezier(0.4, 0.0, 0.6, 1)",
  "@media (min-width: 600px)": {
    width: open ? widthToolbar : 73,
  },
}));

const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== "open",
})(() => ({
  display: "flex",
  alignItems: "center",
  padding: "0 16px",
  minHeight: 56,
  "@media (min-width: 600px)": {
    minHeight: 64,
  },
}));

const StyledContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(() => ({
  flexGrow: 1,
  backgroundColor: "#ffffff",
  height: "100%",
  color: "#383f48",
  maxWidth: "100vw",
  "@media (min-width: 600px)": {
    maxWidth: `calc(100vw - 73px)`,
  },
}));

const StyledContentChildren = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(() => ({
  overflowX: "auto",
  height: `calc(100% - 56px)`,
  "@media (min-width: 600px)": {
    height: `calc(100% - 64px)`,
  },
}));

const DashboardLayout = ({
  ToolbarComponent = DashboardToolbar,
  DrawerComponent = DashboardDrawer,
  menuOptions = adminMenuOptions,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const { width: windowWidth } = useWindowSize();

  const handleDrawerToggle = () => {
    if (windowWidth > 600) setOpen(!open);
    else setMobileOpen(!mobileOpen);
  };

  const container =
    document.window !== undefined ? () => document.window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex", height: "100%", overflow: "hidden" }}>
        <CssBaseline />
        <StyledAppBar position="fixed" open={open}>
          <StyledToolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ marginRight: 1 }}
            >
              <MenuIcon fontSize="inherit" />
            </IconButton>
            <ToolbarComponent menuItems={menuOptions} />
          </StyledToolbar>
        </StyledAppBar>
        <Box aria-label="mailbox folders">
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Drawer
              container={container}
              variant="temporary"
              anchor={"right"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <StyledDrawerPaper>
                <DrawerComponent
                  open={mobileOpen}
                  setOpen={setMobileOpen}
                  mobile={true}
                  menuItems={menuOptions}
                  enableMouseEvents={false}
                />
              </StyledDrawerPaper>
            </Drawer>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <StyledDrawer variant="permanent" open={open}>
              <StyledDrawerPaper open={open}>
                <DrawerComponent open={open} setOpen={setOpen} menuItems={menuOptions} />
              </StyledDrawerPaper>
            </StyledDrawer>
          </Box>
        </Box>
        <StyledContent>
          <StyledToolbar />
          <StyledContentChildren>
            <Outlet />
          </StyledContentChildren>
        </StyledContent>
      </Box>
    </>
  );
};

export default DashboardLayout;
