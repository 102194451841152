//apenas para ter o último dia do mês
export const getLastDayOfMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

// ⇣⇣⇣ usada para criar o params para o fetch
export const getMonth = (month) => {
  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  return months[month];
};

// ⇣⇣⇣ usada em active filter para mostrar o nome do mês
export const getMonthInPortuguese = (month) => {
  const months = {
    1: "Janeiro",
    2: "Fevereiro",
    3: "Março",
    4: "Abril",
    5: "Maio",
    6: "Junho",
    7: "Julho",
    8: "Agosto",
    9: "Setembro",
    10: "Outubro",
    11: "Novembro",
    12: "Dezembro",
  };
  return months[month];
};

//esta a ser usado nas funções em baixo
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// para fecth "01 Jan 2024" primeiro dia do mes (por exemplo)
export const getDateStart = (date) => {
  if (date) {
    const month = months[date?.getUTCMonth()];
    const year = date?.getUTCFullYear();
    return `1 ${month} ${year}`;
  }
};

// para fecth "31 Jan 2024" ultimo dia do mes (por exemplo)
export const getDateEnd = (date) => {
  const currentMonth = new Date().getMonth() + 1;
  const monthIndex = date?.getUTCMonth();
  if (date) {
    const month = months[monthIndex];
    const year = date?.getUTCFullYear();
    if (monthIndex + 1 === currentMonth) {
      const today = date?.getUTCDate().toString().padStart(2, "0");
      return `${today} ${month} ${year}`;
    }
    const lastDay = new Date(Date.UTC(year, monthIndex + 1, 0)).getUTCDate();
    return `${lastDay} ${month} ${year}`;
  }
};

// para mostrar em filtros ativos "Dezembro, 2023" (por exemplo)
export const getDateToShowFilter = (date) => {
  if (date) {
    const month = getMonthInPortuguese(date?.getUTCMonth() + 1);
    const year = date?.getUTCFullYear();
    return `${month}, ${year}`;
  }
};

//Para as tabelas "yyyy-mm-dd"
export function formatDateToBrazilian(dateStr) {
  if (dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString("pt-BR");
  }
}

//formatando competencia selecionada para mes e ano da API
export const formatDateToISO = (year, month) => {
  // Criando uma nova data em UTC para o primeiro dia do mês
  const date = new Date(Date.UTC(year, month - 1)); // O dia é 1, e UTC garante que não há ajuste de fuso horário.
  return date.toISOString(); // Converte a data para formato ISO
};

//DATE USADO NOS FILTROS (DAR FETCH NFSE)
export const formatDateFilter = (date) => {
  if (date) {
    const day = date?.getUTCDate().toString().padStart(2, "0");
    const month = months[date.getUTCMonth()];
    const year = date?.getUTCFullYear();
    return `${day} ${month} ${year}`;
  }
};
