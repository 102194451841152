import React, { useState } from "react";
import TextEditor from "~/presentation/components/TextEditor";
import { Box, Typography } from "@mui/material";
import { formStyles } from "~/presentation/components/Styles/formStyles";
import ButtonCommon from "~/presentation/components/Common/Button";
import validationSchema from "./validationSchema";

const MessageReply = ({ draft, handleSubmit, handleUpload, message, taxInfo = false }) => {
  let response = draft?.text || message;

  const [values, setValues] = useState({
    response: response ? response : "",
    draft: draft ? true : false,
  });

  const [errors, setErrors] = useState({});

  const handleChangeValue = (field, newValue) => {
    setValues((prev) => ({ ...prev, [field]: newValue }));
    clearError(field);
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleValidation = (draft = false) => {
    let submitValues = { ...values, draft: draft };
    validationSchema
      .validate(submitValues, { abortEarly: false })
      .then(async () => {
        setErrors({});
        submit(submitValues);
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const submit = async (values) => {
    let submitValues = { ...values };
    if (taxInfo) {
      const uploads = await handleUpload(submitValues.files);
      if (!values.files || uploads.length > 0) {
        submitValues = {
          ...submitValues,
          files: uploads.length > 0 ? uploads : null,
        };
        await handleSubmit(submitValues);
      } else {
        await handleSubmit(submitValues);
      }
    }
  };

  return (
    <Box sx={formStyles.container}>
      {taxInfo && (
        <Box sx={{ margin: 2 }}>
          <input
            style={{
              fontFamily: "Montserrat",
              color: "#000",
            }}
            name="file"
            type="file"
            id="file"
            multiple
            onChange={(event) => {
              event.preventDefault();
              handleChangeValue("files", [...event.target.files]);
            }}
          />
          {values.files &&
            values.files.map((file) => <Typography key={file.name}>• {file.name}</Typography>)}
        </Box>
      )}

      <Box sx={{ marginBottom: 2 }}>
        <Typography sx={{ ...formStyles.subtitle, color: errors.response && "#d32f2f" }}>
          Texto
        </Typography>{" "}
        <TextEditor
          initialValue={values.response}
          error={errors.response}
          name="response"
          height={300}
          handleEditorChange={(content) => {
            handleChangeValue("response", content);
          }}
        />
        {errors.response && <Typography sx={formStyles.errorMsg}>{errors.response}</Typography>}
      </Box>

      <Box sx={formStyles.doubleButtonContainer}>
        <ButtonCommon
          sx={formStyles.button}
          color="secondary"
          onClick={() => handleValidation(true)}
        >
          {!taxInfo ? "Salvar rascunho" : "Responder"}
        </ButtonCommon>
        <ButtonCommon sx={formStyles.button} onClick={() => handleValidation(false)}>
          {!taxInfo ? "Enviar" : "Responder e Finalizar"}
        </ButtonCommon>
      </Box>
    </Box>
  );
};

export default MessageReply;
