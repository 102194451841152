import React, { useState } from "react";
import LoginForm from "~/presentation/views/LandingPage/Home/LoginBox/LoginForm";
import ForgetPassForm from "~/presentation/views/LandingPage/Home/LoginBox/ForgetPassForm";
import { Box } from "@mui/material";

const LoginBox = ({ handleClose, navigate, isReLogin = false }) => {
  const [currentModal, setCurrentModal] = useState({
    name: "login",
    content: {
      email: "",
    },
  });

  const handleLoginPages = () => {
    if (currentModal.name === "login") {
      return (
        <LoginForm
          handleClose={handleClose}
          isReLogin={isReLogin}
          navigate={navigate}
          currentModal={currentModal}
          setCurrentModal={setCurrentModal}
        />
      );
    }
    if (currentModal.name === "forget-password") {
      return (
        <ForgetPassForm
          handleClose={handleClose}
          currentModal={currentModal}
          setCurrentModal={setCurrentModal}
        />
      );
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.img} component="img" id="logo" src="/assets/logo_gt.png" alt="company logo" />
      <Box sx={{ display: "flex", flexFlow: "column" }}>{handleLoginPages()}</Box>
    </Box>
  );
};

export default LoginBox;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "38px 26px 44px",
    width: "388px",
    maxWidth: "100%",
    height: "480px",
    "@media screen and (min-width: 425px)": {
      padding: "38px 41px 44px",
    },
  },
  img: {
    width: "176px",
    marginBottom: 3,
  },
};
