import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import {
  makeArticle,
  makeArticlePost,
  makeClientHome,
  makeExplanatoryVideo,
  makeLegislation,
  makeLegislationPost,
  makeMultimediaHome,
  makeMyPanel,
  makeQSO,
} from "~/main/factories/pages/client";

const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={makeClientHome()} />
      {/** legislation routes */}
      <Route path="/conteudo-selecionado/legislacao/" element={makeLegislation()} />
      <Route path="/conteudo-selecionado/legislacao/post/:id" element={makeLegislationPost()} />
      {/** article routes */}
      <Route path="/conteudo-selecionado/artigo/" element={makeArticle()} />
      <Route path="/conteudo-selecionado/artigo/post/:id" element={makeArticlePost()} />
      {/* multimedia routes */}
      <Route path="/conteudo-selecionado/multimidia" element={makeMultimediaHome()} />
      {/* explanatory videos routes */}
      <Route path="/ajuda" element={makeExplanatoryVideo()} />
      {/* qso routes */}
      <Route path="/qso" element={makeQSO()} />
      {/* my panel routes */}
      <Route path="/meu-painel" element={makeMyPanel()} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ClientRoutes;
