import FormikCustomErrorMessage from "~/presentation/components/ErrorMessage/index.jsx";
import { Radio as MuiRadio, FormControlLabel as MuiFormControlLabel, styled } from "@mui/material";

export const Radio = styled(MuiRadio)({
  "&.MuiRadio-root, &.MuiRadio-root.Mui-checked": {
    color: "#383f48",
  },
});

export const FormControlLabel = styled(MuiFormControlLabel)({
  ".MuiTypography-root": {
    fontSize: "0.8rem",
    fontWeight: 300,
    lineHeight: "unset",
  },
});

export const ErrorMessage = styled(FormikCustomErrorMessage)({
  marginLeft: "16px",
});
