import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import {
  deductionOptions,
  formatCpfCnpj,
  getDependentLabelById,
} from "~/presentation/views/Reinf/Utils/utilsReinf";
import Grid from "@mui/material/Grid2";
import {
  Container,
  Typography as Text,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import CloseIcon from "@mui/icons-material/Close";
import { ApiDeductionUseCases } from "~/data/usecases/deductions/apiDeductions";
import { ApiDeductionItemUseCases } from "~/data/usecases/deductions/apiDeductionItem";

export const DeductionModal = ({ payData, dependents, handleClose, initialValue }) => {
  const apiDeduction = new ApiDeductionUseCases(makeHttpClient());
  const apiDeductionItem = new ApiDeductionItemUseCases(makeHttpClient());
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const [values, setValues] = useState({
    id: 0,
    paymentRecordId: payData?.id,
    type: null,
    amount: 0,
    isSupplementarySocialSecurity: false,
    cnpjSocialSecurity: "",
    funprespAmount: 0,
  });

  const [deductionItem, setDeductionItem] = useState({
    id: 0,
    deductionId: 0,
    dependentId: 0,
    amount: 0,
  });
  //se valor inicial, carrega os dados para edição
  useEffect(() => {
    if (initialValue) {
      setValues(initialValue);
    }
  }, [initialValue]);

  const handleChange = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeDedItem = (name, value) => {
    setDeductionItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Informações das deduções...");
    try {
      const response = initialValue
        ? await apiDeduction.updateDeduction(values)
        : await apiDeduction.createDeduction(values);

      if (response?.data?.id) {
        const updatedDeductionItem = { ...deductionItem, deductionId: response?.data?.id };
        try {
          await apiDeductionItem.createDeductionItem(updatedDeductionItem);
          setFeedbackSnackbar({
            isOpen: true,
            message: "Item de dedução atualizado com sucesso",
            type: "success",
          });
        } catch (error) {
          setFeedbackSnackbar({
            isOpen: true,
            message: `Erro item da dedução. ${error?.data?.response}`,
            type: "error",
          });
        }
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: "Dados de dedução salvos com sucesso",
        type: "success",
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro ao atualizar dedução. ${error?.data?.response}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Container style={styles.Form}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 6, sm: 11 }}>
          <Text style={styles.Title}>Deduções</Text>
        </Grid>
        <Grid
          size={{ xs: 6, md: 1 }}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <CloseIcon onClick={handleClose} />
        </Grid>
        <Grid size={{ xs: 12, sm: 8 }}>
          <Input
            type="select"
            title="Tipo de dedução"
            options={deductionOptions}
            placeholder="Selecionar tipo de dedução"
            value={deductionOptions.find((item) => item.id === values.type)}
            getOptionLabel={(option) => `${option.id} - ${option.label}`}
            onChange={(value) => handleChange("type", value.id)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Input
            type="amount"
            title="Valor da dedução"
            value={values?.amount || ""}
            onChange={(e) => handleChange("amount", e)}
          />
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            required
            control={
              <Checkbox
                checked={values?.isSupplementarySocialSecurity || false}
                onChange={(e) => handleChange("isSupplementarySocialSecurity", e.target.checked)}
              />
            }
            label="Possui informações da entidade de previdência complementar?"
          />
          {[2, 3, 4].includes(values.type) && (
            <Typography sx={{ color: "red", fontSize: 13 }}>
              Informação obrigatória e exclusiva para o tipo dedução informado.
            </Typography>
          )}
        </Grid>
        {values?.isSupplementarySocialSecurity === true && (
          <Grid size={12}>
            <Input
              type="mask"
              mask={"00.000.000/0000-00"}
              placeholder="00.000.0000/000-00"
              title="CNPJ da entidade de previdência complementar "
              value={values?.cnpjSocialSecurity}
              onChange={(e) => handleChange("cnpjSocialSecurity", e)}
            />
          </Grid>
        )}
        {values.type === 4 && (
          <Grid size={12}>
            <Input
              title="Valor da contribuição Funpresp "
              type="amount"
              value={values?.funprespAmount}
              onChange={(e) => handleChange("funprespAmount", e)}
            />
          </Grid>
        )}
        {/* itens de dedução */}
        {[5, 7].includes(values.type) && (
          <>
            <Grid item size={{ xs: 12, sm: 7 }}>
              <Input
                type="select"
                title="Dependentes cadastrados"
                options={dependents}
                placeholder="Selecionar o Dependente"
                value={dependents.find((item) => item.id === deductionItem.dependentId)}
                getOptionLabel={(option) =>
                  `${formatCpfCnpj(option.cpf)} - ${getDependentLabelById(option.dependentType)}`
                }
                onChange={(value) => handleChangeDedItem("dependentId", value.id)}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 5 }} >
              <Input
                title="Valor da dedução do rend. tributável"
                type="amount"
                value={deductionItem?.amount}
                onChange={(e) => handleChangeDedItem("amount", e)}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={handleClose}>
          Cancelar
        </RButton>
        <RButton sx={{ width: "150px" }} onClick={handleSubmit}>
          {initialValue ? "Atualizar" : "Adicionar"}
        </RButton>
      </Box>
    </Container>
  );
};

const styles = {
  Form: {
    padding: "2rem",
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  uploadText: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontWeight: "500",
    fontSize: 14,
    cursor: "pointer",
    padding: "6px 16px",
    borderRadius: "6px",
    color: "#fff",
    fontFamily: "Montserrat",
  },
  FileReader: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,

    left: 0,
    fontFamily: "Montserrat",
  },
  text: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Montserrat",
  },
};
