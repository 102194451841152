import * as Yup from "yup";

export default Yup.object({
  gtAnswerName: Yup.string().required("Campo obrigatório!"),
  typeTax: Yup.number().typeError("Selecione um imposto").required("Selecione um imposto"),
  categories: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
        letter: Yup.string(),
      })
    )
    .min(1, "Insira ao menos uma categoria")
    .typeError("Insira ao menos uma categoria")
    .required("Insira ao menos uma categoria"),
  companyTypes: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
      })
    )
    .min(1, "Insira ao menos um tipo de empresa")
    .typeError("Insira ao menos um tipo de empresa")
    .required("Insira ao menos um tipo de empresa"),
  gtAnswerItems: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        text: Yup.string(),
      })
    )
    .min(1, "Insira ao menos um tipo de reposta")
    .typeError("Insira ao menos um tipo de reposta")
    .required("Insira ao menos um tipo de reposta"),
});
