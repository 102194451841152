import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";

// layout
import DashboardLayout from "~/presentation/layouts/DashboardLayout";
// factories
import { makeUserTable } from "~/main/factories/pages/admin/user";
import { makeProfile } from "~/main/factories/pages/admin/profile/makeProfile";
import { makeCompanyTable } from "~/main/factories/pages/admin/company";

import { makeContentTable } from "~/main/factories/pages/admin/content";
import { makeBlogTable } from "~/main/factories/pages/admin/blog";
import { makeBlogCategoryTable } from "~/main/factories/pages/admin/blog-category";
import {
  makeBlogCommentList,
  makeBlogCommentReply,
} from "~/main/factories/pages/admin/blog-comment";
import { makeSurveyTable } from "~/main/factories/pages/admin/survey";
import {
  makeGTFacilAnswers,
  makeGTFacilCNAE,
  makeGTFacilFreeSearch,
} from "~/main/factories/pages/admin/gt-facil";
import { makeDialogTable } from "~/main/factories/pages/admin/gt-facil-dialog";
import {
  makeISSAnswers,
  makeISSCities,
  makeISSStandardAnswers,
  makeUpdateCities,
} from "~/main/factories/pages/admin/iss";
import { makeValueVariableTable } from "~/main/factories/pages/admin/value-variable";
import {
  makeReplyTaxInformation,
  makeTaxInformationList,
} from "~/main/factories/pages/admin/tax-information";
import { makeAdminHome } from "~/main/factories/pages/admin/home/makeAdminHome";
import { makeExplanatoryVideoTable } from "~/main/factories/pages/admin/explanatory-video";
import { makePlanList } from "~/main/factories/pages/admin/plan";
import { makeServiceList } from "~/main/factories/pages/admin/service-code";
import { makeCnaeList } from "~/main/factories/pages/admin/cnae";
import { makeAnswerTable } from "~/main/factories/pages/admin/answers";
import { makeTypesAnswerTable } from "~/main/factories/pages/admin/types-answer";
import { makeItemAnswerTable } from "~/main/factories/pages/admin/item-answers";
import { makeIssAnswerTable } from "~/main/factories/pages/admin/iss-answers";
import { makeAnnotationTable } from "~/main/factories/pages/admin/annotation";
import { makeListTerms } from "~/main/factories/pages/admin/search-term";
import { makeStats } from "~/main/factories/pages/admin/stats/makeStats";
import { makeCompanyCategory } from "~/main/factories/pages/admin/company-category";
import PageListHeader from "../views/Admin/components/PageListHeader";
import makeExplanatoryCategory from "~/main/factories/pages/admin/explanatory-category/makeExplanatoryCategory";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/*" element={makeAdminHome()} />
        {/* empresa */}
        <Route path="/empresa" element={makeCompanyTable()} />
        {/* plans */}
        <Route path="/plano" element={makePlanList()} />
        {/* usuario */}
        <Route path="/usuario" element={makeUserTable()} />
        {/* empresa categoria  */}
        <Route path="/empresa/categoria" element={makeCompanyCategory()} />
        {/* meu perfil */}
        <Route path="/meu-perfil" element={makeProfile()} />
        {/* estatística */}
        <Route
          path="/estatistica"
          element={<PageListHeader title="Mostra estatísticas do site" />}
        />
        <Route path="/estatistica/contagem-de-visualizacoes" element={makeStats()} />
        {/* conteúdo */}
        <Route path="/conteudo" element={makeContentTable()} />
        {/* blog */}
        <Route path="/blog" element={makeBlogTable()} />
        {/* blog/categoria */}
        <Route path="/blog/categoria" element={makeBlogCategoryTable()} />
        {/* comentarios */}
        <Route path="/comentarios" element={makeBlogCommentList()} />
        <Route path="/comentarios/responder/:id" element={makeBlogCommentReply()} />
        {/* enquete */}
        <Route path="/enquetes" element={makeSurveyTable()} />
        {/* GT fácil */}
        <Route path="/gt-facil/importar/pesquisa-livre" element={makeGTFacilFreeSearch()} />
        <Route path="/gt-facil/importar/cnae" element={makeGTFacilCNAE()} />
        <Route path="/gt-facil/importar/respostas" element={makeGTFacilAnswers()} />
        {/* GT fácil/diálogo */}
        <Route path="/gt-facil/dialogo" element={makeDialogTable()} />
        {/* Consulta ISS */}
        <Route path="/consulta-iss/importar/respostas-cadastradas" element={makeISSAnswers()} />
        <Route path="/consulta-iss/importar/respostas-padrao" element={makeISSStandardAnswers} />
        <Route path="/consulta-iss/importar/cidade" element={makeISSCities()} />
        <Route path="/consulta-iss/atualizar-municipios" element={makeUpdateCities()} />
        {/* variáveis de valor */}
        <Route path="/variaveis-de-valor" element={makeValueVariableTable()} />
        {/* informações fiscais */}
        <Route path="/informacoes-fiscais" element={makeTaxInformationList()} />
        <Route path="/informacoes-fiscais/responder/:id" element={makeReplyTaxInformation()} />
        {/* FAQ Explanatory Video */}
        <Route path="/ajuda" element={makeExplanatoryVideoTable()} />
        {/* FAQ Explanatory Video Category */}
        <Route path="/ajuda/categoria" element={makeExplanatoryCategory()} />
        {/* Serviços */}
        <Route path="/servicos" element={makeServiceList()} />
        {/* Cnae */}
        <Route path="/cnaes" element={makeCnaeList()} />
        {/* Respostas-ISS*/}
        <Route path="/respostas/respostas-iss" element={makeIssAnswerTable()} />
        {/* Respostas */}
        <Route path="/respostas" element={makeAnswerTable()} />
        {/* Tipos de resposta */}
        <Route path="/tipos-de-resposta" element={makeTypesAnswerTable()} />
        {/* Itens de resposta */}
        <Route path="/itens-de-resposta" element={makeItemAnswerTable()} />
        {/* Anotaçôes */}
        <Route path="/anotacoes" element={makeAnnotationTable()} />
        {/* SearchTerm */}
        <Route path="/termos-de-pesquisa" element={makeListTerms()} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AdminRoutes;
