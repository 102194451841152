import React from "react";
import { Box } from "@mui/material";

const EmbedVideo = ({ url, title }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 360,
      }}
    >
      <iframe
        src={url}
        allow="autoplay; fullscreen"
        style={{ borderRadius: "10px 10px 0 0", height: "100%", width: "100%" }}
        allowFullScreen
        title={title}
      ></iframe>
    </Box>
  );
};

export default EmbedVideo;
